import React from "react";
import { IWorker } from "../../../core/worker/IWorker";
import AccountDetails from "../workerTabs/AccountDetails";

interface IEditAccountDetailsProps {
  data: IWorker;
  onDataChanged: (newWorkerState: IWorker) => void;
}

const EditAccountDetails: React.FC<IEditAccountDetailsProps> = props => {
  return <AccountDetails {...props} />;
};

export default EditAccountDetails;
