/** @jsx jsx */
import moment from "moment";
import React, { useCallback } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import { TOrderOrder, TOrderSortField } from "../../core/order/ServerDto";
import CheckboxWithLabel from "../components/CheckboxWithLabel";
import DateInput from "../components/DateInput";
import Select, { ISelectOption } from "../components/Select";
import { formMargin } from "../theme/globalStyles";
import { Styles } from "../theme/utils";
import { IInvoiceOrderFilters, IInvoiceOrderSort } from "./types";

interface IInvoiceListControlProps {
  onSelectAllChange: (value: boolean) => void;
  selectAll: boolean;
  filters: IInvoiceOrderFilters;
  onFiltersChange: (newFiltersState: IInvoiceOrderFilters) => void;
  sort: IInvoiceOrderSort;
  onSortChange: (newSortState: IInvoiceOrderSort) => void;
}

const styles: Styles = {
  firstLine: {
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "-8px",
    marginRight: "-8px",
    "& > div": {
      marginTop: "8px",
      marginRight: "8px",
    },
  },
  sortFieldContainer: {
    width: "248px",
  },
  datesContainer: {
    alignItems: "center",
    flex: "1 1 0",
  },
  dateContainer: {
    flexWrap: "nowrap",
    alignItems: "center",
  },
  dateText: {
    flexShrink: 0,
    marginRight: "4px",
  },
  dateSeparator: {
    margin: "0 4px",
    flexShrink: 0,
  },
};

const ORDER_SORT_OPTIONS: ISelectOption[] = [
  { value: "", label: "Sort By" },
  { value: "Status Asc", label: "Status" },
  { value: "Status Desc", label: "Status (reversed)" },
];

const InvoiceListControl: React.FC<IInvoiceListControlProps> = ({
  selectAll,
  onSelectAllChange,
  filters,
  onFiltersChange,
  sort,
  onSortChange,
}) => {
  const handleSelectAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSelectAllChange(event.target.checked);
    },
    [onSelectAllChange],
  );

  const handleDateChange = useCallback(
    (newDate: Date | null, fieldName?: string) => {
      onFiltersChange({
        ...filters,
        [fieldName!]: (newDate && moment(newDate).utc(true).startOf("day").toISOString()) ?? null,
      });
    },
    [filters, onFiltersChange],
  );

  const handleSortChange = useCallback(
    (sortParams: string | null | undefined) => {
      const [sortField, sortOrder] = sortParams?.split(" ");
      onSortChange({
        sortField: sortField as TOrderSortField | "",
        sortOrder: sortOrder as TOrderOrder | "",
      });
    },
    [onSortChange],
  );

  return (
    <Box>
      <Flex sx={styles.firstLine}>
        <Box sx={styles.sortFieldContainer}>
          <Select
            options={ORDER_SORT_OPTIONS}
            value={`${sort.sortField} ${sort.sortOrder}`}
            onChange={handleSortChange}
          />
        </Box>
        <Flex sx={styles.dateContainer}>
          <Text variant="subHeading" sx={styles.dateText}>
            Date Order Taken
          </Text>
          <DateInput
            variant="halfWidth"
            value={filters.dateOrderTakenStart}
            onChange={handleDateChange}
            identifier="dateOrderTakenStart"
          />
          <Text variant="subHeading" sx={styles.dateSeparator}>
            —
          </Text>
          <DateInput
            variant="halfWidth"
            value={filters.dateOrderTakenEnd}
            onChange={handleDateChange}
            identifier="dateOrderTakenEnd"
          />
        </Flex>
        <Flex sx={styles.dateContainer}>
          <Text variant="subHeading" sx={styles.dateText}>
            Date Work to be Done
          </Text>
          <DateInput value={filters.dateWorkToBeDone} onChange={handleDateChange} identifier="dateWorkToBeDone" />
        </Flex>
      </Flex>
      <Box sx={formMargin.row}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">Select All</Text>}
          checked={selectAll}
          onChange={handleSelectAllChange}
        />
      </Box>
    </Box>
  );
};

export default InvoiceListControl;
