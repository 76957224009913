import React from "react";
import CustomerEditor, { ICustomerEditorProps, ICustomerEditorTabInfo } from "../components/CustomerEditor";
import Contacts from "../tabs/Contacts";
import Details from "../tabs/Details";
import NewCustomerAccountDetails from "../tabs/NewCustomerAccountDetails";
import Packages from "../tabs/Packages";

type ICreateCustomerEditorProps = Omit<ICustomerEditorProps<TCreateCustomerTab>, "tabs">;

const TABS = {
  Details: {
    id: "Details",
    title: "Details",
    component: Details,
  } as ICustomerEditorTabInfo,
  AccountDetails: {
    id: "AccountDetails",
    title: "Account Details",
    component: NewCustomerAccountDetails,
  } as ICustomerEditorTabInfo,
  Contacts: {
    id: "Contacts",
    title: "Contacts",
    component: Contacts,
  } as ICustomerEditorTabInfo,
  SelectPackage: {
    id: "SelectPackage",
    title: "Select Package",
    component: Packages,
  } as ICustomerEditorTabInfo,
};

export type TCreateCustomerTab = keyof typeof TABS;

const CreateCustomerEditor: React.FC<ICreateCustomerEditorProps> = props => {
  return <CustomerEditor tabs={TABS} {...props} />;
};

export default CreateCustomerEditor;
