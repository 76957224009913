/** @jsx jsx */
import React, { useCallback } from "react";
import { Flex, jsx, Box } from "theme-ui";
import { toBase64 } from "../../core/common/converter";
import { IUser } from "../../core/user/IUser";
import InputField from "../components/InputField";
import PasswordField, { IPasswords } from "../components/PasswordField";
import PhoneInputField from "../components/PhoneInputField";
import UserLogo from "../components/UserLogo";
import { formMargin } from "../theme/globalStyles";

interface IUserEditorProps {
  user: IUser;
  onUserChange: (newUserState: IUser) => void;
}

const UserEditor: React.FC<IUserEditorProps> = ({ user, onUserChange }) => {
  const handleLogoChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const fieldName = event.currentTarget.dataset.fieldName || "";
      const files = event.currentTarget.files || [];
      if (files.length > 0) {
        const value = files[0];

        const base64Name = `${fieldName}Base64`;
        const base64Value = await toBase64(value);

        onUserChange({
          ...user,
          [fieldName]: value,
          [base64Name]: base64Value,
        });
      }
    },
    [onUserChange, user],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      onUserChange({
        ...user,
        [fieldName!]: value,
      });
    },
    [onUserChange, user],
  );

  const handlePhoneChange = useCallback(
    (value: string) => {
      onUserChange({
        ...user,
        PhoneNumber: value,
      });
    },
    [onUserChange, user],
  );

  const handlePasswordsChange = useCallback(
    (passwords: IPasswords) => {
      onUserChange({
        ...user,
        ...passwords,
      });
    },
    [onUserChange, user],
  );

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Box>
        <UserLogo
          onChange={handleLogoChange}
          value={user.AvatarFileBase64 ?? user.AvatarThumb}
          fieldName="AvatarFile"
        />
      </Box>

      <Flex sx={formMargin.group}>
        <InputField label="Login" value={user.UserName ?? ""} onChange={handleInputChange} data-field-name="UserName" />
      </Flex>

      <Flex sx={formMargin.group}>
        <InputField
          label="First Name"
          value={user.FirstName ?? ""}
          onChange={handleInputChange}
          data-field-name="FirstName"
        />
        <InputField
          sx={formMargin.rowElement}
          label="Last Name"
          value={user.LastName ?? ""}
          onChange={handleInputChange}
          data-field-name="LastName"
        />
        <InputField
          sx={formMargin.rowElement}
          label="Email Address"
          value={user.Email ?? ""}
          onChange={handleInputChange}
          data-field-name="Email"
        />
      </Flex>

      <Flex sx={formMargin.group}>
        <PhoneInputField label="Account Phone Number" value={user.PhoneNumber ?? ""} onChange={handlePhoneChange} />
      </Flex>

      <Box sx={formMargin.group}>
        <PasswordField
          passwords={{
            OldPassword: user.OldPassword ?? "",
            Password: user.Password ?? "",
            ConfirmPassword: user.ConfirmPassword ?? "",
          }}
          onChange={handlePasswordsChange}
        />
      </Box>
    </Flex>
  );
};

export default UserEditor;
