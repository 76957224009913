/** @jsx jsx */
import React, { useCallback } from "react";
import { Flex, jsx, SxStyleProp } from "theme-ui";
import { clone, merge } from "lodash";
import { EMPTY_NOTE } from "../../../core/note/consts";
import { INoteFromServer } from "../../../core/note/ServerDto";
import { IWorker } from "../../../core/worker/IWorker";
import AddButton from "../../components/AddButton";
import CardList from "../../components/CardList";
import NoteCard from "../../notes/NoteCard";

interface INotesProps {
  data: IWorker;
  onDataChanged: (newWorkerState: IWorker) => void;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
    marginTop: "24px",
  },
  cardList: {
    flexGrow: 1,
    marginTop: "16px",
    width: "100%",
  },
};

const Notes: React.FC<INotesProps> = ({ data, onDataChanged }) => {
  const notes = data.Notes;

  const handleAddNoteClick = useCallback(() => {
    onDataChanged({
      ...data,
      Notes: notes.concat([clone(EMPTY_NOTE)]),
    });
  }, [data, notes, onDataChanged]);

  const handleNoteChanged = useCallback(
    (changes: Partial<INoteFromServer>, index: number) => {
      const changedNote = merge({}, notes[index], changes);
      const newNotes = clone(notes);
      newNotes[index] = changedNote;
      onDataChanged({
        ...data,
        Notes: newNotes,
      });
    },
    [data, notes, onDataChanged],
  );

  return (
    <Flex sx={styles.root}>
      <AddButton title="Add Note" onClick={handleAddNoteClick} />
      <CardList
        sx={styles.cardList}
        cards={notes.map((note, i) => (
          <NoteCard key={note.NoteId ?? `__${i}`} note={note} onChanged={handleNoteChanged} position={i} />
        ))}
      />
    </Flex>
  );
};

export default Notes;
