/** @jsx jsx */
import { noop } from "lodash";
import React, { useCallback } from "react";
import { jsx, Flex } from "theme-ui";
import { ICustomer } from "../../../../core/customer/ICustomer";
import InputField from "../../../components/InputField";
import { formMargin } from "../../../theme/globalStyles";

interface IInvoiceDataProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const InvoiceData: React.FC<IInvoiceDataProps> = ({ customer, onChanged = noop }) => {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      onChanged({
        Details: {
          [fieldName!]: value,
        },
      });
    },
    [onChanged],
  );

  const details = customer.Details;

  return (
    <Flex sx={{ marginTop: "48px" }}>
      <InputField
        label="First Name"
        value={details.InvoiceFirstName ?? ""}
        data-field-name="InvoiceFirstName"
        onChange={handleInputChange}
      />
      <InputField
        sx={formMargin.rowElement}
        label="Last Name"
        value={details.InvoiceLastName ?? ""}
        data-field-name="InvoiceLastName"
        onChange={handleInputChange}
      />
      <InputField
        sx={formMargin.rowElement}
        label="Email Address"
        type="email"
        value={details.InvoiceEmail ?? ""}
        data-field-name="InvoiceEmail"
        onChange={handleInputChange}
      />
    </Flex>
  );
};

export default InvoiceData;
