import "roboto-fontface";
import "rc-steps/assets/index.css";
import "./AppUI.css";
import React from "react";
import { hot } from "react-hot-loader/root";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import { ThemeProvider as ThemeUiThemeProvider } from "theme-ui";
import { HashRouter } from "react-router-dom";
import { store } from "../store/store";
import Layout from "./layout/Layout";
import Pages from "./layout/Pages";
import appTheme, { appThemeV2 } from "./theme/theme";

function AppUI() {
  return (
    <HashRouter>
      <ThemeUiThemeProvider theme={appThemeV2}>
        <ThemeProvider theme={appTheme}>
          <Provider store={store}>
            <CssBaseline />
            <Layout page={<Pages />} />
          </Provider>
        </ThemeProvider>
      </ThemeUiThemeProvider>
    </HashRouter>
  );
}

export default hot(AppUI);
