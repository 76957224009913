/** @jsx jsx */
import React from "react";
import { jsx, Flex, Box, Heading } from "theme-ui";
import Button from "../components/Button";
import { Styles } from "../theme/utils";

interface IUserHeaderProps {
  onSaveChangesClick: () => void;
}

const styles: Styles = {
  root: {
    justifyContent: "space-between",
  },
  arrowContainer: {
    alignItems: "center",
  },
  heading: {
    height: "32px",
    alignItems: "center",
  },
};

const UserHeader: React.FC<IUserHeaderProps> = ({ onSaveChangesClick }) => {
  return (
    <Flex sx={styles.root}>
      <Flex sx={styles.heading}>
        <Heading>Edit properties</Heading>
      </Flex>
      <Box>
        <Button variant="primaryOutline" title="Save changes" onClick={onSaveChangesClick} />
      </Box>
    </Flex>
  );
};

export default UserHeader;
