import React from "react";
import createIcon from "./createIcon";

const PersonIcon = createIcon(
  <>
    <defs>
      <mask id="person">
        <rect x="0" y="0" width="36" height="36" fill="#FFFFFF" />
        <path
          d="M7 31C7 27 12.875 25.0208 15.5 25.0208C15.5 22.2515 15.95 19.6216 14.0375 18.6408C12.8948 18.0548 13.0842 17.2182 12.8358 16C12.1715 16 11 15 11 13.5C11 12.3 11.6851 11.5 12.2776 11.5C12.2924 7.35421 14.964 5 18 5C21.0341 5 23.7043 7.35919 23.7224 11.5C24.3149 11.5 25 12.3 25 13.5C25 15 24.063 16 23.3988 16C23.3187 17.2169 23.1014 18.0568 21.9625 18.6408C20.05 19.6216 20.5 21.3284 20.5 25.0208C23.125 25.0208 29 27 29 31"
          strokeWidth="2"
          stroke="#000000"
          fill="none"
        />
      </mask>
    </defs>
    <circle cx="18" cy="18" r="18" mask="url(#person)" />
  </>,
  36,
  36,
  "currentColor",
);

export default PersonIcon;
