import { Id, IDtoObject } from "../common/type";

export interface IUser extends IDtoObject {
    Id: Id | null;
    UserName: string | null;
    Email: string | null;
    DaysToPayInvoices?: number | null;
    OldPassword: string | null;
    Password: string | null;
    ConfirmPassword: string | null;
    SendEmailNotification?: boolean | null;
    IsActive?: boolean | null;
    FirstName: string | null;
    LastName: string | null;
    PhoneNumber: string | null;
    InOfficeStaff?: boolean | null;
    IsAdmin?: boolean | null;
    CustomerType?: CustomerType | null;
    AccountType?: AccountType | null;
    IsAppliedForBusiness: boolean | null;
    AvatarFile?: File | null;
    AvatarFileBase64?: string | null;
    Avatar: string | null;
    AvatarThumb: string | null;
}

export enum CustomerType {
    Business,
    Personal,
}

export enum AccountType {
    Customer,
    Worker,
    SystemUser,
}
