import React, { useState } from "react";
import googleConfig from "../../config/google.config";
import useGoogleMaps from "./useGoogleMaps";

const uluru = { lat: 39.8097343, lng: -98.5556199 };
interface Props {
  getAdress: (result: string) => void;
  placeMarker: any;
  inputAutocomplete?: HTMLInputElement;
}

const GoogleMaps = React.memo(function Map({ getAdress, placeMarker, inputAutocomplete }: Props) {
  const { ref, map, google, geocoder, infowindow } = useGoogleMaps(googleConfig.GOOGLE_MAP_API_KEY, {
    zoom: 4,
    center: uluru,
  });

  if (inputAutocomplete && google) {
    const autocomplete = new (google as any).maps.places.Autocomplete(inputAutocomplete);
  }
  const [marker, setMarker] = useState(null as any);

  if (map && geocoder && infowindow) {
    if (placeMarker) {
      mapMarker(map!, placeMarker);
    }
    (map as any).addListener("click", (event: any) => {
      addMarker(event.latLng);
    });
  }

  function mapMarker(resultsMap: google.maps.Map, address: any) {
    (geocoder as any).geocode({ address }, (results: any, status: any) => {
      if (status === "OK") {
        if (results[0]) {
          (map as any).setZoom(11);
          resultsMap.setCenter(results[0].geometry.location);
          const newMarker = new (google as any).maps.Marker({
            position: results[0].geometry.location,
            map,
          });
          setMarker(newMarker);
          (infowindow as any).setContent(results[0].formatted_address);
          (infowindow as any).open(map, marker);
        } else {
          window.alert("No results found");
        }
      }
    });
  }

  function addMarker(location: any) {
    if (marker) {
      marker.setMap(null);
    }
    if (!map) {
      return;
    }
    (geocoder as any).geocode({ location }, (results: any, status: any) => {
      if (status === "OK") {
        if (results[0]) {
          getAdress(results[0].formatted_address);

          const newMarker = new (google as any).maps.Marker({
            position: location,
            map,
          });
          setMarker(newMarker);
        } else {
          window.alert("No results found");
        }
      }
    });
  }

  return (
    <div style={{ width: "100%" }}>
      <div
        ref={ref}
        style={{
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "15px",
          height: "250px",
          width: "auto",
        }}
      />
    </div>
  );
});

export default GoogleMaps;
