/** @jsx jsx */
import moment from "moment";
import { noop } from "lodash";
import React, { useCallback, useState } from "react";
import { Text, Flex, jsx, Card } from "theme-ui";
import { INote } from "../../../../core/order/IDetailedOrder";
import { useAppSelector } from "../../../../store/store";
import AddButton from "../../../components/AddButton";
import TextAreaField from "../../../components/TextAreaField";
import { Styles } from "../../../theme/utils";

interface IOrderNotesProps {
  className?: string;
  notes: Readonly<INote[]>;
  onOrderNotesChanged: (newOrderNotesState: INote[]) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
  },
  note: {
    marginLeft: "32px",
    marginBottom: "16px",
  },
  noteInfo: {
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "8px",
  },
  noteContent: {
    marginLeft: "16px",
    flexGrow: 1,
  },
};

const OrderNotes: React.FC<IOrderNotesProps> = ({ className, onOrderNotesChanged, notes = [] }) => {
  const [showNewNote, setShowNewNote] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState("");

  const handleAddClick = useCallback(() => {
    setShowNewNote(true);
  }, []);

  const handleNewNoteChange = useCallback((newContent: string) => {
    setNewNoteContent(newContent);
  }, []);

  const handleCancelNewNote = useCallback(() => {
    setNewNoteContent("");
    setShowNewNote(false);
  }, []);

  const user = useAppSelector(store => store.user);

  const handleSaveNewNote = useCallback(() => {
    const newNote: INote = {
      NoteId: null,
      CreatedDateTime: new Date().toISOString(),
      Content: newNoteContent,
      OwnerId: user.Id,
      OwnerName: user.DisplayName,
    };
    onOrderNotesChanged(notes.concat([newNote]));

    setNewNoteContent("");
    setShowNewNote(false);
  }, [newNoteContent, notes, onOrderNotesChanged, user.DisplayName, user.Id]);

  return (
    <Flex sx={styles.root} className={className}>
      <Flex>
        <AddButton title="Add Note" onClick={handleAddClick} />
      </Flex>
      {showNewNote && (
        <OrderNote
          date={new Date().toISOString()}
          userName={user.DisplayName ?? ""}
          content={newNoteContent}
          onChange={handleNewNoteChange}
          onCancelRequest={handleCancelNewNote}
          onSaveRequest={handleSaveNewNote}
        />
      )}
      {notes.map((note, i) => (
        <OrderNote
          /* eslint-disable-next-line react/no-array-index-key */
          key={`${note.NoteId}-${i}`}
          disabled
          date={note.CreatedDateTime ?? ""}
          userName={note.OwnerName ?? ""}
          content={note.Content ?? ""}
        />
      ))}
    </Flex>
  );
};

interface IOrderNoteProps {
  disabled?: boolean;
  date: string;
  userName: string;
  content: string;
  onChange?: (newContent: string) => void;
  onSaveRequest?: () => void;
  onCancelRequest?: () => void;
}

const OrderNote: React.FC<IOrderNoteProps> = ({
  disabled,
  date,
  userName,
  content,
  onChange = noop,
  onCancelRequest = noop,
  onSaveRequest = noop,
}) => {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        onCancelRequest();
      }
      if (event.key === "Enter") {
        onSaveRequest();
      }
    },
    [onCancelRequest, onSaveRequest],
  );

  return (
    <Flex sx={styles.note}>
      <Flex sx={styles.noteInfo}>
        <Text variant="subHeadingSecondaryText">{moment(date).format("DD MMM YYYY")}</Text>
        <Text variant="subHeading">{userName}</Text>
      </Flex>
      {disabled ? (
        <Card sx={styles.noteContent} variant="note">
          <Text sx={styles.noteContent} variant="subHeading">
            {content}
          </Text>
        </Card>
      ) : (
        <TextAreaField
          sx={styles.noteContent}
          rows={3}
          value={content}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
      )}
    </Flex>
  );
};

export default OrderNotes;
