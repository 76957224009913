import { store } from "../../store/store";
import orderRepository from "./OrderRepository";
import { setUnmaintainedOrdersCount } from "../../store/order/unmaintainedOrders";

class UnmaintainedOrdersService {
    async updateCount() {
        const count = await orderRepository.getUnmaintainedCount();
        store.dispatch(setUnmaintainedOrdersCount(count));
    }
}

const unmaintainedOrdersService = new UnmaintainedOrdersService();
export default unmaintainedOrdersService;
