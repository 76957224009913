import config from "../../config/config";
import httpService from "../http/HttpService";

const XERO_PATH = `${config.apiUrl}/xero-oauth`;

class XeroRepository {
    XERO_PATH = XERO_PATH;

    async authRedirection(callbackUri: string, state?: string): Promise<string> {
        const path = `${XERO_PATH}/auth-url?callbackUri=${callbackUri}&state=${state}`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as string;
    }

    async connectXero(code: string, callbackUri: string): Promise<string> {
        const path = `${XERO_PATH}/connect`;
        const sData = {
            code,
            callbackUri,
        };
        const response = await httpService.post(path, sData);
        if (response.error) return Promise.reject(response.message);
        return response.response as string;
    }

    async xeroStatus(): Promise<boolean> {
        const path = `${XERO_PATH}/status`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as boolean;
    }
}

const xeroRepository = new XeroRepository();
export default xeroRepository;
