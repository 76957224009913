import { useState, useEffect, useRef } from "react";
import useGoogleMapsApi from "./useGoogleMapsApi";

function useGoogleMaps(apiKey: string, options: any) {
  const google = useGoogleMapsApi(apiKey);
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState();
  const [geocoder, setGeocoder] = useState();
  const [infowindow, setInfowindow] = useState();

  useEffect(() => {
    if (!google || !ref) {
      return;
    }
    setMap(new (google as any).maps.Map(ref.current, options));
    setGeocoder(new (google as any).maps.Geocoder());
    setInfowindow(new (google as any).maps.InfoWindow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [google, ref]);

  return { ref, map, google, geocoder, infowindow };
}

export default useGoogleMaps;
