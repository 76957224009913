import { DeepNullable, Id, IDtoObject } from "../common/type";
import { IAccountDetailsFromServer } from "../customer/ServerDto";
import { INoteFromServer } from "../note/ServerDto";
import { IUserFromServer } from "../user/ServerDto";
import {
    ICommonOrderDataToServer,
    IOrderContactToServer,
    IOrderNoteToServer,
    IUserAddressToServer,
    PropertySize,
    ServiceType,
} from "./ServerDto";

export interface IDetailedOrder extends IDtoObject {
    PropertyAddress: IUserAddress | null;
    Contacts: IOrderContact[] | null;
    Common: ICommonOrderData | null;
    Keys: boolean | null;
    KeysWith: string | null;
    KeysWithDetail: string | null;
    KeysTimeOption: string | null;
    KeysTime: string | null;
    Notes: string | null;
    SizeProperty: PropertySize | null;
    OrderNotes: IOrderNote[] | null;
    CustomerId: Id | null;
    OrderState: OrderState | null;
    Services: IOrderService[];
    OfferedServices: IOfferedService[];
    OrderPrice: number | null;
    Description: string | null;
    CreatedDateTime: string | null;
    UpsellPrice: number | null;
    DefaultAmount: number | null;
    TrustedCustomer: boolean | null;
    Maintainer: IUserFromServer | null;
    OrderId: number | null;
    HasRejections: boolean | null;
    BranchId: number | null;
    CompanyName: string | null;
    AccountDetails: IAccountDetailsFromServer;
    CustomerUserId: Id | null;
    Address?: string | null;
}

export type IUserAddress = DeepNullable<IUserAddressToServer>;

export type IOrderContact = DeepNullable<IOrderContactToServer>;

export type ICommonOrderData = DeepNullable<ICommonOrderDataToServer>;

export type IOrderNote = DeepNullable<IOrderNoteToServer>;

export enum OrderState {
    DateAgreement = -1,
    BeingVerified = 0,
    BeingExecuted = 1,
    WorkDone,
    ContentSelected,
    AwaitingForAdditionalPayment,
    OrderPaid,
    ReturnForAdditionalWork,
}

export interface IOrderService extends IDtoObject {
    OrderServiceId: Id | null;
    CreatedDateTime: string | null;
    AmendedDateTime: string | null;
    Type: ServiceType | null;
    Price: number | null;
    Name: string | null;
    ApplicationUserId: Id | null;
    ApplicationUserName: string | null;
    AppointmentDate: string | null;
    AppointmentDuration: string | null;
    Description: string | null;
    NotificationMessage: string | null;
    ShowConfirm: boolean | null;
    ShowSendMessage: boolean | null;
    Complete: boolean | null;
    Files: number | null;
    UploadFiles: IOrderUpload[] | null;
    SourceFiles: ISelectedFile[] | null;
    Amount: number | null;
    ImageURL: string | null;
    ExternalLink: string | null;
    Label: string | null;
    Rejected: boolean | null;
    RejectReason: string | null;
    IsExtraService: boolean | null;
    ServiceAdditionalFields: IServiceAdditionalFields | null;
}

export interface IServiceAdditionalFields extends IDtoObject {
    ServiceAdditionalFieldsId: Id | null;
    Address: string | null;
    Email: string | null;
    Phone: string | null;
    Style: number | null;
}

export interface IOrderUpload extends IDtoObject {
    ThumbURL: string | null;
    FullURL: string | null;
    IsUpsell: boolean | null;
    UploadFileId: Id;
    UpsellPrice: number | null;
    FileStatus: UploadFileStatus | null;
    FilePaymentStatus: ServicePaymentStatus | null;
}

export enum UploadFileStatus {
    Unconfirmed,
    Confirmed,
    Paid,
    ExUpsell,
}

export enum ServicePaymentStatus {
    NotPaid,
    InProcess,
    Paid,
}

export interface ISelectedFile extends IDtoObject {
    UploadFileId: Id | null;
    Index?: number | null;
}

export interface IOfferedService extends IDtoObject {
    OfferedServiceId: Id;
    CreatedDateTime: string;
    AmendedDateTime: string;
    Type: ServiceType;
    Price: number;
    Name: string;
    Files: number;
    UploadFiles: IOrderUpload[];
    ImageURL: string;
}

export type INote = INoteFromServer;
