/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Text } from "theme-ui";
import { noop } from "lodash";

interface IAmountInputProps {
  className?: string;
  onDecreaseClick?: (id: string) => void;
  onIncreaseClick?: (id: string) => void;
  value: number;
  id?: string;
}

const AmountInput: React.FC<IAmountInputProps> = ({
  className,
  onDecreaseClick = noop,
  onIncreaseClick = noop,
  value,
  id,
}) => {
  const handleIncreaseClick = useCallback(() => {
    onIncreaseClick(id);
  }, [id, onIncreaseClick]);

  const handleDecreaseClick = useCallback(() => {
    onDecreaseClick(id);
  }, [id, onDecreaseClick]);

  return (
    <Flex className={className}>
      <Text
        variant="default"
        sx={{ width: "20px", textAlign: "left", cursor: "pointer", userSelect: "none" }}
        onClick={handleDecreaseClick}
      >
        -
      </Text>
      <Text variant="default">{value}</Text>
      <Text
        variant="default"
        sx={{ width: "20px", textAlign: "right", cursor: "pointer", userSelect: "none" }}
        onClick={handleIncreaseClick}
      >
        +
      </Text>
    </Flex>
  );
};

export default AmountInput;
