/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { jsx, Input, Flex } from "theme-ui";
import { noop, merge } from "lodash";
import SearchIcon from "../icons/Search";
import { Styles } from "../theme/utils";

interface ISearchInputProps {
  className?: string;
  placeholder?: string;
  onSearch?: (value: string) => void;
}

const styles: Styles = {
  root: {
    height: "32px",
    border: "1px solid",
    borderRadius: "16px",
    borderColor: "secondaryInputBorder",
    alignItems: "center",
    flexBasis: "420px",
  },
  rootFocused: {
    borderColor: "inputBorder",
  },
  input: {
    marginLeft: "8px",
    flexGrow: 1,
  },
  icon: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
    marginRight: "7px",
    color: "inputBorder",
    "&:hover": {
      color: "inputColor",
    },
  },
};

const SearchInput: React.FC<ISearchInputProps> = ({ className, placeholder, onSearch = noop }) => {
  const [currentValue, setCurrentValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        onSearch(currentValue);
      }
      if (event.key === "Escape") {
        setCurrentValue("");
        onSearch("");
      }
    },
    [onSearch, currentValue],
  );

  const handleBlur = useCallback(() => {
    onSearch(currentValue);
    setIsFocused(false);
  }, [currentValue, onSearch]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(event.target.value);
  }, []);

  const handleSearchClick = useCallback(() => {
    onSearch(currentValue);
  }, [onSearch, currentValue]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <Flex sx={merge({}, styles.root, isFocused ? styles.rootFocused : {})} className={className}>
      <Input
        sx={styles.input}
        placeholder={placeholder}
        variant="forms.secondarySearch"
        onKeyDown={handleKeyDown}
        value={currentValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <SearchIcon sx={styles.icon} onClick={handleSearchClick} />
    </Flex>
  );
};

export default SearchInput;
