import React from "react";
import createIcon from "./createIcon";

const XeroStatusIcon = createIcon(
  <path
    d="M4.83008 4.05371L6.36377 1.60156H7.35693L5.33545 4.77441L7.40527 8H6.40332L4.83008 5.50391L3.24805 8H2.25049L4.32471 4.77441L2.29883 1.60156H3.2876L4.83008 4.05371ZM12.002 5.04248H9.229V7.31006H12.4502V8H8.38525V1.60156H12.4062V2.2959H9.229V4.35254H12.002V5.04248ZM15.8472 5.41162H14.3442V8H13.4961V1.60156H15.6143C16.335 1.60156 16.8887 1.76562 17.2754 2.09375C17.665 2.42188 17.8599 2.89941 17.8599 3.52637C17.8599 3.9248 17.7515 4.27197 17.5347 4.56787C17.3208 4.86377 17.022 5.08496 16.6382 5.23145L18.1411 7.94727V8H17.2358L15.8472 5.41162ZM14.3442 4.72168H15.6406C16.0596 4.72168 16.3921 4.61328 16.6382 4.39648C16.8872 4.17969 17.0117 3.88965 17.0117 3.52637C17.0117 3.13086 16.8931 2.82764 16.6558 2.6167C16.4214 2.40576 16.0815 2.29883 15.6362 2.2959H14.3442V4.72168ZM23.9683 5.00732C23.9683 5.63428 23.8628 6.18213 23.6519 6.65088C23.4409 7.1167 23.1421 7.47266 22.7554 7.71875C22.3687 7.96484 21.9175 8.08789 21.4019 8.08789C20.8979 8.08789 20.4512 7.96484 20.0615 7.71875C19.6719 7.46973 19.3687 7.1167 19.1519 6.65967C18.938 6.19971 18.8281 5.66797 18.8223 5.06445V4.60303C18.8223 3.98779 18.9292 3.44434 19.1431 2.97266C19.3569 2.50098 19.6587 2.14063 20.0483 1.8916C20.4409 1.63965 20.8892 1.51367 21.3931 1.51367C21.9058 1.51367 22.3569 1.63818 22.7466 1.88721C23.1392 2.1333 23.4409 2.49219 23.6519 2.96387C23.8628 3.43262 23.9683 3.979 23.9683 4.60303V5.00732ZM23.1289 4.59424C23.1289 3.83545 22.9766 3.25391 22.6719 2.84961C22.3672 2.44238 21.9409 2.23877 21.3931 2.23877C20.8599 2.23877 20.4395 2.44238 20.1318 2.84961C19.8271 3.25391 19.6704 3.81641 19.6616 4.53711V5.00732C19.6616 5.74268 19.8154 6.32129 20.123 6.74316C20.4336 7.16211 20.8599 7.37158 21.4019 7.37158C21.9468 7.37158 22.3687 7.17383 22.6675 6.77832C22.9663 6.37988 23.1201 5.81006 23.1289 5.06885V4.59424Z"
    fill="white"
  />,
  26,
  10,
  "none",
  "white",
);

export default XeroStatusIcon;
