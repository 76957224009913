import config from "../../config/config";
import { Id, IDtoObject, TPrimitiveType } from "../common/type";
import httpService from "../http/HttpService";
import { IListOrder } from "../order/IListOrder";

const INVOICE_PATH = `${config.apiUrl}/invoices`;
const ORDER_PATH = `${config.apiUrl}/order`;

class InvoiceRepository {
    async generateInvoices(orderIds: Id[]): Promise<void> {
        const path = `${INVOICE_PATH}/generate`;
        const response = await httpService.post(path, orderIds);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async getOrdersForInvoice(filters: IDtoObject, sort?: IDtoObject): Promise<IListOrder[]> {
        let path = `${ORDER_PATH}/orders-for-invoice`;
        if (sort) {
            path = httpService.addQueryParams(path, sort as Record<string, TPrimitiveType>);
        }
        const response = await httpService.post(path, filters);
        if (response.error) return Promise.reject(response.message);
        if (!Array.isArray(response.response)) return [];
        return response.response as IListOrder[];
    }
}

const invoiceRepository = new InvoiceRepository();
export default invoiceRepository;
