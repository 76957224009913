/** @jsx jsx */
import React from "react";
import { jsx, Flex, SxStyleProp } from "theme-ui";
import { ICustomerContact } from "../../../../core/customer/ICustomer";
import AddButton from "../../../components/AddButton";
import CardList from "../../../components/CardList";
import ContactCard from "../components/ContactCard";

interface IContactsProps {
  contacts: Readonly<Array<ICustomerContact>>;
  onAddContactClick: () => void;
  onChanged: (changes: Readonly<Partial<ICustomerContact>>, contactIndex: number) => void;
  onRemoveClick: (contactIndex: number) => void;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
    marginTop: "24px",
  },
  cardList: {
    flexGrow: 1,
    marginTop: "16px",
    width: "100%",
  },
};

const ContactsContent: React.FC<IContactsProps> = ({ contacts, onAddContactClick, onChanged, onRemoveClick }) => {
  return (
    <Flex sx={styles.root}>
      <AddButton title="Add Contact" onClick={onAddContactClick} />
      <CardList
        sx={styles.cardList}
        cards={contacts.map((contact, i) => (
          <ContactCard
            key={contact.ContactId ?? `__${i}`}
            contact={contact}
            onChanged={onChanged}
            onRemoveClick={onRemoveClick}
            position={i}
          />
        ))}
      />
    </Flex>
  );
};

export default ContactsContent;
