/** @jsx jsx */
import React, { useCallback, useEffect, useState } from "react";
import { jsx } from "theme-ui";
import { isNil } from "lodash";
import { TPrimitiveType } from "../../../core/common/type";
import { IListOrder } from "../../../core/order/IListOrder";
import { useAppSelector } from "../../../store/store";
import orderPresenter from "../OrderPresenter";
import { IOrderListFilters } from "./IOrderListFilters";
import OrderListPageContent from "./OrderListPageContent";

const OrderListPage: React.FC = () => {
  const [orders, setOrders] = useState<IListOrder[]>([]);
  const orderCount = useAppSelector(state => state.orders.count);

  const [filters, setFilters] = useState<IOrderListFilters>({
    searchValue: null,
    orderState: null,
    assignedToMe: null,
    dateOrderTaken: null,
    dateWorkToBeDone: null,
  });

  const user = useAppSelector(store => store.user);

  const getOrders = useCallback(async () => {
    const orderFilters: Record<string, TPrimitiveType> = {};
    if (filters.searchValue) {
      orderFilters.CombinedFilter = filters.searchValue;
    }
    if (!isNil(filters.orderState)) {
      orderFilters.OrderState = filters.orderState;
    }
    if (filters.dateOrderTaken) {
      orderFilters.DateOrderTaken = filters.dateOrderTaken;
    }
    if (filters.dateWorkToBeDone) {
      orderFilters.DateWorkToBeDone = filters.dateWorkToBeDone;
    }
    if (filters.assignedToMe) {
      orderFilters.MaintainerId = user.Id;
    }
    const serverOrders = await orderPresenter.getOrders(orderFilters);
    setOrders(serverOrders);
  }, [filters, user]);

  useEffect(() => {
    getOrders();
    orderPresenter.updateUnmaintainedOrdersCount();
  }, [getOrders]);

  const handleFiltersChange = useCallback((newFiltersState: IOrderListFilters) => {
    setFilters(newFiltersState);
  }, []);

  return (
    <OrderListPageContent
      orderCount={orderCount}
      orders={orders}
      filters={filters}
      onFiltersChange={handleFiltersChange}
    />
  );
};

export default OrderListPage;
