/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Box, Checkbox, SxProps, Label, SxStyleProp } from "theme-ui";
import { merge } from "lodash";
import { Id } from "../../core/common/type";

interface ICheckboxWithIdProps extends Omit<React.ComponentProps<"input">, "ref" | "onChange" | "id">, SxProps {
  className?: string;
  checked: boolean;
  variant?: "primary" | "secondary";
  id: Id;
  onChange: (id: Id, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const styles: Record<string, SxStyleProp> = {
  outerLabel: {
    alignItems: "center",
    "& > div:first-of-type": {
      height: "100%",
    },
    margin: 0,
  },
  checkbox: {
    "input:focus ~ &": {
      backgroundColor: "transparent",
    },
    marginRight: 3,
  },
};

const primaryVariantStyles: Record<string, SxStyleProp> = {
  checkbox: {
    color: "inputBorder",
    "input:checked ~ &": {
      color: "primary",
    },
    "input:focus ~ &": {
      color: "inputBorder",
    },
  },
};

const secondaryVariantStyles: Record<string, SxStyleProp> = {
  checkbox: {
    cursor: "pointer",
    color: "inputBorder",
    "input:checked ~ &": {
      color: "secondary",
    },
    "input:focus ~ &": {
      color: "inputBorder",
    },
  },
  selectedLabel: {
    color: "secondary",
  },
};

const VARIANT_STYLES = {
  primary: primaryVariantStyles,
  secondary: secondaryVariantStyles,
};

const CheckboxWithId: React.FC<ICheckboxWithIdProps> = ({
  className,
  variant = "secondary",
  id,
  onChange,
  ...inputProps
}) => {
  const extraStyles = VARIANT_STYLES[variant];

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return onChange(id, event);
    },
    [id, onChange],
  );

  return (
    <Box className={className}>
      <Label sx={styles.outerLabel}>
        <Checkbox sx={merge({}, styles.checkbox, extraStyles.checkbox)} onChange={handleChange} {...inputProps} />
      </Label>
    </Box>
  );
};

export default CheckboxWithId;
