/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Box, Radio, Label, Text } from "theme-ui";
import { noop } from "lodash";

interface IRadioGroupProps {
  className?: string;
  name: string;
  items: IRadioGroupItem[];
  value?: string;
  onChange?: (newValue: string) => void;
}

export interface IRadioGroupItem {
  value: string;
  title: string;
  defaultChecked?: boolean;
}

const styles = {
  radio: {
    width: "18px",
    height: "100%",
    margin: 0,
    color: "radioBorder",
    "input:focus ~ &": {
      color: "radioBorder",
      backgroundColor: "transparent",
    },
    "input:checked ~ &": {
      color: "secondary",
    },
  },
  text: {
    marginLeft: "16px",
    height: "32px",
    display: "flex",
    alignItems: "center",
  },
};

const RadioGroup: React.FC<IRadioGroupProps> = ({ className, name, items, value, onChange = noop }) => {
  const groupName = name;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      if (newValue !== value) {
        onChange(newValue);
      }
    },
    [onChange, value],
  );

  return (
    <Box className={className}>
      {items.map(it => (
        <Label key={it.value}>
          <Radio
            sx={styles.radio}
            value={it.value}
            name={groupName}
            checked={it.value === value}
            // onClick={handleClick}
            onChange={handleChange}
          />
          <Text sx={styles.text} variant="subHeading">
            {it.title}
          </Text>
        </Label>
      ))}
    </Box>
  );
};

export default RadioGroup;
