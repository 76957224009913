import { differenceWith, intersectionWith, isEqual, merge, cloneDeep } from "lodash";
import React from "react";
import { generateNewPassword } from "../../core/common/passwordGenerator";
import { Id } from "../../core/common/type";
import { EMPTY_CUSTOMER } from "../../core/customer/consts";
import customerRepository from "../../core/customer/CustomerRepository";
import { ICustomer } from "../../core/customer/ICustomer";
import { ICustomerFilters, ICustomerSort } from "../../core/customer/ServerDto";
import noteRepository from "../../core/note/NoteRepository";
import { IPackage } from "../../core/package/IPackage";
import packageRepository from "../../core/package/PackageRepository";
import packageService from "../../core/package/PackageService";
import { IPackageFromServer, ISetCheckedToServer } from "../../core/package/ServerDto";
import { CustomerType, IUser } from "../../core/user/IUser";
import { AccountTypeToServer, CustomerTypeToServer, ICreateUserToServer } from "../../core/user/ServerDto";
import userRepository from "../../core/user/UserRepository";

class CustomerPresenter {
    getCustomers(filters: ICustomerFilters, sort?: ICustomerSort) {
        return customerRepository.getList(filters, sort);
    }

    approveForBusiness(customerId: string): Promise<void> {
        return userRepository.approveForBusiness(customerId);
    }

    async createNewCustomer(): Promise<ICustomer> {
        const serverPackagesData = await packageRepository.getPossiblePackages();

        const defaultBusinessPackages = serverPackagesData?.BusinessOrderTemplates?.map(toPackage);
        const defaultPrivatePackages = serverPackagesData?.PersonalOrderTemplates?.map(toPackage);
        const customPackages = serverPackagesData?.CustomOrderTemplates?.map(toPackage);

        return {
            ...cloneDeep(EMPTY_CUSTOMER),
            DefaultBusinessPackages: defaultBusinessPackages ?? [],
            DefaultPrivatePackages: defaultPrivatePackages ?? [],
            CustomPackages: customPackages ?? [],
        };
    }

    async getCustomer(customerId: string, branchId: string): Promise<ICustomer> {
        const serverCustomerData = await customerRepository.getCustomer({
            Id: customerId,
            BranchId: Number.parseInt(branchId, 10),
        });
        const notes = await customerRepository.getNotes(customerId);
        const serverPackagesData = await packageRepository.getPossiblePackages(branchId);
        const notifications = await customerRepository.getNotifications(branchId);

        const serverAccountDetails = serverCustomerData.AccountDetails;
        const accountDetails: IUser = {
            Id: customerId,
            UserName: serverAccountDetails.Name,
            Email: serverAccountDetails.AccountsEmailAddress,
            DaysToPayInvoices: serverAccountDetails.DaysToPayInvoices,
            FirstName: serverAccountDetails.FirstName,
            LastName: serverAccountDetails.LastName,
            OldPassword: null,
            Password: serverAccountDetails.AccountPassword,
            ConfirmPassword: null,
            SendEmailNotification: serverAccountDetails.SendEmailNotification,
            IsActive: serverAccountDetails.IsActive,
            PhoneNumber: serverAccountDetails.AccountPhoneNumber,
            IsAppliedForBusiness: serverAccountDetails.IsAppliedForBusiness,
            CustomerType: serverAccountDetails.CustomerType,
            Avatar: serverAccountDetails.Avatar,
            AvatarThumb: serverAccountDetails.AvatarThumb,
        };

        const defaultBusinessPackages = serverPackagesData?.BusinessOrderTemplates?.map(toPackage);
        const defaultPrivatePackages = serverPackagesData?.PersonalOrderTemplates?.map(toPackage);
        const customPackages = serverPackagesData?.CustomOrderTemplates?.map(toPackage);

        return {
            Details: serverCustomerData.Details,
            AccountDetails: accountDetails,
            Contacts: serverCustomerData.Contacts,
            BranchId: serverCustomerData.BranchId,
            Notes: notes ?? [],
            DefaultBusinessPackages: defaultBusinessPackages ?? [],
            DefaultPrivatePackages: defaultPrivatePackages ?? [],
            CustomPackages: customPackages ?? [],
            Notifications: notifications ?? [],
        };
    }

    updateCustomerState(changes: Partial<ICustomer>, setCustomer: (value: React.SetStateAction<ICustomer>) => void) {
        if (changes.Contacts || changes.CustomPackages || changes.Notifications) {
            setCustomer(prevCustomerState => ({ ...prevCustomerState, ...changes }));
        }
        if (changes.Details?.PackageTypes) {
            setCustomer(prevCustomerState => ({
                ...prevCustomerState,
                AccountDetails: {
                    ...prevCustomerState.AccountDetails,
                    ...changes.AccountDetails,
                },
                Details: {
                    ...prevCustomerState.Details,
                    PackageTypes: changes.Details!.PackageTypes,
                },
            }));
        } else {
            setCustomer(prevCustomerState => merge({}, prevCustomerState, changes));
        }
    }

    async saveChangedCustomer(
        oldCustomerState: ICustomer,
        newCustomerState: ICustomer,
        customerUserId: string,
        branchId: string,
    ) {
        if (!isEqual(oldCustomerState.Details, newCustomerState.Details)) {
            await this.updateLogo(newCustomerState);
            await customerRepository.updateDetails(newCustomerState.Details, customerUserId, branchId);
        }
        if (!isEqual(oldCustomerState.AccountDetails, newCustomerState.AccountDetails)) {
            await userRepository.updateAccountDetails(newCustomerState.AccountDetails, customerUserId);
        }
        if (!isEqual(oldCustomerState.AccountDetails.CustomerType, newCustomerState.AccountDetails.CustomerType)) {
            await customerRepository.changeCustomerType(
                newCustomerState.AccountDetails.Id!,
                newCustomerState.AccountDetails.CustomerType!,
            );
        }

        if (!isEqual(oldCustomerState.Contacts, newCustomerState.Contacts)) {
            const changedContacts = intersectionWith(
                newCustomerState.Contacts,
                oldCustomerState.Contacts,
                (serverContact, customerContact) =>
                    serverContact.ContactId === customerContact.ContactId && !isEqual(serverContact, customerContact),
            );

            const removedContacts = differenceWith(
                oldCustomerState.Contacts,
                newCustomerState.Contacts,
                (serverContact, customerContact) => serverContact.ContactId === customerContact.ContactId,
            );

            const addedContacts = newCustomerState.Contacts.filter(contact => !contact.ContactId);

            await Promise.all(
                changedContacts.map(contact => {
                    const newContact = { ...contact, Name: contact.Name ?? "" };
                    return customerRepository.editContact(newContact, customerUserId, branchId, contact.ContactId!);
                }),
            );

            await Promise.all(
                removedContacts.map(contact => {
                    return customerRepository.removeContact(customerUserId, branchId, contact.ContactId!);
                }),
            );

            await Promise.all(
                addedContacts.map(contact => {
                    return customerRepository.newContact(contact, customerUserId, branchId);
                }),
            );
        }

        if (!isEqual(oldCustomerState.Notes, newCustomerState.Notes)) {
            const addedNotes = newCustomerState.Notes.filter(note => !note.NoteId);
            if (addedNotes)
                await Promise.all(
                    addedNotes.map(note => {
                        return noteRepository.addNote({
                            Content: note.Content ?? "",
                            CustomerId: Number.parseInt(branchId, 10),
                        });
                    }),
                );
        }

        const isDefaultPrivatePackagesChanged = !isEqual(
            oldCustomerState.DefaultPrivatePackages,
            newCustomerState.DefaultPrivatePackages,
        );
        const isDefaultBusinessPackagesChanged = !isEqual(
            oldCustomerState.DefaultBusinessPackages,
            newCustomerState.DefaultBusinessPackages,
        );
        if (isDefaultPrivatePackagesChanged || isDefaultBusinessPackagesChanged) {
            const changedPrivatePackages = intersectionWith(
                newCustomerState.DefaultPrivatePackages,
                oldCustomerState.DefaultPrivatePackages,
                (serverPkg, pkg) => serverPkg.Id === pkg.Id && !isEqual(serverPkg, pkg),
            );
            const changedBusinessPackages = intersectionWith(
                newCustomerState.DefaultBusinessPackages,
                oldCustomerState.DefaultBusinessPackages,
                (serverPkg, pkg) => serverPkg.Id === pkg.Id && !isEqual(serverPkg, pkg),
            );
            const changedPackages = changedPrivatePackages.concat(changedBusinessPackages);

            const data: ISetCheckedToServer = {
                CustomerId: newCustomerState.BranchId!,
                OrderTemplates: changedPackages.map(pkg => ({
                    OrderTemplateId: pkg.Id!,
                    Checked: pkg.Checked!,
                })),
            };
            await packageRepository.setChecked(data);
        }

        if (!isEqual(oldCustomerState.CustomPackages, newCustomerState.CustomPackages)) {
            await packageService.savePackages(oldCustomerState.CustomPackages, newCustomerState.CustomPackages);
        }

        if (!isEqual(oldCustomerState.Notifications, newCustomerState.Notifications)) {
            await customerRepository.putNotifications(branchId, newCustomerState.Notifications);
        }
    }

    async saveNewCustomer(customer: ICustomer): Promise<void> {
        if (!customer.Details.Name) throw new Error("Please input company name!");
        if (!customer.Details.CustomerCode) throw new Error("Please input customer code!");
        if (!customer.AccountDetails.FirstName) throw new Error("Please input first name!");
        if (!customer.AccountDetails.LastName) throw new Error("Please input customer last name!");
        if (!customer.AccountDetails.Email) throw new Error("Please input customer email!");
        if (!customer.AccountDetails.Password) throw new Error("Please input customer password!");
        if (!customer.AccountDetails.ConfirmPassword) throw new Error("Please confirm customer password!");

        const accountDetails = customer.AccountDetails;
        const sAccountDetails: ICreateUserToServer = {
            Email: accountDetails.Email ?? "",
            UserName: accountDetails.UserName ?? "",
            FirstName: accountDetails.FirstName ?? "",
            LastName: accountDetails.LastName ?? "",
            Password: accountDetails.Password ?? "",
            ConfirmPassword: accountDetails.ConfirmPassword ?? "",
            SendEmailNotification: accountDetails.SendEmailNotification ?? false,
            IsActive: accountDetails.IsActive ?? false,
            CustomerType: ((accountDetails.CustomerType ?? CustomerType.Personal) as unknown) as CustomerTypeToServer,
            AccountType: ((accountDetails.AccountType ?? undefined) as unknown) as AccountTypeToServer,
            IsAdmin: accountDetails.IsAdmin ?? false,
            InOfficeStaff: accountDetails.InOfficeStaff ?? false,
        };

        await this.updateLogo(customer);
        const { BranchId: branchId, ApplicationUserId: customerId } = await customerRepository.create({
            Details: customer.Details,
            AccountDetails: sAccountDetails,
            Notes: { Notes: null },
        });

        if (!branchId || !customerId) throw new Error("Error creating customer");

        await Promise.all(
            customer.Contacts.map(contact => {
                return customerRepository.newContact(contact, customerId, branchId);
            }),
        );

        await Promise.all(
            customer.Notes.map(note => {
                return noteRepository.addNote({
                    Content: note.Content ?? "",
                    CustomerId: branchId,
                });
            }),
        );

        const defaultPackages = customer.DefaultPrivatePackages.concat(customer.DefaultBusinessPackages);
        const data: ISetCheckedToServer = {
            CustomerId: branchId,
            OrderTemplates: defaultPackages.map(pkg => ({
                OrderTemplateId: pkg.Id!,
                Checked: pkg.Checked!,
            })),
        };
        await packageRepository.setChecked(data);

        await packageService.saveNewPackages(customer.CustomPackages);
    }

    changePassword(customerId: Id, oldPassword: string, newPassword: string): Promise<void> {
        return userRepository.changePassword({
            UserId: customerId,
            OldPassword: oldPassword,
            NewPassword: newPassword,
            ConfirmPassword: newPassword,
        });
    }

    generatePassword(): string {
        return generateNewPassword();
    }

    private async updateLogo(customer: ICustomer) {
        /* eslint-disable no-param-reassign */
        const { LogoFile } = customer.Details;
        if (LogoFile) {
            const { Logo, Thumb } = await customerRepository.uploadLogo(LogoFile);
            customer.Details.Logo = Logo;
            customer.Details.LogoThumb = Thumb;
        }
        customer.Details.LogoFile = undefined;
        customer.Details.LogoFileBase64 = undefined;
        /* eslint-enable no-param-reassign */
    }
}

// TODO: DRY!!
function toPackage(serverPackage: IPackageFromServer): IPackage {
    return {
        Id: serverPackage.OrderTemplateId,
        CustomerId: serverPackage.CustomerId,
        Title: serverPackage.Description,
        Price: serverPackage.PriceExVAT,
        Checked: serverPackage.Checked,
        Services: serverPackage.Services.map(serverSrv => ({
            Id: serverSrv.OrderTypeId,
            Title: serverSrv.Name,
            Image: serverSrv.ImageURL,
            Amount: serverSrv.DefaultAmount,
        })),
    };
}

const customerPresenter = new CustomerPresenter();
export default customerPresenter;
