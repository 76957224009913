/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Card, Flex, Box } from "theme-ui";
import { ICustomer } from "../../../../core/customer/ICustomer";
import Tabs, { ITabInfo } from "../../../components/Tabs";

export interface ICustomerEditorProps<TTab extends string> {
  tabs: Record<TTab, ICustomerEditorTabInfo>;
  currentTab: TTab;
  onTabSelected: (tab: TTab) => void;
  customer: ICustomer;
  onCustomerChanged: (changes: Partial<ICustomer>) => void;
}

export interface ICustomerEditorTabInfo extends ITabInfo {
  component: React.ComponentType<{
    customer: ICustomer;
    onChanged?: (changes: Partial<ICustomer>) => void;
  }>;
  componentProps?: Record<string, unknown>;
}

const CustomerEditor = <TTab extends string>({
  tabs,
  currentTab,
  onTabSelected,
  customer,
  onCustomerChanged,
}: ICustomerEditorProps<TTab>): React.ReactElement => {
  const handleTabSelected = useCallback(
    (tabId: string) => {
      onTabSelected(tabId as TTab);
    },
    [onTabSelected],
  );

  const TabContent = tabs[currentTab].component;
  const tabContentProps = tabs[currentTab].componentProps ?? {};

  return (
    <Flex sx={{ flexGrow: 1 }}>
      <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} variant="paper">
        <Box>
          <Tabs tabs={Object.values(tabs)} defaultValue={currentTab} onSelect={handleTabSelected} />
        </Box>
        <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
          <TabContent customer={customer} onChanged={onCustomerChanged} {...tabContentProps} />
        </Flex>
      </Card>
    </Flex>
  );
};

export default CustomerEditor;
