import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { EMPTY_WORKER } from "../../../core/worker/consts";
import { IWorker } from "../../../core/worker/IWorker";
import workerPresenter from "../WorkerPresenter";
import EditWorkerPageContent from "./EditWorkerPageContent";

interface IRouteParams {
  workerId: string;
}

const EditWorkerPage: React.FC = () => {
  const {
    params: { workerId },
  } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const [worker, setWorker] = useState(EMPTY_WORKER);
  const [serverWorker, setServerWorker] = useState(EMPTY_WORKER);

  const getWorker = useCallback(async () => {
    const workerData = await workerPresenter.getWorker(workerId);
    setServerWorker(workerData);
    setWorker(workerData);
  }, [workerId]);

  useEffect(() => {
    getWorker();
  }, [getWorker]);

  const handleSaveChangesClick = useCallback(async () => {
    try {
      await workerPresenter.saveWorker(worker, serverWorker);
      history.push("/workers/list");
      alert("worker updated!");
    } catch (err) {
      alert(err);
    }
  }, [history, worker, serverWorker]);

  const handleRemoveClick = useCallback(async () => {
    try {
      await workerPresenter.deleteWorker(worker.WorkerId!);
      history.push("/workers/list");
      alert("worker deleted!");
    } catch (err) {
      alert(err);
    }
  }, [history, worker]);

  const handleWorkerChanged = useCallback((newWorkerState: IWorker) => {
    setWorker(newWorkerState);
  }, []);

  return (
    <EditWorkerPageContent
      worker={worker}
      onWorkerChanged={handleWorkerChanged}
      onSaveChangesClick={handleSaveChangesClick}
      onRemoveClick={handleRemoveClick}
    />
  );
};

export default EditWorkerPage;
