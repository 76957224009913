/** @jsx jsx */
import React from "react";
import { jsx, Box, Flex } from "theme-ui";
import { ICustomer } from "../../../../core/customer/ICustomer";
import CreateCustomerEditor, { TCreateCustomerTab } from "./CreateCustomerEditor";
import CreateCustomerHeader from "./CreateCustomerHeader";

interface IEditCustomerPageContentProps {
  onCancelClick: () => void;
  onAddCustomerClick: () => void;
  currentTab: TCreateCustomerTab;
  onTabSelected: (tab: TCreateCustomerTab) => void;
  customer: ICustomer;
  onCustomerChanged: (changes: Partial<ICustomer>) => void;
}

const CreateCustomerPageContent: React.FC<IEditCustomerPageContentProps> = ({
  onCancelClick,
  onAddCustomerClick,
  currentTab,
  onTabSelected,
  customer,
  onCustomerChanged,
}) => {
  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <CreateCustomerHeader onCancelClick={onCancelClick} onAddCustomerClick={onAddCustomerClick} />
      </Box>
      <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
        <CreateCustomerEditor
          currentTab={currentTab}
          onTabSelected={onTabSelected}
          customer={customer}
          onCustomerChanged={onCustomerChanged}
        />
      </Flex>
    </Flex>
  );
};

export default CreateCustomerPageContent;
