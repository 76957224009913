import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { EMPTY_ADMINISTRATION } from "../../../core/administration/consts";
import EditAdministrationPageContent from "./EditAdministrationPageContent";
import administrationRepository from "../../../core/administration/AdministrationRepository";

interface IRouteParams {
  administrationId: string;
}

const EditAdministrationPage: React.FC = () => {
  const {
    params: { administrationId },
  } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const [administration, setAdministration] = useState(EMPTY_ADMINISTRATION);

  const getAdministration = useCallback(async () => {
    const administrationData = await administrationRepository.getAdministrationData(administrationId);

    setAdministration(administrationData);
  }, [administrationId]);

  useEffect(() => {
    getAdministration();
  }, [getAdministration]);

  const handleRemoveClick = useCallback(async () => {
    try {
      await administrationRepository.deleteAdministration(administration.Id);
      history.push("/administration/list");
      alert("Administration deleted!");
    } catch (err) {
      alert(err);
    }
  }, [history, administration]);

  const handleCancelClick = useCallback(() => {
    history.push("/administration");
  }, [history]);

  return (
    <EditAdministrationPageContent
      administration={administration}
      onRemoveClick={handleRemoveClick}
      onCancelClick={handleCancelClick}
    />
  );
};

export default EditAdministrationPage;
