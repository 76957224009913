/** @jsx jsx */
import React from "react";
import { jsx, Flex, Box, Heading } from "theme-ui";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import LeftArrowIcon from "../../../icons/LeftArrow";
import { link } from "../../../theme/globalStyles";

interface ICustomerEditorHeaderProps {
  onRemoveCustomerClick: () => void;
  onSaveChangesClick: () => void;
  onApproveClick: () => void;
  showApproveButton: boolean;
}

const EditCustomerHeader: React.FC<ICustomerEditorHeaderProps> = ({
  onRemoveCustomerClick,
  onSaveChangesClick,
  onApproveClick,
  showApproveButton,
}) => {
  return (
    <Flex sx={{ justifyContent: "space-between" }}>
      <Box>
        <Flex>
          <Flex sx={{ alignItems: "center" }}>
            <Link to="/customers" sx={link.neutralColor}>
              <LeftArrowIcon />
            </Link>
          </Flex>
          <Box sx={{ marginLeft: 3 }}>
            <Heading>Customer</Heading>
          </Box>
        </Flex>
      </Box>
      <Flex>
        <Box>
          <Button variant="dangerOutline" title="Remove customer" onClick={onRemoveCustomerClick} />
        </Box>
        <Box sx={{ marginLeft: 4 }}>
          <Button variant="primaryOutline" title="Save changes" onClick={onSaveChangesClick} />
        </Box>
        {showApproveButton && (
          <Box sx={{ marginLeft: 4 }}>
            <Button variant="primary" title="Approve" onClick={onApproveClick} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default EditCustomerHeader;
