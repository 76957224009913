import React, { useCallback, useEffect, useState } from "react";
import { IListWorker } from "../../../core/worker/IWorker";
import workerPresenter from "../WorkerPresenter";
import { IWorkerListFilters, IWorkerListSort } from "./types";
import WorkerListPageContent from "./WorkerListPageContent";

const WorkerListPage: React.FC = () => {
  const [workers, setWorkers] = useState([] as IListWorker[]);
  const [filters, setFilters] = useState<IWorkerListFilters>({
    searchValue: null,
  });
  const [sort, setSort] = useState<IWorkerListSort>({
    sortField: "",
    sortOrder: "",
  });

  const getWorkers = useCallback(async () => {
    const serverWorkers = await workerPresenter.getWorkers(filters, sort);
    setWorkers(serverWorkers);
  }, [filters, sort]);

  useEffect(() => {
    getWorkers();
  }, [getWorkers]);

  return (
    <WorkerListPageContent
      workers={workers}
      filters={filters}
      onFiltersChange={setFilters}
      sort={sort}
      onSortChange={setSort}
    />
  );
};

export default WorkerListPage;
