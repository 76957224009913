/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Box, Checkbox, Label, Text } from "theme-ui";
import { noop } from "lodash";

interface ICheckboxGroupProps {
  className?: string;
  name: string;
  items: ICheckboxGroupItem[];
  value: string[];
  onChange?: (newValue: string[]) => void;
}

export interface ICheckboxGroupItem {
  value: string;
  title: string;
  defaultChecked?: boolean;
}

const styles = {
  radio: {
    width: "18px",
    height: "100%",
    margin: 0,
    color: "radioBorder",
    "input:focus ~ &": {
      color: "radioBorder",
      backgroundColor: "transparent",
    },
    "input:checked ~ &": {
      color: "secondary",
    },
  },
  text: {
    marginLeft: "16px",
    height: "32px",
    display: "flex",
    alignItems: "center",
  },
};

const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({ className, name, items, value, onChange = noop }) => {
  const groupName = name;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const targetValue = event.currentTarget.value;
      let newValue: string[];
      if (value?.includes(targetValue)) {
        newValue = value.filter(item => item !== targetValue);
      } else {
        newValue = [targetValue, ...value];
      }
      onChange(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, value],
  );

  return (
    <Box className={className}>
      {items.map(it => (
        <Label key={it.value}>
          <Checkbox
            sx={styles.radio}
            value={it.value}
            name={groupName}
            onChange={handleChange}
            checked={value.includes(it.value)}
            key={it.value}
          />
          <Text sx={styles.text} variant="subHeading">
            {it.title}
          </Text>
        </Label>
      ))}
    </Box>
  );
};

export default CheckboxGroup;
