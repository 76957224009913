import React from "react";
import createIcon from "./createIcon";

const InvoicesIcon = createIcon(
  <>
    <path
      d="M1 3C1 1.89543 1.89543 1 3 1H23C24.1046 1 25 1.89543 25 3V24C25 25.1071 23.6615 25.6615 22.8787 24.8787V24.8787C22.3934 24.3934 21.6066 24.3934 21.1213 24.8787L20.4142 25.5858C19.6332 26.3668 18.3668 26.3668 17.5858 25.5858L17.4142 25.4142C16.6332 24.6332 15.3668 24.6332 14.5858 25.4142L14.4142 25.5858C13.6332 26.3668 12.3668 26.3668 11.5858 25.5858L11.4142 25.4142C10.6332 24.6332 9.36684 24.6332 8.58579 25.4142L8.41421 25.5858C7.63317 26.3668 6.36684 26.3668 5.58579 25.5858L4.87868 24.8787C4.3934 24.3934 3.6066 24.3934 3.12132 24.8787V24.8787C2.3385 25.6615 1 25.1071 1 24L1 3Z"
      strokeWidth="2"
    />
    <path
      d="M5 6H7M11 6H21M5 10H7M11 10H21M5 14H7M11 14H21"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  26,
  28,
  "none",
  "currentColor",
);

export default InvoicesIcon;
