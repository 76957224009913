/** @jsx jsx */
import React, { useCallback } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import { clone } from "lodash";
import { TPrimitiveType } from "../../../core/common/type";
import { EMPTY_POST_CODE } from "../../../core/worker/consts";
import { IWorker, IWorkerPostCode } from "../../../core/worker/IWorker";
import AddButton from "../../components/AddButton";
import InputField from "../../components/InputField";
import PasswordField, { IPasswords } from "../../components/PasswordField";
import PhoneInputField from "../../components/PhoneInputField";
import { formMargin } from "../../theme/globalStyles";

interface IAccountDetailsProps {
  data: IWorker;
  onDataChanged: (newWorkerState: IWorker) => void;
  isCreatingPassword?: boolean;
}

const AccountDetails: React.FC<IAccountDetailsProps> = ({ data, onDataChanged, isCreatingPassword = false }) => {
  const details = data.AccountDetails;

  const callOnChanged = useCallback(
    (fieldName: Record<string, TPrimitiveType> | string, value: TPrimitiveType = null) => {
      const changes: Record<string, TPrimitiveType> =
        typeof fieldName === "string" ? { [fieldName]: value } : fieldName;
      onDataChanged({
        ...data,
        AccountDetails: {
          ...data.AccountDetails,
          ...changes,
        },
      });
    },
    [data, onDataChanged],
  );

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const { fieldName } = event.target.dataset;
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handlePhoneInputChanged = useCallback(
    (value: string, fieldName?: string) => {
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handlePostCodesChange = useCallback(
    Postcodes => {
      onDataChanged({
        ...data,
        Postcodes,
      });
    },
    [data, onDataChanged],
  );

  const handlePasswordsChange = useCallback(
    (passwords: IPasswords) => {
      onDataChanged({
        ...data,
        AccountDetails: {
          ...data.AccountDetails,
          ...passwords,
        },
      });
    },
    [data, onDataChanged],
  );

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={formMargin.group}>
        <InputField
          label="Login"
          value={details.UserName ?? ""}
          data-field-name="UserName"
          onChange={handleInputChanged}
        />
      </Flex>

      <Flex sx={formMargin.group}>
        <InputField
          label="First Name"
          value={details.FirstName ?? ""}
          data-field-name="FirstName"
          onChange={handleInputChanged}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Last Name"
          value={details.LastName ?? ""}
          data-field-name="LastName"
          onChange={handleInputChanged}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Email Address"
          value={details.Email ?? ""}
          data-field-name="Email"
          onChange={handleInputChanged}
        />
      </Flex>

      <Flex sx={formMargin.group}>
        <PhoneInputField
          label="Account Phone Number"
          value={details.PhoneNumber ?? ""}
          id="PhoneNumber"
          onChange={handlePhoneInputChanged}
        />
      </Flex>

      <Box sx={formMargin.group}>
        <PostCodes postCodes={data.Postcodes ?? []} onChange={handlePostCodesChange} />
      </Box>

      <PasswordField
        sx={formMargin.group}
        isCreatingPassword={isCreatingPassword}
        passwords={{
          OldPassword: details.OldPassword ?? "",
          Password: details.Password ?? "",
          ConfirmPassword: details.ConfirmPassword ?? "",
        }}
        onChange={handlePasswordsChange}
      />
    </Flex>
  );
};

interface IPostCodesProps {
  postCodes: IWorkerPostCode[];
  onChange: (newPostcodes: IWorkerPostCode[]) => void;
}

const PostCodes: React.FC<IPostCodesProps> = ({ postCodes, onChange }) => {
  const handleAddPostCodeClick = useCallback(() => {
    onChange(postCodes.concat([clone(EMPTY_POST_CODE)]));
  }, [postCodes, onChange]);

  const handlePostCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const { index: indexStr = "" } = event.target.dataset;
      const changedPostcodes = clone(postCodes);
      const index = Number.parseInt(indexStr, 10);
      if (Number.isNaN(index)) return;
      changedPostcodes[index] = {
        ...changedPostcodes[index],
        Postcode: value,
      };

      onChange(changedPostcodes);
    },
    [onChange, postCodes],
  );

  const handlePostCodeBlur = useCallback(() => {
    onChange(postCodes.filter(it => it.Postcode));
  }, [postCodes, onChange]);

  return (
    <Box>
      <Text variant="label.input">Zip Codes</Text>
      <Flex sx={{ alignItems: "flex-end" }}>
        <AddButton title="Add Zip Code" onClick={handleAddPostCodeClick} />
        {postCodes.map((postcode, i) => (
          <InputField
            key={postcode.WorkerPostcodeId ?? `__${i}`}
            sx={formMargin.rowElement}
            variant="narrow"
            value={postcode.Postcode ?? ""}
            data-index={i}
            onChange={handlePostCodeChange}
            onBlur={handlePostCodeBlur}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default AccountDetails;
