/* the import fixes a strange bug with requiring of the store inside better-queue lib */
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import MemoryStore from "better-queue-memory";
import BetterQueue from "better-queue";
import { noop } from "lodash";
import { store } from "../../store/store";
import { Id } from "../common/type";
import { IOfferedService, IOrderService } from "../order/IDetailedOrder";
import orderRepository from "../order/OrderRepository";
import { incrementTotalCount, incrementUploadedCount, clear, close, open } from "../../store/uploader/uploader";

interface IUpload {
    id?: Id;
    files: File[];
    serviceId: Id;
    type: TFileType;
    onDone: (updatedService: IOrderService | IOfferedService) => void;
}

type TUploadCallback = (updatedService: IOrderService | IOfferedService) => void;

type TFileType = "orderPhoto" | "orderPlan" | "offeredServiceFile";

const UPLOADERS: Record<TFileType, (files: File[], serviceId: Id) => Promise<IOrderService | IOfferedService>> = {
    orderPhoto: (files, orderServiceId) => orderRepository.uploadPhotos(files, orderServiceId),
    orderPlan: (files, orderServiceId) => orderRepository.uploadPlans(files, orderServiceId),
    offeredServiceFile: (files, offeredServiceId) =>
        orderRepository.uploadOfferedServicePhotos(files, offeredServiceId),
};

class Uploader {
    queue = new BetterQueue<IUpload>(
        ({ files, serviceId, type, onDone }: IUpload, next) => {
            UPLOADERS[type](files, serviceId)
                .then(onDone)
                .finally(() => {
                    store.dispatch(incrementUploadedCount(files.length));
                    next();
                });
        },
        {
            store: new MemoryStore(),
        },
    );

    constructor() {
        this.queue.on("drain", () => {
            store.dispatch(close());
            store.dispatch(clear());
        });
    }

    addOrderPhoto(files: File[], serviceId: Id, onDone: TUploadCallback = noop) {
        if (!files.length) return;
        store.dispatch(open());
        this.addToQueue(files, serviceId, "orderPhoto", onDone);
        store.dispatch(incrementTotalCount(files.length));
    }

    addOrderPlan(files: File[], serviceId: Id, onDone: TUploadCallback = noop) {
        if (!files.length) return;
        store.dispatch(open());
        this.addToQueue(files, serviceId, "orderPlan", onDone);
        store.dispatch(incrementTotalCount(files.length));
    }

    addOfferedServiceFile(files: File[], serviceId: Id, onDone: TUploadCallback = noop) {
        if (!files.length) return;
        store.dispatch(open());
        this.addToQueue(files, serviceId, "offeredServiceFile", onDone);
        store.dispatch(incrementTotalCount(files.length));
    }

    removeFile() {}

    cancelAll() {
        this.queue.destroy(() => {});
        store.dispatch(close());
        store.dispatch(clear());
    }

    private addToQueue(files: File[], serviceId: Id, type: TFileType, onDone: TUploadCallback) {
        files.forEach(file => {
            this.queue.push({ files: [file], serviceId, type, onDone });
        });
    }
}

const uploader = new Uploader();
export default uploader;
