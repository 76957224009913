import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import xeroRepository from "../../core/xero/xeroRepository";
import { useAppSelector } from "../../store/store";
import orderPresenter from "../orders/OrderPresenter";

const useStyles = makeStyles(theme => ({
  root: {},
  panels: {
    paddingTop: "24px",
  },
  panel: {
    height: "400px",
    padding: "8px 16px 16px",
    display: "flex",
    flexDirection: "column",
  },
  panelHeader: {},
  subPanel: {
    flexGrow: 1,
    marginTop: "8px",
    padding: "16px",
    background: theme.palette.common.white,
  },
  subPanelHeader: {
    textTransform: "uppercase",
  },
  ordersHeader: {
    display: "flex",
  },
  ordersHeaderTitle: {
    flexGrow: 1,
  },
  ordersBadge: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 2px 8px rgba(41, 198, 208, 0.25)`,
    width: "40px",
    height: "16px",
    borderRadius: "8px",
    textAlign: "center",
  },
}));

interface IDashboardItemProps {
  header: React.ReactNode;
}

const DashboardItem: React.FC<IDashboardItemProps> = ({ header }) => {
  const styles = useStyles();

  return (
    <Paper className={styles.panel}>
      <div className={styles.panelHeader}>{header}</div>
      <div className={styles.subPanel}>
        <Typography color="textSecondary" className={styles.subPanelHeader}>
          Recent Current Events
        </Typography>
      </div>
    </Paper>
  );
};

const DashboardPage: React.FC = () => {
  const styles = useStyles();

  const ordersCount = useAppSelector(state => state.orders.count);

  useEffect(() => {
    xeroRepository.xeroStatus();
    orderPresenter.updateUnmaintainedOrdersCount();
  }, []);

  return (
    <div>
      <Typography variant="h1">Home</Typography>
      <Grid container spacing={2} className={styles.panels}>
        <Grid item xs={6}>
          <DashboardItem
            header={
              <Typography variant="h4" color="textPrimary">
                Customers
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardItem
            header={
              <div className={styles.ordersHeader}>
                <Typography variant="h4" className={styles.ordersHeaderTitle}>
                  Orders
                </Typography>
                <div className={styles.ordersBadge}>
                  <Typography variant="h6">{ordersCount}</Typography>
                </div>
              </div>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardItem
            header={
              <Typography variant="h4" color="textPrimary">
                Invoices
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DashboardItem
            header={
              <Typography variant="h4" color="textPrimary">
                Administration
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardPage;
