import React, { useCallback, useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { EMPTY_UPSELL } from "../../core/package/consts";
import { IPackagesPageInfo } from "./IPackagesPageInfo";
import PackagesPageContent from "./PackagesPageContent";
import packagesPresenter from "./PackagesPresenter";

const EMPTY_DATA: IPackagesPageInfo = {
  packages: [],
  upsell: cloneDeep(EMPTY_UPSELL),
};

const PackagesPage: React.FC = () => {
  const [data, setData] = useState<IPackagesPageInfo>(EMPTY_DATA);
  const [serverData, setServerData] = useState<IPackagesPageInfo>(EMPTY_DATA);

  const getPageData = useCallback(async () => {
    const dataFromServer = await packagesPresenter.getPackagesUpsellInfo();
    setData(dataFromServer);
    setServerData(dataFromServer);
  }, []);

  useEffect(() => {
    getPageData();
  }, [getPageData]);

  const handleSaveChangesClick = useCallback(async () => {
    await packagesPresenter.saveChanges(data, serverData);
    await getPageData();
    alert("Packages saved!");
  }, [getPageData, data, serverData]);

  return (
    <PackagesPageContent
      onSaveChangesClick={handleSaveChangesClick}
      data={data}
      onDataChanged={setData}
      basePath="/packages"
      baseUrl="/packages"
    />
  );
};

export default PackagesPage;
