import { isEqual, cloneDeep } from "lodash";
import { Id } from "../../core/common/type";
import { EMPTY_PACKAGE, EMPTY_SERVICE, EMPTY_UPSELL } from "../../core/package/consts";
import { toPackageService } from "../../core/package/convert";
import { IPackage, IService, IUpsell } from "../../core/package/IPackage";
import packageRepository from "../../core/package/PackageRepository";
import packageService from "../../core/package/PackageService";
import { IPackagesPageInfo } from "./IPackagesPageInfo";

class PackagesPresenter {
    async createNewPackage(customerId: number | null = null): Promise<IPackage> {
        const pkg = cloneDeep(EMPTY_PACKAGE);
        const serverServices = await packageRepository.getNewPackageServicesList();
        pkg.Services = serverServices.map(toPackageService);
        pkg.CustomerId = customerId;
        return pkg;
    }

    createNewService(): IService {
        return cloneDeep(EMPTY_SERVICE);
    }

    createUpsellInfo(): IUpsell {
        return cloneDeep(EMPTY_UPSELL);
    }

    async getPackagesUpsellInfo(): Promise<IPackagesPageInfo> {
        const [packages, upsell] = await Promise.all([packageService.getPackages(), packageService.getUpsell()]);
        return { packages, upsell };
    }

    async getServices(): Promise<IService[]> {
        return packageService.getServices();
    }

    async saveChanges(newData: IPackagesPageInfo, oldData: IPackagesPageInfo): Promise<void> {
        await this.savePackages(newData.packages, oldData.packages);
        await this.saveUpsell(newData.upsell, oldData.upsell);
    }

    addService(newServiceData: IService): Promise<void> {
        return packageService.addService(newServiceData);
    }

    removeService(serviceId: Id): Promise<void> {
        return packageService.removeService(serviceId);
    }

    updateService(newServiceData: IService): Promise<void> {
        return packageService.updateService(newServiceData);
    }

    private async savePackages(newPackages: IPackage[], oldPackages: IPackage[]): Promise<void> {
        if (!isEqual(newPackages, oldPackages)) {
            return packageService.savePackages(oldPackages, newPackages);
        }
    }

    private async saveUpsell(newUpsell: IUpsell, oldUpsell: IUpsell): Promise<void> {
        if (!isEqual(newUpsell, oldUpsell)) {
            await packageService.saveUpsell(newUpsell);
        }
    }
}

const packagesPresenter = new PackagesPresenter();
export default packagesPresenter;
