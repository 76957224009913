import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useCallback } from "react";
import { ICustomerListItemFromServer, TCustomerListFromServer } from "../../../core/customer/ServerDto";
import SelectedIcon from "../../components/SelectedIcon";

interface IRowProps {
  className?: string;
  children: React.ReactElement[];
  onClick: (customerId: string) => void;
  data: ICustomerListItemFromServer;
}

const Row: React.FC<IRowProps> = ({ onClick, className, children, data }) => {
  const handleOnClick = useCallback(() => {
    onClick(data.ApplicationUserId!);
  }, [onClick, data]);

  return (
    <TableRow onClick={handleOnClick} className={className}>
      {children}
    </TableRow>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    height: 500,
  },
  customersTable: {
    tableLayout: "fixed",
  },
  customerCodeColumn: {
    width: 300,
  },
  isActiveColumn: {
    width: 70,
  },
  phoneColumn: {
    width: 40,
  },
  inviteColumn: {
    width: 80,
  },
  codeCell: {
    display: "flex",
    alignItems: "center",
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  cell: {
    padding: "0 16px 0 0",
  },
  headerCell: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.background.paper,
    fontSize: 12,
  },
  phone: {
    cursor: "pointer",
  },
  invite: {
    color: "#27AE60",
    cursor: "pointer",
  },
  selectedIconBloc: {
    width: 34,
    display: "flex",
    justifyContent: "center",
  },
}));

interface ICustomersTableProps {
  className?: string;
  customers: TCustomerListFromServer;
  onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedApplicationUserId?: string;
}

const CustomersTable: React.FC<ICustomersTableProps> = ({
  className,
  customers,
  onClick,
  selectedApplicationUserId,
}) => {
  const styles = useStyles();

  return (
    <TableContainer className={classNames(styles.container, className)}>
      <Table size="small" stickyHeader className={styles.customersTable}>
        <colgroup span={1}>
          <col className={styles.customerCodeColumn} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Customer Code</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Address</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Email Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map(customer => (
            <Row
              key={`${customer.EmailAddress}-${customer.Code}`}
              data={customer}
              onClick={onClick}
              className={styles.row}
            >
              <TableCell className={classNames(styles.cell, styles.codeCell)}>
                <div className={styles.selectedIconBloc}>
                  {selectedApplicationUserId === customer.ApplicationUserId && <SelectedIcon />}
                </div>
                <Typography noWrap>{customer.Code}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{customer.Address}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{customer.EmailAddress}</Typography>
              </TableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomersTable;
