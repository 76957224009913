/** @jsx jsx */
import React, { useCallback } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import { TPrimitiveType } from "../../../core/common/type";
import { IWorker } from "../../../core/worker/IWorker";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import InputField from "../../components/InputField";
import PhoneInputField from "../../components/PhoneInputField";
import Rating from "../../components/Rating";
import { formMargin } from "../../theme/globalStyles";

interface IWorkerDetailsProps {
  data: IWorker;
  onDataChanged: (newWorkerState: IWorker) => void;
  showBottomCheckboxes?: boolean;
}

const WorkerDetails: React.FC<IWorkerDetailsProps> = ({ data, onDataChanged, showBottomCheckboxes }) => {
  const details = data.WorkerDetails;
  const accountDetails = data.AccountDetails;

  const callOnChanged = useCallback(
    (fieldName: string, value: TPrimitiveType) => {
      onDataChanged({
        ...data,
        WorkerDetails: {
          ...data.WorkerDetails!,
          [fieldName]: value,
        },
      });
    },
    [data, onDataChanged],
  );

  const handlePhoneInputChanged = useCallback(
    (value: string, fieldName?: string) => {
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const { fieldName } = event.target.dataset;
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      const { fieldName } = event.target.dataset;
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handleRatingChange = useCallback(
    (value: number) => {
      callOnChanged("Rating", value);
    },
    [callOnChanged],
  );

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={formMargin.group}>
        <PhoneInputField
          label="Phone Number"
          value={details?.PhoneNumber ?? ""}
          id="PhoneNumber"
          onChange={handlePhoneInputChanged}
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField
          label="House Name"
          value={details?.HouseName ?? ""}
          data-field-name="HouseName"
          onChange={handleInputChanged}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Number"
          variant="narrow"
          value={details?.HouseNumber ?? ""}
          data-field-name="HouseNumber"
          onChange={handleInputChanged}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Road"
          value={details?.Road ?? ""}
          data-field-name="Road"
          onChange={handleInputChanged}
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField label="Area" value={details?.Area ?? ""} data-field-name="Area" onChange={handleInputChanged} />
        <InputField
          sx={formMargin.rowElement}
          label="Town"
          value={details?.Town ?? ""}
          data-field-name="Town"
          onChange={handleInputChanged}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Country"
          value={details?.Country ?? ""}
          data-field-name="Country"
          onChange={handleInputChanged}
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField
          label="Zip Code"
          variant="narrow"
          value={details?.Postcode ?? ""}
          data-field-name="Postcode"
          onChange={handleInputChanged}
        />
      </Flex>

      <Box sx={formMargin.group}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">Full time worker</Text>}
          checked={!!details?.FullTimeType}
          data-field-name="FullTimeType"
          onChange={handleCheckboxChange}
        />
      </Box>

      {/* hack to make rating work properly (it initializes it's state only on mount) */}
      {data.WorkerId && (
        <Box sx={formMargin.group}>
          <Text variant="subHeading">Worker Rate</Text>
          <Rating sx={{ marginTop: "11px" }} value={details?.Rating ?? 0} onChange={handleRatingChange} />
        </Box>
      )}

      {showBottomCheckboxes && (
        <Box sx={formMargin.group}>
          <CheckboxWithLabel
            label={<Text variant="subHeading">Active</Text>}
            checked={!!accountDetails.IsActive}
            data-field-name="IsActive"
            onChange={handleCheckboxChange}
            disabled
          />
          <CheckboxWithLabel
            sx={formMargin.row}
            label={<Text variant="subHeading">Send email notification</Text>}
            checked={!!accountDetails.SendEmailNotification}
            data-field-name="IsSendEmailNotifications"
            onChange={handleCheckboxChange}
            disabled
          />
        </Box>
      )}
    </Flex>
  );
};

export default WorkerDetails;
