import { INoteFromServer } from "./ServerDto";

// eslint-disable-next-line import/prefer-default-export
export const EMPTY_NOTE: INoteFromServer = {
    Content: null,
    CreatedDateTime: null,
    NoteId: null,
    OwnerId: null,
    OwnerName: null,
    OrderId: null,
    CustomerId: null,
    WorkerId: null,
};
