import React from "react";
import createIcon from "./createIcon";

const CancelIcon = createIcon(
  <>
    <rect width="24" height="24" rx="12" fill="#E0E0E0" />
    <path d="M7 7L17 17M17 7L7 17" stroke="#EB5757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </>,
  24,
  24,
  "none",
  "none",
);

export default CancelIcon;
