/** @jsx jsx */
import React from "react";
import { jsx, Box, Label } from "theme-ui";
import IntegerSelector, { IIntegerSelectorProps } from "./IntegerSelector";

interface IIntegerSelectorFieldProps extends IIntegerSelectorProps {
  className?: string;
  label?: string;
}

const IntegerSelectorField: React.FC<IIntegerSelectorFieldProps> = ({ className, label, ...selectorProps }) => {
  return (
    <Box className={className}>
      {label && <Label variant="label.input">{label}</Label>}
      <IntegerSelector {...selectorProps} />
    </Box>
  );
};

export default IntegerSelectorField;
