import config from "../../config/config";
import { Id, TPrimitiveType } from "../common/type";
import httpService from "../http/HttpService";
import { ICustomerContact, ICustomerNote } from "./ICustomer";
import { IOrderListFilter } from "./IOrderListFilter";
import {
    IBranchSelectItemFromServer,
    ICreateCustomerResponse,
    ICreateCustomerToServer,
    ICustomerContactsToServer,
    ICustomerDataFromServer,
    ICustomerFilters,
    ICustomerSort,
    IGetCustomerDataToServer,
    INoteListFromServer,
    IServerNotifications,
    IUploadLogoResponse,
    TCustomerDetailsToServer,
    TCustomerListFromServer,
} from "./ServerDto";
import { CustomerType } from "../user/IUser";

const CUSTOMERS_PATH = `${config.apiUrl}/customers`;
const ORDERS_PATH = `${config.apiUrl}/order`;

class CustomerRepository {
    async getList(data: ICustomerFilters = { page: 1 }, sort?: ICustomerSort): Promise<TCustomerListFromServer> {
        let path = `${CUSTOMERS_PATH}/list`;
        if (sort) {
            path = httpService.addQueryParams(path, sort as Record<string, TPrimitiveType>);
        }
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return (response.response ?? []) as TCustomerListFromServer;
    }

    async create(data: ICreateCustomerToServer): Promise<ICreateCustomerResponse> {
        const path = `${CUSTOMERS_PATH}/customer`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response as ICreateCustomerResponse;
    }

    async update(data: ICreateCustomerToServer): Promise<void> {
        const path = `${CUSTOMERS_PATH}/customer`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    updateDetails(data: TCustomerDetailsToServer, id: string, branchId: string) {
        const path = `${CUSTOMERS_PATH}/${id}/updateBranchDetails/${branchId}`;
        return httpService.post(path, data);
    }

    updateNotes(data: ICustomerNote, id: string, branchId: string) {
        const path = `${CUSTOMERS_PATH}/${id}/updateBranchNotes/${branchId}`;
        return httpService.post(path, data);
    }

    newBranch(data: any, id: string) {
        const path = `${CUSTOMERS_PATH}/${id}/createBranch`;
        return httpService.post(path, data);
    }

    newContact(data: ICustomerContact, customerId: string, branchId: number | string) {
        const path = `${CUSTOMERS_PATH}/${customerId}/createBranchContact/${branchId}`;
        const sData: ICustomerContactsToServer = {
            ContactId: data.ContactId,
            Name: data.Name ?? "",
            EmailAddress: data.EmailAddress ?? "",
            PhoneNumber1: data.PhoneNumber1 ?? "",
            PhoneNumber2: data.PhoneNumber2 ?? "",
        };
        return httpService.post(path, sData);
    }

    editContact(data: ICustomerContact, customerId: string, branchId: string, contactId: number) {
        const path = `${CUSTOMERS_PATH}/${customerId}/updateBranchContact/${branchId}/${contactId}`;
        const sData: ICustomerContactsToServer = {
            ContactId: data.ContactId,
            Name: data.Name ?? "",
            EmailAddress: data.EmailAddress ?? "",
            PhoneNumber1: data.PhoneNumber1 ?? "",
            PhoneNumber2: data.PhoneNumber2 ?? "",
        };
        return httpService.post(path, sData);
    }

    removeContact(customerId: string, branchId: string, contactId: number) {
        const path = `${CUSTOMERS_PATH}/${customerId}/deleteBranchContact/${branchId}/${contactId}`;
        return httpService.delete(path);
    }

    async uploadLogo(file: File): Promise<IUploadLogoResponse> {
        const path = `${CUSTOMERS_PATH}/upload-logo`;
        const response = await httpService.upload(path, file);
        if (response.error) return Promise.reject(response.message);
        return (response.response || {}) as IUploadLogoResponse;
    }

    async getCustomer(data: IGetCustomerDataToServer): Promise<ICustomerDataFromServer> {
        const path = `${CUSTOMERS_PATH}/customer/getData`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response as ICustomerDataFromServer;
    }

    async getBranches(customerId: string): Promise<IBranchSelectItemFromServer> {
        const path = `${CUSTOMERS_PATH}/${customerId}/get-branches`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as IBranchSelectItemFromServer;
    }

    getOrders() {
        const path = `${CUSTOMERS_PATH}/order-list`;
        return httpService.post(path);
    }

    async getAddressList() {
        const path = `${CUSTOMERS_PATH}/address-list`;
        const res = await httpService.post(path);
        if (typeof res.response === "object" && !Array.isArray(res.response)) {
            const dlPath = `${CUSTOMERS_PATH}/address-list-get/${
                (res.response?.FileDownloadRequestID as string) ?? ""
            }`;
            window.open(dlPath, "_blank");
        }
    }

    invite(id: string) {
        const path = `${CUSTOMERS_PATH}/invite/${id}`;
        return httpService.post(path);
    }

    getOrderList(filters: IOrderListFilter = {}) {
        const path = `${ORDERS_PATH}/branch-orders`;
        return httpService.post(path, filters);
    }

    async getNotes(customerId: string): Promise<INoteListFromServer> {
        const path = `${CUSTOMERS_PATH}/${customerId}/notes`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject(response.message);
        return (response.response as unknown) as INoteListFromServer;
    }

    changeCustomerType = (userId: Id, customerType: CustomerType) =>
        customerType === CustomerType.Business ? this.setBusinessType(userId) : this.setPrivateType(userId);

    setBusinessType = async (userId: Id): Promise<void> => {
        const path = `${CUSTOMERS_PATH}/${userId}/setbusinesstype`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    };

    setPrivateType = async (userId: Id): Promise<void> => {
        const path = `${CUSTOMERS_PATH}/${userId}/setprivatetype`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    };

    putNotifications = async (customerId: Id, data: IServerNotifications): Promise<void> => {
        const path = `${CUSTOMERS_PATH}/${customerId}/notifications`;
        const response = await httpService.put(path, data);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    };

    getNotifications = async (customerId: Id): Promise<IServerNotifications> => {
        const path = `${CUSTOMERS_PATH}/${customerId}/notifications`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as IServerNotifications;
    };
}
const customerRepository = new CustomerRepository();
export default customerRepository;
