import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccessToken } from "../../core/auth/IAccessToken";

const authTokenSlice = createSlice({
    name: "auth-token",
    initialState: {
        access_token: null,
        expires_in: null,
        token_type: null,
        token_id: null,
    } as IAccessToken,
    reducers: {
        setAccessToken(state, action: PayloadAction<IAccessToken>) {
            return {
                ...action.payload,
            };
        },
    },
});

export const { setAccessToken } = authTokenSlice.actions;

export default authTokenSlice.reducer;
