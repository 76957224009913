/** @jsx jsx */
import moment from "moment";
import React, { useCallback, useState } from "react";
import { DatePickerModal } from "react-rainbow-components";
// @ts-ignore
import DateTimePickerModal from "react-rainbow-components/components/DateTimePicker/pickerModal";
import { jsx, SxProps, Flex, Label, Input } from "theme-ui";
import { noop, merge } from "lodash";
import { Styles } from "../theme/utils";

interface IDateInputFieldProps
  extends Omit<React.ComponentProps<"input">, "ref" | "value" | "onSelect" | "onChange">,
    SxProps {
  identifier?: string;
  className?: string;
  label?: string;
  variant?: "default" | "halfWidth" | "fullWidth";
  value?: string | Date | null;
  onChange?: (date: Date | null, identifier?: string) => void;
  dateFormat?: string;
  showTimeSelect?: boolean;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
  },
  default: {
    maxWidth: "250px",
  },
  halfWidth: {
    maxWidth: "125px",
  },
  fullWidth: {},
  input: {
    height: "32px",
    border: "1px solid",
    borderRadius: "4px",
    borderColor: "inputBorder",
    minWidth: 0,
    padding: "0 8px",
    fontSize: 4,
    color: "inputColor",
    backgroundColor: "transparent",
    "&::placeholder": {
      color: "inputBorder",
    },
    "&:disabled": {
      color: "inputBorder",
    },
  },
};

const DateInput: React.FC<IDateInputFieldProps> = ({
  className,
  label,
  variant = "default",
  value,
  onChange = noop,
  placeholder = "MM/DD/YYYY",
  identifier,
  dateFormat,
  showTimeSelect,
  ...inputProps
}) => {
  const variantStyles = styles[variant];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onInputValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value) {
        onChange(null, identifier);
      }
    },
    [identifier, onChange],
  );

  const handleChange = useCallback(
    (newDate: Date) => {
      onChange(newDate, identifier);
      if (!showTimeSelect) {
        setIsModalOpen(false);
      }
    },
    [onChange, identifier, showTimeSelect],
  );

  const handleInputClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModalRequest = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const format = dateFormat ?? showTimeSelect ? "MM/DD/YYYY hh:mm a" : "MM/DD/YYYY";

  const inputValue = value ? moment.utc(value).local().format(format) : "";

  return (
    <Flex className={className} sx={merge({}, variantStyles, styles.root)}>
      {label && <Label variant="label.input">{label}</Label>}
      <Input
        sx={styles.input}
        value={inputValue}
        onClick={handleInputClick}
        onChange={onInputValueChange}
        placeholder={placeholder}
        {...inputProps}
      />
      {showTimeSelect ? (
        <DateTimePickerModal
          title={inputValue}
          value={inputValue ?? new Date()}
          isOpen={isModalOpen}
          onChange={handleChange}
          onRequestClose={handleCloseModalRequest}
          formatStyle="medium"
        />
      ) : (
        <DatePickerModal
          title={inputValue || placeholder || "Select a date"}
          value={inputValue ?? new Date()}
          isOpen={isModalOpen}
          onChange={handleChange}
          onRequestClose={handleCloseModalRequest}
        />
      )}
    </Flex>
  );
};

export default DateInput;
