import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
  icon: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
});

function createIcon(
  path: React.ReactNode,
  width: number,
  height: number,
  fill?: string,
  stroke?: string,
): React.FC<SvgIconProps> {
  return ({ className, ...props }) => {
    const styles = useStyles();
    return (
      <SvgIcon
        className={`${styles.icon} ${className}`}
        style={{ fill, stroke }}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {path}
      </SvgIcon>
    );
  };
}

export default createIcon;
