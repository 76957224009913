import React from "react";
import { IWorker } from "../../../core/worker/IWorker";
import WorkerDetails from "../workerTabs/WorkerDetails";

interface ICreateWorkerDetailsProps {
  data: IWorker;
  onDataChanged: (newWorkerState: IWorker) => void;
}

const CreateWorkerDetails: React.FC<ICreateWorkerDetailsProps> = props => {
  return <WorkerDetails showBottomCheckboxes {...props} />;
};

export default CreateWorkerDetails;
