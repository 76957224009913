/** @jsx jsx */
import React, { useCallback } from "react";
import { Flex, jsx, SxStyleProp, Text } from "theme-ui";
import { IService } from "../../../../core/package/IPackage";
import Button from "../../../components/Button";

interface IPopupProps {
  service: IService;
  onConfirm: (id: string) => void;
  closePopup: () => void;
}

const styles: Record<string, SxStyleProp> = {
  shadow: {
    position: "fixed",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    width: "388px",
    height: "310px",
    background: "white",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  },
  title: {
    fontSize: "24px",
    fontFamily: "Roboto",
    padding: "12px",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
  },
  mainText: {
    fontSize: "24px",
    fontFamily: "Roboto",
    padding: "12px",
    marginTop: "-82px",
    flexDirection: "column",
    flexGrow: 1,
    textAlign: "center",
    fontWeight: "700",
  },
  text: {
    fontSize: "24px",
    fontFamily: "Roboto",
    padding: "12px",
    marginTop: "-82px",
    flexDirection: "column",
    flexGrow: 1,
    textAlign: "center",
    alignSelf: "center",
  },
  button: {
    width: "120px",
  },
  leftButton: {
    width: "120px",
    marginRight: "15px",
  },
  buttonBlock: {
    justifyContent: "center",
    width: "100%",
  },
};

const DeletePopup: React.FC<IPopupProps> = ({ service, onConfirm, closePopup }) => {
  const handleSave = useCallback(() => {
    onConfirm(service.Id!);
    closePopup();
  }, [closePopup, onConfirm, service.Id]);

  const handleCancel = useCallback(() => {
    closePopup();
  }, [closePopup]);

  return (
    <div>
      <Flex sx={styles.shadow}>
        <Flex sx={styles.title}>
          <Text sx={styles.title}>Delete Service</Text>
          <Text sx={styles.mainText}>Are you sure you want to delete the service?</Text>
          <Text sx={styles.text}>This action cannot be undone.</Text>
          <Flex sx={styles.buttonBlock}>
            <Button sx={styles.leftButton} variant="danger" title="No" onClick={handleCancel} />
            <Button sx={styles.button} variant="primaryOutline" title="Yes" onClick={handleSave} />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default DeletePopup;
