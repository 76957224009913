/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import PlusIcon from "../icons/Plus";
import ButtonWithIcon, { IButtonWithIconProps } from "./ButtonWithIcon";

interface IAddButtonProps extends Omit<IButtonWithIconProps, "icon"> {
  variant?: "normal" | "small";
}

const ICON_SIZE: Record<NonNullable<IAddButtonProps["variant"]>, string> = {
  normal: "20px",
  small: "14px",
};

const AddButton: React.FC<IAddButtonProps> = ({ variant = "normal", ...buttonProps }) => {
  const iconSize = ICON_SIZE[variant];
  return <ButtonWithIcon icon={<PlusIcon sx={{ width: iconSize, height: iconSize }} />} {...buttonProps} />;
};

export default AddButton;
