import React from "react";
import createIcon from "./createIcon";

const WorkersIcon = createIcon(
  <>
    <path
      d="M1 20C1 16.0319 4.35786 12.8151 8.5 12.8151C12.6421 12.8151 16 16.0319 16 20M13 5.47058C13 7.85144 10.9853 9.94116 8.5 9.94116C6.01472 9.94116 4 7.85144 4 5.47058C4 3.08973 6.01472 1 8.5 1C10.9853 1 13 3.08973 13 5.47058Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0855 2.94202C24.27 2.66823 25.3318 3.73 25.058 4.91448L25.0091 5.12616C24.8859 5.65907 25.0357 6.21835 25.4089 6.61827L25.5571 6.77713C26.3864 7.66601 25.9978 9.11643 24.8351 9.47155L24.6273 9.53502C24.1042 9.6948 23.6948 10.1042 23.535 10.6273L23.4716 10.8351C23.1164 11.9978 21.666 12.3864 20.7771 11.5571L20.6183 11.4089C20.2183 11.0357 19.6591 10.8859 19.1262 11.0091L18.9145 11.058C17.73 11.3318 16.6682 10.27 16.942 9.08552L16.9909 8.87384C17.1141 8.34093 16.9643 7.78165 16.5911 7.38173L16.4429 7.22287C15.6136 6.33399 16.0022 4.88358 17.1649 4.52845L17.3727 4.46498C17.8958 4.3052 18.3052 3.89578 18.465 3.37268L18.5284 3.16489C18.8836 2.00222 20.334 1.61358 21.2229 2.44292L21.3817 2.59114C21.7817 2.96427 22.3409 3.11413 22.8738 2.99095L23.0855 2.94202Z"
      strokeWidth="2"
    />
    <path
      d="M21.8222 7C21.8222 7.45408 21.4541 7.82219 21 7.82219C20.5459 7.82219 20.1778 7.45408 20.1778 7C20.1778 6.54592 20.5459 6.17781 21 6.17781C21.4541 6.17781 21.8222 6.54592 21.8222 7Z"
      strokeWidth="2"
    />
    <path
      d="M26.5428 13.471C27.135 13.3341 27.6659 13.865 27.529 14.4572L27.5045 14.5631C27.4429 14.8295 27.5179 15.1092 27.7044 15.3091L27.7785 15.3886C28.1932 15.833 27.9989 16.5582 27.4176 16.7358L27.3137 16.7675C27.0521 16.8474 26.8474 17.0521 26.7675 17.3137L26.7358 17.4176C26.5582 17.9989 25.833 18.1932 25.3886 17.7785L25.3091 17.7044C25.1092 17.5179 24.8295 17.4429 24.5631 17.5045L24.4572 17.529C23.865 17.6659 23.3341 17.135 23.471 16.5428L23.4955 16.4369C23.5571 16.1705 23.4821 15.8908 23.2956 15.6909L23.2215 15.6114C22.8068 15.167 23.0011 14.4418 23.5824 14.2642L23.6863 14.2325C23.9479 14.1526 24.1526 13.9479 24.2325 13.6863L24.2642 13.5824C24.4418 13.0011 25.167 12.8068 25.6114 13.2215L25.6909 13.2956C25.8908 13.4821 26.1705 13.5571 26.4369 13.4955L26.5428 13.471Z"
      strokeWidth="2"
    />
  </>,
  29,
  21,
  "none",
  "currentColor",
);

export default WorkersIcon;
