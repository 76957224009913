/** @jsx jsx */
import React, { useCallback, useEffect, useState } from "react";
import { Flex, jsx, SxStyleProp, Text } from "theme-ui";
import InputField from "../../../components/InputField";
import { IService } from "../../../../core/package/IPackage";
import Button from "../../../components/Button";
import UserLogo from "../../../components/UserLogo";
import { toBase64 } from "../../../../core/common/converter";
import packagesPresenter from "../../PackagesPresenter";

export interface IEditPopupProps {
  service?: IService;
  title?: string;
  onConfirm: (newServiceInfo: IService) => void;
  closePopup: () => void;
}

const styles: Record<string, SxStyleProp> = {
  shadow: {
    position: "fixed",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    width: "388px",
    height: "412px",
    background: "white",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  },
  title: {
    fontSize: "24px",
    fontFamily: "Roboto",
    padding: "12px",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
  },
  icon: {
    height: "35px",
    width: "35px",
    padding: "38px",
    margin: "26px 38px 38px 38px",
    background: "center center no-repeat",
    backgroundSize: "contain",
  },
  cell: {
    width: "360px",
    margin: "0px",
  },
  button: {
    width: "120px",
  },
  leftButton: {
    width: "120px",
    marginRight: "15px",
  },
  buttonBlock: {
    justifyContent: "flex-end",
    width: "100%",
  },
  addImage: {
    height: "155px",
    width: "125",
    padding: "12px",
  },
};

const EditPopup: React.FC<IEditPopupProps> = ({ service, title = "Edit Service", onConfirm, closePopup }) => {
  const [serviceInfo, setServiceInfo] = useState(packagesPresenter.createNewService());
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [logo, setLogo] = useState("");
  const [logoBase64, setLogoBase64] = useState("");
  const [newLogoFile, setNewLogo] = useState<File | null>(null);

  const updateLogoByFile = useCallback(async (imageFile?: File) => {
    if (!imageFile) return;
    const base64Image = await toBase64(imageFile);
    setLogoBase64(base64Image);
  }, []);

  useEffect(() => {
    if (service) {
      setServiceInfo(service);
      setName(service.Title ?? "");
      setPrice(service.Price ? service.Price.toString() : "");
      setLogo(service.Image ?? "");
      setLogoBase64(service.ImageFileBase64 ?? "");
      updateLogoByFile(service.ImageFile);
    }
  }, [service, setServiceInfo, updateLogoByFile]);

  const handleSave = useCallback(() => {
    onConfirm({
      ...serviceInfo,
      Title: name,
      Price: Number.parseInt(price, 10) ?? 0,
      ImageFile: newLogoFile ?? undefined,
      ImageFileBase64: logoBase64 ?? undefined,
    });
    closePopup();
  }, [onConfirm, serviceInfo, name, price, newLogoFile, logoBase64, closePopup]);

  const handleCancel = useCallback(() => {
    closePopup();
  }, [closePopup]);

  const handleName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.currentTarget.value);
    },
    [setName],
  );

  const handlePrice = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPrice(event.currentTarget.value);
    },
    [setPrice],
  );

  const handleLogoChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.currentTarget.files || [];
      if (files.length > 0) {
        const imageFile = files[0];
        setNewLogo(imageFile);
        updateLogoByFile(imageFile);
      }
    },
    [updateLogoByFile],
  );

  return (
    <div>
      <Flex sx={styles.shadow}>
        <Flex sx={styles.title}>
          <Text sx={styles.title}>{title}</Text>
          <Flex sx={styles.addImage}>
            <UserLogo value={logoBase64 || logo} onChange={handleLogoChange} />
          </Flex>
          <InputField label="Name" sx={styles.cell} value={name} onChange={handleName} />
          <InputField label="Price" sx={styles.cell} value={price} onChange={handlePrice} />
          <Flex sx={styles.buttonBlock}>
            <Button sx={styles.leftButton} variant="dangerOutline" title="Cancel" onClick={handleCancel} />
            <Button sx={styles.button} variant="primary" title="Save" onClick={handleSave} />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
};

export default EditPopup;
