import React from "react";
import createIcon from "./createIcon";

const XeroLoginIcon = createIcon(
  <path
    d="M3 0.5V0C2.72386 0 2.5 0.223858 2.5 0.5L3 0.5ZM7.5 0.5H8C8 0.223858 7.77614 0 7.5 0V0.5ZM7.5 7.5V8C7.77614 8 8 7.77614 8 7.5H7.5ZM3 7.5H2.5C2.5 7.77614 2.72386 8 3 8V7.5ZM6 4L6.35355 4.35355L6.70711 4L6.35355 3.64645L6 4ZM3 1H7.5V0H3V1ZM7 0.5V7.5H8V0.5H7ZM7.5 7H3V8H7.5V7ZM2.5 0.5V2H3.5V0.5H2.5ZM3.5 7.5V6H2.5V7.5H3.5ZM0 4.5H6V3.5H0V4.5ZM6.35355 3.64645L4.85355 2.14645L4.14645 2.85355L5.64645 4.35355L6.35355 3.64645ZM5.64645 3.64645L4.14645 5.14645L4.85355 5.85355L6.35355 4.35355L5.64645 3.64645Z"
    fill="#29C6D0"
  />,
  12,
  12,
  "none",
  "#29C6D0",
);

export default XeroLoginIcon;
