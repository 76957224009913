import React from "react";
import createIcon from "./createIcon";

const Edit = createIcon(
  <>
    <path
      d="M22.6892 6.15381L26.1704 9.63495L16.5972 19.2081L13.1161 15.7269L22.6892 6.15381Z"
      stroke="#29C6D0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5052 18.3378L13.9864 21.8189L13.1161 22.6892L7.89436 24.4298L9.63493 19.2081L10.5052 18.3378Z"
      stroke="#29C6D0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>,
  32,
  32,
  "none",
  "currentColor",
);

export default Edit;
