/** @jsx jsx */
import React from "react";
import { jsx, Flex, Heading } from "theme-ui";
import SearchInput from "../../components/SearchInput";

interface IAdministrationListHeaderProps {
  onSearch: (searchValue: string) => void;
}

const AdministrationListHeader: React.FC<IAdministrationListHeaderProps> = ({ onSearch }) => {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading>Administration</Heading>
    </Flex>
  );
};

export default AdministrationListHeader;
