import React from "react";
import createIcon from "./createIcon";

const PhoneIcon = createIcon(
  <>
    <path d="M5.45491 14.5451C7.94112 17.0313 12.8052 18.4811 14.6389 18.9589C15.0896 19.0763 15.5608 18.9368 15.8901 18.6075L17.6095 16.8881C18.0033 16.4943 18.1208 15.8996 17.8626 15.406C17.58 14.866 17.1758 14.1846 16.7447 13.7536C16.3137 13.3225 15.6322 12.9183 15.0922 12.6357C14.5987 12.3775 14.004 12.4949 13.6101 12.8888L12.7866 13.7124C12.3868 14.1121 11.7803 14.2283 11.2728 13.9793C10.0664 13.3871 8.90663 12.681 8.1128 11.8872C7.31897 11.0934 6.61292 9.93364 6.02074 8.72716C5.77165 8.21967 5.8879 7.61317 6.28764 7.21343L7.11122 6.38985C7.50507 5.996 7.6225 5.4013 7.36429 4.90778C7.08175 4.36776 6.6775 3.68635 6.24644 3.25529C5.81539 2.82423 5.13398 2.41999 4.59396 2.13745C4.10044 1.87924 3.50573 1.99667 3.11189 2.39051L1.3925 4.1099C1.06317 4.43922 0.923664 4.91041 1.04109 5.3611C1.51886 7.19485 2.9687 12.0589 5.45491 14.5451Z" />
    <path d="M10.8242 6.16676C10.8242 6.16676 11.9332 6.78027 12.5585 7.4055C13.1837 8.03072 13.7972 9.13973 13.7972 9.13973M11.8152 4.18478C11.8152 4.18478 13.4591 4.83763 14.2927 5.67127C15.1263 6.5049 15.7792 8.14874 15.7792 8.14874M12.3107 1.70731C12.3107 1.70731 14.7765 2.68658 16.0269 3.93704C17.2774 5.18749 18.2566 7.65325 18.2566 7.65325" />
  </>,
  20,
  20,
  "none",
  "currentColor",
);

export default PhoneIcon;
