import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EMPTY_CUSTOMER } from "../../../../core/customer/consts";
import { ICustomer } from "../../../../core/customer/ICustomer";
import customerPresenter from "../../CustomerPresenter";
import { TCreateCustomerTab } from "./CreateCustomerEditor";
import CreateCustomerPageContent from "./CreateCustomerPageContent";

const CreateCustomerPage: React.FC = () => {
  const history = useHistory();
  const [customer, setCustomer] = useState(EMPTY_CUSTOMER);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getCustomer() {
      setIsLoading(true);
      const newCustomer = await customerPresenter.createNewCustomer();
      setCustomer(newCustomer);
      setIsLoading(false);
    }
    getCustomer();
  }, []);

  const handleCancelClick = useCallback(() => {
    history.push("/customers/list");
  }, [history]);

  const handleAddCustomerClick = useCallback(() => {
    customerPresenter
      .saveNewCustomer(customer)
      // eslint-disable-next-line promise/always-return
      .then(() => {
        history.push("/customers/list");
      })
      .catch(err => {
        alert(err.message);
      });
  }, [customer, history]);

  const handleCustomerChanged = useCallback((changes: Partial<ICustomer>) => {
    customerPresenter.updateCustomerState(changes, setCustomer);
  }, []);

  const [tab, setTab] = useState<TCreateCustomerTab>("Details");

  const handleTabChanged = useCallback(
    (newTab: TCreateCustomerTab) => {
      setTab(newTab);
    },
    [setTab],
  );

  return (
    <>
      {!isLoading && (
        <CreateCustomerPageContent
          onCancelClick={handleCancelClick}
          onAddCustomerClick={handleAddCustomerClick}
          currentTab={tab}
          onTabSelected={handleTabChanged}
          customer={customer}
          onCustomerChanged={handleCustomerChanged}
        />
      )}
    </>
  );
};

export default CreateCustomerPage;
