import { noop } from "lodash";
import React from "react";
import { Button as ThemeUiButton, Text } from "theme-ui";

interface IButtonProps {
  className?: string;
  variant: string;
  title: string;
  onClick?: () => void;
}

const Button: React.FC<IButtonProps> = ({ className, variant, title, onClick = noop }) => {
  return (
    <ThemeUiButton className={className} variant={variant} onClick={onClick}>
      <Text variant="button">{title}</Text>
    </ThemeUiButton>
  );
};

export default Button;
