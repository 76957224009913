/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { jsx, Flex, Box } from "theme-ui";
import { noop } from "lodash";
import { ICustomer } from "../../../../core/customer/ICustomer";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import PhoneInputField from "../../../components/PhoneInputField";
import { formMargin } from "../../../theme/globalStyles";
import customerPresenter from "../../CustomerPresenter";

interface IAccountDetailsProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const AccountDetails: React.FC<IAccountDetailsProps> = ({ customer, onChanged = noop }) => {
  const accountDetails = customer.AccountDetails;

  const handlePhoneInputChange = useCallback(
    (value: string) => {
      onChanged({
        AccountDetails: {
          PhoneNumber: value,
        },
      });
    },
    [onChanged],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName, fieldType } = event.currentTarget.dataset;
      const { value } = event.currentTarget;

      let fieldValue: number | string = value;
      if (fieldType === "number") {
        fieldValue = Number.parseInt(value, 10);
      }

      onChanged({
        AccountDetails: {
          [fieldName!]: fieldValue,
        },
      });
    },
    [onChanged],
  );

  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const handleUpdatePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  const handleUpdateOldPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
  }, []);

  const handleChangePasswordClick = useCallback(async () => {
    if (!oldPassword) {
      alert("Please input current password");
      return;
    }
    if (!password) {
      alert("Please input new password");
      return;
    }

    try {
      await customerPresenter.changePassword(accountDetails.Id!, oldPassword, password);
      alert("password changed!");
    } catch (err) {
      alert(err);
    }
  }, [oldPassword, password, accountDetails.Id]);

  return (
    <Box sx={{ marginTop: 7 }}>
      <Flex>
        <InputField
          label="Login"
          value={accountDetails.UserName ?? ""}
          data-field-name="UserName"
          onChange={handleInputChange}
        />
      </Flex>
      <Flex sx={formMargin.group}>
        <InputField
          label="First Name"
          value={accountDetails.FirstName ?? ""}
          data-field-name="FirstName"
          onChange={handleInputChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Last Name"
          value={accountDetails.LastName ?? ""}
          data-field-name="LastName"
          onChange={handleInputChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Email Address"
          value={accountDetails.Email ?? ""}
          data-field-name="Email"
          onChange={handleInputChange}
        />
      </Flex>
      <Flex sx={formMargin.group}>
        <PhoneInputField
          label="Account Phone Number"
          value={accountDetails.PhoneNumber ?? ""}
          data-field-name="PhoneNumber"
          onChange={handlePhoneInputChange}
        />
      </Flex>
      <Flex sx={formMargin.group}>
        <InputField
          label="Days To Pay Invoices"
          value={accountDetails.DaysToPayInvoices ?? ""}
          data-field-type="number"
          data-field-name="DaysToPayInvoices"
          onChange={handleInputChange}
        />
      </Flex>
      <Box sx={formMargin.group}>
        <Flex>
          <InputField label="Old Password" type="password" value={oldPassword} onChange={handleUpdateOldPassword} />
        </Flex>
        <Flex sx={formMargin.row}>
          <InputField label="New Password" type="password" value={password} onChange={handleUpdatePassword} />
        </Flex>
      </Box>
      <Box sx={formMargin.row}>
        <Button title="Change Password" variant="primary" onClick={handleChangePasswordClick} />
      </Box>
    </Box>
  );
};

export default AccountDetails;
