/** @jsx jsx */
import React from "react";
import { jsx, Flex } from "theme-ui";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { formMargin } from "../../../theme/globalStyles";
import { Styles } from "../../../theme/utils";

interface IServiceTabHeaderProps {
  workerName: string;
  onChangeWorkerClick: () => void;
  onNotifyCustomerClick: () => void;
}

const styles: Styles = {
  root: {
    justifyContent: "space-between",
  },
  group: {
    alignItems: "flex-end",
  },
  workerName: {
    lineHeight: "32px",
    marginTop: "8px",
  },
};

const ServiceTabHeader: React.FC<IServiceTabHeaderProps> = ({
  workerName,
  onChangeWorkerClick,
  onNotifyCustomerClick,
}) => {
  return (
    <Flex sx={styles.root}>
      <Flex sx={styles.group}>
        <InputField label="Worker" value={workerName} disabled />
        <Button
          sx={formMargin.rowElement}
          variant="primaryOutline"
          title="Change worker"
          onClick={onChangeWorkerClick}
        />
      </Flex>
      <Flex sx={styles.group}>
        <Button variant="textPrimary" title="Notify Customer" onClick={onNotifyCustomerClick} />
      </Flex>
    </Flex>
  );
};

export default ServiceTabHeader;
