/** @jsx jsx */
import React from "react";
import { jsx, Flex, Box, Card } from "theme-ui";
import { merge } from "lodash";
import { IWorker } from "../../../core/worker/IWorker";
import CardList from "../../components/CardList";
import ImageList from "../../components/ImageList";
import { formMargin } from "../../theme/globalStyles";
import { Styles } from "../../theme/utils";

interface IPortfolioProps {
  data: IWorker;
}

const styles: Styles = {
  root: {
    flexGrow: 1,
    flexDirection: "column",
  },
  outerContainer: {
    display: "flex",
    flexGrow: 1,
    height: 0,
    padding: "16px",
  },
  innerContainer: {
    overflow: "auto",
  },
};

const Portfolio: React.FC<IPortfolioProps> = ({ data }) => {
  const portfolio = data.Portfolio;
  return (
    <Flex sx={merge({}, formMargin.group, styles.root)}>
      <Card sx={styles.outerContainer}>
        <Box sx={styles.innerContainer}>
          <ImageList
            images={portfolio.map(file => {
              const fileId = file.UploadFileId ?? "";
              return {
                id: fileId,
                thumbURL: file.ThumbURL ?? "",
                fullURL: file.ThumbURL ?? "",
                isSelected: false,
              };
            })}
          />
        </Box>
      </Card>
    </Flex>
  );
};

export default Portfolio;
