import React from "react";
import createIcon from "./createIcon";

const HomeIcon = createIcon(
  <path
    d="M9 28H7C5.89543 28 5 27.1046 5 26V17.8175C5 16.8137 4.18627 16 3.18249 16V16C1.59329 16 0.769452 14.1042 1.85379 12.9424L11.5379 2.56654C12.3286 1.71939 13.6714 1.7194 14.4621 2.56655L24.1462 12.9424C25.2306 14.1042 24.4067 16 22.8175 16V16C21.8137 16 21 16.8137 21 17.8175V26C21 27.1046 20.1046 28 19 28V28C17.8954 28 17 27.1046 17 26V18C17 16.8954 16.1046 16 15 16H11C9.89543 16 9 16.8954 9 18V19"
    strokeWidth="2"
  />,
  26,
  29,
  "none",
  "currentColor",
);

export default HomeIcon;
