import { IPackage, IService, IUpsell } from "./IPackage";

export const EMPTY_PACKAGE: IPackage = {
    Id: null,
    Title: null,
    Price: null,
    Services: [],
    Checked: null,
    CustomerId: null,
};

export const EMPTY_SERVICE: IService = {
    Id: null,
    Title: null,
    CreatedDateTime: null,
    AmendedDateTime: null,
    Image: null,
    Price: null,
    Amount: null,
};

export const EMPTY_UPSELL: IUpsell = {
    UplsellPriceId: null,
    Price: null,
    DefaultPhotos: null,
};
