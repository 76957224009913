import { cloneDeep, merge, noop } from "lodash";
import React, { useCallback } from "react";
import { ICustomer } from "../../../../core/customer/ICustomer";
import { PackageType } from "../../../../core/customer/PackageType";
import { IPackage } from "../../../../core/package/IPackage";
import { CustomerType } from "../../../../core/user/IUser";
import packagesPresenter from "../../../packages/PackagesPresenter";
import { splitToGroups } from "./PackageGroups";
import PackagesContent from "./PackagesContent";

interface IPackagesProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const Packages: React.FC<IPackagesProps> = ({ customer, onChanged = noop }) => {
  const branchId = customer.BranchId;
  const customerType = customer.AccountDetails.CustomerType?.toString() ?? "";
  const defaultBusinessPackages = customer.DefaultBusinessPackages;
  const defaultPrivatePackages = customer.DefaultPrivatePackages;
  const customPackages = customer.CustomPackages;
  const packageTypes = customer.Details.PackageTypes ?? [];

  const handlePackageTypesChange = useCallback(
    (newPackageTypes: PackageType[]) => {
      onChanged({
        Details: {
          PackageTypes: newPackageTypes,
        },
      });
    },
    [onChanged],
  );

  const handleCustomerTypeChange = useCallback(
    (newCustomerType: string) => {
      const changes: Partial<ICustomer> = {
        AccountDetails: {
          ...customer.AccountDetails,
          CustomerType: Number.parseInt(newCustomerType, 10),
        },
      };
      if (newCustomerType === CustomerType.Personal.toString()) {
        changes.Details = {
          ...customer.Details,
          PackageTypes: [PackageType.Private],
        };
      } else {
        changes.Details = {
          ...customer.Details,
          PackageTypes: [PackageType.Default],
        };
      }
      onChanged(changes);
    },
    [customer.AccountDetails, customer.Details, onChanged],
  );

  const handleDefaultPackagesSelectionChange = useCallback(
    (changes: Record<number, boolean>, packagesField: "DefaultPrivatePackages" | "DefaultBusinessPackages") => {
      const newPackages = customer[packagesField].map(pkg => {
        if (pkg.Id! in changes) {
          return {
            ...pkg,
            Checked: changes[pkg.Id!],
          };
        }
        return pkg;
      });
      onChanged({ [packagesField]: newPackages });
    },
    [onChanged, customer],
  );

  const handleDefaultPrivatePackagesSelectionChange = useCallback(
    (changes: Record<number, boolean>) => {
      handleDefaultPackagesSelectionChange(changes, "DefaultPrivatePackages");
    },
    [handleDefaultPackagesSelectionChange],
  );

  const handleDefaultBusinessPackagesSelectionChange = useCallback(
    (changes: Record<number, boolean>) => {
      handleDefaultPackagesSelectionChange(changes, "DefaultBusinessPackages");
    },
    [handleDefaultPackagesSelectionChange],
  );

  const handleAddCustomPackageClick = useCallback(async () => {
    const newPackage = await packagesPresenter.createNewPackage(branchId);
    const newPackages = customer.CustomPackages.concat([newPackage]);
    onChanged({
      CustomPackages: newPackages,
    });
  }, [onChanged, customer.CustomPackages, branchId]);

  const handleCustomPackageChange = useCallback(
    (changes: Partial<IPackage>, position: number) => {
      const newPackages = cloneDeep(customer.CustomPackages);
      merge(newPackages[position], changes);
      onChanged({
        CustomPackages: newPackages,
      });
    },
    [onChanged, customer.CustomPackages],
  );

  const handleRemoveCustomPackageClick = useCallback(
    (index: number) => {
      const newPackages = customer.CustomPackages.filter((pkg, i) => i !== index);
      onChanged({
        CustomPackages: newPackages,
      });
    },
    [onChanged, customer.CustomPackages],
  );

  return (
    <PackagesContent
      onCustomerTypeChange={handleCustomerTypeChange}
      customerType={customerType}
      onPackageTypesChange={handlePackageTypesChange}
      packageTypes={packageTypes}
      defaultPrivatePackages={splitToGroups(defaultPrivatePackages)}
      defaultBusinessPackages={splitToGroups(defaultBusinessPackages)}
      onDefaultPrivatePackagesSelectionChange={handleDefaultPrivatePackagesSelectionChange}
      onDefaultBusinessPackagesSelectionChange={handleDefaultBusinessPackagesSelectionChange}
      customPackages={customPackages}
      onAddCustomPackageClick={handleAddCustomPackageClick}
      onCustomPackageChange={handleCustomPackageChange}
      onRemoveCustomPackageClick={handleRemoveCustomPackageClick}
    />
  );
};

export default Packages;
