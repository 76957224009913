import React, { FC } from "react";

interface ISelectedIconProps {
  className?: string;
}

const SelectedIcon: FC<ISelectedIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 5L5 9L13 1" stroke="#27AE60" strokeWidth="2" />
    </svg>
  );
};

export default SelectedIcon;
