export const SERVER_ERRORS: Record<number, string> = {
    10: "Login process failed",
    11: "Account is not activated",
    12: "This email has already been registered. Sign into your existing account, or register with a different email.",
    13: "User already activated.",

    20: "Password too short",
    21: "Password and confirm password mismatch",
    22: "User creation error",
    23: "Please check email for activation link",
    24: "Display Name is empty",
    25: "User password change attempt failed",
    26: "Password incorrect",
    27: "Passwords missmatch",
    28: "User delete failed",
    29: "User password reset attempt failed",
    30: "User update data failed",
    31: "Incorrect old password",

    35: "Error create customer",
    36: "Account not exists to bind to customer",
    37: "Data for account binding is not complete",

    50: "Customer name required",
    51: "Customer name too long",
    52: "Customer code required",
    53: "Customer code too long",
    54: "Customer branch name too long",
    55: "Customer house name too long",
    56: "Customer house number too long",
    57: "Customer road loo long",
    58: "Customer area too long",
    59: "Customer town too long",
    60: "Customer country too long",
    61: "Post code too long",
    62: "Customer account name required",
    63: "Customer account name too long",
    64: "Accounts email address required",
    65: "Accounts email address too long",
    66: "Account email invalid",
    67: "Contact name required",
    68: "Contact name too long",
    69: "Phone number too long",
    70: "Missing CustomerId",
    71: "Customer not found",
    72: "Contact not found",
    73: "Contact belongs to another customer",

    100: "Error getting user list",
    101: "User not found",
    102: "Login already exists",

    150: "Order type name required",
    151: "Order type name too long",
    152: "Order type price required",
    153: "Order type price violation",
    154: "Order type id missed",
    157: "Size of property missed",

    170: "Worker type name required",
    171: "Worker type name too long",
    172: "Worker type id missed",
    173: "Worker type name collision",
    174: "Worker not found",
    175: "Worker mismatch",
    176: "Worker post code missed",

    200: "Description required",

    300: "Order template id required",
    301: "Quantity required",
    302: "Application user id required",
    303: "User id mismatch",
    304: "Order not found",
    305: "Order denied",
    306: "Order template not found",
    307: "Order template denied",
    308: "Worker suggest post code not found",
    309: "Looks like you are trying to edit an already confirmed order. You can change the package only if the status is 'Booking in Progress'.",
    310: "Please, specify the Date and Time and change order status",

    325: "Google task duration zero",
    326: "Google task appointment time not set",
    328: "Calendar not authorized",

    350: "Invoice creation error",
    351: "Invoices creation error",

    401: "Authorization failed for request",

    600: "Authorization failed for request",
    601: "Forbidden request",
    602: "Unknown request",

    700: "Something was wrong, please try again later.",

    800: "No Xero connection",

    // internal client errors, when server response differs from expected
    1000: "Problem connecting to the server. Please check your internet connection.",
    1001: "Server response is empty",
    1002: "Server module is broken",
};

export const HTTP_ERRORS: Record<number, string> = {
    0: "Problem connecting to the server. Please check your internet connection.",
    200: "Ok",
    401: "Authorization failed for request",
    404: "Not found",
};
