export function toArray<T>(param: T | T[]): T[] {
    if (Array.isArray(param)) return param;
    return [param];
}

export async function toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}
