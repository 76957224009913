import { List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import CustomersIcon from "../icons/Customers";
import HomeIcon from "../icons/Home";
import AdministrationIcon from "../icons/Administration";
import InvoicesIcon from "../icons/Invoices";
import OrdersIcon from "../icons/OrdersIcon";
import PackagesIcon from "../icons/PackagesIcon";
import WorkersIcon from "../icons/Workers";
import orderPresenter from "../orders/OrderPresenter";

const useStyles = makeStyles(theme => ({
  listItem: {
    marginTop: "24px",
    height: "32px",
    padding: 0,
  },
  listItemLink: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    textDecoration: "none",
    cursor: "pointer",
    color: "inherit",
    "&:visited": {
      color: "inherit",
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.palette.primary.main,
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    marginLeft: "2px",
    marginRight: "2px",
    width: "24px",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: "24px",
    },
  },
  title: {
    flexGrow: 1,
    marginLeft: "12px",
  },
  ordersBadge: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 2px 8px rgba(41, 198, 208, 0.25)`,
    width: "40px",
    height: "16px",
    borderRadius: "8px",
    textAlign: "center",
  },
  ordersBadgeMinimized: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 2px 8px rgba(41, 198, 208, 0.25)`,
    width: "40px",
    position: "absolute",
    left: "20px",
    top: "0px",
    height: "16px",
    borderRadius: "8px",
    textAlign: "center",
  },
}));

interface INavigationMenuProps {
  isOpen: boolean;
}

const NavigationMenu: React.FC<INavigationMenuProps> = ({ isOpen }: INavigationMenuProps) => {
  const ordersCount = useAppSelector(state => state.orders.count);
  useEffect(() => {
    orderPresenter.updateUnmaintainedOrdersCount();
  }, []);

  return (
    <List disablePadding>
      <MenuItem isOpen={isOpen} link="/" exact icon={<HomeIcon />} title="Home" />
      <MenuItem isOpen={isOpen} link="/customers" icon={<CustomersIcon />} title="Customers" />
      <ItemOrders isOpen={isOpen} link="/orders" icon={<OrdersIcon />} title="Orders" ordersCount={ordersCount} />
      <MenuItem isOpen={isOpen} link="/invoices" icon={<InvoicesIcon />} title="Invoices" />
      <MenuItem isOpen={isOpen} link="/administration" icon={<AdministrationIcon />} title="Administration" />
      <MenuItem isOpen={isOpen} link="/packages" icon={<PackagesIcon />} title="Packages" />
      <MenuItem isOpen={isOpen} link="/workers" icon={<WorkersIcon />} title="Workers" />
    </List>
  );
};

interface IMenuItemProps {
  isOpen: boolean;
  exact?: boolean;
  link: string;
  icon: React.ReactNode;
  title: string;
}

const MenuItem: React.FC<IMenuItemProps> = ({ isOpen, exact, link, icon, title }) => {
  const styles = useStyles();
  return (
    <ListItem className={styles.listItem}>
      <NavLink to={link} exact={exact} className={styles.listItemLink} activeClassName={styles.active}>
        <div className={styles.icon}>{icon}</div>
        {!isOpen && (
          <Typography variant="h3" className={styles.title}>
            {title}
          </Typography>
        )}
      </NavLink>
    </ListItem>
  );
};

const DisabledMenuItem: React.FC<IMenuItemProps> = ({ isOpen, icon, title }) => {
  const styles = useStyles();
  return (
    <ListItem className={styles.listItem}>
      <div className={styles.icon}>{icon}</div>
      {!isOpen && (
        <Typography variant="h3" className={styles.title}>
          {title}
        </Typography>
      )}
    </ListItem>
  );
};

interface IItemOrderProps {
  isOpen: boolean;
  link: string;
  icon: React.ReactNode;
  title: string;
  ordersCount: number;
}

const ItemOrders: React.FC<IItemOrderProps> = ({ isOpen, link, icon, title, ordersCount }) => {
  const styles = useStyles();
  return (
    <ListItem className={styles.listItem}>
      <NavLink to={link} className={styles.listItemLink} activeClassName={styles.active}>
        <div className={styles.icon}>{icon}</div>
        {!isOpen && (
          <Typography variant="h3" className={styles.title}>
            {title}
          </Typography>
        )}
        <div className={!isOpen ? styles.ordersBadge : styles.ordersBadgeMinimized}>
          <Typography variant="h6" color="textPrimary">
            {ordersCount}
          </Typography>
        </div>
      </NavLink>
    </ListItem>
  );
};

export default NavigationMenu;
