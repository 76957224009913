import React from "react";
import createIcon from "./createIcon";

const MenuIcon = createIcon(
  <>
    <path d="M0 0H26C27.1046 0 28 0.895431 28 2V22C28 23.1046 27.1046 24 26 24H0V0Z" />
    <circle cx="8" cy="12" r="2" fill="white" />
    <circle cx="8" cy="5" r="2" fill="white" />
    <circle cx="8" cy="19" r="2" fill="white" />
    <rect x="13" y="4" width="10" height="2" rx="1" fill="white" />
    <rect x="13" y="11" width="10" height="2" rx="1" fill="white" />
    <rect x="13" y="18" width="10" height="2" rx="1" fill="white" />,
  </>,
  28,
  24,
  "currentColor",
  "none",
);

export default MenuIcon;
