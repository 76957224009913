/** @jsx jsx */
import React from "react";
import { Box, jsx } from "theme-ui";
import { Styles } from "../theme/utils";

interface IServiceTypeIconProps {
  iconURL: string;
}

const styles: Styles = {
  icon: {
    marginRight: "8px",
    display: "inline-block",
    height: "20px",
    padding: "0 20px 10px 0",
    background: "center center no-repeat",
    backgroundSize: "contain",
    content: '""',
  },
};

const ServiceTypeIcon: React.FC<IServiceTypeIconProps> = ({ iconURL }) => {
  return <Box sx={styles.icon} style={{ backgroundImage: `url("${iconURL}")` }} />;
};

export default ServiceTypeIcon;
