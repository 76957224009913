import React from "react";
import createIcon from "./createIcon";

const CustomersIcon = createIcon(
  <path
    d="M1 21C1 16.8231 4.4237 13.437 8.64705 13.437C12.8704 13.437 16.2941 16.8231 16.2941 21M16.8403 13.437C21.0637 13.437 24.4874 16.8231 24.4874 21M15.2017 1.32015C15.7106 1.11418 16.263 1 16.8403 1C19.3743 1 21.4285 3.19971 21.4285 5.70588C21.4285 8.21204 19.3743 10.4118 16.8403 10.4118C16.263 10.4118 15.7106 10.2976 15.2017 10.0916M13.2353 5.70588C13.2353 8.21204 11.1811 10.4118 8.64705 10.4118C6.11304 10.4118 4.05882 8.21204 4.05882 5.70588C4.05882 3.19971 6.11304 1 8.64705 1C11.1811 1 13.2353 3.19971 13.2353 5.70588Z"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  26,
  22,
  "none",
  "currentColor",
);

export default CustomersIcon;
