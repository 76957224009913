/** @jsx jsx */
import { merge } from "lodash";
import React, { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { jsx, Flex, Box } from "theme-ui";
import { Id } from "../../../../core/common/type";
import { IDetailedOrder, IOrderService } from "../../../../core/order/IDetailedOrder";
import InputField from "../../../components/InputField";
import ServiceTypeIcon from "../../../components/ServiceTypeIcon";
import { formMargin } from "../../../theme/globalStyles";
import { Styles } from "../../../theme/utils";
import orderPresenter from "../../OrderPresenter";
import ServiceTabHeader from "../components/ServiceTabHeader";

interface ISimpleServiceTabProps {
  order: IDetailedOrder;
  onServiceChanged: (newServiceState: IOrderService) => void;
  onChangeWorkerRequest: (serviceId: Id) => void;
}

interface IRouteParams {
  serviceType: string;
  serviceId: string;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  mainContent: {
    flexGrow: 1,
  },
  linkPreview: {
    alignItems: "center",
  },
};

const SimpleServiceTab: React.FC<ISimpleServiceTabProps> = ({ order, onServiceChanged, onChangeWorkerRequest }) => {
  const {
    params: { serviceId },
  } = useRouteMatch<IRouteParams>();

  const handleChangeWorkerClick = useCallback(() => {
    onChangeWorkerRequest(serviceId);
  }, [onChangeWorkerRequest, serviceId]);

  const handleNotifyCustomerClick = useCallback(() => {
    orderPresenter.notifyCustomer(serviceId);
  }, [serviceId]);

  const service = order.Services.find(srv => srv.OrderServiceId === serviceId);

  const handleLabelChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onServiceChanged({
        ...service!,
        Label: newValue,
      });
    },
    [service, onServiceChanged],
  );

  const handleExternalLinkChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      onServiceChanged({
        ...service!,
        ExternalLink: newValue,
      });
    },
    [service, onServiceChanged],
  );

  return (
    <Flex sx={styles.root}>
      <ServiceTabHeader
        workerName={service?.ApplicationUserName ?? ""}
        onChangeWorkerClick={handleChangeWorkerClick}
        onNotifyCustomerClick={handleNotifyCustomerClick}
      />
      <Box sx={merge({}, styles.mainContent, formMargin.row)}>
        <Flex>
          <InputField label="Label" value={service?.Label ?? ""} onChange={handleLabelChange} />
        </Flex>
        <Flex sx={formMargin.row}>
          <InputField label="External link" value={service?.ExternalLink ?? ""} onChange={handleExternalLinkChange} />
        </Flex>
        <Flex sx={merge({}, formMargin.row, styles.linkPreview)}>
          <ServiceTypeIcon iconURL={service?.ImageURL ?? ""} />
          <a href={service?.ExternalLink ?? ""}>{service?.Label ?? ""}</a>
        </Flex>
      </Box>
    </Flex>
  );
};

export default SimpleServiceTab;
