/** @jsx jsx */
import { merge } from "lodash";
import React, { useCallback } from "react";
import { Flex, jsx } from "theme-ui";
import { PackageType } from "../../../../core/customer/PackageType";
import { IPackage } from "../../../../core/package/IPackage";
import { CustomerType } from "../../../../core/user/IUser";
import CardList from "../../../components/CardList";
import CheckboxGroup, { ICheckboxGroupItem } from "../../../components/CheckboxGroup";
import RadioGroup, { IRadioGroupItem } from "../../../components/RadioGroup";
import PackageGroupCard from "../../../packages/PackagesEditor/PackageGroupCard";
import PackagesEditor from "../../../packages/PackagesEditor/PackagesEditor";
import { formMargin } from "../../../theme/globalStyles";
import { Styles } from "../../../theme/utils";
import { IPackageGroup } from "./PackageGroups";

interface IPackagesContentProps {
  onCustomerTypeChange: (newCustomerType: string) => void;
  customerType: string;
  onPackageTypesChange: (newPackageType: PackageType[]) => void;
  packageTypes: PackageType[];

  defaultPrivatePackages: IPackageGroup[];
  defaultBusinessPackages: IPackageGroup[];
  onDefaultPrivatePackagesSelectionChange: (changes: Record<number, boolean>) => void;
  onDefaultBusinessPackagesSelectionChange: (changes: Record<number, boolean>) => void;

  customPackages: IPackage[];
  onAddCustomPackageClick: () => void;
  onCustomPackageChange: (changes: Partial<IPackage>, position: number) => void;
  onRemoveCustomPackageClick: (position: number) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
    marginTop: "24px",
  },
  radioGroup: {
    border: "1px solid",
    borderColor: "light",
    borderRadius: "8px",
    padding: "8px 16px",
  },
  cardList: {
    flexGrow: 1,
    width: "100%",
    marginTop: "16px",
  },
  packageEditor: {
    marginTop: "16px",
  },
};

const CUSTOMER_TYPE_ITEMS: IRadioGroupItem[] = [
  {
    title: "Business Customer",
    value: CustomerType.Business.toString(),
  },
  {
    title: "Private Customer",
    value: CustomerType.Personal.toString(),
  },
];

const PACKAGE_TYPE_ITEMS: ICheckboxGroupItem[] = [
  {
    title: "Default Packages",
    value: PackageType.Default.toString(),
    defaultChecked: true,
  },
  {
    title: "Custom Packages",
    value: PackageType.Custom.toString(),
  },
];

const PackagesContent: React.FC<IPackagesContentProps> = ({
  onCustomerTypeChange,
  customerType,
  onPackageTypesChange,
  packageTypes,
  defaultPrivatePackages,
  defaultBusinessPackages,
  onDefaultPrivatePackagesSelectionChange,
  onDefaultBusinessPackagesSelectionChange,
  customPackages,
  onAddCustomPackageClick,
  onCustomPackageChange,
  onRemoveCustomPackageClick,
}) => {
  const handlePackageTypesChange = useCallback(
    (newPackageTypes: string[]) => {
      onPackageTypesChange(newPackageTypes.map(it => Number.parseInt(it, 10)));
    },
    [onPackageTypesChange],
  );

  return (
    <Flex sx={styles.root}>
      <Flex>
        <RadioGroup
          sx={styles.radioGroup}
          name="customerType"
          items={CUSTOMER_TYPE_ITEMS}
          value={customerType ?? ""}
          onChange={onCustomerTypeChange as (value: string) => void}
        />
        {customerType === CustomerType.Business.toString() && (
          <CheckboxGroup
            sx={merge({}, styles.radioGroup, formMargin.rowElement)}
            name="packageType"
            items={PACKAGE_TYPE_ITEMS}
            value={packageTypes.map(it => it.toString(10))}
            onChange={handlePackageTypesChange}
          />
        )}
      </Flex>
      {packageTypes.includes(PackageType.Private) && (
        <CardList
          sx={styles.cardList}
          cards={defaultPrivatePackages.map(group => (
            <PackageGroupCard
              key={group.title}
              title={group.title}
              packages={group.items}
              onChange={onDefaultPrivatePackagesSelectionChange}
            />
          ))}
        />
      )}
      {packageTypes.includes(PackageType.Default) && (
        <CardList
          sx={styles.cardList}
          cards={defaultBusinessPackages.map(group => (
            <PackageGroupCard
              key={group.title}
              title={group.title}
              packages={group.items}
              onChange={onDefaultBusinessPackagesSelectionChange}
            />
          ))}
        />
      )}
      {packageTypes.includes(PackageType.Custom) && (
        <PackagesEditor
          sx={styles.packageEditor}
          packages={customPackages}
          onAddPackageClick={onAddCustomPackageClick}
          onPackageChanged={onCustomPackageChange}
          onRemovePackageClick={onRemoveCustomPackageClick}
        />
      )}
    </Flex>
  );
};

export default PackagesContent;
