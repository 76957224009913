/** @jsx jsx */
import React from "react";
import { Box, Card, Flex, jsx } from "theme-ui";
import { IAdministration } from "../../../core/administration/IAdministration";
import { Styles } from "../../theme/utils";
import CreateAdministrationEditor from "./CreateAdministrationEditor";
import CreateAdministrationHeader from "./CreateAdministrationHeader";

interface ICreateAdministrationPageContentProps {
  administration: IAdministration;
  onAdministrationChanged: (newAdministrationState: IAdministration) => void;
  onCancelClick: () => void;
  onAddClick: () => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    height: "80px",
    marginBottom: 3,
  },
  cardContainer: {
    flexGrow: 1,
    flexDirection: "column",
  },
  card: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
};

const CreateAdministrationPageContent: React.FC<ICreateAdministrationPageContentProps> = ({
  administration,
  onAdministrationChanged,
  onCancelClick,
  onAddClick,
}) => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.header}>
        <CreateAdministrationHeader onCancelClick={onCancelClick} onAddClick={onAddClick} />
      </Box>
      <Flex sx={styles.cardContainer}>
        <Card sx={styles.card} variant="paper">
          <CreateAdministrationEditor data={administration} onDataChanged={onAdministrationChanged} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default CreateAdministrationPageContent;
