import { INewAdministrationToServer } from "./ServerDto";

export const EMPTY_ADMINISTRATION: any = {
    AdministrationDetails: {
        firstName: null,
        lastName: null,
        email: null,
        confirmPassword: null,
        isActive: false,
        inOfficeStaff: false,
        isAdmin: true,
        sendEmailNotification: true,
        phoneNumber: "",
        customerType: 0,
        accountType: 2,
        userName: null,
        password: null,
    },
};

export default EMPTY_ADMINISTRATION;
