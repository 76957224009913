/** @jsx jsx */
import React from "react";
import { jsx, SxProps, Input, Box, Label } from "theme-ui";
import { Styles } from "../theme/utils";

export interface IInputFieldProps extends Omit<React.ComponentProps<"input">, "ref">, SxProps {
  className?: string;
  label?: string;
  variant?: "wide" | "narrow" | "fullWidth";
}

export const styles: Styles = {
  wide: {
    flexBasis: "340px",
  },
  narrow: {
    flexBasis: "100px",
  },
  fullWidth: {},
};

const InputField: React.FC<IInputFieldProps> = ({ className, label, variant = "wide", ...inputProps }) => {
  const variantStyles = styles[variant];

  return (
    <Box className={className} sx={variantStyles}>
      {label && <Label variant="label.input">{label}</Label>}
      <Input {...inputProps} />
    </Box>
  );
};

export default InputField;
