import React from "react";
import createIcon from "./createIcon";

const Delete = createIcon(
  <path
    d="M10 10L14.5858 14.5858C16 16 16 16 14.5858 17.4142L10 22M22 10L17.4142 14.5858C16 16 16 16 17.4142 17.4142L22 22"
    stroke="#29C6D0"
    strokeWidth="2"
  />,
  32,
  32,
  "none",
  "currentColor",
);

export default Delete;
