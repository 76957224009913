import React, { useCallback } from "react";
import { Card, Flex, Box } from "theme-ui";
import { ICustomer } from "../../../../core/customer/ICustomer";
import Tabs, { ITabInfo } from "../../../components/Tabs";
import AccountDetails from "../tabs/AccountDetails";
import Contacts from "../tabs/Contacts";
import Details from "../tabs/Details";
import InvoiceData from "../tabs/InvoiceData";
import Notes from "../tabs/Notes";
import Notifications from "../tabs/Notifications";
import Orders from "../tabs/Orders";
import Packages from "../tabs/Packages";

interface ICustomerEditorProps {
  tab: TCustomerEditorTab;
  onTabChanged: (tab: TCustomerEditorTab) => void;
  customer: ICustomer;
  onCustomerChanged: (changes: Partial<ICustomer>) => void;
  customerId: string;
}

export type TCustomerEditorTab =
  | "Details"
  | "AccountDetails"
  | "Contacts"
  | "Notes"
  | "Orders"
  | "Notifications"
  | "InvoiceData"
  | "SelectPackage";

interface ICustomerEditorTabInfo extends ITabInfo {
  component: React.ComponentType<{
    customer: Readonly<ICustomer>;
    onChanged?: (changes: Partial<ICustomer>) => void;
    customerId?: string;
  }>;
}

const TABS: Record<TCustomerEditorTab, ICustomerEditorTabInfo> = {
  Details: {
    id: "Details",
    title: "Details",
    component: Details,
  },
  AccountDetails: {
    id: "AccountDetails",
    title: "Account Details",
    component: AccountDetails,
  },
  Contacts: {
    id: "Contacts",
    title: "Contacts",
    component: Contacts,
  },
  Notes: {
    id: "Notes",
    title: "Notes",
    component: Notes,
  },
  Orders: {
    id: "Orders",
    title: "Orders",
    component: Orders,
  },
  Notifications: {
    id: "Notifications",
    title: "Notifications",
    component: Notifications,
  },
  InvoiceData: {
    id: "InvoiceData",
    title: "Invoice Data",
    component: InvoiceData,
  },
  SelectPackage: {
    id: "SelectPackage",
    title: "Select Package",
    component: Packages,
  },
};

const CustomerEditor: React.FC<ICustomerEditorProps> = ({
  tab,
  onTabChanged,
  customer,
  onCustomerChanged,
  customerId,
}) => {
  const handleTabSelected = useCallback(
    (tabId: string) => {
      onTabChanged(tabId as TCustomerEditorTab);
    },
    [onTabChanged],
  );

  const TabContent = TABS[tab].component;

  return (
    <Flex sx={{ flexGrow: 1 }}>
      <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} variant="paper">
        <Box>
          <Tabs tabs={Object.values(TABS)} defaultValue={tab} onSelect={handleTabSelected} />
        </Box>
        <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
          <TabContent customer={customer} customerId={customerId} onChanged={onCustomerChanged} />
        </Flex>
      </Card>
    </Flex>
  );
};

export default CustomerEditor;
