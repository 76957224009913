/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import InputField from "../../components/InputField";
import { formMargin } from "../../theme/globalStyles";
import { IAdministration } from "../../../core/administration/IAdministration";

interface IAdministrationProps {
  data: IAdministration;
  showBottomCheckboxes?: boolean;
}

const EditAdministrationEditor: React.FC<IAdministrationProps> = ({ data }) => {
  const details = data;
  const [marker, setMarker] = useState(false);

  useEffect(() => {
    if (details.Roles) {
      if ((details.Roles as any[]).includes("InOfficeStaff")) {
        setMarker(true);
      }
    }
  }, [setMarker, details.Roles]);

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={formMargin.row}>
        <InputField label="Login" value={details?.UserName as any} data-field-name="userName" disabled />

        <InputField
          sx={formMargin.rowElement}
          label="Email Address"
          value={details?.Email as any}
          data-field-name="email"
          disabled
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField label="First Name" value={details?.FirstName as any} data-field-name="firstName" disabled />
        <InputField
          sx={formMargin.rowElement}
          label="Last Name"
          value={details?.LastName as any}
          data-field-name="lastName"
          disabled
        />
      </Flex>
      <Box sx={formMargin.group}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">In Office staff</Text>}
          checked={marker}
          data-field-name="inOfficeStaff"
          disabled
        />
        <CheckboxWithLabel
          label={<Text variant="subHeading">Active</Text>}
          checked={!!details?.isActive}
          data-field-name="isActive"
          disabled
        />
      </Box>
    </Flex>
  );
};

export default EditAdministrationEditor;
