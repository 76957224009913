import { isEqual } from "lodash";
import { Id } from "../../core/common/type";
import { IUser } from "../../core/user/IUser";
import { IChangePasswordToServer } from "../../core/user/ServerDto";
import userRepository from "../../core/user/UserRepository";
import { store } from "../../store/store";
import ls from "../../core/localStorage/localStorage";
import { setUser } from "../../store/user/user";

class UserPresenter {
    async getUser(userId: Id): Promise<IUser> {
        const serverUser = await userRepository.getUser(userId);
        return {
            ...serverUser,
            IsActive: serverUser.isActive,
            PhoneNumber: serverUser.AccountPhoneNumber,
            OldPassword: "",
            Password: "",
            ConfirmPassword: "",
        };
    }

    async saveUser(oldUserState: IUser, newUserState: IUser): Promise<void> {
        if (!isEqual(oldUserState, newUserState)) {
            await this.updateAvatar(newUserState);
            await userRepository.updateAccountDetails(newUserState, newUserState.Id!);
        }

        const newPasswordInfo = getNewPasswordInfo(newUserState);
        if (newPasswordInfo) {
            await userRepository.changePassword(newPasswordInfo);
        }

        const serverUser = await userRepository.getUser(newUserState.Id!);
        store.dispatch(setUser(serverUser));
        ls.set("user", serverUser);
    }

    private async updateAvatar(user: IUser) {
        /* eslint-disable no-param-reassign */
        const { AvatarFile } = user;
        if (AvatarFile) {
            const { Avatar, Thumb } = await userRepository.uploadAvatar(AvatarFile);
            user.Avatar = Avatar;
            user.AvatarThumb = Thumb;
        }
        user.AvatarFile = undefined;
        user.AvatarFileBase64 = undefined;
        /* eslint-enable no-param-reassign */
    }
}

function getNewPasswordInfo(user: IUser): IChangePasswordToServer | undefined {
    if (user.Id && user.OldPassword && user.Password && user.ConfirmPassword) {
        return {
            UserId: user.Id,
            OldPassword: user.OldPassword,
            NewPassword: user.Password,
            ConfirmPassword: user.ConfirmPassword,
        };
    }
}

const userPresenter = new UserPresenter();
export default userPresenter;
