import { IListWorker, IWorker, IWorkerPostCode } from "./IWorker";

export const EMPTY_LIST_WORKER: IListWorker = {
    WorkerId: null,
    Email: "",
    IsActive: null,
    WorkerType: null,
    SystemLogin: null,
    Name: null,
    ApplicationUserId: null,
    Postcodes: null,
    Invited: null,
    Rating: null,
};

const EMPTY_ACCOUNT_DETAILS: IWorker["AccountDetails"] = {
    Id: null,
    UserName: null,
    Email: null,
    DaysToPayInvoices: null,
    OldPassword: null,
    Password: null,
    ConfirmPassword: null,
    SendEmailNotification: null,
    IsActive: null,
    FirstName: null,
    LastName: null,
    PhoneNumber: null,
    IsAppliedForBusiness: null,
    CustomerType: null,
    Avatar: null,
    AvatarThumb: null,
};

const EMPTY_WORKER_DETAILS: IWorker["WorkerDetails"] = {
    GoogleCalendarId: null,
    Notes: null,
    PhoneNumber: null,
    Rating: null,
    FullTimeType: null,
    AddressId: null,
    CreatedDateTime: null,
    AmendedDateTime: null,
    Timestamp: null,
    HouseName: null,
    HouseNumber: null,
    DoorNumber: null,
    Road: null,
    Area: null,
    Town: null,
    Country: null,
    Postcode: null,
    Flat: null,
    AddressString: null,
    IsActive: null,
    IsSendEmailNotifications: null,
};

export const EMPTY_WORKER: IWorker = {
    AccountDetails: EMPTY_ACCOUNT_DETAILS,
    WorkerId: null,
    WorkerType: null,
    WorkerDetails: EMPTY_WORKER_DETAILS,
    Postcodes: [],
    Rates: [],
    Services: [],
    Notes: [],
    Portfolio: [],
};

export const EMPTY_POST_CODE: IWorkerPostCode = {
    WorkerPostcodeId: null,
    WorkerId: null,
    Postcode: "",
};
