import React, { useCallback, useEffect, useState } from "react";
import administrationRepository from "../../../core/administration/AdministrationRepository";
import {
  IAdministrationListFiltersToServer,
  IListAdministrationFromServer,
} from "../../../core/administration/ServerDto";
import AdministrationListPageContent from "./AdministrationListPageContent";
import { IAdministrationListFilters, IAdministrationListSort } from "./types";

const AdministrationListPage: React.FC = () => {
  const [admins, setAdmins] = useState([] as IListAdministrationFromServer[]);
  const [filters, setFilters] = useState<IAdministrationListFilters>({
    searchValue: null,
  });
  const [sort, setSort] = useState<IAdministrationListSort>({
    sortField: "",
    sortOrder: "",
  });

  const getAdministration = useCallback(async () => {
    const adminFilters: IAdministrationListFiltersToServer = {
      Name: "",
      EmailAddress: "",
      IsAdmin: true,
    };
    const serverAdmins = await administrationRepository.getList(adminFilters, undefined);
    setAdmins(serverAdmins);
  }, []);

  useEffect(() => {
    getAdministration();
  }, [getAdministration]);

  return (
    <AdministrationListPageContent
      admins={admins}
      filters={filters}
      onFiltersChange={setFilters}
      sort={sort}
      onSortChange={setSort}
    />
  );
};

export default AdministrationListPage;
