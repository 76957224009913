/** @jsx jsx */
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { jsx, Flex, Box, Card } from "theme-ui";
import { IAdministrationListFilters, IAdministrationListSort } from "./types";
import AdministrationListHeader from "./AdministrationListHeader";
import AdministrationListControl from "./AdministrationListControl";
import AdministrationTable from "./AdministrationTable";
import { IListAdministrationFromServer } from "../../../core/administration/ServerDto";

interface IAdministrationListPageContentProps {
  admins: IListAdministrationFromServer[];
  filters: IAdministrationListFilters;
  onFiltersChange: (newFiltersState: IAdministrationListFilters) => void;
  sort: IAdministrationListSort;
  onSortChange: (newSortState: IAdministrationListSort) => void;
}

const AdministrationListPageContent: React.FC<IAdministrationListPageContentProps> = ({
  admins,
  filters,
  onFiltersChange,
  sort,
  onSortChange,
}) => {
  const history = useHistory();

  const handleSearch = useCallback(
    (searchValue: string) => {
      onFiltersChange({
        ...filters,
        searchValue,
      });
    },
    [filters, onFiltersChange],
  );

  const handleAddWorkerClick = useCallback(() => {
    history.push("/administration/add");
  }, [history]);

  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <AdministrationListHeader onSearch={handleSearch} />
      </Box>
      <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} variant="paper">
          <AdministrationListControl onAddClick={handleAddWorkerClick} sort={sort} onSortChange={onSortChange} />
          <AdministrationTable sx={{ marginTop: 3 }} admins={admins} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default AdministrationListPageContent;
