/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box, Text } from "theme-ui";
import { IDetailedOrder } from "../../../../core/order/IDetailedOrder";
import CheckboxWithLabel from "../../../components/CheckboxWithLabel";
import InputField from "../../../components/InputField";
import Select, { ISelectOption } from "../../../components/Select";
import TextAreaField from "../../../components/TextAreaField";
import { formMargin } from "../../../theme/globalStyles";
import GoogleMaps from "../../../../core/google/Map";

interface IPropertyTabProps {
  order: IDetailedOrder;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
}

const WITH_OPTIONS: ISelectOption[] = [
  {
    label: "Agent",
    value: "Agent",
  },
  {
    label: "Porter",
    value: "Porter",
  },
];

const AVAILABLE_OPTIONS: ISelectOption[] = [
  {
    label: "Any time",
    value: "Any time",
  },
  {
    label: "Specific time",
    value: "Specific time",
  },
];

const PropertyTab: React.FC<IPropertyTabProps> = ({ order, onOrderChanged }) => {
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { checked } = event.currentTarget;
      onOrderChanged({
        ...order,
        [fieldName!]: checked,
      });
    },
    [onOrderChanged, order],
  );

  const input = document.getElementById("addressInput") as HTMLInputElement;

  const handleAddressInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      onOrderChanged({
        ...order,
        PropertyAddress: {
          ...order.PropertyAddress!,
          [fieldName!]: value,
        },
      });
    },
    [onOrderChanged, order],
  );

  const changeAddress = useCallback(
    (newValue: string) => {
      onOrderChanged({
        ...order,
        PropertyAddress: {
          ...order.PropertyAddress!,
          AddressString: newValue,
        },
      });
    },
    [onOrderChanged, order],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      onOrderChanged({
        ...order,
        [fieldName!]: value,
      });
    },
    [onOrderChanged, order],
  );

  const handleNotesChange = useCallback(
    (newValue: string) => {
      onOrderChanged({
        ...order,
        Notes: newValue,
      });
    },
    [onOrderChanged, order],
  );

  const handleWithChange = useCallback(
    (newValue: string | undefined | null) => {
      onOrderChanged({
        ...order,
        KeysWith: newValue ?? null,
      });
    },
    [onOrderChanged, order],
  );

  const handleAvailableChange = useCallback(
    (newValue: string | undefined | null) => {
      onOrderChanged({
        ...order,
        KeysTimeOption: newValue ?? null,
      });
    },
    [onOrderChanged, order],
  );

  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Flex>
        <Flex sx={{ flexDirection: "column" }}>
          <Flex>
            <InputField
              label="Address or zip code"
              value={order.PropertyAddress?.AddressString || order.Address || ""}
              data-field-name="AddressString"
              onChange={handleAddressInputChange}
              id="addressInput"
            />
          </Flex>
          <Flex>
            <InputField
              sx={formMargin.row}
              label="Flat Number or Letter"
              value={order.PropertyAddress?.Flat ?? ""}
              data-field-name="Flat"
              onChange={handleAddressInputChange}
            />
          </Flex>
          <Flex>
            <InputField
              sx={formMargin.row}
              label="Door Number"
              value={order.PropertyAddress?.DoorNumber ?? ""}
              data-field-name="DoorNumber"
              onChange={handleAddressInputChange}
            />
          </Flex>
        </Flex>
        <GoogleMaps
          getAdress={changeAddress}
          placeMarker={order.PropertyAddress?.AddressString}
          inputAutocomplete={input}
        />
      </Flex>
      <Box sx={formMargin.row}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">Keys</Text>}
          checked={order.Keys ?? false}
          data-field-name="Keys"
          onChange={handleCheckboxChange}
        />
      </Box>
      <Flex sx={formMargin.row}>
        <Select label="With" options={WITH_OPTIONS} value={order.KeysWith ?? ""} onChange={handleWithChange} />
        <InputField
          sx={formMargin.rowElement}
          label="Contact"
          value={order.KeysWithDetail ?? ""}
          data-field-name="KeysWithDetail"
          onChange={handleInputChange}
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <Select
          label="Available"
          options={AVAILABLE_OPTIONS}
          value={order.KeysTimeOption ?? ""}
          onChange={handleAvailableChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="At"
          value={order.KeysTime ?? ""}
          data-field-name="KeysTime"
          onChange={handleInputChange}
        />
      </Flex>
      <Box sx={formMargin.row}>
        <TextAreaField label="Notes" rows={5} value={order.Notes ?? ""} onChange={handleNotesChange} />
      </Box>
    </Flex>
  );
};

export default PropertyTab;
