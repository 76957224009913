/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx } from "theme-ui";
import { cloneDeep } from "lodash";
import { EMPTY_ORDER_CONTACT } from "../../../../core/order/consts";
import { IDetailedOrder, IOrderContact } from "../../../../core/order/IDetailedOrder";
import AddButton from "../../../components/AddButton";
import CardList from "../../../components/CardList";
import OrderContact from "./OrderContact";

interface IOrderContactListProps {
  order: IDetailedOrder;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
}

const OrderContactList: React.FC<IOrderContactListProps> = ({ order, onOrderChanged }) => {
  const handleAddContactClick = useCallback(() => {
    const contacts = order.Contacts ?? [];
    const newContacts = cloneDeep(contacts);
    newContacts.push(EMPTY_ORDER_CONTACT);
    onOrderChanged({
      ...order,
      Contacts: newContacts,
    });
  }, [order, onOrderChanged]);

  const handleContactChanged = useCallback(
    (updatedContact: IOrderContact, listPosition: number) => {
      const contacts = order.Contacts ?? [];
      const newContacts = cloneDeep(contacts);
      newContacts[listPosition] = cloneDeep(updatedContact);
      onOrderChanged({
        ...order,
        Contacts: newContacts,
      });
    },
    [order, onOrderChanged],
  );

  const handleContactRemove = useCallback(
    (listPosition: number) => {
      const contacts = order.Contacts ?? [];
      const newContacts = contacts.filter((_, i) => i !== listPosition);
      onOrderChanged({
        ...order,
        Contacts: newContacts,
      });
    },
    [order, onOrderChanged],
  );

  return (
    <CardList
      sx={{ flexGrow: 1 }}
      customControls={<AddButton title="Add" onClick={handleAddContactClick} />}
      cards={order.Contacts?.map((contact, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <OrderContact
          key={`${contact.OrderContactId}-${i}`}
          title={`Contact ${i + 1}`}
          listPosition={i}
          contact={contact}
          onContactChanged={handleContactChanged}
          onRemove={handleContactRemove}
        />
      ))}
    />
  );
};

export default OrderContactList;
