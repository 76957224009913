import React from "react";
import createIcon from "./createIcon";

const SearchIcon = createIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.6699 16.1674L12.7417 11.2371C13.6251 10.0322 14.0983 8.57553 14.0917 7.08149C14.0748 3.18154 10.9206 0.0225968 7.02083 7.16407e-05C5.15457 -0.00837243 3.36245 0.729951 2.0437 2.05057C0.724951 3.37118 -0.0108836 5.16438 0.000121694 7.0307C0.0169772 10.931 3.1715 14.0902 7.07162 14.1128C8.57169 14.1193 10.0336 13.6405 11.2393 12.7479L11.2444 12.744L16.1681 17.6705C16.4343 17.9498 16.831 18.0628 17.2044 17.9658C17.5779 17.8689 17.8694 17.5771 17.9661 17.2035C18.0627 16.83 17.9494 16.4334 17.6699 16.1674ZM7.06635 12.7007C3.94643 12.6828 1.42285 10.1557 1.40916 7.0356C1.40074 5.54274 1.98945 4.10847 3.04425 3.05206C4.09906 1.99564 5.5324 1.40477 7.02521 1.41097C10.1451 1.42892 12.6687 3.95604 12.6824 7.07611C12.6908 8.56897 12.1021 10.0032 11.0473 11.0597C9.99251 12.1161 8.55917 12.7069 7.06635 12.7007Z"
    fill="currentColor"
  />,
  18,
  18,
  "none",
);

export default SearchIcon;
