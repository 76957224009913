/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Flex, jsx } from "theme-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { TCustomerListFromServer, TCustomerOrder, TCustomerSortField } from "../../../core/customer/ServerDto";
import Tabs, { ITabInfo } from "../../components/Tabs";
import { Styles } from "../../theme/utils";
import SearchInput from "../../components/SearchInput";
import Select, { ISelectOption } from "../../components/Select";
import customerPresenter from "../../customers/CustomerPresenter";
import { CustomerType } from "../../../core/user/IUser";
import CustomersTable from "./CustomersTable";
import AddOrderHeader from "./AddOrderHeader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  pageContent: {
    marginTop: 24,
    padding: 16,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  pageContentBlock: {
    marginTop: 16,
  },
  tableBlock: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  table: {
    flexGrow: 1,
    height: 0,
  },
  contentLine: {
    display: "flex",
    alignItems: "center",
  },
  contentLineItem: {
    display: "block",
  },
  customerSort: {
    width: 258,
    color: theme.palette.text.secondary,
  },
  customersTable: {
    tableLayout: "fixed",
  },
}));

const styles: Styles = {
  searchInput: {
    marginTop: "16px",
  },
  nextBlock: {
    justifyContent: "flex-end",
  },
};

const TAB_FILTERS: Record<string, ITabFilter> = {
  business: {
    customerType: CustomerType.Business,
  },
  private: {
    customerType: CustomerType.Personal,
    IsAppliedForBusiness: false,
  },
};

interface ITabFilter {
  customerType?: CustomerType;
  IsAppliedForBusiness?: boolean;
}

const TABS: Readonly<ITabInfo[]> = [
  {
    id: "business",
    title: "Business customer",
  },
  {
    id: "private",
    title: "Private client",
  },
];

const SORT_OPTIONS: ISelectOption[] = [
  {
    value: "Code Asc",
    label: "Code",
  },
  {
    value: "Code Desc",
    label: "Code (reversed)",
  },
  {
    value: "Address Asc",
    label: "Address",
  },
  {
    value: "Address Desc",
    label: "Address (reversed)",
  },
  {
    value: "EmailAddress Asc",
    label: "EmailAddress",
  },
  {
    value: "EmailAddress Desc",
    label: "EmailAddress (reversed)",
  },
];

interface IState {
  tabId: string;
  searchValue: string;
  sortField: TCustomerSortField;
  sortOrder: TCustomerOrder;
  page: number;
}

const INITIAL_STATE: IState = {
  tabId: TABS[0].id,
  searchValue: "",
  sortField: "Code" as TCustomerSortField,
  sortOrder: "Asc" as TCustomerOrder,
  page: 1,
};

const AddOrderPage: React.FC = () => {
  const muiStyles = useStyles();
  const [customers, setCustomers] = useState<TCustomerListFromServer>([]);
  const [selectedApplicationUserId, setSelectedApplicationUserId] = useState<string | undefined>();
  const history = useHistory();

  const [state, setState] = useState({
    ...INITIAL_STATE,
  });

  const disablesNext = useMemo(() => selectedApplicationUserId === undefined, [selectedApplicationUserId]);

  const onNextClick = useCallback(() => {
    if (selectedApplicationUserId) {
      const selectedCustomer = customers.find(customer => customer.ApplicationUserId === selectedApplicationUserId);
      history.push(`/orders/add/generate/${selectedApplicationUserId}/${selectedCustomer?.BranchId || ""}`);
    }
  }, [history, selectedApplicationUserId, customers]);

  const onCancelClick = useCallback(() => {
    setSelectedApplicationUserId(undefined);
    setState({ ...INITIAL_STATE });
  }, [setState, setSelectedApplicationUserId]);

  const handleTabChange = useCallback((tabId: string) => {
    setSelectedApplicationUserId(undefined);
    setState(prevState => ({
      ...prevState,
      tabId,
    }));
  }, []);

  const handleCustomerSearch = useCallback(
    (searchValue: string) => {
      setSelectedApplicationUserId(undefined);
      setState(prevState => ({
        ...prevState,
        searchValue,
      }));
    },
    [setState, setSelectedApplicationUserId],
  );

  const handleSortChange = useCallback((sortParams: string | null | undefined) => {
    const [sortField, sortOrder] = sortParams?.split(" ");
    setSelectedApplicationUserId(undefined);
    setState(prevState => ({
      ...prevState,
      sortField: sortField as TCustomerSortField,
      sortOrder: sortOrder as TCustomerOrder,
    }));
  }, []);

  useEffect(() => {
    async function getCustomers() {
      const tabFilter = TAB_FILTERS[state.tabId];

      const list = await customerPresenter.getCustomers(
        {
          ...tabFilter,
          CombinedFilter: state.searchValue,
          page: state.page,
        },
        {
          SortBy: state.sortField,
          OrderBy: state.sortOrder,
        },
      );
      setCustomers(list);
    }
    getCustomers();
  }, [state]);

  return (
    <div className={muiStyles.root}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <AddOrderHeader
          disabledCreateOrder
          onCancelClick={onCancelClick}
          onNextClick={onNextClick}
          disablesNext={disablesNext}
        />
      </Box>

      <Flex sx={styles.searchInput}>
        <SearchInput placeholder="Find customer" onSearch={handleCustomerSearch} />
      </Flex>
      <Paper className={muiStyles.pageContent}>
        <Tabs tabs={TABS} defaultValue={state.tabId} onSelect={handleTabChange} />
        <div className={classNames(muiStyles.pageContentBlock, muiStyles.contentLine)}>
          <Select
            className={muiStyles.customerSort}
            sx={{ zIndex: "99999" }}
            placeholder="Sort by"
            options={SORT_OPTIONS}
            value={`${state.sortField} ${state.sortOrder}`}
            onChange={handleSortChange}
          />
        </div>
        <div className={classNames(muiStyles.pageContentBlock, muiStyles.tableBlock)}>
          <CustomersTable
            selectedApplicationUserId={selectedApplicationUserId}
            onClick={setSelectedApplicationUserId}
            className={muiStyles.table}
            customers={customers}
          />
        </div>
      </Paper>
    </div>
  );
};

export default AddOrderPage;
