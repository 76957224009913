/** @jsx jsx */
import { cloneDeep, merge } from "lodash";
import { jsx } from "theme-ui";
import React, { useCallback } from "react";
import { IPackage } from "../../../core/package/IPackage";
import { IPackagesPageInfo } from "../IPackagesPageInfo";
import PackagesEditor from "../PackagesEditor/PackagesEditor";
import packagesPresenter from "../PackagesPresenter";

interface IPackagesProps {
  data: IPackagesPageInfo;
  onDataChanged: (newEntityState: IPackagesPageInfo) => void;
}

const Packages: React.FC<IPackagesProps> = ({ data, onDataChanged }) => {
  const handleAddPackageClick = useCallback(async () => {
    const newPackage = await packagesPresenter.createNewPackage();
    onDataChanged({
      ...data,
      packages: [...data.packages, newPackage],
    });
  }, [data, onDataChanged]);

  const handlePackageChanged = useCallback(
    (changes: Partial<IPackage>, position: number) => {
      const newPackages = cloneDeep(data.packages);
      merge(newPackages[position], changes);

      onDataChanged({
        ...data,
        packages: newPackages,
      });
    },
    [data, onDataChanged],
  );

  const handleRemovePackageClick = useCallback(
    (index: number) => {
      onDataChanged({
        ...data,
        packages: data.packages.filter((pkg, i) => i !== index),
      });
    },
    [data, onDataChanged],
  );

  return (
    <PackagesEditor
      sx={{ paddingTop: "16px" }}
      packages={data.packages}
      onAddPackageClick={handleAddPackageClick}
      onPackageChanged={handlePackageChanged}
      onRemovePackageClick={handleRemovePackageClick}
    />
  );
};

export default Packages;
