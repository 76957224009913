/** @jsx jsx */
import React from "react";
import { Link } from "react-router-dom";
import { Box, Flex, Heading, jsx } from "theme-ui";
import { link } from "../../theme/globalStyles";
import LeftArrowIcon from "../../icons/LeftArrow";
import Button from "../../components/Button";

interface IAddOrderHeaderProps {
  onCancelClick?: () => void;
  createOrderClick?: () => void;
  disabledCreateOrder?: boolean;
  disablesNext?: boolean;
  onNextClick?: () => void;
  onBackClick?: () => void;
}

const AddOrderHeader: React.FC<IAddOrderHeaderProps> = ({
  onCancelClick,
  createOrderClick,
  disabledCreateOrder,
  disablesNext,
  onNextClick,
  onBackClick,
}) => {
  return (
    <Flex sx={{ justifyContent: "space-between" }}>
      <Box>
        <Flex>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/orders/list" sx={link.neutralColor}>
              <LeftArrowIcon />
            </Link>
          </Box>
          <Box sx={{ marginLeft: 3 }}>
            <Heading>New Order</Heading>
          </Box>
        </Flex>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Link to="/orders/list" sx={link.neutralColor}>
          <Box sx={{ marginRight: 4 }}>
            <Button variant="dangerOutline" title="Cancel" onClick={onCancelClick} />
          </Box>
        </Link>
        {!onNextClick && (
          <Box sx={{ marginRight: 4 }}>
            <Button variant="primaryOutline" title="Back" onClick={onBackClick} />
          </Box>
        )}
        {onNextClick && (
          <Box sx={{ marginRight: 4 }}>
            <Button
              variant={disablesNext ? "primaryDisabledOutline" : "primaryOutline"}
              title="Next"
              onClick={onNextClick}
            />
          </Box>
        )}
        <Box>
          <Button
            variant={disabledCreateOrder ? "primaryDisabledFull" : "primaryFull"}
            title="Generate Order"
            onClick={createOrderClick}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default AddOrderHeader;
