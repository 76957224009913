const config = {
    apiUrl: process.env.API_URL || "",
    logoutUrl: process.env.LOGOUT_URL || "",
    logLevel: getLogLevel(),
    currency: process.env.CURRENCY || "£",
    xeroUrl: process.env.XERO_URL || "",
    preferredCountries: (process.env.PREFERRED_COUNTRIES || "us,gb").split(","),
};

function getLogLevel(): string {
    let logLevel = "debug";
    if (process.env.NODE_ENV === "production") {
        logLevel = "info";
    }
    if (process.env.LOG_LEVEL) {
        logLevel = process.env.LOG_LEVEL || logLevel;
    }
    return logLevel;
}

export default config;
