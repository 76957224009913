/** @jsx jsx */
import { merge, noop } from "lodash";
import moment from "moment";
import React, { useCallback } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import { Link } from "react-router-dom";
import config from "../../../config/config";
import { Id } from "../../../core/common/type";
import { OrderState } from "../../../core/order/IDetailedOrder";
import { IListOrder } from "../../../core/order/IListOrder";
import CheckboxWithId from "../../components/CheckboxWithId";
import ServiceTypeIcon from "../../components/ServiceTypeIcon";
import { Styles } from "../../theme/utils";
import orderPresenter from "../OrderPresenter";

interface IOrderListProps {
  className?: string;
  orders: IListOrder[];
  selectable?: boolean;
  selected?: Set<Id>;
  onSelect?: (id: Id, value: boolean) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
  },
  order: {
    padding: "8px 0",
    borderTop: "1px solid",
    borderTopColor: "lightGray",
    "&:last-of-type": {
      borderBottom: "1px solid",
      borderBottomColor: "lightGray",
    },
  },
  orderLink: {
    flexGrow: 1,
    display: "flex",
    textDecoration: "none",
    color: "inherit",
  },
  orderCheckbox: {
    padding: "4px 0",
  },
  orderDate: {
    flexBasis: "100px",
    flexShrink: 0,
    lineHeight: "32px",
    color: "textSecondary",
  },
  orderMainInfo: {
    flexGrow: 1,
    flexShrink: 1,
  },
  mainInfoLine: {
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "8px",
    height: "32px",
    "&:first-of-type": {
      marginTop: 0,
    },
  },
  iconsLine: {
    justifyContent: "flex-start",
  },
  secondaryInfo: {
    color: "textSecondary",
  },
  icon: {
    marginRight: "8px",
    display: "inline-block",
    height: "20px",
    padding: "0 20px 10px 0",
    background: "center center no-repeat",
    backgroundSize: "contain",
    content: '""',
  },
};

const OrderList: React.FC<IOrderListProps> = ({
  orders,
  className,
  selectable = false,
  selected = new Set(),
  onSelect = noop,
}) => {
  const { currency } = config;

  const handleCheckboxChange = useCallback(
    (id: Id, event: React.ChangeEvent<HTMLInputElement>) => {
      onSelect(id, event.target.checked);
    },
    [onSelect],
  );

  return (
    <Flex sx={styles.root} className={className}>
      {orders.map(order => (
        <Flex sx={styles.order} key={order.OrderId!}>
          {selectable && (
            <Box sx={styles.orderCheckbox}>
              <CheckboxWithId
                id={order.OrderId!}
                onChange={handleCheckboxChange}
                checked={selected.has(order.OrderId!)}
              />
            </Box>
          )}
          <Link key={order.OrderId!} to={`/orders/edit/${order.OrderId!}`} sx={styles.orderLink}>
            <Box sx={styles.orderDate}>{moment(order.DateTaken).format("DD MMM YYYY")}</Box>
            <Box sx={styles.orderMainInfo}>
              <Flex sx={merge({}, styles.mainInfoLine, styles.iconsLine)}>
                {order.Services.map(service => (
                  <ServiceTypeIcon key={service.Name!} iconURL={service.ImageURL ?? ""} />
                ))}
              </Flex>
              <Flex sx={styles.mainInfoLine}>
                <Text>{order.Ref}</Text>
                <Text variant="heading">
                  {currency}
                  {order.Cost}
                </Text>
              </Flex>
              <Flex sx={merge({}, styles.mainInfoLine, styles.secondaryInfo)}>
                <Text variant="subHeading">{order.PropertyAddress}</Text>
                <Text variant="subHeading">
                  {orderPresenter.orderStatusToString(order.OrderState ?? OrderState.DateAgreement)}
                </Text>
              </Flex>
            </Box>
          </Link>
        </Flex>
      ))}
    </Flex>
  );
};

export default OrderList;
