import config from "../../config/config";
import httpService from "../http/HttpService";

const ACCOUNTS_PATH = `${config.apiUrl}/accounts`;

class AuthRepository {
    async auth(loginId: string): Promise<[Record<string, unknown> | null, Record<string, unknown> | null]> {
        const response = await httpService.get(`${ACCOUNTS_PATH}/login-response/${loginId}`);
        if (response.error) return Promise.reject(response.message);

        if (typeof response.response === "object" && !Array.isArray(response.response)) {
            const user = response.response;
            const token = (user?.token as Record<string, unknown>) ?? null;
            return [user, token];
        }
        return [null, null];
    }
}

const authRepository = new AuthRepository();
export default authRepository;
