import React from "react";
import createIcon from "./createIcon";

const DownArrowIcon = createIcon(
  <path d="M1 0.5L5 4.5L9 0.5" stroke="#8D8F92" strokeLinecap="round" strokeLinejoin="round" />,
  10,
  5,
  "none",
  "currentColor",
);

export default DownArrowIcon;
