/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { Flex, Box, jsx, Card } from "theme-ui";
import { ITabbedEditorTabInfo } from "../components/TabbedEditor";
import TabbedEditorWithRoutes from "../components/TabbedEditorWithRoutes";
import { IPackagesPageInfo } from "./IPackagesPageInfo";
import PackagesPageHeader from "./PackagesPageHeader";
import Packages from "./tabs/Packages";
import ServicesTab from "./tabs/Services/Services";
import Upsell from "./tabs/Upsell";

interface IPackagesPagesContentProps {
  onSaveChangesClick: () => void;
  data: IPackagesPageInfo;
  onDataChanged: (newData: IPackagesPageInfo) => void;
  basePath: string;
  baseUrl: string;
}

const TABS = {
  packages: {
    id: "packages",
    title: "Packages",
    component: Packages,
  } as ITabbedEditorTabInfo<IPackagesPageInfo>,
  services: {
    id: "services",
    title: "Services",
    component: ServicesTab,
  } as ITabbedEditorTabInfo<IPackagesPageInfo>,
  upsell: {
    id: "upsell",
    title: "Upsell",
    component: Upsell,
  } as ITabbedEditorTabInfo<IPackagesPageInfo>,
};

const PackagesPageContent: React.FC<IPackagesPagesContentProps> = ({
  onSaveChangesClick,
  data,
  onDataChanged,
  basePath,
  baseUrl,
}) => {
  const [showSaveChanges, setShowSaveChanges] = useState(true);

  const handleTabChanged = useCallback((newTabId: string) => {
    setShowSaveChanges(newTabId !== "services");
  }, []);

  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <PackagesPageHeader showSaveChanges={showSaveChanges} onSaveChangesClick={onSaveChangesClick} />
      </Box>
      <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} variant="paper">
          <TabbedEditorWithRoutes
            tabs={TABS}
            data={data}
            onDataChanged={onDataChanged}
            baseUrl={baseUrl}
            basePath={basePath}
            onTabChanged={handleTabChanged}
          />
        </Card>
      </Flex>
    </Flex>
  );
};

export default PackagesPageContent;
