/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box } from "theme-ui";
import { noop } from "lodash";
import { ICustomer } from "../../../../core/customer/ICustomer";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { formMargin } from "../../../theme/globalStyles";
import customerPresenter from "../../CustomerPresenter";

interface INewCustomerAccountDetailsProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const NewCustomerAccountDetails: React.FC<INewCustomerAccountDetailsProps> = ({ customer, onChanged = noop }) => {
  const accountDetails = customer.AccountDetails;

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName, fieldType } = event.currentTarget.dataset;
      const { value } = event.currentTarget;

      let fieldValue: number | string = value;
      if (fieldType === "number") {
        fieldValue = Number.parseInt(value, 10);
      }

      onChanged({
        AccountDetails: {
          [fieldName!]: fieldValue,
        },
      });
    },
    [onChanged],
  );

  const handleGeneratePasswordClick = useCallback(() => {
    const password = customerPresenter.generatePassword();
    onChanged({
      AccountDetails: {
        Password: password,
        ConfirmPassword: password,
      },
    });
  }, [onChanged]);

  return (
    <Box sx={{ marginTop: 7 }}>
      {/* <Flex> */}
      {/*  <InputField */}
      {/*    label="Login" */}
      {/*    value={accountDetails.UserName ?? ""} */}
      {/*    data-field-name="UserName" */}
      {/*    onChange={handleInputChange} */}
      {/*  /> */}
      {/* </Flex> */}
      <Flex>
        <InputField
          label="First Name"
          value={accountDetails.FirstName ?? ""}
          data-field-name="FirstName"
          onChange={handleInputChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Last Name"
          value={accountDetails.LastName ?? ""}
          data-field-name="LastName"
          onChange={handleInputChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Email Address"
          value={accountDetails.Email ?? ""}
          data-field-name="Email"
          onChange={handleInputChange}
        />
      </Flex>
      <Flex sx={formMargin.group}>
        <InputField
          label="Password"
          // type="password"
          value={accountDetails.Password ?? ""}
          data-field-name="Password"
          onChange={handleInputChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Confirm Password"
          // type="password"
          value={accountDetails.ConfirmPassword ?? ""}
          data-field-name="ConfirmPassword"
          onChange={handleInputChange}
        />
      </Flex>
      <Box sx={formMargin.row}>
        <Button title="Generate Password" variant="primary" onClick={handleGeneratePasswordClick} />
      </Box>
    </Box>
  );
};

export default NewCustomerAccountDetails;
