import { IPackage, IService } from "./IPackage";
import {
    IPackageFromServer,
    IPackageToServer,
    IPackageServiceFromServer,
    IPackageServiceToServer,
    IOrderTypeFromServer,
    IUpdateOrderTypeToServer,
} from "./ServerDto";

export function toPackage(serverPackage: IPackageFromServer): IPackage {
    return {
        Id: serverPackage.OrderTemplateId,
        CustomerId: serverPackage.CustomerId,
        Title: serverPackage.Description,
        Price: serverPackage.PriceExVAT,
        Checked: serverPackage.Checked,
        Services: serverPackage.Services.map(toPackageService),
    };
}

export function toPackageToServer(pkg: IPackage): IPackageToServer {
    return {
        OrderTemplateId: pkg.Id,
        CustomerId: pkg.CustomerId,
        Description: pkg.Title ?? "",
        PriceExVAT: pkg.Price,
        Services: pkg.Services?.map(toPackageServiceToServer) ?? [],
    };
}

export function toPackageService(srv: IPackageServiceFromServer): IService {
    return {
        Id: srv.OrderTypeId,
        Title: srv.Name,
        Image: srv.ImageURL,
        Amount: srv.DefaultAmount,
    };
}

export function toPackageServiceToServer(service: IService): IPackageServiceToServer {
    return {
        OrderTypeId: service.Id,
        Name: service.Title ?? "",
        Checked: !!service.Amount && service.Amount > 0,
        ImageURL: service.Image,
        DefaultAmount: service.Amount,
    };
}

export function toOrderTypeService(srv: IOrderTypeFromServer): IService {
    return {
        Id: srv.OrderTypeId ?? srv.ServiceId ?? null,
        CreatedDateTime: srv.CreatedDateTime,
        AmendedDateTime: srv.AmendedDateTime,
        Title: srv.Name,
        Image: srv.ImageURL,
        Amount: srv.DefaultAmount,
        Price: srv.Price,
    };
}

export function toUpdateOrderTypeToServer(service: IService): IUpdateOrderTypeToServer {
    return {
        OrderTypeId: service.Id,
        CreatedDateTime: service.CreatedDateTime,
        AmendedDateTime: service.AmendedDateTime,
        Name: service.Title ?? "",
        Price: service.Price ?? 0,
    };
}
