/** @jsx jsx */
import React from "react";
import { jsx, Button, Box, Text } from "theme-ui";

export interface IButtonWithIconProps {
  className?: string;
  icon: React.ReactElement;
  title: string;
  onClick?: () => void;
}

const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({ className, icon, title, onClick }) => {
  return (
    <Button
      className={className}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
      variant="iconAndText"
    >
      <Box
        sx={{
          marginRight: "8px",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <Text
        sx={{
          flexGrow: 1,
          marginRight: "8px",
        }}
        variant="textButton"
      >
        {title}
      </Text>
    </Button>
  );
};

export default ButtonWithIcon;
