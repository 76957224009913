/** @jsx jsx */
import React, { useCallback } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import { findIndex, cloneDeep } from "lodash";
import { IWorker } from "../../../core/worker/IWorker";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import { formMargin } from "../../theme/globalStyles";

interface IServicesProps {
  data: IWorker;
  onDataChanged: (newWorkerState: IWorker) => void;
}

const Services: React.FC<IServicesProps> = ({ data, onDataChanged }) => {
  const services = data.Services;

  const handleValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const { id } = event.target.dataset;
      const changedServiceIndex = findIndex(services, it => it.OrderTypeId === id);
      if (changedServiceIndex === -1) return;
      const updatedServices = cloneDeep(services);
      updatedServices[changedServiceIndex].Checked = checked;
      onDataChanged({
        ...data,
        Services: updatedServices,
      });
    },
    [data, onDataChanged, services],
  );

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Box sx={formMargin.group}>
        {services.map(srv => (
          <CheckboxWithLabel
            key={srv.OrderTypeId ?? ""}
            label={<Text variant="subHeading">{srv.Name}</Text>}
            checked={!!srv.Checked}
            data-id={srv.OrderTypeId}
            onChange={handleValueChanged}
          />
        ))}
      </Box>
    </Flex>
  );
};

export default Services;
