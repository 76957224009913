import React from "react";
import createIcon from "./createIcon";

const PlusIcon = createIcon(
  <>
    <path d="M9 1C9 0.447716 9.44772 0 10 0C10.5523 0 11 0.447715 11 1V19C11 19.5523 10.5523 20 10 20C9.44772 20 9 19.5523 9 19V1Z" />
    <path d="M1 11C0.447715 11 -6.58593e-09 10.5523 0 10C6.58593e-09 9.44771 0.447715 9 1 9H19C19.5523 9 20 9.44771 20 10C20 10.5523 19.5523 11 19 11H1Z" />
  </>,
  20,
  20,
  "currentColor",
);

export default PlusIcon;
