/** @jsx jsx */
import { merge } from "lodash";
import React, { useCallback } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import { IDetailedOrder } from "../../../../core/order/IDetailedOrder";
import { PropertySize } from "../../../../core/order/ServerDto";
import RadioGroup, { IRadioGroupItem } from "../../../components/RadioGroup";
import { formMargin } from "../../../theme/globalStyles";
import { Styles } from "../../../theme/utils";

interface IInteractiveFloorPlanSettingsTabProps {
  order: IDetailedOrder;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  mainContent: {
    flexGrow: 1,
  },
};

const PROPERTY_SIZES: IRadioGroupItem[] = [
  {
    title: "$49: 0-200 square metres (0-2010 sq ft)",
    value: "1",
  },
  {
    title: "$99: 201-400 square metres (2010-4010 sq ft)",
    value: "2",
  },
  {
    title: "$149: 401-600 square metres (4010-6000 sq ft)",
    value: "3",
  },
];

const InteractiveFloorPlanSettingsTab: React.FC<IInteractiveFloorPlanSettingsTabProps> = ({
  order,
  onOrderChanged,
}) => {
  const handlePropertySizeChange = useCallback(
    (newValue: string) => {
      const newPropertySize: PropertySize = Number.parseInt(newValue, 10);

      onOrderChanged({
        ...order,
        SizeProperty: newPropertySize,
      });
    },
    [onOrderChanged, order],
  );

  return (
    <Flex sx={styles.root}>
      <Box sx={merge({}, styles.mainContent, formMargin.row)}>
        <Text variant="heading">Size of the property:</Text>
        <RadioGroup
          name="3d-floor-plan"
          items={PROPERTY_SIZES}
          value={order.SizeProperty?.toString() ?? PropertySize.Unknown.toString()}
          onChange={handlePropertySizeChange}
        />
      </Box>
    </Flex>
  );
};

export default InteractiveFloorPlanSettingsTab;
