import React, { useCallback, useEffect, useState } from "react";
import { Id, TPrimitiveType } from "../../core/common/type";
import { IListOrder } from "../../core/order/IListOrder";
import { IInvoiceOrderFilters, IInvoiceOrderSort } from "./types";
import invoicePresenter from "./InvoicePresenter";
import InvoicesPageContent from "./InvoicesPageContent";

const InvoicesPage: React.FC = () => {
  const [orders, setOrders] = useState([] as IListOrder[]);
  const [filters, setFilters] = useState<IInvoiceOrderFilters>({
    searchValue: null,
    dateOrderTakenStart: null,
    dateOrderTakenEnd: null,
    dateWorkToBeDone: null,
  });
  const [sort, setSort] = useState<IInvoiceOrderSort>({
    sortField: "",
    sortOrder: "",
  });

  const getOrders = useCallback(async () => {
    const orderFilters: Record<string, TPrimitiveType> = {};
    if (filters.searchValue) {
      orderFilters.CombinedFilter = filters.searchValue;
    }
    if (filters.dateOrderTakenStart) {
      orderFilters.DateOrderTakenStart = filters.dateOrderTakenStart;
    }
    if (filters.dateOrderTakenEnd) {
      orderFilters.DateOrderTakenEnd = filters.dateOrderTakenEnd;
    }
    if (filters.dateWorkToBeDone) {
      orderFilters.DateWorkToBeDone = filters.dateWorkToBeDone;
    }

    const orderSort =
      sort.sortField && sort.sortOrder
        ? {
            SortBy: sort.sortField,
            OrderBy: sort.sortOrder,
          }
        : undefined;

    const serverOrders = await invoicePresenter.getOrders(orderFilters, orderSort);
    setOrders(serverOrders);
  }, [filters, sort.sortField, sort.sortOrder]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  const handleGenerateClick = useCallback(
    (orderIds: Set<Id>) => {
      if (!orderIds.size) {
        return alert("Select some orders, please!");
      }

      invoicePresenter
        .generateInvoices(orderIds)
        .then(() => {
          alert("Invoices generated!");
        })
        .catch((err: string) => {
          alert(err);
        })
        .finally(() => {
          getOrders();
        });
    },
    [getOrders],
  );

  return (
    <InvoicesPageContent
      orders={orders}
      onGenerateInvoicesClick={handleGenerateClick}
      filters={filters}
      onFiltersChange={setFilters}
      sort={sort}
      onSortChange={setSort}
    />
  );
};

export default InvoicesPage;
