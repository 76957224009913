// eslint-disable-next-line import/prefer-default-export
export function generateNewPassword(): string {
    return rndString(12);
}

function rndString(num: number): string {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < num; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}
