import config from "../../config/config";
import { Id } from "../common/type";
import httpService from "../http/HttpService";
import {
    IPackageFromServer,
    IPackageToServer,
    IPossiblePackagesFromServer,
    IPackageServiceFromServer,
    ISetCheckedToServer,
    IUpsellFromServer,
    IUpsellToServer,
    IUpdateOrderTypeToServer,
    IOrderTypeFromServer,
    IUploadImageResponse,
} from "./ServerDto";

const ORDER_TEMPLATES_PATH = `${config.apiUrl}/order-templates`;
const ORDER_TYPES_PATH = `${config.apiUrl}/order-types`;
const UPSELL_PATH = `${config.apiUrl}/upsell`;

class PackageRepository {
    async getPackages(customerId?: string | null): Promise<IPackageFromServer[]> {
        const path = `${ORDER_TEMPLATES_PATH}/list`;
        const customerList = customerId ? [customerId] : [];
        const response = await httpService.post(path, customerList);
        if (response.error) return Promise.reject(response.message);
        if (Array.isArray(response.response)) return response.response as IPackageFromServer[];
        return [];
    }

    async getPossiblePackages(customerId?: Id): Promise<IPossiblePackagesFromServer> {
        let path = `${ORDER_TEMPLATES_PATH}/possible-list/`;
        if (customerId) path += customerId;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as IPossiblePackagesFromServer;
    }

    async setPackage(packageData: IPackageToServer) {
        const path = `${ORDER_TEMPLATES_PATH}/set`;
        const response = await httpService.post(path, packageData);
        if (response.error) return Promise.reject(response.message);
        return response.response ?? [];
    }

    async setChecked(data: ISetCheckedToServer) {
        const path = `${ORDER_TEMPLATES_PATH}/set-checked`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response ?? [];
    }

    async deletePackage(packageId: number) {
        const path = `${ORDER_TEMPLATES_PATH}/delete/${packageId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return response.response ?? {};
    }

    async getNewPackageServicesList(): Promise<IPackageServiceFromServer[]> {
        const path = httpService.addQueryParams(`${ORDER_TEMPLATES_PATH}/services-list`, { OrderTemplateId: null });
        const response = await httpService.post(path, null);
        if (response.error) return Promise.reject(response.message);
        if (Array.isArray(response.response)) return (response.response as IPackageServiceFromServer[]) ?? [];
        return [];
    }

    async getServices(customerId?: Id): Promise<IOrderTypeFromServer[]> {
        const path = `${ORDER_TYPES_PATH}/edit-list`;
        const response = await httpService.post(path, { CustomerApplicationUserId: customerId });
        if (response.error) return Promise.reject(response.message);
        return (response.response as IOrderTypeFromServer[]) ?? [];
    }

    async setService(data: IUpdateOrderTypeToServer): Promise<IOrderTypeFromServer> {
        const path = `${ORDER_TYPES_PATH}/set`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response as IOrderTypeFromServer;
    }

    async deleteService(id: Id): Promise<IOrderTypeFromServer> {
        const path = `${ORDER_TYPES_PATH}/delete`;
        const response = await httpService.post(path, { OrderTypeId: id });
        if (response.error) return Promise.reject(response.message);
        return response.response as IOrderTypeFromServer;
    }

    async uploadLogo(id: Id, image: File): Promise<IUploadImageResponse> {
        const path = `${ORDER_TYPES_PATH}/upload-image/${id}`;
        const response = await httpService.upload(path, image);
        if (response.error) return Promise.reject(response.message);
        return response.response as IUploadImageResponse;
    }

    async getUpsell(): Promise<IUpsellFromServer> {
        const path = `${UPSELL_PATH}/get`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as IUpsellFromServer;
    }

    async setUpsell(data: IUpsellToServer) {
        const path = `${UPSELL_PATH}/set`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response;
    }
}

const packageRepository = new PackageRepository();
export default packageRepository;
