/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box, Text } from "theme-ui";
import { lowerFirst, noop } from "lodash";
import { ICustomer } from "../../../../core/customer/ICustomer";
import InputField from "../../../components/InputField";
import CheckboxWithLabel from "../../../components/CheckboxWithLabel";
import { formMargin } from "../../../theme/globalStyles";
import UserLogo from "../../../components/UserLogo";
import { toBase64 } from "../../../../core/common/converter";

interface IDetailsProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const Details: React.FC<IDetailsProps> = ({ customer, onChanged = noop }) => {
  const details = customer.Details;

  const handleLogoChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const fieldName = event.currentTarget.dataset.fieldName || "";
      const files = event.currentTarget.files || [];
      if (files.length > 0) {
        const value = files[0];

        const base64Name = `${fieldName}Base64`;
        const base64Value = await toBase64(value);

        onChanged({
          Details: {
            [fieldName]: value,
            [base64Name]: base64Value,
          },
        });
      }
    },
    [onChanged],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      onChanged({
        Details: {
          [fieldName!]: value,
        },
      });
    },
    [onChanged],
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { checked } = event.currentTarget;
      onChanged({
        Details: {
          [fieldName!]: checked,
        },
      });
    },
    [onChanged],
  );

  return (
    <Box sx={{ marginTop: 7 }}>
      <Flex>
        <UserLogo
          value={details.LogoFileBase64 || details.LogoThumb}
          onChange={handleLogoChange}
          fieldName="LogoFile"
        />
        <InputField
          sx={formMargin.rowElement}
          label="Company Name"
          value={details.Name ?? ""}
          data-field-name="Name"
          onChange={handleInputChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Customer Code"
          variant="narrow"
          value={details.CustomerCode ?? ""}
          data-field-name="CustomerCode"
          onChange={handleInputChange}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Website"
          value={details.Website ?? ""}
          data-field-name="Website"
          onChange={handleInputChange}
        />
      </Flex>
      <Flex sx={{ marginTop: 4 }}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">Customer VAT</Text>}
          checked={details.IsTaxAdded ?? false}
          data-field-name="IsTaxAdded"
          onChange={handleCheckboxChange}
        />
      </Flex>
      <Box sx={formMargin.group}>
        <Text variant="subHeading">Office Address</Text>
        <Flex sx={formMargin.row}>
          <InputField
            label="House Name"
            value={details.HouseName ?? ""}
            data-field-name="HouseName"
            onChange={handleInputChange}
          />
          <InputField
            sx={formMargin.rowElement}
            label="Number"
            variant="narrow"
            value={details.HouseNumber ?? ""}
            data-field-name="HouseNumber"
            onChange={handleInputChange}
          />
          <InputField
            sx={formMargin.rowElement}
            label="Road"
            value={details.Road ?? ""}
            data-field-name="Road"
            onChange={handleInputChange}
          />
        </Flex>
        <Flex sx={formMargin.row}>
          <InputField label="Area" value={details.Area ?? ""} data-field-name="Area" onChange={handleInputChange} />
          <InputField
            sx={formMargin.rowElement}
            label="Town"
            value={details.Town ?? ""}
            data-field-name="Town"
            onChange={handleInputChange}
          />
          <InputField
            sx={formMargin.rowElement}
            label="Country"
            value={details.Country ?? ""}
            data-field-name="Country"
            onChange={handleInputChange}
          />
        </Flex>
        <Flex sx={formMargin.row}>
          <InputField
            label="Zip Code"
            variant="narrow"
            value={details.Postcode ?? ""}
            data-field-name="Postcode"
            onChange={handleInputChange}
          />
        </Flex>
      </Box>
      <Box sx={formMargin.group}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">Is Active</Text>}
          checked={details.IsActive ?? false}
          data-field-name="IsActive"
          onChange={handleCheckboxChange}
        />
      </Box>
    </Box>
  );
};

export default Details;
