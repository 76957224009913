/** @jsx jsx */
import Steps, { Step } from "rc-steps";
import React from "react";
import ReactModal from "react-modal";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Box, Card, Flex, jsx, Text } from "theme-ui";
import { merge } from "lodash";
import config from "../../../config/config";
import { Id } from "../../../core/common/type";
import {
  IDetailedOrder,
  INote,
  IOfferedService,
  IOrderService,
  IOrderUpload,
  OrderState,
} from "../../../core/order/IDetailedOrder";
import { ServiceType } from "../../../core/order/ServerDto";
import { IListWorkerFromServer } from "../../../core/worker/ServerDto";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import { ITabInfo, SimpleTabs } from "../../components/Tabs";
import { formMargin } from "../../theme/globalStyles";
import { Styles } from "../../theme/utils";
import WorkerList from "../../workers/FindWorker/WorkerList";
import orderPresenter from "../OrderPresenter";
import OrderPackageSelect from "./components/OrderPackageSelect";
import AccountTab from "./tabs/AccountTab";
import DateTab from "./tabs/DateTab";
import InteractiveFloorPlanSettingsTab from "./tabs/InteractiveFloorPlanSettingsTab";
import UploadsTab from "./tabs/UploadsTab";
import PropertyTab from "./tabs/PropertyTab";
import SimpleServiceTab from "./tabs/SimpleServiceTab";

interface IOrderEditorContentProps {
  url: string;
  path: string;

  tab: string;
  onTabSelect: (tabId: string) => void;

  order: Readonly<IDetailedOrder>;
  orderNotes: Readonly<Array<INote>>;

  onChangeAllWorkersRequest: () => void;
  onChangeWorkerRequest: (serviceId: Id) => void;
  onWorkerListCloseRequest: () => void;
  onWorkerSelect: (worker: IListWorkerFromServer) => void;
  workerListState: IWorkerListState;

  onPhotoUploadRequest: (serviceId: Id, files: File[]) => void;
  onPlanUploadRequest: (serviceId: Id, files: File[]) => void;
  onRegularUploadDeleteRequest: (serviceId: Id, uploads: IOrderUpload[]) => void;
  onOfferedPhotoUploadRequest: (serviceId: Id, files: File[]) => void;
  getRegularService: (id: Id) => IOrderService | undefined;
  getOfferedService: (id: Id) => IOfferedService | undefined;

  onOfferedUploadDeleteRequest: (serviceId: Id, uploads: IOrderUpload[]) => void;
  onServiceChanged: (newServiceState: IOrderService | IOfferedService) => void;

  onOrderChanged: (newOrderState: IDetailedOrder) => void;
  onOrderNotesChanged: (newNotesState: INote[]) => void;
}

export interface IWorkerListState {
  serviceId: Id;
  state: "single-service-assign" | "all-services-assign";
  isOpen: boolean;
  workers: IListWorkerFromServer[];
}

const styles: Styles = {
  root: {
    flexGrow: 1,
  },
  editorMenu: {
    flexDirection: "column",
    paddingRight: "24px",
    flexShrink: 0,
  },
  menu: {
    paddingTop: "48px",
  },
  leftMenuButton: {
    width: "100%",
  },
  mainContent: {
    flexGrow: 1,
    flexDirection: "column",
  },
  progress: {
    height: "32px",
  },
  editorPage: {
    flexGrow: 1,
    display: "flex",
    marginTop: "48px",
    overflowY: "auto",
  },
  linkContainer: {
    height: "24px",
    marginTop: "24px",
    "&:first-of-type": {
      marginTop: 0,
    },
  },
  link: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    textDecoration: "none",
    cursor: "pointer",
    color: "inherit",
    "&:visited": {
      color: "inherit",
    },
    "&:hover": {
      color: "primary",
    },
    "&.active": {
      color: "primary",
    },
  },
};

export type TOrderEditorMenuTab = "order" | "pack";

const TABS: ITabInfo[] = [
  {
    id: "order",
    title: "Order details",
  },
  {
    id: "pack",
    title: "Pack details",
  },
];

const NullStepIcon = () => null;

const OrderEditorContent: React.FC<IOrderEditorContentProps> = ({
  order,
  orderNotes,
  url,
  path,
  tab,
  onTabSelect,
  onChangeWorkerRequest,
  onChangeAllWorkersRequest,
  onWorkerListCloseRequest,
  onWorkerSelect,
  workerListState,
  onPhotoUploadRequest,
  onPlanUploadRequest,
  onRegularUploadDeleteRequest,
  onOfferedPhotoUploadRequest,
  onOfferedUploadDeleteRequest,
  getRegularService,
  getOfferedService,
  onServiceChanged,
  onOrderChanged,
  onOrderNotesChanged,
}) => {
  return (
    <Flex sx={styles.root}>
      <Flex sx={styles.editorMenu}>
        <Box>
          <SimpleTabs tabs={TABS} value={tab} onSelect={onTabSelect} />
        </Box>
        {tab === "order" && (
          <Box sx={styles.menu}>
            <Box sx={styles.linkContainer}>
              <NavLink exact to={`${url}/account`} sx={styles.link}>
                <Text variant="menuItem">Account & Status</Text>
              </NavLink>
            </Box>
            <Box sx={styles.linkContainer}>
              <NavLink exact to={`${url}/property`} sx={styles.link}>
                <Text variant="menuItem">Property address</Text>
              </NavLink>
            </Box>
            <Box sx={styles.linkContainer}>
              <NavLink exact to={`${url}/date`} sx={styles.link}>
                <Text variant="menuItem">Date & Time & Contacts</Text>
              </NavLink>
            </Box>
          </Box>
        )}
        {tab === "pack" && (
          <Box sx={styles.menu}>
            <OrderPackageSelect order={order} onOrderChanged={onOrderChanged} />
            <InputField
              sx={formMargin.row}
              label="PriceExVAT"
              value={`${config.currency}${order.OrderPrice ?? ""}`}
              disabled
            />
            <Button
              sx={merge({}, styles.leftMenuButton, formMargin.row)}
              variant="primary"
              title="Assign worker for all services"
              onClick={onChangeAllWorkersRequest}
            />
            <Box sx={formMargin.group}>
              {order.Services.map(service => (
                <Box sx={styles.linkContainer} key={service.OrderServiceId!}>
                  <NavLink exact to={`${url}/service/${service.Type}/${service.OrderServiceId}`} sx={styles.link}>
                    <Text variant="menuItem">{service.Name}</Text>
                  </NavLink>
                </Box>
              ))}
              {order.OfferedServices.map(service => (
                <Box sx={styles.linkContainer} key={service.OfferedServiceId}>
                  <NavLink exact to={`${url}/offer/${service.Type}/${service.OfferedServiceId}`} sx={styles.link}>
                    <Text variant="menuItem">(Offered) {service.Name}</Text>
                  </NavLink>
                </Box>
              ))}
              <Box sx={styles.linkContainer}>
                <NavLink exact to={`${url}/offer/3d-floor-plan/0`} sx={styles.link}>
                  <Text variant="menuItem">(Offered) 3D Interactive Floor Plan</Text>
                </NavLink>
              </Box>
            </Box>
          </Box>
        )}
      </Flex>
      <Flex sx={styles.mainContent}>
        <Box sx={styles.progress}>
          <Steps
            current={orderPresenter.orderStatusToProgress(order.OrderState ?? OrderState.DateAgreement)}
            labelPlacement="vertical"
            size="small"
            stepIcon={NullStepIcon}
          >
            <Step title="In Progress" />
            <Step title="Booking Placed" />
            <Step title="Completed" />
            <Step title="Content selected" />
            <Step title="Paid" />
          </Steps>
        </Box>
        <Card sx={styles.editorPage}>
          <Switch>
            <Route path={`${path}/account`}>
              <AccountTab
                order={order}
                orderNotes={orderNotes}
                onOrderChanged={onOrderChanged}
                onOrderNotesChanged={onOrderNotesChanged}
              />
            </Route>
            <Route path={`${path}/property`}>
              <PropertyTab order={order} onOrderChanged={onOrderChanged} />
            </Route>
            <Route path={`${path}/date`}>
              <DateTab order={order} onOrderChanged={onOrderChanged} />
            </Route>
            <Route path={`${path}/service/${ServiceType.Photo}/:serviceId`}>
              <UploadsTab
                order={order}
                fileTypes="image/*"
                onChangeWorkerRequest={onChangeWorkerRequest}
                onUploadRequest={onPhotoUploadRequest}
                onDeleteRequest={onRegularUploadDeleteRequest}
                getService={getRegularService}
              />
            </Route>
            <Route path={`${path}/service/${ServiceType.FloorPlan}/:serviceId`}>
              <UploadsTab
                order={order}
                onChangeWorkerRequest={onChangeWorkerRequest}
                onUploadRequest={onPlanUploadRequest}
                onDeleteRequest={onRegularUploadDeleteRequest}
                getService={getRegularService}
              />
            </Route>
            <Route path={`${path}/service/:serviceType/:serviceId`}>
              <SimpleServiceTab
                order={order}
                onServiceChanged={onServiceChanged}
                onChangeWorkerRequest={onChangeWorkerRequest}
              />
            </Route>
            <Route path={`${path}/offer/${ServiceType.VirtualTour}/:serviceId`}>
              <UploadsTab
                order={order}
                fileTypes="image/*"
                onChangeWorkerRequest={onChangeWorkerRequest}
                onUploadRequest={onOfferedPhotoUploadRequest}
                onDeleteRequest={onOfferedUploadDeleteRequest}
                getService={getOfferedService}
              />
            </Route>
            <Route path={`${path}/offer/3d-floor-plan/:serviceId`}>
              <InteractiveFloorPlanSettingsTab order={order} onOrderChanged={onOrderChanged} />
            </Route>
            <Redirect to={`${url}/account`} />
          </Switch>
        </Card>
      </Flex>
      <ReactModal isOpen={workerListState.isOpen} onRequestClose={onWorkerListCloseRequest}>
        <WorkerList workers={workerListState.workers} onSelect={onWorkerSelect} />
      </ReactModal>
    </Flex>
  );
};

export default OrderEditorContent;
