/** @jsx jsx */
import React, { useCallback } from "react";
import { Box, Flex, jsx } from "theme-ui";
import { TWorkerOrder, TWorkerSortField } from "../../../core/worker/ServerDto";
import AddButton from "../../components/AddButton";
import Select, { ISelectOption } from "../../components/Select";
import { Styles } from "../../theme/utils";
import { IWorkerListSort } from "./types";

interface IWorkerListControlProps {
  onAddClick: () => void;
  sort: IWorkerListSort;
  onSortChange: (newSortState: IWorkerListSort) => void;
}

const styles: Styles = {
  root: {
    alignItems: "center",
    flexWrap: "wrap",
  },
  sortFieldContainer: {
    marginLeft: "30px",
    width: "248px",
  },
};

const SORT_OPTIONS: ISelectOption[] = [
  { value: "", label: "Sort By" },
  { value: "Name Asc", label: "Name" },
  { value: "Name Desc", label: "Name (reversed)" },
  { value: "CreationDate Asc", label: "Registration Date" },
  { value: "CreationDate Desc", label: "Registration Date (reversed)" },
  { value: "Rating Asc", label: "Rate" },
  { value: "Rating Desc", label: "Rate (reversed)" },
  { value: "IsActive Asc", label: "Is Active" },
  { value: "IsActive Desc", label: "Is Active (reversed)" },
];

const WorkerListControl: React.FC<IWorkerListControlProps> = ({ onAddClick, sort, onSortChange }) => {
  const handleSortChange = useCallback(
    (sortParams: string | null | undefined) => {
      const [sortField, sortOrder] = sortParams?.split(" ");
      onSortChange({
        sortField: sortField as TWorkerSortField | "",
        sortOrder: sortOrder as TWorkerOrder | "",
      });
    },
    [onSortChange],
  );

  return (
    <Flex sx={styles.root}>
      <AddButton title="Add Worker" onClick={onAddClick} />
      <Box sx={styles.sortFieldContainer}>
        <Select
          sx={{ zIndex: "99999" }}
          options={SORT_OPTIONS}
          value={`${sort.sortField} ${sort.sortOrder}`}
          onChange={handleSortChange}
        />
      </Box>
    </Flex>
  );
};

export default WorkerListControl;
