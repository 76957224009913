import config from "../../config/config";
import httpService from "../http/HttpService";
import { INewNoteToServer } from "./ServerDto";

const NOTES_PATH = `${config.apiUrl}/notes`;

class NoteRepository {
    async addNote(noteData: Partial<INewNoteToServer>) {
        const path = `${NOTES_PATH}/create`;
        const response = await httpService.post(path, noteData);
        if (response.error) return Promise.reject();
        return Promise.resolve();
    }
}

const noteRepository = new NoteRepository();
export default noteRepository;
