import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { EMPTY_USER } from "../../core/user/consts";
import { IUserFromServer } from "../../core/user/ServerDto";

// @ts-ignore
const userSlice = createSlice({
    name: "user",
    initialState: ({
        ...cloneDeep(EMPTY_USER),
    } as unknown) as IUserFromServer,
    reducers: {
        setUser(state, action: PayloadAction<IUserFromServer>) {
            const user = action.payload;
            return cloneDeep(user);
        },
        setAvatar(state, action: PayloadAction<string>) {
            state.Avatar = action.payload;
            state.AvatarThumb = action.payload;
        },
    },
});

export const { setUser, setAvatar } = userSlice.actions;

export default userSlice.reducer;
