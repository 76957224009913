import { differenceWith, intersectionWith, isEqual, isNil } from "lodash";
import { Id } from "../common/type";
import { toOrderTypeService, toPackage, toPackageToServer, toUpdateOrderTypeToServer } from "./convert";
import { IPackage, IService, IUpsell } from "./IPackage";
import packageRepository from "./PackageRepository";

class PackageService {
    async getPackages(): Promise<IPackage[]> {
        const serverPackages = await packageRepository.getPackages();
        return serverPackages.map(toPackage);
    }

    async getServices(): Promise<IService[]> {
        const serverServices = await packageRepository.getServices();
        return serverServices.map(toOrderTypeService);
    }

    getUpsell(): Promise<IUpsell> {
        return packageRepository.getUpsell();
    }

    saveNewPackages(packages: IPackage[]): Promise<void> {
        return (Promise.all(
            packages.map(pkg => {
                const serverData = toPackageToServer(pkg);
                return packageRepository.setPackage(serverData);
            }),
        ) as unknown) as Promise<void>;
    }

    async savePackages(oldPackages: IPackage[], newPackages: IPackage[]): Promise<void> {
        if (!isEqual(oldPackages, newPackages)) {
            const changedPackages = intersectionWith(
                newPackages,
                oldPackages,
                (newPackage, oldPackage) => newPackage.Id === oldPackage.Id && !isEqual(newPackage, oldPackage),
            );

            const removedPackages = differenceWith(
                oldPackages,
                newPackages,
                (oldPackage, newPackage) => oldPackage.Id === newPackage.Id,
            );

            const addedPackages = newPackages.filter(pkg => !pkg.Id);

            await Promise.all(
                changedPackages.map(pkg => {
                    const serverData = toPackageToServer(pkg);
                    return packageRepository.setPackage(serverData);
                }),
            );

            await Promise.all(
                removedPackages.map(async pkg => {
                    if (!isNil(pkg.Id)) {
                        return packageRepository.deletePackage(pkg.Id);
                    }
                }),
            );

            await Promise.all(
                addedPackages.map(pkg => {
                    const serverData = toPackageToServer(pkg);
                    return packageRepository.setPackage(serverData);
                }),
            );
        }
    }

    async saveUpsell(newUpsell: IUpsell): Promise<void> {
        await packageRepository.setUpsell(newUpsell);
    }

    addService(serviceData: IService): Promise<void> {
        return this.setService(serviceData);
    }

    updateService(newServiceData: IService): Promise<void> {
        return this.setService(newServiceData);
    }

    async removeService(serviceId: Id): Promise<void> {
        await packageRepository.deleteService(serviceId);
    }

    private async setService(service: IService): Promise<void> {
        const srv = toUpdateOrderTypeToServer(service);
        const updatedSrv = await packageRepository.setService(srv);
        await this.updateLogo(updatedSrv.ServiceId!, service);
    }

    private async updateLogo(serviceId: Id, service: IService): Promise<void> {
        const { ImageFile } = service;
        if (ImageFile) {
            await packageRepository.uploadLogo(serviceId, ImageFile);
        }
    }
}

const packageService = new PackageService();
export default packageService;
