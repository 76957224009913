/** @jsx jsx */
import React from "react";
import { jsx, Box, Flex } from "theme-ui";
import { ICustomer } from "../../../../core/customer/ICustomer";
import CustomerEditor, { TCustomerEditorTab } from "./CustomerEditor";
import EditCustomerHeader from "./EditCustomerHeader";

interface IEditCustomerPageContentProps {
  onRemoveCustomerClick: () => void;
  onSaveChangesClick: () => void;
  onApproveClick: () => void;
  canApprove: boolean;
  tab: TCustomerEditorTab;
  onTabChanged: (tab: TCustomerEditorTab) => void;
  customer: Readonly<ICustomer>;
  onCustomerChanged: (changes: Partial<ICustomer>) => void;
  customerId: string;
}

const EditCustomerPageContent: React.FC<IEditCustomerPageContentProps> = ({
  onRemoveCustomerClick,
  onSaveChangesClick,
  onApproveClick,
  canApprove,
  tab,
  onTabChanged,
  customer,
  onCustomerChanged,
  customerId,
}) => {
  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <EditCustomerHeader
          onRemoveCustomerClick={onRemoveCustomerClick}
          onSaveChangesClick={onSaveChangesClick}
          onApproveClick={onApproveClick}
          showApproveButton={canApprove}
        />
      </Box>
      <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
        <CustomerEditor
          tab={tab}
          customerId={customerId}
          onTabChanged={onTabChanged}
          customer={customer}
          onCustomerChanged={onCustomerChanged}
        />
      </Flex>
    </Flex>
  );
};

export default EditCustomerPageContent;
