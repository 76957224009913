import React, { useCallback, useState } from "react";
import { noop } from "lodash";
import PhoneInput from "react-phone-input-2";
import { Box, Label } from "theme-ui";
import config from "../../config/config";
import { appThemeV2 } from "../theme/theme";
import "react-phone-input-2/lib/high-res.css";
import { styles } from "./InputField";

interface IPhoneInputFieldProps extends Omit<React.ComponentProps<"input">, "onChange" | "id"> {
  className?: string;
  label?: string;
  variant?: "wide" | "narrow" | "fullWidth";
  id?: string;
  onChange?: (value: string, id?: string) => void;
}

const inputStyles: Record<string, React.CSSProperties> = {
  input: {
    height: "32px",
    backgroundColor: "transparent",
    borderColor: appThemeV2.colors.inputBorder,
    width: "100%",
  },
  flagButton: {
    borderColor: appThemeV2.colors.inputBorder,
  },
};

const PhoneInputField: React.FC<IPhoneInputFieldProps> = ({
  className,
  label,
  variant = "wide",
  id,
  onChange = noop,
  disabled,
  value,
  placeholder,
  ...props
}) => {
  const [country, setCountry] = useState(config.preferredCountries[0]);
  const variantStyles = styles[variant];

  const handleChange = useCallback(
    (newValue: string, newCountry: Record<string, string>) => {
      if (newCountry) {
        setCountry(newCountry.countryCode);
      }
      // timeout fixes bug with setting value to empty string on country change
      setTimeout(() => {
        onChange(newValue, id);
      }, 0);
    },
    [id, onChange],
  );

  return (
    <Box className={className} sx={variantStyles}>
      {label && <Label variant="label.input">{label}</Label>}
      <PhoneInput
        inputStyle={inputStyles.input}
        buttonStyle={inputStyles.flagButton}
        preferredCountries={config.preferredCountries}
        value={value as string}
        onChange={handleChange}
        enableSearch
        disableSearchIcon
        inputProps={props}
        disabled={disabled}
        disableDropdown={disabled}
        placeholder={placeholder ?? ""}
        country={country}
      />
    </Box>
  );
};

export default PhoneInputField;
