/** @jsx jsx */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Box, jsx } from "theme-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Styles } from "../../theme/utils";
import AddOrderHeader from "./AddOrderHeader";
import CardList from "../../components/CardList";
import orderPresenter from "../OrderPresenter";
import { IPackageGroup, splitToGroups } from "../../customers/SingleCustomer/tabs/PackageGroups";
import GeneratePackageGroupCard from "./GeneratePackageGroupCard";
import { IPackage } from "../../../core/package/IPackage";
import { IGenerateOrderToServer } from "../../../core/order/ServerDto";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  pageContent: {
    marginTop: 24,
    padding: 16,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  pageContentBlock: {
    marginTop: 16,
  },
  tableBlock: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  table: {
    flexGrow: 1,
    height: 0,
  },
  contentLine: {
    display: "flex",
    alignItems: "center",
  },
  contentLineItem: {
    display: "block",
  },
  customerSort: {
    width: 258,
    color: theme.palette.text.secondary,
  },
  customersTable: {
    tableLayout: "fixed",
  },
}));

const styles: Styles = {
  backBlock: {
    justifyContent: "flex-end",
    marginTop: "1.1em",
  },
};

const toGenerateOrderData = (state: IOrderState, applicationUserId: string): IGenerateOrderToServer | null => {
  const { orderTemplateId } = state;
  return {
    quantity: 1,
    orderTemplateId,
    applicationUserId,
  };
};

interface IOrderState {
  orderTemplateId: number;
  title: string;
}

// business BusinessOrderTemplates  CustomOrderTemplates
// private  PersonalOrderTemplates

const GeneratePackagePage: React.FC = () => {
  const muiStyles = useStyles();
  const history = useHistory();
  const [orderState, setOrderState] = useState<IOrderState>();
  const [packages, setPackages] = useState<IPackageGroup[]>([]);
  const { userId, branchId } = useParams();

  useEffect(() => {
    async function loadPackages() {
      const newPackages = await orderPresenter.getPossiblePackages(branchId);
      setPackages(splitToGroups(newPackages));
    }

    loadPackages();
  }, [branchId]);

  const onClickOrderState = useCallback(
    (pkg: IPackage) => () => {
      setOrderState({ orderTemplateId: pkg.Id!, title: pkg.Title! });
    },
    [setOrderState],
  );

  const disabledCreateOrder = useMemo(() => !orderState?.orderTemplateId, [orderState?.orderTemplateId]);

  const onCancelClick = useCallback(() => {
    setOrderState(undefined);
  }, []);

  const onGenerateOrderClick = useCallback(async () => {
    if (!orderState) {
      return;
    }

    const generateOrderData = toGenerateOrderData(orderState, userId);
    if (generateOrderData) {
      const orderId = await orderPresenter.generateOrder(generateOrderData);
      await orderPresenter.updateUnmaintainedOrdersCount();
      history.push(`/orders/edit/${orderId}`);
    }
  }, [orderState, history, userId]);

  const onBackClick = useCallback(() => {
    history.push(`/orders/add`);
  }, [history]);

  return (
    <div className={muiStyles.root}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <AddOrderHeader
          createOrderClick={onGenerateOrderClick}
          disabledCreateOrder={disabledCreateOrder}
          onCancelClick={onCancelClick}
          onBackClick={onBackClick}
        />
      </Box>
      <Paper className={muiStyles.pageContent}>
        <CardList
          sx={styles.cardList}
          cards={packages.map(group => (
            <GeneratePackageGroupCard
              key={group.title}
              selectedOrderTemplateId={orderState?.orderTemplateId}
              onClick={onClickOrderState}
              title={group.title}
              packages={group.items}
            />
          ))}
        />
      </Paper>
    </div>
  );
};

export default GeneratePackagePage;
