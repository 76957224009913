/** @jsx jsx */
import React from "react";
import { jsx, Flex, Box, Heading } from "theme-ui";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import LeftArrowIcon from "../../icons/LeftArrow";
import { link } from "../../theme/globalStyles";
import { Styles } from "../../theme/utils";

interface IEditAdministrationHeaderProps {
  onCancelClick: () => void;
  onAddClick: () => void;
}

const styles: Styles = {
  root: {
    justifyContent: "space-between",
  },
  arrowContainer: {
    alignItems: "center",
  },
  heading: {
    marginLeft: 3,
    height: "32px",
    alignItems: "center",
  },
  buttonSpacing: {
    marginLeft: 4,
  },
};

const CreateAdministrationHeader: React.FC<IEditAdministrationHeaderProps> = ({ onCancelClick, onAddClick }) => {
  return (
    <Flex sx={styles.root}>
      <Box>
        <Flex sx={{ flexDirection: "column" }}>
          <Flex>
            <Flex sx={styles.arrowContainer}>
              <Link to="/administration/list" sx={link.neutralColor}>
                <LeftArrowIcon />
              </Link>
            </Flex>
            <Flex sx={styles.heading}>
              <Heading>New User</Heading>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Flex>
        <Button variant="dangerOutline" title="Cancel" onClick={onCancelClick} />
        <Button sx={styles.buttonSpacing} variant="primaryOutline" title="Add User" onClick={onAddClick} />
      </Flex>
    </Flex>
  );
};

export default CreateAdministrationHeader;
