/** @jsx jsx */
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { jsx, Card, Flex, Box, Text } from "theme-ui";
import { merge, noop, cloneDeep } from "lodash";
import { IPackage } from "../../../core/package/IPackage";
import AddButton from "../../components/AddButton";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import { Styles } from "../../theme/utils";
import AmountInput from "../../customers/SingleCustomer/components/AmountInput";

interface ICustomPackageCardProps {
  className?: string;
  pkg?: IPackage;
  position?: number;
  onChange?: (changes: Partial<IPackage>, position: number) => void;
  onRemoveClick?: (position: number) => void;
}

const styles: Styles = {
  root: {
    padding: "16px 16px 24px 16px",
    borderColor: "lightGray",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  inputs: {
    alignItems: "flex-end",
  },
  selectPackage: {
    flexGrow: 1,
    flexBasis: "250px",
    marginRight: "16px",
  },
  price: {
    flexBasis: "340px",
    flexShrink: 1,
  },
  packages: {
    marginTop: "24px",
  },
  row: {
    borderBottom: "1px solid",
    borderColor: "lightGray",
    paddingRight: "8px",
    paddingLeft: "8px",
    alignItems: "center",
    height: "32px",
    "&:first-of-type": {
      borderTop: "1px solid",
    },
  },
  icon: {
    marginRight: "8px",
    display: "inline-block",
    height: "20px",
    padding: "0 20px 10px 0",
    background: "center center no-repeat",
    backgroundSize: "contain",
    content: '""',
  },
  title: {
    flexGrow: 1,
  },
  removePackage: {
    justifyContent: "flex-end",
  },
  addLink: {
    textDecoration: "none",
  },
};

const AmountIdGenerator = {
  newId(packageId: string | number, serviceId: string | number): string {
    return `${packageId}-${serviceId}`;
  },
  parseId(id: string) {
    const [packageId, serviceId] = id.split("-");
    return { packageId, serviceId };
  },
};

const CustomPackageCard: React.FC<ICustomPackageCardProps> = ({
  className,
  pkg,
  onChange = noop,
  onRemoveClick = noop,
  position,
}) => {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      onChange(
        {
          [fieldName!]: value,
        },
        position,
      );
    },
    [onChange, position],
  );

  const handleIncreaseAmount = useCallback(
    (id: string) => {
      const { packageId, serviceId } = AmountIdGenerator.parseId(id);
      const pkgPosition = Number.parseInt(packageId, 10);
      const servicePosition = Number.parseInt(serviceId, 10);
      const pkgServices = cloneDeep(pkg?.Services ?? []);
      const changedService = pkgServices[servicePosition];
      if (changedService) {
        // @ts-ignore
        changedService.Amount += 1;
      }
      onChange(
        {
          Services: pkgServices,
        },
        pkgPosition,
      );
    },
    [pkg?.Services, onChange],
  );

  const handleRemoveClick = useCallback(() => onRemoveClick(position), [onRemoveClick, position]);

  const handleDecreaseAmount = useCallback(
    (id: string) => {
      const { packageId, serviceId } = AmountIdGenerator.parseId(id);
      const pkgPosition = Number.parseInt(packageId, 10);
      const servicePosition = Number.parseInt(serviceId, 10);
      const pkgServices = cloneDeep(pkg?.Services ?? []);
      const changedService = pkgServices[servicePosition];
      // @ts-ignore
      if (changedService && changedService.Amount > 0) {
        // @ts-ignore
        changedService.Amount -= 1;
      }
      onChange(
        {
          Services: pkgServices,
        },
        pkgPosition,
      );
    },
    [pkg?.Services, onChange],
  );

  const services = pkg?.Services ?? [];

  return (
    <Card sx={styles.root} className={className}>
      <Box>
        <Flex sx={styles.inputs}>
          <Box sx={styles.selectPackage}>
            <InputField
              label="Title"
              variant="fullWidth"
              value={pkg?.Title ?? ""}
              data-field-name="Title"
              onChange={handleInputChange}
            />
          </Box>
          <Box sx={styles.price}>
            <InputField
              label="PriceExVAT"
              variant="fullWidth"
              value={pkg?.Price ?? ""}
              data-field-name="Price"
              onChange={handleInputChange}
            />
          </Box>
        </Flex>
        <Box sx={styles.packages}>
          {services.map((srv, i) => (
            <Flex sx={styles.row} key={srv.Id ?? ""}>
              <Box sx={merge({}, styles.icon, { backgroundImage: `url("${srv.Image}")` })} />
              <Text sx={styles.title} variant="subHeading">
                {srv.Title}
              </Text>
              <AmountInput
                value={srv.Amount ?? 0}
                id={AmountIdGenerator.newId(position!, i)}
                onDecreaseClick={handleDecreaseAmount}
                onIncreaseClick={handleIncreaseAmount}
              />
            </Flex>
          ))}
        </Box>
        <Link to="/packages/services" sx={styles.addLink}>
          <AddButton title="Add" variant="small" />
        </Link>
      </Box>
      <Flex sx={styles.removePackage}>
        <Button variant="dangerOutline" title="Remove Package" onClick={handleRemoveClick} data-id={position} />
      </Flex>
    </Card>
  );
};

export default CustomPackageCard;
