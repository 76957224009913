import React, { useCallback } from "react";
import uploader from "../../core/upload/uploader";
import { useAppSelector } from "../../store/store";
import UploadWindowContent from "./UploadWindowContent";

const UploadWindow: React.FC = () => {
  const uploads = useAppSelector(state => state.uploads);

  const handleCancelClick = useCallback(() => {
    uploader.cancelAll();
  }, []);

  return uploads.isOpen ? (
    <UploadWindowContent
      onCancelClick={handleCancelClick}
      uploadedCount={uploads.uploadedCount}
      totalCount={uploads.totalCount}
    />
  ) : null;
};

export default UploadWindow;
