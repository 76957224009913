/** @jsx jsx */
import React, { useCallback, useEffect } from "react";
import { jsx, Flex, Box, Text } from "theme-ui";
import xeroRepository from "../../core/xero/xeroRepository";
import { IUserFromServer } from "../../core/user/ServerDto";
import { Styles } from "../theme/utils";

interface IUserListProps {
  users: IUserFromServer[];
  onSelect: (worker: IUserFromServer) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
  },
  user: {
    cursor: "pointer",
    padding: "16px 0",
    borderTop: "1px solid",
    borderColor: "gray",
    "&:last-of-type": {
      borderBottom: "1px solid",
      borderColor: "gray",
    },
    "&:hover": {
      color: "primary",
    },
  },
};

const UserList: React.FC<IUserListProps> = ({ users, onSelect }) => {
  const handleWorkerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const userId = event.currentTarget.dataset.userId ?? "";
      const user = users.find(usr => usr.Id?.toString() === userId)!;
      onSelect(user);
    },
    [users, onSelect],
  );

  useEffect(() => {
    xeroRepository.xeroStatus();
  });

  return (
    <Flex sx={styles.root}>
      {users.map(user => (
        <Box key={user.Id ?? ""} sx={styles.user} data-user-id={user.Id} onClick={handleWorkerClick}>
          <Text variant="menuItem">{user.DisplayName}</Text>
          <Text variant="subHeadingSecondaryText">{user.Email}</Text>
        </Box>
      ))}
    </Flex>
  );
};

export default UserList;
