/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box, Text } from "theme-ui";
import { merge } from "lodash";
import { IDetailedOrder } from "../../../../core/order/IDetailedOrder";
import Button from "../../../components/Button";
import CheckboxWithLabel from "../../../components/CheckboxWithLabel";
import DateInput from "../../../components/DateInput";
import InputField from "../../../components/InputField";
import IntegerSelectorField from "../../../components/IntegerSelectorField";
import { formMargin } from "../../../theme/globalStyles";
import OrderContactList from "../components/OrderContactList";

interface IDateTabProps {
  order: IDetailedOrder;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
}

const DateTab: React.FC<IDateTabProps> = ({ order, onOrderChanged }) => {
  const data = order.Common;
  const appointmentDateTime = data?.AppointmentDateTime ? data?.AppointmentDateTime : null;

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { checked } = event.currentTarget;
      onOrderChanged({
        ...order,
        Common: {
          ...order.Common!,
          [fieldName!]: checked,
        },
      });
    },
    [onOrderChanged, order],
  );

  const handleAppointmentDateTimeChange = useCallback(
    (newDate: Date | null) => {
      onOrderChanged({
        ...order,
        Common: {
          ...order.Common!,
          AppointmentDateTime: newDate ? newDate.toISOString() : "",
        },
      });
    },
    [onOrderChanged, order],
  );

  const handleDurationChange = useCallback(
    (newValue: number) => {
      const newDuration = Math.max(0, newValue);
      onOrderChanged({
        ...order,
        Common: {
          ...order.Common!,
          AppointmentDuration: newDuration,
        },
      });
    },
    [order, onOrderChanged],
  );

  const handleSendMessageClick = useCallback(() => {
    alert("not implemented!");
  }, []);

  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <CheckboxWithLabel
        label={<Text variant="subHeading">Contact Vendor or Tenant directly for the most convenient Date & Time</Text>}
        checked={order.Common?.ContactVendorDirectly ?? false}
        onChange={handleCheckboxChange}
        data-field-name="ContactVendorDirectly"
      />
      {false && (
        <Text variant="label">
          Please note that appointment dates & times requested are not guaranteed. We will need to confirm our
          availability when we allocate a photoplanner to the job.
        </Text>
      )}
      <Flex sx={formMargin.row}>
        <DateInput
          label="Appointment date and time"
          value={appointmentDateTime}
          onChange={handleAppointmentDateTimeChange}
          showTimeSelect
          dateFormat="MMMM D, YYYY h:mm a"
        />
        <InputField sx={formMargin.rowElement} label="Time zone" disabled value="Europe/London" />
      </Flex>
      <IntegerSelectorField
        sx={formMargin.row}
        label="Appointment duration (in minutes)"
        onChangeRequest={handleDurationChange}
        value={data?.AppointmentDuration ?? 0}
      />
      <CheckboxWithLabel
        sx={formMargin.row}
        label={<Text variant="subHeading">Notify about progress via sms</Text>}
        checked={order.Common?.NotifyViaSMS ?? false}
        onChange={handleCheckboxChange}
        data-field-name="NotifyViaSMS"
      />
      <Box sx={formMargin.row}>
        <Button variant="primaryOutline" title="Send Message" onClick={handleSendMessageClick} />
      </Box>
      <Flex sx={merge({}, formMargin.row, { flexGrow: 1, flexDirection: "column" })}>
        <OrderContactList order={order} onOrderChanged={onOrderChanged} />
      </Flex>
    </Flex>
  );
};

export default DateTab;
