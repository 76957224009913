import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EMPTY_WORKER } from "../../../core/worker/consts";
import { IWorker } from "../../../core/worker/IWorker";
import workerPresenter from "../WorkerPresenter";
import CreateWorkerPageContent from "./CreateWorkerPageContent";

const CreateWorkerPage: React.FC = () => {
  const history = useHistory();
  const [worker, setWorker] = useState(EMPTY_WORKER);

  useEffect(() => {
    workerPresenter.createNewWorker().then(setWorker).catch(alert);
  }, []);

  const handleCancelClick = useCallback(() => {
    history.push("/workers/list");
  }, [history]);

  const handleAddClick = useCallback(async () => {
    try {
      await workerPresenter.addWorker(worker);
      history.push("/workers/list");
      alert("worker created!");
    } catch (err) {
      alert(err);
    }
  }, [history, worker]);

  const handleWorkerChanged = useCallback((newWorkerState: IWorker) => {
    setWorker(newWorkerState);
  }, []);

  return (
    <CreateWorkerPageContent
      worker={worker}
      onWorkerChanged={handleWorkerChanged}
      onAddClick={handleAddClick}
      onCancelClick={handleCancelClick}
    />
  );
};

export default CreateWorkerPage;
