import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { TCustomerListFromServer } from "../../../core/customer/ServerDto";
import PhoneIcon from "../../icons/Phone";

interface IRowProps {
  className?: string;
  children: React.ReactElement[];
  href: string;
}

const Row: React.FC<IRowProps> = ({ href, className, children }) => {
  const history = useHistory();

  const handleOnClick = useCallback(() => {
    history.push(href);
  }, [href, history]);

  return (
    <TableRow onClick={handleOnClick} className={className}>
      {children}
    </TableRow>
  );
};

interface ICustomersTableProps {
  className?: string;
  customers: TCustomerListFromServer;
}

const useStyles = makeStyles(theme => ({
  container: {
    height: 500,
  },
  customersTable: {
    tableLayout: "fixed",
  },
  customerCodeColumn: {
    width: 150,
  },
  isActiveColumn: {
    width: 70,
  },
  phoneColumn: {
    width: 40,
  },
  inviteColumn: {
    width: 80,
  },
  row: {
    cursor: "pointer",
    border: "1px solid #E0E0E0",
    "&:hover": {
      background: "#FFFFFF",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
    },
  },
  cell: {
    padding: "0 16px 0 0",
  },
  headerCell: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.background.paper,
    fontSize: 12,
  },
  phone: {
    cursor: "pointer",
  },
  invite: {
    color: "#27AE60",
    cursor: "pointer",
  },
}));

const CustomersTable: React.FC<ICustomersTableProps> = ({ className, customers }) => {
  const styles = useStyles();

  return (
    <TableContainer className={classNames(styles.container, className)}>
      <Table size="small" stickyHeader className={styles.customersTable}>
        <colgroup span={2} />
        <colgroup span={1}>
          <col className={styles.customerCodeColumn} />
        </colgroup>
        <colgroup span={2} />
        <colgroup span={3}>
          <col className={styles.isActiveColumn} />
          <col className={styles.phoneColumn} />
          <col className={styles.inviteColumn} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Company Name</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Display Name</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Customer Code</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Address</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Email Address</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Is Active</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)} />
            <TableCell className={classNames(styles.cell, styles.headerCell)} />
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map(customer => (
            <Row
              key={`${customer.EmailAddress}-${customer.Code}`}
              href={`/customers/edit/${customer.ApplicationUserId}/${customer.BranchId}`}
              className={styles.row}
            >
              <TableCell className={styles.cell}>
                <Typography noWrap>{customer.Name}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{`${customer.FirstName} ${customer.LastName}`}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{customer.Code}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{customer.Address}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{customer.EmailAddress}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{customer.IsActive ? "true" : "false"}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <PhoneIcon className={styles.phone} />
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap color="inherit" className={styles.invite}>
                  Invite
                </Typography>
              </TableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomersTable;
