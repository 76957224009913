/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Card, Flex, Text, Box } from "theme-ui";
import { noop, merge } from "lodash";
import { IOrderContact } from "../../../../core/order/IDetailedOrder";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import PhoneInputField from "../../../components/PhoneInputField";
import { formMargin } from "../../../theme/globalStyles";
import orderPresenter from "../../OrderPresenter";

interface IOrderContactListProps {
  title?: string;
  contact: IOrderContact;
  listPosition: number;
  onContactChanged?: (newContactState: IOrderContact, listPosition: number) => void;
  onRemove?: (listPosition: number) => void;
}

const OrderContact: React.FC<IOrderContactListProps> = ({
  title,
  contact,
  listPosition,
  onContactChanged = noop,
  onRemove = noop,
}) => {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      onContactChanged(
        {
          ...contact,
          [fieldName!]: value,
        },
        listPosition,
      );
    },
    [contact, onContactChanged, listPosition],
  );

  const handlePhoneChange = useCallback(
    (value: string, fieldName?: string) => {
      onContactChanged(
        {
          ...contact,
          [fieldName!]: value,
        },
        listPosition,
      );
    },
    [contact, onContactChanged, listPosition],
  );

  const handleRemoveClick = useCallback(() => {
    onRemove(listPosition);
  }, [onRemove, listPosition]);

  const typeString = orderPresenter.contactTypeToString(contact.Type!);

  return (
    <Card sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Text variant="heading">
        {title} {!!typeString && `(${typeString})`}
      </Text>
      <Box sx={{ flexDirection: "column" }}>
        <Flex sx={formMargin.row}>
          <InputField
            label="Contact Name"
            value={contact.Name ?? ""}
            onChange={handleInputChange}
            data-field-name="Name"
          />
          <InputField
            sx={formMargin.rowElement}
            label="Email Address"
            value={contact.Email ?? ""}
            onChange={handleInputChange}
            data-field-name="Email"
          />
        </Flex>
        <Flex sx={formMargin.row}>
          <PhoneInputField
            label="Phone Number 1"
            value={contact.Phone1 ?? ""}
            onChange={handlePhoneChange}
            id="Phone1"
          />
          <PhoneInputField
            sx={formMargin.rowElement}
            label="Phone Number 2"
            value={contact.Phone2 ?? ""}
            onChange={handlePhoneChange}
            id="Phone2"
          />
        </Flex>
      </Box>
      <Flex sx={merge({}, formMargin.row, { justifyContent: "flex-end" })}>
        <Button variant="dangerOutline" title="Remove contact" onClick={handleRemoveClick} />
      </Flex>
    </Card>
  );
};

export default OrderContact;
