import { createMuiTheme, useMediaQuery, useTheme } from "@material-ui/core";
import createPalette from "@material-ui/core/styles/createPalette";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

// TODO
// add main shadow

export const appThemeV2 = {
    breakpoints: ["40em", "52em", "64em"],
    fontSizes: [8, 10, 12, 14, 16, 24, 32],
    fonts: {
        body: ["Roboto", "sans-serif"].join(","),
        heading: ["Roboto", "sans-serif"].join(","),
    },
    fontWeights: {
        body: 400,
        heading: 400,
        normal: 400,
        bold: 700,
    },
    letterSpacings: {},
    lineHeights: {
        body: 1.4,
        heading: 1.4,
    },
    opacities: {},
    radii: [0, 4, 8, 16, 32, 64],
    space: [0, 4, 8, 16, 24, 32, 48, 64, 128, 256],
    colors: {
        primary: "#29C6D0",
        primaryContrastText: "#FFF",
        primaryAccentContrastText: "#3E4347",
        secondary: "#27AE60",
        background: "#F2F8F9",
        cardBackground: "#F8FBFC",
        textPrimary: "#3E4347",
        textSecondary: "#8D8F92",
        danger: "#EB5757",
        white: "#FFF",
        gray: "#8D8F92",
        darkGray: "#3E4347",
        disabledDarkGray: "#3E43477F",
        lightGray: "#505356",
        light: "#F2F2F2",
        green: "#27AE60",
        inputBorder: "#8D8F92",
        inputLabel: "#8D8F92",
        inputColor: "#3E4347",
        radioBorder: "#828282",
        secondaryInputBorder: "#E0E0E0",
        secondaryInputPlaceholder: "#828282",
    },
    shadows: {
        paper: "0 4px 16px rgba(0, 0, 0, 0.15)",
        tableRowHover: "0 0 10px rgba(0, 0, 0, 0.25)",
    },
    buttons: {
        null: {},
        primary: {
            color: "white",
            backgroundColor: "primary",
            border: "1px solid",
            borderColor: "primary",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "192px",
            cursor: "pointer",
        },
        primaryOutline: {
            color: "primary",
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: "primary",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "192px",
            cursor: "pointer",
        },
        primaryDisabledOutline: {
            color: "primary",
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: "primary",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "192px",
            opacity: 0.5,
        },
        primaryFull: {
            color: "white",
            backgroundColor: "primary",
            border: "1px solid",
            borderColor: "primary",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "192px",
            cursor: "pointer",
        },
        primaryDisabledFull: {
            color: "white",
            backgroundColor: "primary",
            border: "1px solid",
            borderColor: "primary",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "192px",
            opacity: 0.5,
        },
        dangerOutline: {
            color: "danger",
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: "danger",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "192px",
            cursor: "pointer",
        },
        danger: {
            color: "white",
            backgroundColor: "danger",
            border: "1px solid",
            borderColor: "danger",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "192px",
            cursor: "pointer",
        },
        primaryOutlineSmall: {
            color: "primary",
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: "primary",
            borderRadius: 1,
            padding: 0,
            height: "32px",
            width: "100px",
            cursor: "pointer",
        },
        icon: {
            color: "darkGray",
            padding: 0,
            cursor: "pointer",
        },
        iconDisabled: {
            color: "disabledDarkGray",
            padding: 0,
            cursor: "pointer",
        },
        iconAndText: {
            color: "primary",
            backgroundColor: "transparent",
            padding: 0,
            height: "32px",
            cursor: "pointer",
        },
        textPrimary: {
            color: "primary",
            backgroundColor: "transparent",
            padding: 0,
            height: "32px",
            cursor: "pointer",
        },
    },
    text: {
        default: {
            fontSize: 4,
        },
        heading: {
            fontSize: 5,
        },
        headingPrimary: {
            fontSize: 5,
            fontWeight: "bold",
            color: "primary",
        },
        subHeadingPrimary: {
            fontSize: 4,
            color: "primary",
        },
        subHeading: {
            fontSize: 4,
        },
        subHeadingSecondaryText: {
            fontSize: 4,
            color: "textSecondary",
        },
        accentSubHeading: {
            fontSize: 4,
            fontWeight: "bold",
        },
        label: {
            fontSize: 2,
        },
        labelSecondaryText: {
            fontSize: 2,
            color: "textSecondary",
        },
        button: {
            fontSize: 4,
            fontWeight: "bold",
        },
        textButton: {
            fontSize: 4,
        },
        input: {
            fontSize: 4,
        },
        menuItem: {
            fontSize: "18px",
            color: "inherit",
        },
        windowHeader: {
            fontSize: 4,
            fontWeight: "bold",
            color: "primaryContrastText",
        },
        progressDone: {
            fontSize: "18px",
            fontWeight: "bold",
            color: "primary",
        },
        progress: {
            fontSize: "18px",
        },
    },
    forms: {
        margin: {
            group: 6,
            row: 3,
            rowElement: 3,
        },
        label: {
            input: {
                color: "inputLabel",
                fontSize: 2,
                marginLeft: 1,
                marginBottom: 2,
            },
        },
        input: {
            height: "32px",
            borderRadius: "4px",
            borderColor: "inputBorder",
            minWidth: 0,
            padding: "0 8px",
            fontSize: 4,
            color: "inputColor",
            "&::placeholder": {
                color: "inputBorder",
            },
            "&:disabled": {
                color: "inputBorder",
            },
        },
        textarea: {
            fullArea: {
                width: "100%",
                height: "100%",
                resize: "none",
                borderRadius: 1,
                borderColor: "inputBorder",
                fontSize: 4,
                fontFamily: "body",
                color: "inputColor",
            },
        },
        secondarySearch: {
            height: "32px",
            border: "none",
            minWidth: 0,
            padding: "0 8px",
            fontSize: 4,
            color: "inputColor",
            "&::placeholder": {
                color: "secondaryInputPlaceholder",
            },
        },
    },
    variants: {
        badge: {
            display: "inline-block",
            p: 1,
            color: "primaryAccentContrastText",
            bg: "primary",
            borderRadius: 2,
        },
    },
    cards: {
        primary: {
            padding: 3,
            paddingTop: 2,
            paddingBottom: 4,
            border: "1px solid",
            borderColor: "lightGray",
            borderRadius: 2,
            backgroundColor: "transparent",
        },
        paper: {
            padding: 3,
            backgroundColor: "cardBackground",
            boxShadow: "paper",
            borderRadius: 2,
        },
        note: {
            border: "1px solid",
            borderColor: "gray",
            borderRadius: 2,
            padding: 2,
        },
        window: {
            backgroundColor: "white",
            boxShadow: "paper",
            borderRadius: 2,
        },
    },
};

const appTheme = createMuiTheme({
    palette: createPalette({
        primary: {
            main: "#29C6D0",
            contrastText: "#3E4347",
        },
        background: {
            default: "#F2F8F9",
            paper: "#F8FBFC",
        },
        text: {
            primary: "#3E4347",
            secondary: "#8D8F92",
        },
    }),
    typography: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        h1: {
            fontSize: "24px",
            lineHeight: "140%",
            fontStyle: "normal",
            fontWeight: "normal",
        },
        h3: {
            fontSize: "18px",
            lineHeight: "140%",
            fontStyle: "normal",
            fontWeight: "normal",
        },
        h4: {
            fontSize: "16px",
            lineHeight: "140%",
            fontStyle: "normal",
            fontWeight: "normal",
        },
        h6: {
            fontSize: "12px",
            lineHeight: "140%",
            fontStyle: "normal",
            fontWeight: "bold",
        },
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 8,
            },
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
});

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export function useWidth(): Breakpoint {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce<Breakpoint | null>((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || "xs"
    );
}

export default appTheme;
