import config from "../../config/config";
import { Id } from "../common/type";
import httpService from "../http/HttpService";
import { INoteFromServer } from "../note/ServerDto";
import { IOrderUpload } from "../order/IDetailedOrder";
import {
    IDetailedWorkerFromServer,
    IListWorkerFromServer,
    INewPostCodeToServer,
    INewWorkerToServer,
    IRemovePostCodeToServer,
    IWorkerDetailsToServer,
    IWorkerFiltersToServer,
    IWorkerListFiltersToServer,
    IWorkerPostCodeFromServer,
    IWorkerServiceFromServer,
    IWorkerServicesToServer,
    IWorkerSort,
} from "./ServerDto";

const WORKERS_PATH = `${config.apiUrl}/workers`;

class WorkerRepository {
    async getList(filters: IWorkerListFiltersToServer, workerSort?: IWorkerSort): Promise<IListWorkerFromServer[]> {
        let path = `${WORKERS_PATH}/list`;
        if (workerSort) {
            path = httpService.addQueryParams(path, workerSort);
        }
        const response = await httpService.post(path, filters);
        if (response.error) return Promise.reject(response.message);
        return response.response as IListWorkerFromServer[];
    }

    async getFilteredList(filters: IWorkerFiltersToServer): Promise<IListWorkerFromServer[]> {
        const path = `${WORKERS_PATH}/filtered-list`;
        const response = await httpService.post(path, filters);
        if (response.error) return Promise.reject(response.message);
        return response.response as IListWorkerFromServer[];
    }

    async create(data: INewWorkerToServer): Promise<Id> {
        const path = `${WORKERS_PATH}/new`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response as Id;
    }

    async delete(workerId: Id): Promise<void> {
        const path = `${WORKERS_PATH}/delete/${workerId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async getData(workerUserId: Id): Promise<IDetailedWorkerFromServer> {
        const path = `${WORKERS_PATH}/get-data/${workerUserId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as IDetailedWorkerFromServer;
    }

    async updateDetails(workerUserId: Id, data: IWorkerDetailsToServer): Promise<void> {
        const path = `${WORKERS_PATH}/update-details/${workerUserId}`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async updateServices(workerUserId: Id, data: IWorkerServicesToServer): Promise<void> {
        const path = `${WORKERS_PATH}/update-services/${workerUserId}`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async getServices(workerUserId?: Id): Promise<IWorkerServiceFromServer[]> {
        const path = `${WORKERS_PATH}/get-services/${workerUserId ?? ""}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        if (Array.isArray(response.response)) return response.response as IWorkerServiceFromServer[];
        return [];
    }

    async addPostCode(data: INewPostCodeToServer): Promise<IWorkerPostCodeFromServer> {
        const path = `${WORKERS_PATH}/add-postcode`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response as IWorkerPostCodeFromServer;
    }

    async removePostCode(data: IRemovePostCodeToServer): Promise<void> {
        const path = `${WORKERS_PATH}/delete-postcode`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async getNotes(workerUserId: Id): Promise<INoteFromServer[]> {
        const path = `${WORKERS_PATH}/${workerUserId}/notes`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject(response.message);
        if (Array.isArray(response.response)) return response.response as INoteFromServer[];
        return [];
    }

    async getCalendarId(workerUserId: Id): Promise<string> {
        const path = `${WORKERS_PATH}/get-calendar/${workerUserId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as string;
    }

    async getPortfolio(workerUserId: Id): Promise<IOrderUpload[]> {
        const path = `${WORKERS_PATH}/get-portfolio/${workerUserId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        if (Array.isArray(response.response)) return response.response as IOrderUpload[];
        return [];
    }
}

const workerRepository = new WorkerRepository();
export default workerRepository;
