import * as React from "react";
import * as ReactDOM from "react-dom";
import { setAccessToken } from "../store/auth/authToken";
import config from "../config/config";
import { IAccessToken } from "../core/auth/IAccessToken";
import logger from "../core/logger/logger";
import { IUserFromServer } from "../core/user/ServerDto";
import { store } from "../store/store";
import AppUI from "../ui/AppUI";
import ls from "../core/localStorage/localStorage";
import { setUser } from "../store/user/user";

const log = logger("main");

class App {
  start(): void {
    printBuildInfo((process.env.BUILD_INFO as unknown) as string);
    printConfig();

    initUser();
    initAccessToken();

    // @ts-ignore
    if (!window.preventApp) {
      const appEl = document.getElementById("app");
      ReactDOM.render(<AppUI />, appEl);
    }
  }
}

function initUser() {
  const savedUser = ls.get("user") as Record<string, unknown> | null;
  if (savedUser) {
    store.dispatch(setUser(savedUser as IUserFromServer));
  }
}

function initAccessToken() {
  const savedAccessToken = ls.get("token") as Record<string, unknown> | null;
  if (savedAccessToken) {
    store.dispatch(setAccessToken((savedAccessToken as unknown) as IAccessToken));
  }
}

interface IBuildInfo {
  buildDate: number;
  version: string;
  branchName: string;
  commitId: string;
  test: string;
}

function printBuildInfo(buildInfoString: string): void {
  try {
    const buildInfo = JSON.parse(buildInfoString) as IBuildInfo;
    log.info(`version: ${buildInfo.version}`);
    log.info(`build date: ${new Date(buildInfo.buildDate).toISOString()}`);
    log.info(`branch: "${buildInfo.branchName}"`);
    log.info(`commit: "${buildInfo.commitId}"`);
  } catch {}
}

function printConfig() {
  log.debug(config);
}

export default App;
