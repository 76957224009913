/** @jsx jsx */
import React from "react";
import { Box, Card, Flex, jsx } from "theme-ui";
import { IWorker } from "../../../core/worker/IWorker";
import { Styles } from "../../theme/utils";
import EditWorkerHeader from "./EditWorkerHeader";
import EditWorkerEditor from "./EditWorkerEditor";

interface IEditWorkerPageContentProps {
  worker: IWorker;
  onWorkerChanged: (newWorkerState: IWorker) => void;
  onSaveChangesClick: () => void;
  onRemoveClick: () => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    height: "80px",
    marginBottom: 3,
  },
  cardContainer: {
    flexGrow: 1,
    flexDirection: "column",
  },
  card: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
};

const EditWorkerPageContent: React.FC<IEditWorkerPageContentProps> = ({
  worker,
  onWorkerChanged,
  onSaveChangesClick,
  onRemoveClick,
}) => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.header}>
        <EditWorkerHeader
          workerName={`${worker.AccountDetails.FirstName ?? ""} ${worker.AccountDetails.LastName ?? ""}`}
          onSaveChangesClick={onSaveChangesClick}
          onRemoveClick={onRemoveClick}
        />
      </Box>
      <Flex sx={styles.cardContainer}>
        <Card sx={styles.card} variant="paper">
          <EditWorkerEditor worker={worker} onWorkerChanged={onWorkerChanged} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default EditWorkerPageContent;
