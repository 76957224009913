import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import administrationRepository from "../../../core/administration/AdministrationRepository";
import { IAdministrationListFiltersToServer } from "../../../core/administration/ServerDto";
import { IListWorker } from "../../../core/worker/IWorker";
import Rating from "../../components/Rating";
import { appThemeV2 } from "../../theme/theme";

interface IWorkerListProps {
  className?: string;
  workers: IListWorker[];
}

const useStyles = makeStyles(theme => ({
  container: {
    height: 500,
  },
  workersTable: {
    tableLayout: "fixed",
  },
  nameColumn: {
    width: 350,
  },
  emailColumn: {
    width: 350,
  },
  ratingColumn: {
    width: 130,
  },
  isActiveColumn: {
    width: 70,
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      background: "white",
      boxShadow: appThemeV2.shadows.tableRowHover,
    },
  },
  cell: {
    padding: "0 16px 0 0",
  },
  headerCell: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.background.paper,
    fontSize: 12,
  },
}));

const WorkersTable: React.FC<IWorkerListProps> = ({ className, workers }) => {
  const styles = useStyles();

  return (
    <TableContainer className={classNames(styles.container, className)}>
      <Table size="small" stickyHeader className={styles.workersTable}>
        <colgroup span={1}>
          <col className={styles.nameColumn} />
        </colgroup>
        <colgroup span={1} />
        <colgroup span={1}>
          <col className={styles.emailColumn} />
        </colgroup>
        <colgroup span={1}>
          <col className={styles.ratingColumn} />
        </colgroup>
        <colgroup span={1}>
          <col className={styles.isActiveColumn} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Display Name</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Work Type</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Email Address</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Rate</TableCell>
            <TableCell className={classNames(styles.cell, styles.headerCell)}>Is Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workers.map(worker => (
            <Row key={`${worker.WorkerId}`} href={`/workers/edit/${worker.ApplicationUserId}`} className={styles.row}>
              <TableCell className={styles.cell}>
                <Typography noWrap>{worker.Name}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Tooltip title={worker.WorkerType ?? ""}>
                  <Typography noWrap>{worker.WorkerType}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={styles.cell}>
                <Tooltip title={worker.Email ?? ""}>
                  <Typography noWrap>{worker.Email}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={styles.cell}>
                <Rating value={worker.Rating ?? 0} size="small" canEdit={false} />
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{worker.IsActive ? "true" : "false"}</Typography>
              </TableCell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface IRowProps {
  className?: string;
  children: React.ReactElement[];
  href: string;
}

const Row: React.FC<IRowProps> = ({ href, className, children }) => {
  const history = useHistory();

  const handleOnClick = useCallback(() => {
    history.push(href);
  }, [href, history]);

  return (
    <TableRow onClick={handleOnClick} className={className}>
      {children}
    </TableRow>
  );
};

export default WorkersTable;
