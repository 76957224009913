import React from "react";
import createIcon from "./createIcon";

const MinusIcon = createIcon(
  <>
    <rect width="20" height="2" />
  </>,
  20,
  2,
  "currentColor",
);

export default MinusIcon;
