import { assign } from "lodash";
import config from "../../config/config";
import { Id, IDto, IDtoObject, TPrimitiveType } from "../common/type";
import httpService from "../http/HttpService";
import { IPackageFromServer } from "../package/ServerDto";
import { IUserFromServer } from "../user/ServerDto";
import { IListWorkerFromServer } from "../worker/ServerDto";
import { IDetailedOrder, INote, IOfferedService, IOrderService } from "./IDetailedOrder";
import { IListOrder } from "./IListOrder";
import {
    IAdditionalOrderServiceToServer,
    IDeletePhotosToServer,
    IOrderDataToServer,
    IMaintainerSearchFilter,
    IOrderSort,
    IGenerateOrderToServer,
} from "./ServerDto";

const ORDER_PATH = `${config.apiUrl}/order`;
const UPLOAD_PATH = `${config.apiUrl}/workers`;
const ORDER_TEMPLATES_PATH = `${config.apiUrl}/order-templates`;

class OrderRepository {
    ORDER_PATH = ORDER_PATH;

    async getUnmaintainedCount(): Promise<number> {
        const path = `${ORDER_PATH}/unmaintained-list/`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject();
        return response.response as number;
    }

    async getList(branches?: any): Promise<IListOrder[]> {
        const path = `${ORDER_PATH}/list/`;
        const response = await httpService.post(path, branches);
        if (response.error) return Promise.reject();
        if (Array.isArray(response.response)) return response.response as IListOrder[];
        return [];
    }

    async getFilteredList(filters: any = {}, sort?: IOrderSort): Promise<IListOrder[]> {
        let path = `${ORDER_PATH}/filtered-list/`;
        if (sort) {
            path = httpService.addQueryParams(path, sort as Record<string, TPrimitiveType>);
        }
        const response = await httpService.post(path, filters);
        if (response.error) return Promise.reject();
        if (Array.isArray(response.response)) return response.response as IListOrder[];
        return [];
    }

    async getPossiblePackages(customerId: Id): Promise<IPackageFromServer[]> {
        const path = `${ORDER_TEMPLATES_PATH}/possible-list/fororder/${customerId}`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject();
        if (Array.isArray(response.response)) return response.response as IPackageFromServer[];
        return [];
    }

    async delete(orderId: Id): Promise<void> {
        const path = `${ORDER_PATH}/delete`;
        const sData = {
            OrderId: orderId,
        };
        const response = await httpService.post(path, sData);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async getDetailedOrderData(id: Id): Promise<IDetailedOrder> {
        const path = `${ORDER_PATH}/get-details/${id}/`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject();
        return response.response as IDetailedOrder;
    }

    async setDetailedOrderData(sData: IDetailedOrder): Promise<IDetailedOrder> {
        const path = `${ORDER_PATH}/set-details/`;
        const response = await httpService.post(path, sData);
        if (response.error) return Promise.reject(response.message);
        return response.response as IDetailedOrder;
    }

    async confirmFiles(orderId: Id, fileIds: Id[]): Promise<void> {
        const path = `${ORDER_PATH}/confirm-files/${orderId}/`;
        const response = await httpService.post(path, fileIds);
        if (response.error) return Promise.reject();
        return Promise.resolve();
    }

    async changeTemplate(orderId: Id, templateId: Id): Promise<void> {
        const path = `${ORDER_PATH}/change-template/`;
        const sData = {
            OrderId: orderId,
            OrderTemplateId: templateId,
        };
        const response = await httpService.post(path, sData);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async systemSuggest(data: any): Promise<IDto> {
        const path = `${ORDER_PATH}/suggest-worker/`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject();
        return response.response;
    }

    async markServiceAsComplete(orderServiceId: Id) {
        const path = `${ORDER_PATH}/mark-as-complete/${orderServiceId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject();
        return response.response;
    }

    async downloadOrderFiles(
        orderId: Id,
        resolutions: IDtoObject[],
        fileIds: Id[] = [],
        serviceData: IDto = {},
    ): Promise<IDtoObject> {
        const path = `${ORDER_PATH}/download-files/`;

        const resolutionsData = [];
        for (let i = 0; i < resolutions.length; i++) {
            resolutionsData.push(resolutions[i].name);
        }

        const requestData = {
            OrderId: orderId,
            Resolutions: resolutionsData,
            SelectedFiles: fileIds,
        };
        assign(requestData, serviceData);

        const response = await httpService.post(path, requestData as any);
        if (response.error) return Promise.reject();
        return response.response as IDtoObject;
    }

    async findMaintainer(data: IMaintainerSearchFilter): Promise<IUserFromServer[]> {
        const path = `${ORDER_PATH}/find-maintainer/`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject();
        return response.response as IUserFromServer[];
    }

    async findWorker(data: IMaintainerSearchFilter): Promise<IListWorkerFromServer[]> {
        const path = `${ORDER_PATH}/find-worker/`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject();
        return response.response as IListWorkerFromServer[];
    }

    async reAssignWorker(orderServiceId: Id, applicationUserId: Id): Promise<void> {
        const path = `${ORDER_PATH}/re-assign-worker/`;
        const data = {
            OrderServiceId: orderServiceId,
            ApplicationUserId: applicationUserId,
        };
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject();
        return Promise.resolve();
    }

    async notifyCustomer(orderServiceId: Id): Promise<string> {
        const path = `${ORDER_PATH}/notify-customer/${orderServiceId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject();
        return response.response as string;
    }

    async getNotes(orderId: Id): Promise<INote[]> {
        const path = `${ORDER_PATH}/${orderId}/notes`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject();
        if (Array.isArray(response.response)) return response.response as INote[];
        return [];
    }

    async addNote(noteData: any = {}): Promise<void> {
        const path = `${ORDER_PATH}/notes/create`;
        const response = await httpService.post(path, noteData);
        if (response.error) return Promise.reject();
        return Promise.resolve();
    }

    async orderAdditionalService(orderId: Id, serviceData: any): Promise<void> {
        const path = `${ORDER_PATH}/${orderId}/service`;
        const response = await httpService.post(path, serviceData);
        if (response.error) return Promise.reject();
        return Promise.resolve();
    }

    async generateOrder(data: IGenerateOrderToServer): Promise<string> {
        const path = `${ORDER_PATH}/generate`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject();
        return response.response as string;
    }

    async createOrder(packageId: Id, data: IOrderDataToServer): Promise<string> {
        const path = `${ORDER_PATH}/create/${packageId}`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject();
        return response.response as string;
    }

    async getPriceForService(orderId: Id, serviceData: IAdditionalOrderServiceToServer): Promise<number> {
        const path = `${ORDER_PATH}/${orderId}/service/price`;
        const response = await httpService.post(path, serviceData);
        if (response.error) return Promise.reject();
        return response.response as number;
    }

    async createOfferedService(orderId: Id, serviceData: IAdditionalOrderServiceToServer): Promise<void> {
        const path = `${ORDER_PATH}/${orderId}/offered-service`;
        const response = await httpService.post(path, serviceData);
        if (response.error) return Promise.reject();
        return Promise.resolve();
    }

    async uploadPhotos(files: File[], orderServiceId: Id): Promise<IOrderService> {
        const path = `${UPLOAD_PATH}/upload-file/${orderServiceId}`;
        const response = await httpService.upload(path, files);
        if (response.error) return Promise.reject();
        return response.response as IOrderService;
    }

    async uploadPlans(files: File[], orderServiceId: Id): Promise<IOrderService> {
        const path = `${UPLOAD_PATH}/upload-plan/${orderServiceId}`;
        const response = await httpService.upload(path, files);
        if (response.error) return Promise.reject();
        return response.response as IOrderService;
    }

    async uploadOfferedServicePhotos(files: File[], offeredServiceId: Id): Promise<IOfferedService> {
        const path = `${UPLOAD_PATH}/upload-file-offered/${offeredServiceId}`;
        const response = await httpService.upload(path, files);
        if (response.error) return Promise.reject();
        return response.response as IOfferedService;
    }

    async deletePhotos(toDelete: IDeletePhotosToServer, orderServiceId: Id): Promise<IOrderService | IOfferedService> {
        const path = `${UPLOAD_PATH}/delete-files/${orderServiceId}`;
        const response = await httpService.post(path, toDelete);
        if (response.error) return Promise.reject();
        return response.response as IOrderService | IOfferedService;
    }
}

const orderRepository = new OrderRepository();
export default orderRepository;
