import { Action, combineReducers, ThunkAction } from "@reduxjs/toolkit";
import authTokenReducer from "./auth/authToken";
import uploaderReducer from "./uploader/uploader";
import userReducer from "./user/user";
import unmaintainedOrdersReducer from "./order/unmaintainedOrders";

export const rootReducer = combineReducers({
    token: authTokenReducer,
    user: userReducer,
    uploads: uploaderReducer,
    orders: unmaintainedOrdersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
