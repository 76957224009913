/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { Box, Flex, jsx } from "theme-ui";
import { generateNewPassword } from "../../core/common/passwordGenerator";
import { formMargin } from "../theme/globalStyles";
import Button from "./Button";
import InputField from "./InputField";

interface IPasswordFieldProps {
  className?: string;
  passwords: IPasswords;
  onChange: (passwords: IPasswords) => void;
  isCreatingPassword?: boolean;
}

export interface IPasswords {
  OldPassword: string;
  Password: string;
  ConfirmPassword: string;
}

const PasswordField: React.FC<IPasswordFieldProps> = ({ className, passwords, onChange, isCreatingPassword }) => {
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isShowingPassword, setIsShowingPassword] = useState(false);

  const handleChangePasswordClick = useCallback(() => {
    setIsChangingPassword(true);
  }, []);

  const handleGeneratePasswordClick = useCallback(() => {
    const newPassword = generateNewPassword();
    onChange({
      ...passwords,
      Password: newPassword,
      ConfirmPassword: newPassword,
    });
    setIsShowingPassword(true);
  }, [passwords, onChange]);

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const { fieldName } = event.target.dataset;
      onChange({
        ...passwords,
        [fieldName!]: value,
      });
    },
    [onChange, passwords],
  );

  return (
    <Box className={className}>
      {isChangingPassword || isCreatingPassword ? (
        <Box>
          <Flex>
            {isChangingPassword && (
              <InputField
                label="Old Password"
                type="password"
                value={passwords.OldPassword}
                data-field-name="OldPassword"
                onChange={handleInputChanged}
              />
            )}
            <InputField
              sx={isChangingPassword ? formMargin.rowElement : {}}
              label="Password"
              type={isShowingPassword ? "text" : "password"}
              value={passwords.Password}
              data-field-name="Password"
              onChange={handleInputChanged}
            />
            <InputField
              sx={formMargin.rowElement}
              label="Confirm Password"
              type={isShowingPassword ? "text" : "password"}
              value={passwords.ConfirmPassword}
              data-field-name="ConfirmPassword"
              onChange={handleInputChanged}
            />
          </Flex>
          <Box sx={formMargin.row}>
            <Button variant="primary" title="Generate Password" onClick={handleGeneratePasswordClick} />
          </Box>
        </Box>
      ) : (
        <Box>
          <Flex>
            <InputField label="Password" type="password" value="●●●●●●●●" disabled />
          </Flex>
          <Box sx={formMargin.row}>
            <Button variant="primary" title="Change Password" onClick={handleChangePasswordClick} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PasswordField;
