/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { jsx, Flex, Box, SxStyleProp, IconButton } from "theme-ui";
import LeftArrowIcon from "../icons/LeftArrow";
import RightArrowIcon from "../icons/RightArrow";

interface ICardListProps {
  className?: string;
  cards?: React.ReactChild[];
  customControls?: React.ReactChild | React.ReactChild[];
}

const styles: Record<string, SxStyleProp> = {
  root: {
    flexDirection: "column",
    alignItems: "stretch",
  },
  listControls: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconButton: {
    width: "24px",
  },
  icon: {
    width: "24px",
    height: "20px",
  },
  listContainer: {
    marginTop: 2,
    flexGrow: 1,
    justifyContent: "space-between",
    "& > *": {
      flexBasis: "calc(50% - 16px / 2)",
      flexShrink: 1,
    },
  },
  rightArrowContainer: {
    marginLeft: "16px",
    display: "inline",
  },
};

const CARDS_PER_PAGE = 2;

const CardList: React.FC<ICardListProps> = ({ className, customControls, cards = [] }) => {
  const [page, setPage] = useState(0);

  const handleLeftArrowClick = useCallback(() => {
    setPage(currPage => (canGoLeft(currPage) ? currPage - 1 : 0));
  }, [setPage]);

  const handleRightArrowClick = useCallback(() => {
    setPage(currPage => (canGoRight(currPage, CARDS_PER_PAGE, cards.length) ? currPage + 1 : currPage));
  }, [setPage, cards]);

  return (
    <Flex className={className} sx={styles.root}>
      <Flex sx={styles.listControls}>
        <Box sx={styles.leftControls}>{customControls}</Box>
        <Box sx={styles.rightControls}>
          <IconButton sx={styles.iconButton} variant={canGoLeft(page) ? "icon" : "iconDisabled"}>
            <LeftArrowIcon sx={styles.icon} onClick={handleLeftArrowClick} />
          </IconButton>
          <Box sx={styles.rightArrowContainer}>
            <IconButton
              variant={canGoRight(page, CARDS_PER_PAGE, cards.length) ? "icon" : "iconDisabled"}
              sx={styles.iconButton}
            >
              <RightArrowIcon sx={styles.icon} onClick={handleRightArrowClick} />
            </IconButton>
          </Box>
        </Box>
      </Flex>
      <Flex sx={styles.listContainer}>{cards.slice(page * CARDS_PER_PAGE, (page + 1) * CARDS_PER_PAGE)}</Flex>
    </Flex>
  );
};

function canGoLeft(page: number): boolean {
  return page !== 0;
}

function canGoRight(page: number, itemsPerPage: number, totalItemsCount: number): boolean {
  return (page + 1) * itemsPerPage < totalItemsCount;
}

export default CardList;
