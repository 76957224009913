import { IPackage } from "../../../../core/package/IPackage";

export interface IPackageGroup {
    title: string;
    items: IPackage[];
}

const GROUPS = [
    {
        groupName: "Photos Only",
        groupMatchRegExp: /^(\d)+ photos$/i,
    },
    {
        groupName: "Photos & Floor Plan",
        groupMatchRegExp: /^(\d)+ photos & floor plan$/i,
    },
    {
        groupName: "Photos & Floor Plan & EPC",
        groupMatchRegExp: /^(\d)+ photos & floor plan & EPC$/i,
    },
    {
        groupName: "Photos & Floor Plan & 360 Tour",
        groupMatchRegExp: /^(\d)+ photos & floor plan & x(\d)+ 360 tour$/i,
    },
    {
        groupName: "Photos & Floor Plan & 360° Virtual Tour",
        groupMatchRegExp: /photos & floor plan & 360° virtual tour with up to [\d]+ images$/i,
    },
    {
        groupName: "Photos & Floor Plan Conversion",
        groupMatchRegExp: /property \([\d]+ Photos & One 2D Floor Plan Conversion\) Up to [\d]+ sq ft$/i,
    },
    {
        groupName: "Photos & Floor Plan & EPC",
        groupMatchRegExp: /Photos \| 2D Floor Plan \| EPC[\w\s]*[\d]+ Bedrooms/i,
    },
    {
        groupName: "Other",
        groupMatchRegExp: /\S+/i,
    },
];

export function splitToGroups(packages: IPackage[]): IPackageGroup[] {
    const packagesByGroup: Record<string, IPackage[]> = {};
    let remainingPackages = packages;

    GROUPS.forEach(group => {
        packagesByGroup[group.groupName] = [];

        remainingPackages.forEach(pkg => {
            if (group.groupMatchRegExp.test(pkg.Title ?? "")) {
                packagesByGroup[group.groupName].push(pkg);
            }
        });

        packagesByGroup[group.groupName].sort((a, b) => {
            if (a.Price! < b.Price!) return -1;
            if (a.Price! > b.Price!) return 1;
            return 0;
        });

        remainingPackages = remainingPackages.filter(pkg => !group.groupMatchRegExp.test(pkg.Title ?? ""));
    });

    return GROUPS.reduce((result, group) => {
        const groupPackages = packagesByGroup[group.groupName];
        if (groupPackages && groupPackages.length) {
            result.push({
                title: group.groupName,
                items: groupPackages,
            });
        }
        return result;
    }, [] as IPackageGroup[]);
}
