/** @jsx jsx */
import React from "react";
import { Link } from "react-router-dom";
import { Flex, Box, Heading, jsx } from "theme-ui";
import Button from "../../../components/Button";
import LeftArrowIcon from "../../../icons/LeftArrow";
import { link } from "../../../theme/globalStyles";

interface ICustomerEditorHeaderProps {
  onCancelClick: () => void;
  onAddCustomerClick: () => void;
}

const CreateCustomerHeader: React.FC<ICustomerEditorHeaderProps> = ({ onCancelClick, onAddCustomerClick }) => {
  return (
    <Flex sx={{ justifyContent: "space-between" }}>
      <Box>
        <Flex>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/customers" sx={link.neutralColor}>
              <LeftArrowIcon />
            </Link>
          </Box>
          <Box sx={{ marginLeft: 3 }}>
            <Heading>New Customer</Heading>
          </Box>
        </Flex>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ marginRight: 4 }}>
          <Button variant="dangerOutline" title="Cancel" onClick={onCancelClick} />
        </Box>
        <Box>
          <Button variant="primaryOutline" title="Add Customer" onClick={onAddCustomerClick} />
        </Box>
      </Box>
    </Flex>
  );
};

export default CreateCustomerHeader;
