import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import config from "../../config/config";
import { useAppSelector } from "../../store/store";
import LogoutIcon from "../icons/Logout";
import PersonIcon from "../icons/Person";
import logo from "./logo360.svg";
import MenuIcon from "../icons/Menu";
import NavigationMenu from "./NavigationMenu";
import XeroStatusIcon from "../icons/XeroStatus";
import XeroLoginIcon from "../icons/XeroLogin";
import xeroRepository from "../../core/xero/xeroRepository";
import UploadWindow from "../upload/UploadWindow";

interface ILayoutProps {
  page: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    height: "52px",
    paddingLeft: "48px",
  },
  logo: {
    height: "22px",
    marginTop: "16px",
    "& img": {
      height: "100%",
    },
  },
  sidebarAndPage: {
    flexGrow: 1,
    display: "flex",
  },
  sidebar: {
    flexShrink: 0,
    width: "320px",
    paddingLeft: "48px",
  },
  sidebarMinimized: {
    flexShrink: 0,
    paddingLeft: "48px",
  },
  user: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
  },
  menu: {
    position: "absolute",
    left: 0,
  },
  userLink: {
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  userLinkActive: {
    color: theme.palette.primary.main,
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
  userAvatar: {
    width: "36px",
    height: "36px",
    borderRadius: "18px",
    background: "transparent no-repeat center / cover",
  },
  userAvatarMinimized: {
    flex: "auto",
    width: "36px",
    height: "36px",
    borderRadius: "18px",
    background: "transparent no-repeat center / cover",
  },
  userIcon: {
    width: "36px",
    height: "36px",
    color: theme.palette.primary.main,
  },
  userIconMinimized: {
    flex: "auto",
    width: "36px",
    height: "36px",
    color: theme.palette.primary.main,
  },
  menuIconButton: {
    height: "48px",
    minWidth: "44px",
  },
  menuIcon: {
    width: "28px",
    height: "48px",
    color: theme.palette.primary.main,
    position: "absolute",
    left: 0,
  },
  userName: {
    marginLeft: "8px",
    width: "155px",
    textOverflow: "ellipsis",
  },
  logoutLink: {
    marginLeft: "8px",
    cursor: "pointer",
  },
  logoutIcon: {
    width: "17px",
    height: "18px",
    color: theme.palette.primary.main,
  },
  navigation: {
    marginTop: "36px",
    paddingRight: "16px",
  },
  navigationMinimized: {
    marginTop: "48px",
  },
  page: {
    flexGrow: 1,
    padding: "24px 48px",
    display: "flex",
    flexDirection: "column",
  },
  XeroIcon: {
    backgroundColor: "#EB5757",
    borderRadius: "5px",
    width: "28px",
    height: "12px",
  },
  XeroIconActive: {
    backgroundColor: "#27AE60",
    borderRadius: "5px",
    width: "28px",
    height: "12px",
  },
  XeroStatus: {
    width: "28px",
    height: "12px",
    marginLeft: "40px",
  },
  XeroStatusMinimized: {
    width: "28px",
    height: "12px",
    marginLeft: "5px",
  },
  XeroLogin: {
    width: "16px",
    paddingRight: "91px",
    marginTop: "-17px",
    height: "16px",
    float: "right",
  },
  XeroLoginIcon: {
    width: "16px",
    height: "16px",
  },
  uploadWindow: {
    position: "fixed",
    bottom: "24px",
    left: "24px",
  },
}));

const Layout: React.FC<ILayoutProps> = ({ page }) => {
  const styles = useStyles();
  const [isOpen, setOpen] = useState(true);
  const [status, setStatus] = useState(false);

  const changeMenu = () => {
    setOpen(currentState => !currentState);
  };

  const updateXeroStatus = useCallback(async () => {
    try {
      let newStatus = await xeroRepository.xeroStatus();

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code") as string;
      if (code && !newStatus) {
        await xeroRepository.connectXero(code, config.xeroUrl);
        newStatus = await xeroRepository.xeroStatus();
      }

      setStatus(newStatus);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    updateXeroStatus();
  });

  const xeroLogin = async () => {
    const url = await xeroRepository.authRedirection(config.xeroUrl, "state");

    window.location.href = url;
  };

  const user = useAppSelector(state => state.user);
  const userName = user.DisplayName;
  const userAvatar = user.AvatarThumb;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} alt="Photoplan" />
        </div>
      </div>
      <div className={styles.sidebarAndPage}>
        <div className={!isOpen ? styles.sidebar : styles.sidebarMinimized}>
          <div className={styles.user}>
            <div className={styles.menu}>
              <Button className={styles.menuIconButton} onClick={changeMenu}>
                <MenuIcon className={styles.menuIcon} />
              </Button>
            </div>
            <NavLink to="/user" className={styles.userLink} activeClassName={styles.userLinkActive}>
              {userAvatar ? (
                <div
                  className={!isOpen ? styles.userAvatar : styles.userAvatarMinimized}
                  style={{ backgroundImage: `url("${userAvatar}")` }}
                >
                  &nbsp;
                </div>
              ) : (
                <PersonIcon className={!isOpen ? styles.userIcon : styles.userIconMinimized} />
              )}
              {!isOpen && (
                <div className={styles.userName}>
                  <Typography variant="h1" noWrap>
                    {userName}
                  </Typography>
                </div>
              )}
            </NavLink>
            {!isOpen && (
              <a className={styles.logoutLink} href={config.logoutUrl}>
                <LogoutIcon className={styles.logoutIcon} />
              </a>
            )}
          </div>
          <div className={!isOpen ? styles.XeroStatus : styles.XeroStatusMinimized}>
            <XeroStatusIcon className={!status ? styles.XeroIcon : styles.XeroIconActive} />
          </div>
          {!isOpen && (
            <div className={styles.XeroLogin}>
              <Button onClick={xeroLogin}>
                <XeroLoginIcon className={styles.XeroLoginIcon} />
              </Button>
            </div>
          )}
          <nav className={!isOpen ? styles.navigation : styles.navigationMinimized}>
            <NavigationMenu isOpen={isOpen} />
          </nav>
        </div>
        <div className={styles.page}>{page}</div>
        <div className={styles.uploadWindow}>
          <UploadWindow />
        </div>
      </div>
    </div>
  );
};

export default Layout;
