import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import EditOrderPage from "./EditOrder/EditOrderPage";
import OrderListPage from "./OrderList/OrderListPage";
import AddOrderPage from "./AddOrder/AddOrderPage";
import GeneratePackagePage from "./AddOrder/GeneratePackagePage";
import xeroRepository from "../../core/xero/xeroRepository";

const OrdersRootPage: React.FC = () => {
  const { path, url } = useRouteMatch();

  useEffect(() => {
    xeroRepository.xeroStatus();
  });

  return (
    <Switch>
      <Route path={`${path}/list`} component={OrderListPage} />
      <Route path={`${path}/add`} exact component={AddOrderPage} />
      <Route path={`${path}/add/generate/:userId/:branchId`} exact component={GeneratePackagePage} />
      <Route path={`${path}/edit/:orderId`} component={EditOrderPage} />
      <Redirect to={`${url}/list`} />
    </Switch>
  );
};

export default OrdersRootPage;
