/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box } from "theme-ui";
import { TObject } from "../../core/common/type";
import Tabs, { ITabInfo } from "./Tabs";

// eslint-disable-next-line @typescript-eslint/ban-types
export interface ITabbedEditorProps<TTab extends string, TData extends object> {
  tabs: Record<TTab, ITabbedEditorTabInfo<TData>>;
  currentTab: TTab;
  onTabSelected: (tab: TTab) => void;
  data: TData;
  onDataChanged: (newData: TData) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface ITabbedEditorTabInfo<TData extends object> extends ITabInfo {
  component: React.ComponentType<{
    data: TData;
    onDataChanged?: (newData: TData) => void;
  }>;
  componentProps?: TObject;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TabbedEditor = <TTab extends string, TData extends object>({
  tabs,
  currentTab,
  onTabSelected,
  data,
  onDataChanged,
}: ITabbedEditorProps<TTab, TData>): React.ReactElement => {
  const handleTabSelected = useCallback(
    (tabId: string) => {
      onTabSelected(tabId as TTab);
    },
    [onTabSelected],
  );

  const Tab = tabs[currentTab].component;
  const tabContentProps = tabs[currentTab].componentProps ?? {};

  return (
    <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
      <Box>
        <Tabs tabs={Object.values(tabs)} defaultValue={currentTab} onSelect={handleTabSelected} />
      </Box>
      <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
        <Tab data={data} onDataChanged={onDataChanged} {...tabContentProps} />
      </Flex>
    </Flex>
  );
};

export default TabbedEditor;
