/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { jsx, Flex, Button, Text, Box } from "theme-ui";
import { noop } from "lodash";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Styles } from "../theme/utils";

interface ITabsProps {
  className?: string;
  tabs: Readonly<ITabInfo[]>;
  defaultValue?: TabId;
  onSelect?: (tabId: TabId) => void;
}

type TabId = string;

export interface ITabInfo {
  id: TabId;
  title: string;
}

const tabStyles: Styles = {
  base: {
    height: "32px",
    padding: "0 16px",

    color: "textSecondary",
    border: "1px solid",
    borderColor: "textSecondary",
    backgroundColor: "transparent",
    marginLeft: 16,
  },
};

const styles: Styles = {
  tab: tabStyles.base,
  selectedTab: {
    ...tabStyles.base,
    color: "white",
    borderColor: "primary",
    backgroundColor: "primary",
  },
  text: {
    fontSize: 4,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
};

const MenuItem = ({ tab, selectedTab }: { tab: ITabInfo; selectedTab?: string }) => {
  return (
    <Button sx={selectedTab === tab.id ? styles.selectedTab : styles.tab} variant="null" data-id={tab.id}>
      <Text sx={styles.text}>{tab.title}</Text>
    </Button>
  );
};

export const Menu = (list: ReadonlyArray<ITabInfo>, selectedTab?: string) =>
  list.map(tab => {
    return <MenuItem tab={tab} key={tab.id} selectedTab={selectedTab} />;
  });

const Arrow = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        width: "32px",
        fontSize: "24px",
        textAlign: "center",
      }}
    >
      <Text>{text}</Text>
    </Box>
  );
};

const ArrowLeft = Arrow({ text: "<" });
const ArrowRight = Arrow({ text: ">" });

const Tabs: React.FC<ITabsProps> = ({ tabs, defaultValue, onSelect = noop }) => {
  const [selectedTab, setSelectedTab] = useState(defaultValue);

  const menu = Menu(tabs, selectedTab);

  const handleTabClick = useCallback(
    (tabId: string | number | null) => {
      setSelectedTab(tabId as string);
      onSelect(tabId);
    },
    [onSelect],
  );

  return (
    <Flex>
      <div style={{ width: 0, flexGrow: 1 }}>
        <ScrollMenu
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={selectedTab}
          onSelect={handleTabClick}
        />
      </div>
    </Flex>
  );
};

interface ISimpleTabsProps {
  className?: string;
  tabs: Readonly<ITabInfo[]>;
  value: TabId;
  onSelect?: (tabId: TabId) => void;
}

export const SimpleTabs: React.FC<ISimpleTabsProps> = ({ className = "", tabs, value, onSelect = noop }) => {
  const handleTabClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const tabId = event.currentTarget.dataset.id;
      onSelect(tabId);
    },
    [onSelect],
  );

  return (
    <Flex className={className}>
      {tabs.map(tab => (
        <Button
          key={tab.id}
          className={`${className} tab-container`}
          sx={value === tab.id ? styles.selectedTab : styles.tab}
          variant="null"
          onClick={handleTabClick}
          data-id={tab.id}
        >
          <Text sx={styles.text}>{tab.title}</Text>
        </Button>
      ))}
    </Flex>
  );
};

export const ScrollableTabs = Tabs;

export default Tabs;
