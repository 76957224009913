import React from "react";
import EditPopup, { IEditPopupProps } from "./EditPopup";

type IAddPopupProps = IEditPopupProps;

const AddPopup: React.FC<IAddPopupProps> = props => {
  return <EditPopup title="Add New Service" {...props} />;
};

export default AddPopup;
