/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { IPackage } from "../../../core/package/IPackage";
import AddButton from "../../components/AddButton";
import CardList from "../../components/CardList";
import { Styles } from "../../theme/utils";
import CustomPackageCard from "./CustomPackageCard";

interface IPackagesEditorProps {
  className?: string;
  packages: IPackage[];
  onAddPackageClick: () => void;
  onPackageChanged: (changes: Partial<IPackage>, position: number) => void;
  onRemovePackageClick: (position: number) => void;
}

const styles: Styles = {
  cardList: {
    flexGrow: 1,
    width: "100%",
  },
};

const PackagesEditor: React.FC<IPackagesEditorProps> = ({
  className,
  packages,
  onAddPackageClick,
  onPackageChanged,
  onRemovePackageClick,
}) => {
  return (
    <CardList
      className={className}
      sx={styles.cardList}
      customControls={<AddButton title="Add Package" onClick={onAddPackageClick} />}
      cards={packages.map((pkg, i) => (
        <CustomPackageCard
          // eslint-disable-next-line react/no-array-index-key
          key={`${pkg.Id}-${i}`}
          onChange={onPackageChanged}
          position={i}
          onRemoveClick={onRemovePackageClick}
          pkg={pkg}
        />
      ))}
    />
  );
};

export default PackagesEditor;
