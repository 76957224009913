/** @jsx jsx */
import React from "react";
import { jsx, Flex, Text, Heading } from "theme-ui";
import SearchInput from "../../components/SearchInput";

interface IOrderListHeaderProps {
  orderCount: number | null;
  onSearch: (searchValue: string) => void;
}

const OrderListHeader: React.FC<IOrderListHeaderProps> = ({ orderCount, onSearch }) => {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={{ marginLeft: 0 }}>
        <Heading>Orders</Heading>
        <Text sx={{ marginLeft: 3 }} variant="headingPrimary">
          {orderCount}
        </Text>
      </Flex>
      <Flex sx={{ marginTop: 3 }}>
        <SearchInput onSearch={onSearch} placeholder="Find order" />
      </Flex>
    </Flex>
  );
};

export default OrderListHeader;
