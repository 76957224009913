/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Box, Text, Flex, Label } from "theme-ui";
import LibSelect, { ValueType } from "react-select";
import { noop } from "lodash";
import { Styles } from "../theme/utils";
import DownArrowIcon from "../icons/DownArrowIcon";

interface ISelectProps {
  className?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (newValue: string | undefined | null) => void;
  options?: ISelectOption[];
}

export interface ISelectOption {
  value: string;
  label: string;
}

const styles: Styles = {
  root: {
    flexBasis: "340px",
    cursor: "pointer",
  },
  select: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "inputBorder",
    borderRadius: "32px",
    height: "32px",
  },
  value: {
    alignItems: "center",
    flexWrap: "nowrap",
    padding: "2px 20px 2px 12px",
    overflow: "hidden",
    position: "relative",
  },
  placeholder: {
    color: "inputBorder",
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  downIcon: {
    width: "12px",
    height: "6px",
    marginLeft: "-24px",
    alignSelf: "center",
    pointerEvents: "none",
  },
};

const Select: React.FC<ISelectProps> = ({
  className: cName,
  label,
  placeholder,
  value,
  options = [],
  onChange = noop,
}) => {
  const handleOnChange = useCallback(
    (newValue: ValueType<ISelectOption>) => {
      onChange((newValue as ISelectOption).value);
    },
    [onChange],
  );

  const innerValue = options.find(it => it.value === value);

  return (
    <Box sx={styles.root}>
      {label && <Label variant="label.input">{label}</Label>}
      <LibSelect
        className={cName}
        options={options}
        placeholder={placeholder}
        theme={theme => ({
          ...theme,
          spacing: {
            ...theme.spacing,
            controlHeight: 32,
          },
        })}
        value={innerValue}
        onChange={handleOnChange}
        components={{
          Control: ({ children, className, innerProps, innerRef }) => {
            return (
              <Box sx={styles.select} className={className} ref={innerRef} {...innerProps}>
                {children}
              </Box>
            );
          },
          IndicatorsContainer: ({ children, className }) => {
            return <Box className={className}>{children}</Box>;
          },
          DropdownIndicator: ({ innerProps }) => {
            return (
              <Box>
                <DownArrowIcon sx={styles.downIcon} {...innerProps} />
              </Box>
            );
          },
          ValueContainer: ({ className, children }) => {
            return (
              <Flex sx={styles.value} className={className}>
                {children}
              </Flex>
            );
          },
          Placeholder: ({ className, children, innerProps }) => {
            return (
              <Text sx={styles.placeholder} variant="input" className={className} {...innerProps}>
                {children}
              </Text>
            );
          },
          SingleValue: ({ className, children, innerProps }) => {
            return (
              <Text sx={styles.text} variant="input" className={className} {...innerProps}>
                {children}
              </Text>
            );
          },
        }}
      />
    </Box>
  );
};

export default Select;
