import config from "../../config/config";
import { Id } from "../common/type";
import httpService from "../http/HttpService";
import {
    IListAdministrationFromServer,
    INewAdministrationToServer,
    IAdministrationListFiltersToServer,
    IAdministrationSort,
    IDetailedAdministrationFromServer,
} from "./ServerDto";

const ADMINISTRATION_PATH = `${config.apiUrl}/accounts`;

class AdministrationRepository {
    async getList(
        filters: IAdministrationListFiltersToServer,
        administrationSort?: IAdministrationSort,
    ): Promise<IListAdministrationFromServer[]> {
        let path = `${ADMINISTRATION_PATH}/users`;
        if (administrationSort) {
            path = httpService.addQueryParams(path, administrationSort);
        }
        const response = await httpService.post(path, filters);
        if (response.error) return Promise.reject(response.message);
        return response.response as IListAdministrationFromServer[];
    }

    async create(data: INewAdministrationToServer): Promise<Id> {
        const path = `${ADMINISTRATION_PATH}/create`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return response.response as Id;
    }

    async getAdministrationData(administrationId: Id): Promise<IDetailedAdministrationFromServer> {
        const path = `${ADMINISTRATION_PATH}/user/${administrationId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as IDetailedAdministrationFromServer;
    }

    async deleteAdministration(administrationId: Id): Promise<void> {
        const path = `${ADMINISTRATION_PATH}/user/delete/${administrationId}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }
}

const administrationRepository = new AdministrationRepository();
export default administrationRepository;
