import { useState, useEffect } from "react";

const initialized: HTMLScriptElement[] = [];
function useGoogleMapsApi(apiKey: string) {
  const [googleApi, setGoogleApi] = useState("");

  useEffect(() => {
    if ((window as any).google) {
      setGoogleApi((window as any).google);
      return;
    }
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;

    const onLoad = () => {
      setGoogleApi((window as any).google);
    };

    const script = document.createElement(`script`);
    script.src = src;
    script.async = true;
    script.defer = true;
    script.addEventListener(`load`, onLoad);
    (document.head as any).appendChild(script);
    initialized.push(script);
  }, [apiKey]);

  return googleApi;
}

export default useGoogleMapsApi;
