import React from "react";
import createIcon from "./createIcon";

const RightArrowIcon = createIcon(
  <path d="M1 11H25M25 11L15 1M25 11L15 21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
  26,
  22,
  "none",
  "currentColor",
);

export default RightArrowIcon;
