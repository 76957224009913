import React, { useCallback, useEffect, useState } from "react";
import { Id } from "../../../../core/common/type";
import { IService } from "../../../../core/package/IPackage";
import packagesPresenter from "../../PackagesPresenter";
import ServicesTabContent from "./ServicesContent";

const ServicesTab: React.FC = () => {
  const [services, setServices] = useState<IService[]>([]);

  const reloadServices = useCallback(async () => {
    const serverServices = await packagesPresenter.getServices();
    setServices(serverServices);
  }, []);

  useEffect(() => {
    reloadServices();
  }, [reloadServices]);

  const handleAddService = useCallback(
    async (serviceData: IService) => {
      await packagesPresenter.addService(serviceData);
      reloadServices();
    },
    [reloadServices],
  );

  const handleChangeService = useCallback(
    async (newServiceData: IService) => {
      await packagesPresenter.updateService(newServiceData);
      reloadServices();
    },
    [reloadServices],
  );

  const handleDeleteService = useCallback(
    async (serviceId: Id) => {
      await packagesPresenter.removeService(serviceId);
      reloadServices();
    },
    [reloadServices],
  );

  return (
    <ServicesTabContent
      services={services}
      onAddService={handleAddService}
      onChangeService={handleChangeService}
      onDeleteService={handleDeleteService}
    />
  );
};

export default ServicesTab;
