/** @jsx jsx */
import React, { useCallback } from "react";
import { Flex, jsx, SxStyleProp, Text } from "theme-ui";
import { IUpsell } from "../../../core/package/IPackage";
import IntegerSelector from "../../components/IntegerSelector";
import { IPackagesPageInfo } from "../IPackagesPageInfo";

interface IUpsellProps {
  data: IPackagesPageInfo;
  onDataChanged: (newEntityState: IPackagesPageInfo) => void;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
    marginTop: "24px",
  },
  mainTitle: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Roboto",
    color: "#3E4347",
  },
  secondaryTitle: {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Roboto",
    color: "#8D8F92",
    paddingBottom: "8px",
  },
  additionalBlock: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "16px",
  },
  priceBlock: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "flex-start",
    marginTop: "0px",
  },
  iconDecrement: {
    marginRight: "10px",
  },
  iconIncrement: {
    marginLeft: "10px",
  },
  price: {
    width: "100px",
    fontSize: "16px",
    color: "#8D8F92",
  },
};

const Upsell: React.FC<IUpsellProps> = ({ data, onDataChanged }) => {
  const handleInputChange = useCallback(
    (name: string) => (newValue: number) => {
      onDataChanged({
        ...data,
        upsell: {
          ...data.upsell,
          [name]: newValue,
        } as IUpsell,
      });
    },
    [data, onDataChanged],
  );

  return (
    <Flex sx={styles.root}>
      <Text sx={styles.mainTitle} variant="subHeader">
        Common upsell price per photo
      </Text>
      <Flex sx={styles.additionalBlock}>
        <Text sx={styles.secondaryTitle} variant="subHeader">
          Price
        </Text>
        <Flex sx={styles.priceBlock}>
          <IntegerSelector value={data.upsell.Price ?? 0} onChangeRequest={handleInputChange("Price")} />
        </Flex>
      </Flex>
      <Flex sx={styles.additionalBlock}>
        <Text sx={styles.secondaryTitle} variant="subHeader">
          Default photos amount
        </Text>
        <Flex sx={styles.priceBlock}>
          <IntegerSelector
            value={data.upsell.DefaultPhotos || 0}
            onChangeRequest={handleInputChange("DefaultPhotos")}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Upsell;
