import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EMPTY_USER } from "../../core/user/consts";
import { IUser } from "../../core/user/IUser";
import { store, useAppSelector } from "../../store/store";
import UserPageContent from "./UserPageContent";
import userPresenter from "./UserPresenter";

const UserPage: React.FC = () => {
  const history = useHistory();
  const [serverUser, setServerUser] = useState(EMPTY_USER);
  const [user, setUser] = useState(EMPTY_USER);

  const storedUser = useAppSelector(state => state.user);

  const getUser = useCallback(async () => {
    const userId = storedUser.Id;
    if (!userId) return;
    const userFromServer = await userPresenter.getUser(userId);
    setUser(userFromServer);
    setServerUser(userFromServer);
  }, [storedUser.Id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handleUserChange = useCallback((newUserState: IUser) => {
    setUser(newUserState);
  }, []);

  const handleSaveClick = useCallback(async () => {
    try {
      await userPresenter.saveUser(serverUser, user);
      alert("user updated!");
      history.push("/");
    } catch (err) {
      alert(err);
    }
  }, [history, serverUser, user]);

  return <UserPageContent user={user} onUserChange={handleUserChange} onSaveClick={handleSaveClick} />;
};

export default UserPage;
