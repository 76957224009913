/** @jsx jsx */
import { Line } from "rc-progress";
import React from "react";
import { jsx, Flex, Box, Card, Text } from "theme-ui";
import CancelIcon from "../icons/Cancel";
import { appThemeV2 } from "../theme/theme";

interface IUploadWindowContentProps {
  onCancelClick: () => void;
  uploadedCount: number;
  totalCount: number;
}

const UploadWindowContent: React.FC<IUploadWindowContentProps> = ({ onCancelClick, uploadedCount, totalCount }) => {
  return (
    <Card variant="window" sx={{ overflow: "hidden", width: "272px" }}>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "primary",
          padding: "6px 12px",
          height: "44px",
        }}
      >
        <Text variant="windowHeader">Uploading files</Text>
        <CancelIcon sx={{ cursor: "pointer" }} onClick={onCancelClick} />
      </Flex>
      <Flex sx={{ padding: "16px 12px 12px", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1, marginRight: "32px" }}>
          <Line
            percent={(100 * uploadedCount) / totalCount}
            strokeWidth={5}
            strokeColor={appThemeV2.colors.primary}
            trailWidth={5}
            trailColor={appThemeV2.colors.inputLabel}
          />
        </Box>
        <Text variant="progressDone">{uploadedCount}</Text>
        <Text variant="progress">/{totalCount}</Text>
      </Flex>
    </Card>
  );
};

export default UploadWindowContent;
