/** @jsx jsx */
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, jsx } from "theme-ui";
import { TCustomerListFromServer, TCustomerOrder, TCustomerSortField } from "../../../core/customer/ServerDto";
import { CustomerType } from "../../../core/user/IUser";
import AddButton from "../../components/AddButton";
import SearchInput from "../../components/SearchInput";
import Select, { ISelectOption } from "../../components/Select";
import Tabs, { ITabInfo } from "../../components/Tabs";
import { Styles } from "../../theme/utils";
import customerPresenter from "../CustomerPresenter";
import CustomersTable from "./CustomersTable";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  pageContent: {
    marginTop: 24,
    padding: 16,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  pageContentBlock: {
    marginTop: 16,
  },
  tableBlock: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  table: {
    flexGrow: 1,
    height: 0,
  },
  contentLine: {
    display: "flex",
    alignItems: "center",
  },
  contentLineItem: {
    display: "block",
  },
  customerSort: {
    marginLeft: 92,
    width: 258,
  },
  customersTable: {
    tableLayout: "fixed",
  },
});

const styles: Styles = {
  searchInput: {
    marginTop: "16px",
  },
};

const TABS: Readonly<ITabInfo[]> = [
  {
    id: "business",
    title: "Business customer",
  },
  {
    id: "private",
    title: "Private client",
  },
  {
    id: "approve",
    title: "Clients to approve",
  },
];

const TAB_FILTERS: Record<string, ITabFilter> = {
  business: {
    customerType: CustomerType.Business,
  },
  private: {
    customerType: CustomerType.Personal,
    IsAppliedForBusiness: false,
  },
  approve: {
    IsAppliedForBusiness: true,
  },
};

interface ITabFilter {
  customerType?: CustomerType;
  IsAppliedForBusiness?: boolean;
}

const SORT_OPTIONS: ISelectOption[] = [
  {
    value: "Name Asc",
    label: "Name",
  },
  {
    value: "Name Desc",
    label: "Name (reversed)",
  },
  {
    value: "CreationDate Asc",
    label: "Creation Date",
  },
  {
    value: "CreationDate Desc",
    label: "Creation Date (reversed)",
  },
  {
    value: "IsActive Asc",
    label: "Is Active",
  },
  {
    value: "IsActive Desc",
    label: "Is Active (reversed)",
  },
];

const CustomersListPage: React.FC = () => {
  const muiStyles = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    tabId: TABS[0].id,
    searchValue: "",
    sortField: "CreationDate" as TCustomerSortField,
    sortOrder: "Asc" as TCustomerOrder,
    page: 1,
  });
  const [customers, setCustomers] = useState<TCustomerListFromServer>([]);

  useEffect(() => {
    async function getCustomers() {
      const tabFilter = TAB_FILTERS[state.tabId];

      const list = await customerPresenter.getCustomers(
        {
          ...tabFilter,
          CombinedFilter: state.searchValue,
          page: state.page,
        },
        {
          SortBy: state.sortField,
          OrderBy: state.sortOrder,
        },
      );
      setCustomers(list);
    }
    getCustomers();
  }, [state]);

  const handleAddCustomerClick = useCallback(() => {
    history.push("/customers/add");
  }, [history]);

  const handleTabChange = useCallback((tabId: string) => {
    setState(prevState => ({
      ...prevState,
      tabId,
    }));
  }, []);

  const handleCustomerSearch = useCallback((searchValue: string) => {
    setState(prevState => ({
      ...prevState,
      searchValue,
    }));
  }, []);

  const handleSortChange = useCallback((sortParams: string | null | undefined) => {
    const [sortField, sortOrder] = sortParams?.split(" ");
    setState(prevState => ({
      ...prevState,
      sortField: sortField as TCustomerSortField,
      sortOrder: sortOrder as TCustomerOrder,
    }));
  }, []);

  return (
    <div className={muiStyles.root}>
      <Typography variant="h1">Customers</Typography>
      <Flex sx={styles.searchInput}>
        <SearchInput placeholder="Find customer" onSearch={handleCustomerSearch} />
      </Flex>
      <Paper className={muiStyles.pageContent}>
        <Tabs tabs={TABS} defaultValue={state.tabId} onSelect={handleTabChange} />
        <div className={classNames(muiStyles.pageContentBlock, muiStyles.contentLine)}>
          <AddButton title="Customer" onClick={handleAddCustomerClick} />
          <Select
            className={muiStyles.customerSort}
            sx={{ zIndex: "99999" }}
            placeholder="Sort by"
            options={SORT_OPTIONS}
            value={`${state.sortField} ${state.sortOrder}`}
            onChange={handleSortChange}
          />
        </div>
        <div className={classNames(muiStyles.pageContentBlock, muiStyles.tableBlock)}>
          <CustomersTable className={muiStyles.table} customers={customers} />
        </div>
      </Paper>
    </div>
  );
};

export default CustomersListPage;
