import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { EMPTY_CUSTOMER } from "../../../../core/customer/consts";
import { ICustomer } from "../../../../core/customer/ICustomer";
import customerPresenter from "../../CustomerPresenter";
import { TCustomerEditorTab } from "./CustomerEditor";
import EditCustomerPageContent from "./EditCustomerPageContent";

interface IRouteParams {
  customerId: string;
  branchId: string;
}

const EditCustomerPage: React.FC = () => {
  const {
    params: { customerId, branchId },
  } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const [customer, setCustomer] = useState(EMPTY_CUSTOMER);
  const [serverCustomer, setServerCustomer] = useState(EMPTY_CUSTOMER);

  const getCustomer = useCallback(async () => {
    const customerData = await customerPresenter.getCustomer(customerId, branchId);
    setServerCustomer(customerData);
    setCustomer(customerData);
  }, [customerId, branchId]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  const handleRemoveCustomerClick = useCallback(() => {
    history.push("/customers/list");
  }, [history]);

  const handleSaveChangesClick = useCallback(async () => {
    await customerPresenter.saveChangedCustomer(serverCustomer, customer, customerId, branchId);
    history.push("/customers/list");
  }, [history, customer, serverCustomer, customerId, branchId]);

  const handleApproveCustomerClick = useCallback(() => {
    customerPresenter.approveForBusiness(customerId);
    history.push("/customers/list");
  }, [history, customerId]);

  const [tab, setTab] = useState<TCustomerEditorTab>("Details");

  const handleTabChanged = useCallback(
    (newTab: TCustomerEditorTab) => {
      setTab(newTab);
    },
    [setTab],
  );

  const handleCustomerChanged = useCallback((changes: Partial<ICustomer>) => {
    customerPresenter.updateCustomerState(changes, setCustomer);
  }, []);

  return (
    <EditCustomerPageContent
      onRemoveCustomerClick={handleRemoveCustomerClick}
      onSaveChangesClick={handleSaveChangesClick}
      customerId={branchId}
      onApproveClick={handleApproveCustomerClick}
      canApprove={!!customer.AccountDetails.IsAppliedForBusiness}
      tab={tab}
      onTabChanged={handleTabChanged}
      customer={customer}
      onCustomerChanged={handleCustomerChanged}
    />
  );
};

export default EditCustomerPage;
