import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CreateAdministrationPageContent from "./CreateAdministrationPageContent";
import { IAdministration } from "../../../core/administration/IAdministration";
import { EMPTY_ADMINISTRATION } from "../../../core/administration/consts";
import AdministrationRepository from "../../../core/administration/AdministrationRepository";

const CreateAdministrationPage: React.FC = () => {
  const history = useHistory();
  const [administration, setAdministration] = useState(EMPTY_ADMINISTRATION);

  const handleCancelClick = useCallback(() => {
    history.push("/administration");
  }, [history]);

  const handleAddClick = useCallback(async () => {
    if (
      administration.AdministrationDetails.userName &&
      administration.AdministrationDetails.email &&
      administration.AdministrationDetails.firstName &&
      administration.AdministrationDetails.lastName &&
      administration.AdministrationDetails.password &&
      administration.AdministrationDetails.confirmPassword
    ) {
      if (administration.AdministrationDetails.password === administration.AdministrationDetails.confirmPassword) {
        try {
          await AdministrationRepository.create(administration.AdministrationDetails);
          history.push("/administration");
          alert("Administration created!");
        } catch (err) {
          alert(err);
        }
      } else {
        alert("Password mismatch!");
      }
    } else {
      alert("Please fill in all fields!");
    }
  }, [history, administration]);

  const handleAdministrationChanged = useCallback((newAdministrationState: IAdministration) => {
    setAdministration(newAdministrationState);
  }, []);

  return (
    <CreateAdministrationPageContent
      administration={administration}
      onAdministrationChanged={handleAdministrationChanged}
      onAddClick={handleAddClick}
      onCancelClick={handleCancelClick}
    />
  );
};

export default CreateAdministrationPage;
