/** @jsx jsx */
import React from "react";
import { Flex, jsx } from "theme-ui";
import AddButton from "../../components/AddButton";
import { Styles } from "../../theme/utils";
import { IAdministrationListSort } from "./types";

interface IAdministrationListControlProps {
  onAddClick: () => void;
  sort: IAdministrationListSort;
  onSortChange: (newSortState: IAdministrationListSort) => void;
}

const styles: Styles = {
  root: {
    alignItems: "center",
    flexWrap: "wrap",
  },
  sortFieldContainer: {
    marginLeft: "30px",
    width: "248px",
  },
};

const AdministrationListControl: React.FC<IAdministrationListControlProps> = ({ onAddClick, sort, onSortChange }) => {
  return (
    <Flex sx={styles.root}>
      <AddButton title="Add User" onClick={onAddClick} />
    </Flex>
  );
};

export default AdministrationListControl;
