import React, { useCallback, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Id } from "../../../core/common/type";
import {
  IDetailedOrder,
  INote,
  IOfferedService,
  IOrderService,
  IOrderUpload,
} from "../../../core/order/IDetailedOrder";
import { IListWorkerFromServer } from "../../../core/worker/ServerDto";
import orderPresenter from "../OrderPresenter";
import OrderEditorContent, { IWorkerListState, TOrderEditorMenuTab } from "./OrderEditorContent";

interface IOrderEditorProps {
  order: Readonly<IDetailedOrder>;
  orderNotes: Readonly<Array<INote>>;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
  onOrderNotesChanged: (newNotesState: INote[]) => void;
}

const OrderEditor: React.FC<IOrderEditorProps> = ({ order, orderNotes, onOrderChanged, onOrderNotesChanged }) => {
  const { path, url } = useRouteMatch();

  const [tab, setTab] = useState<TOrderEditorMenuTab>("order");

  const handleTabSelect = useCallback((tabId: string) => {
    setTab(tabId as TOrderEditorMenuTab);
  }, []);

  const handlePhotoServiceChanged = useCallback(
    (newServiceState: IOrderService | IOfferedService) => {
      const newOrderServices = order.Services.map(service => {
        if (service.OrderServiceId === newServiceState.OrderServiceId) {
          return {
            ...newServiceState,
            ApplicationUserId: service.ApplicationUserId,
            ApplicationUserName: service.ApplicationUserName,
          } as IOrderService;
        }
        return service;
      });
      const newOfferedServices = order.OfferedServices.map(service => {
        if (service.OfferedServiceId === newServiceState.OfferedServiceId) {
          return {
            ...newServiceState,
            ApplicationUserId: service.ApplicationUserId,
            ApplicationUserName: service.ApplicationUserName,
          } as IOfferedService;
        }
        return service;
      });
      onOrderChanged({
        ...order,
        Services: newOrderServices,
        OfferedServices: newOfferedServices,
      });
    },
    [onOrderChanged, order],
  );

  const handleServiceChanged = useCallback(
    (newServiceState: IOrderService | IOfferedService) => {
      const newOrderServices = order.Services.map(service => {
        if (service.OrderServiceId === newServiceState.OrderServiceId) {
          return newServiceState as IOrderService;
        }
        return service;
      });
      const newOfferedServices = order.OfferedServices.map(service => {
        if (service.OfferedServiceId === newServiceState.OfferedServiceId) {
          return newServiceState as IOfferedService;
        }
        return service;
      });
      onOrderChanged({
        ...order,
        Services: newOrderServices,
        OfferedServices: newOfferedServices,
      });
    },
    [order, onOrderChanged],
  );

  const handlePhotoUploadRequest = useCallback(
    (serviceId: Id, files: File[]) => {
      orderPresenter.uploadPhotos(serviceId, files, handlePhotoServiceChanged);
    },
    [handlePhotoServiceChanged],
  );

  const handlePlanUploadRequest = useCallback(
    (serviceId: Id, files: File[]) => {
      orderPresenter.uploadPlans(serviceId, files, handlePhotoServiceChanged);
    },
    [handlePhotoServiceChanged],
  );

  const handleRegularUploadDeleteRequest = useCallback(
    async (serviceId: Id, uploads: IOrderUpload[]) => {
      const updatedService = await orderPresenter.deleteServiceFiles(serviceId, uploads);
      handlePhotoServiceChanged(updatedService);
    },
    [handlePhotoServiceChanged],
  );

  const getRegularService = useCallback(
    (id: Id) => {
      return order.Services.find(srv => srv.OrderServiceId === id);
    },
    [order.Services],
  );

  const getOfferedService = useCallback(
    (id: Id) => {
      return order.OfferedServices.find(srv => srv.OfferedServiceId === id);
    },
    [order.OfferedServices],
  );

  const handleOfferedPhotoUploadRequest = useCallback(
    (serviceId: Id, files: File[]) => {
      orderPresenter.uploadOfferedServicePhotos(serviceId, files, handlePhotoServiceChanged);
    },
    [handlePhotoServiceChanged],
  );

  const handleOfferedUploadDeleteRequest = useCallback(
    async (serviceId: Id, uploads: IOrderUpload[]) => {
      const updatedService = await orderPresenter.deleteOfferedServiceFiles(serviceId, uploads);
      handlePhotoServiceChanged(updatedService);
    },
    [handlePhotoServiceChanged],
  );

  const [workerListState, setWorkerListState] = useState<IWorkerListState>({
    serviceId: "",
    state: "single-service-assign",
    isOpen: false,
    workers: [],
  });

  const handleChangeAllWorkersRequest = useCallback(async () => {
    const newWorkers = await orderPresenter.findWorkers();
    setWorkerListState({
      serviceId: "",
      state: "all-services-assign",
      isOpen: true,
      workers: newWorkers,
    });
  }, []);

  const handleChangeWorkerRequest = useCallback(async (serviceId: Id) => {
    const newWorkers = await orderPresenter.findWorkers();
    setWorkerListState({
      serviceId,
      state: "single-service-assign",
      isOpen: true,
      workers: newWorkers,
    });
  }, []);

  const handleWorkerListCloseRequest = useCallback(() => {
    setWorkerListState({
      ...workerListState,
      isOpen: false,
    });
  }, [workerListState]);

  const handleWorkerSelect = useCallback(
    (worker: IListWorkerFromServer) => {
      if (workerListState.state === "single-service-assign") {
        const service = getRegularService(workerListState.serviceId);
        if (service) {
          handleServiceChanged({
            ...service,
            ApplicationUserId: worker.ApplicationUserId!,
            ApplicationUserName: worker.Name,
          });
        }
      }
      if (workerListState.state === "all-services-assign") {
        onOrderChanged({
          ...order,
          Services: order.Services.map(service => ({
            ...service,
            ApplicationUserId: worker.ApplicationUserId,
            ApplicationUserName: worker.Name,
          })),
        });
      }

      setWorkerListState({
        ...workerListState,
        workers: [],
        isOpen: false,
      });
    },
    [getRegularService, onOrderChanged, handleServiceChanged, order, workerListState],
  );

  return (
    <OrderEditorContent
      url={url}
      path={path}
      tab={tab}
      onTabSelect={handleTabSelect}
      order={order}
      orderNotes={orderNotes}
      onChangeAllWorkersRequest={handleChangeAllWorkersRequest}
      onChangeWorkerRequest={handleChangeWorkerRequest}
      onWorkerListCloseRequest={handleWorkerListCloseRequest}
      onWorkerSelect={handleWorkerSelect}
      workerListState={workerListState}
      onPhotoUploadRequest={handlePhotoUploadRequest}
      onPlanUploadRequest={handlePlanUploadRequest}
      onRegularUploadDeleteRequest={handleRegularUploadDeleteRequest}
      onOfferedPhotoUploadRequest={handleOfferedPhotoUploadRequest}
      getRegularService={getRegularService}
      getOfferedService={getOfferedService}
      onOfferedUploadDeleteRequest={handleOfferedUploadDeleteRequest}
      onServiceChanged={handleServiceChanged}
      onOrderChanged={onOrderChanged}
      onOrderNotesChanged={onOrderNotesChanged}
    />
  );
};

export default OrderEditor;
