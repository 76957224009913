import config from "../../config/config";
import { Id } from "../common/type";
import httpService from "../http/HttpService";
import { IUser } from "./IUser";
import { IAccountDetailsToServer, IChangePasswordToServer, IUploadAvatarResponse, IUserFromServer } from "./ServerDto";

const ACCOUNTS_PATH = `${config.apiUrl}/accounts`;

class UserRepository {
    async getUser(userId: Id): Promise<IUserFromServer> {
        const path = `${ACCOUNTS_PATH}/user/${userId}`;
        const response = await httpService.get(path);
        if (response.error) return Promise.reject(response.message);
        return response.response as IUserFromServer;
    }

    async updateAccountDetails(data: IUser, id: Id): Promise<void> {
        const path = `${ACCOUNTS_PATH}/user`;
        const serverData: IAccountDetailsToServer = {
            Id: id,
            Email: data.Email,
            UserName: data.UserName,
            // todo: removeSpacesFromString
            AccountPhoneNumber: data.PhoneNumber,
            FirstName: data.FirstName,
            LastName: data.LastName,
            UserUpsellPrice: null,
            DefaultPhotos: null,
            DaysToPayInvoices: data.DaysToPayInvoices,
            Avatar: data.Avatar,
            AvatarThumb: data.AvatarThumb,
        };
        const response = await httpService.post(path, serverData);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async approveForBusiness(id: string): Promise<void> {
        const path = `${ACCOUNTS_PATH}/approve/${id}`;
        const response = await httpService.post(path);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async changePassword(data: IChangePasswordToServer): Promise<void> {
        const path = `${ACCOUNTS_PATH}/change-password`;
        const response = await httpService.post(path, data);
        if (response.error) return Promise.reject(response.message);
        return Promise.resolve();
    }

    async uploadAvatar(file: File): Promise<IUploadAvatarResponse> {
        const path = `${ACCOUNTS_PATH}/upload-avatar`;
        const response = await httpService.upload(path, file);
        if (response.error) return Promise.reject(response.message);
        return (response.response || {}) as IUploadAvatarResponse;
    }
}

const userRepository = new UserRepository();
export default userRepository;
