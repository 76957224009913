import React from "react";
import { IWorker } from "../../../core/worker/IWorker";
import WorkerDetails from "../workerTabs/WorkerDetails";

interface IEditWorkerDetailsProps {
  data: IWorker;
  onDataChanged: (newWorkerState: IWorker) => void;
}

const EditWorkerDetails: React.FC<IEditWorkerDetailsProps> = props => {
  return <WorkerDetails {...props} />;
};

export default EditWorkerDetails;
