/** @jsx jsx */
import React from "react";
import { jsx, Box, Flex, Text, Input } from "theme-ui";
import PlusIcon from "../icons/Plus";
import { Styles } from "../theme/utils";

interface IUserLogoProps {
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | null;
  fieldName?: string;
}

const styles: Styles = {
  root: {
    width: "101px",
    height: "101px",
    backgroundColor: "gray",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "6px",
    position: "relative",
  },
  logo: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "6px",
  },
  input: {
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  logoRoot: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  logoIcon: {
    width: "20px",
    height: "20px",
  },
  logoText: {
    marginTop: 3,
  },
};

const UserLogo: React.FC<IUserLogoProps> = ({ onChange, value, fieldName }) => {
  return (
    <Flex sx={styles.root}>
      {value ? <img alt="Logo" sx={styles.logo} src={value} /> : <AddLogo />}
      <Input sx={styles.input} data-field-name={fieldName} onChange={onChange} type="file" accept=".jpg, .jpeg, .png" />
    </Flex>
  );
};

const AddLogo = () => (
  <Flex sx={styles.logoRoot}>
    <Box sx={styles.logoIcon}>
      <PlusIcon />
    </Box>
    <Box sx={styles.logoText}>
      <Text variant="label">Add Logo</Text>
    </Box>
  </Flex>
);

export default UserLogo;
