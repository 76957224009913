/** @jsx jsx */
import React from "react";
import { jsx, Flex, Text, Heading, Box } from "theme-ui";
import Button from "../components/Button";
import SearchInput from "../components/SearchInput";

interface IOrderListHeaderProps {
  onSearch: (searchValue: string) => void;
  onGenerateInvoicesClick: () => void;
}

const InvoicesHeader: React.FC<IOrderListHeaderProps> = ({ onSearch, onGenerateInvoicesClick }) => {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={{ justifyContent: "space-between" }}>
        <Flex sx={{ marginLeft: 0, alignItems: "center" }}>
          <Heading>Invoices</Heading>
          <Text sx={{ marginLeft: "87px" }} variant="subHeadingSecondaryText">
            Invoices will go to your Xero account
          </Text>
        </Flex>
        <Box>
          <Button variant="primary" title="Generate Invoices" onClick={onGenerateInvoicesClick} />
        </Box>
      </Flex>
      <Flex sx={{ marginTop: 3 }}>
        <SearchInput onSearch={onSearch} placeholder="Find order" />
      </Flex>
    </Flex>
  );
};

export default InvoicesHeader;
