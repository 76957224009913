/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Card, Flex, Box, SxStyleProp, Text } from "theme-ui";
import { noop } from "lodash";
import { IPackage } from "../../../core/package/IPackage";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";

interface IPackageCardProps {
  className?: string;
  title: string;
  packages: IPackage[];
  onChange?: (changes: Record<number, boolean>) => void;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    padding: "8px 16px",
    borderColor: "lightGray",
  },
  firstRow: {
    paddingBottom: "8px",
    borderBottom: "1px solid",
    borderBottomColor: "lightGray",
  },
  row: {
    borderBottom: "1px solid",
    borderBottomColor: "lightGray",
    paddingRight: "4px",
    alignItems: "center",
  },
  checkboxWithLabel: {
    flexGrow: 1,
  },
  label: {
    flexGrow: 1,
  },
  checkboxLabel: {
    height: "unset",
  },
  title: {
    flexGrow: 1,
  },
  price: {
    flexBasis: "50px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "16px",
  },
  generate: {
    flexShrink: 0,
  },
};

const PackageGroupCard: React.FC<IPackageCardProps> = ({ className, title, packages, onChange = noop }) => {
  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      const id = Number.parseInt(event.target.dataset.fieldId ?? "", 10);
      onChange({
        [id]: isChecked,
      });
    },
    [onChange],
  );

  const handleTitleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      const changes = packages.reduce((acc, pkg) => {
        acc[pkg.Id!] = isChecked;
        return acc;
      }, {} as Record<string, boolean>);
      onChange(changes);
    },
    [onChange, packages],
  );

  return (
    <Card sx={styles.root} className={className}>
      <Box sx={styles.firstRow}>
        <CheckboxWithLabel
          checked={packages.every(pkg => pkg.Checked)}
          label={<Text variant="accentSubHeading">{title}</Text>}
          onChange={handleTitleCheckboxChange}
        />
      </Box>
      {packages.map(pkg => (
        <Flex sx={styles.row} key={pkg.Id ?? ""}>
          <CheckboxWithLabel
            sx={styles.checkboxWithLabel}
            labelStyles={styles.checkboxLabel}
            checked={!!pkg.Checked}
            label={
              <Flex sx={styles.label}>
                <Text sx={styles.title} variant="subHeading">
                  {pkg.Title}
                </Text>
                <Text sx={styles.price} variant="subHeading">
                  {pkg.Price}
                </Text>
              </Flex>
            }
            onChange={handleCheckboxChange}
            data-field-id={pkg.Id}
          />
          <Text sx={styles.generate} variant="subHeading">
            Generate
          </Text>
        </Flex>
      ))}
    </Card>
  );
};

export default PackageGroupCard;
