import React from "react";
import createIcon from "./createIcon";

const LeftArrowIcon = createIcon(
  <path d="M25 11H1M1 11L11 1M1 11L11 21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />,
  26,
  22,
  "none",
  "currentColor",
);

export default LeftArrowIcon;
