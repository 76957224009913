import React from "react";
import createIcon from "./createIcon";

const OrdersIcon = createIcon(
  <path
    d="M1 7.5L13 13.5M1 7.5L12.1056 1.94721C12.6686 1.66569 13.3288 1.66439 13.8918 1.94592C15.9837 2.99183 21.3075 5.65373 25 7.5M1 7.5V21.2639C1 22.0215 1.428 22.714 2.10557 23.0528L13 28.5M13 13.5C13 13.5 20.3137 9.84315 25 7.5M13 13.5V28.5M25 7.5C25 12.0473 25 18.5738 25 21.2664C25 22.0239 24.572 22.714 23.8944 23.0528L13 28.5"
    strokeWidth="2"
  />,
  26,
  30,
  "none",
  "currentColor",
);

export default OrdersIcon;
