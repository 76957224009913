/** @jsx jsx */
import React from "react";
import { Box, Card, Flex, jsx } from "theme-ui";
import { IWorker } from "../../../core/worker/IWorker";
import { Styles } from "../../theme/utils";
import CreateWorkerEditor from "./CreateWorkerEditor";
import CreateWorkerHeader from "./CreateWorkerHeader";

interface ICreateWorkerPageContentProps {
  worker: IWorker;
  onWorkerChanged: (newWorkerState: IWorker) => void;
  onCancelClick: () => void;
  onAddClick: () => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    height: "80px",
    marginBottom: 3,
  },
  cardContainer: {
    flexGrow: 1,
    flexDirection: "column",
  },
  card: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
};

const CreateWorkerPageContent: React.FC<ICreateWorkerPageContentProps> = ({
  worker,
  onWorkerChanged,
  onCancelClick,
  onAddClick,
}) => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.header}>
        <CreateWorkerHeader onCancelClick={onCancelClick} onAddClick={onAddClick} />
      </Box>
      <Flex sx={styles.cardContainer}>
        <Card sx={styles.card} variant="paper">
          <CreateWorkerEditor worker={worker} onWorkerChanged={onWorkerChanged} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default CreateWorkerPageContent;
