import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const uploaderSlice = createSlice({
    name: "uploader",
    initialState: {
        isOpen: false,
        uploadedCount: 0,
        totalCount: 0,
    },
    reducers: {
        open(state) {
            state.isOpen = true;
        },
        close(state) {
            state.isOpen = false;
        },
        clear(state) {
            state.uploadedCount = 0;
            state.totalCount = 0;
        },
        incrementUploadedCount(state, action: PayloadAction<number>) {
            state.uploadedCount += action.payload;
        },
        setUploadedCount(state, action: PayloadAction<number>) {
            state.uploadedCount = action.payload;
        },
        incrementTotalCount(state, action: PayloadAction<number>) {
            state.totalCount += action.payload;
        },
        setTotalCount(state, action: PayloadAction<number>) {
            state.totalCount = action.payload;
        },
    },
});

export const { open, close, clear, incrementUploadedCount, incrementTotalCount } = uploaderSlice.actions;

export default uploaderSlice.reducer;
