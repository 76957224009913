import { clone } from "lodash";
import { CustomerType } from "../user/IUser";
import { ICustomer, ICustomerContact, ICustomerNotificationInfo } from "./ICustomer";

export const EMPTY_NOTIFICATION_INFO: ICustomerNotificationInfo = {
    Enabled: false,
    CustomerContacts: [],
};

export const EMPTY_CUSTOMER: ICustomer = {
    Details: {
        Name: null,
        CustomerCode: null,
        Branch: null,
        Website: null,
        HouseName: null,
        HouseNumber: null,
        Road: null,
        Area: null,
        Town: null,
        Country: null,
        Postcode: null,
        IsActive: null,
        IsTaxAdded: null,
        InvoiceFirstName: null,
        InvoiceLastName: null,
        InvoiceEmail: null,
        Logo: null,
        LogoThumb: null,
        PackageTypes: [],
    },
    AccountDetails: {
        Id: null,
        UserName: null,
        Email: null,
        DaysToPayInvoices: null,
        OldPassword: null,
        Password: null,
        ConfirmPassword: null,
        SendEmailNotification: null,
        IsActive: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        IsAppliedForBusiness: null,
        CustomerType: CustomerType.Personal,
        Avatar: null,
        AvatarThumb: null,
    },
    Contacts: [],
    Notes: [],
    BranchId: null,
    DefaultBusinessPackages: [],
    DefaultPrivatePackages: [],
    CustomPackages: [],
    Notifications: {
        SendBookingInprogressNotification: clone(EMPTY_NOTIFICATION_INFO),
        SendBookingPlacingNotification: clone(EMPTY_NOTIFICATION_INFO),
        SendWorkCompletedNotification: clone(EMPTY_NOTIFICATION_INFO),
        SendContentSelectedNotification: clone(EMPTY_NOTIFICATION_INFO),
        SendAwaitAdditionalPaymentNotification: clone(EMPTY_NOTIFICATION_INFO),
        SendOrderPayedNotification: clone(EMPTY_NOTIFICATION_INFO),
        SendReturnForAdditionalWorkNotification: clone(EMPTY_NOTIFICATION_INFO),
    },
};

export const EMPTY_CONTACT: ICustomerContact = {
    ContactId: null,
    Name: null,
    CustomerId: null,
    AmendedDateTime: null,
    CreatedDateTime: null,
    EmailAddress: null,
    PhoneNumber1: null,
    PhoneNumber2: null,
};
