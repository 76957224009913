import axios from "axios";
import config from "../../config/config";
import { store } from "../../store/store";
import { IDto, ISingleLevelDto } from "../common/type";
import { HTTP_ERRORS, SERVER_ERRORS } from "./ServerErrors";
import { toArray } from "../common/converter";

import { IRequestSettings, IResponse, TQueryData } from "./type";

const FIVE_MINUTES = 5 * 60 * 1000;

class HttpService {
    get(url: string, queryData: TQueryData = {}, settings: IRequestSettings = {}): Promise<IResponse> {
        const urlWithQuery = this.addQueryParams(url, queryData);
        return request({
            method: "GET",
            url: urlWithQuery,
            ...settings,
        });
    }

    post(url: string, data: IDto = {}, settings: IRequestSettings = {}): Promise<IResponse> {
        return request({
            url,
            data,
            method: "POST",
            ...settings,
        });
    }

    delete(url: string, settings: IRequestSettings = {}): Promise<IResponse> {
        return request({
            url,
            method: "DELETE",
            ...settings,
        });
    }

    put(url: string, data: IDto = {}, settings: IRequestSettings = {}): Promise<IResponse> {
        return request({
            url,
            data,
            method: "PUT",
            ...settings,
        });
    }

    patch(url: string, data: IDto = {}, settings: IRequestSettings = {}): Promise<IResponse> {
        return request({
            url,
            data,
            method: "PATCH",
            ...settings,
        });
    }

    upload(url: string, fileOrFiles: File | File[], settings: IRequestSettings = {}): Promise<IResponse> {
        const files = toArray(fileOrFiles);
        const data = new FormData();
        files.forEach((file, i) => {
            data.append(`infile[${i}]`, file);
        });

        const settingsHeaders = settings.headers || {};
        const headers = {
            ...settingsHeaders,
            "Content-Type": "multipart/form-data",
        };

        return request({
            url,
            data,
            method: "POST",
            headers,
            timeout: FIVE_MINUTES,
            ...settings,
        });
    }

    addQueryParams(url: string, params: TQueryData) {
        let resultUrl = url;
        let isFirst = true;
        for (const key in params) {
            const prefix = isFirst ? "?" : "&";
            resultUrl += `${prefix}${key}=${params[key]}`;
            isFirst = false;
        }
        return resultUrl;
    }
}

interface IRequestParams {
    method: "GET" | "POST" | "DELETE" | "PATCH" | "PUT";
    url: string;
    data?: IDto | FormData;
    headers?: ISingleLevelDto;
    retryCount?: number;
    timeout?: number;
}

async function request({ data, method, timeout, url, headers = {} }: IRequestParams): Promise<IResponse> {
    try {
        const axiosResponse = await axios.request({
            method,
            url,
            headers,
            timeout,
            data: setData(data, headers),
            transformRequest: (requestData, requestHeaders) => {
                setHeaderContentType(requestHeaders);
                setHeaderAuthorization(requestHeaders);
                return requestData;
            },
        });

        const serverData = axiosResponse.data;
        return makeResponse({
            httpCode: axiosResponse.status,
            code: serverData.code,
            error: serverData.error,
            message: HTTP_ERRORS[serverData?.error] ?? serverData.message,
            response: serverData.response,
            time: serverData.time,
        });
    } catch (e) {
        return makeResponse({
            httpCode: e?.response?.status ?? 0,
            error: true,
            message: HTTP_ERRORS[e?.response?.status] ?? null,
            response: e?.response?.data ?? null,
        });
    }
}

function makeResponse(data: Partial<IResponse>): IResponse {
    let message = data.message ?? null;
    if (SERVER_ERRORS[data.code!]) {
        message = SERVER_ERRORS[data.code!];
    }

    return {
        httpCode: data.httpCode ?? 0,
        code: data.code ?? 0,
        error: data.error ?? false,
        message: message ?? null,
        response: data.response ?? null,
        time: data.time ?? null,
    };
}

function setHeaderContentType(headers: ISingleLevelDto) {
    if (!headers["Content-Type"]) {
        // eslint-disable-next-line no-param-reassign
        headers["Content-Type"] = "application/json; charset=utf-8";
    }
}
function setHeaderAuthorization(headers: ISingleLevelDto) {
    if (!headers.Authorization) {
        const { token } = store.getState();
        // eslint-disable-next-line no-param-reassign
        headers.Authorization = `Bearer ${token.access_token}`;
    }
}

function setData(data: IDto | FormData, headers: ISingleLevelDto) {
    if (isMultipartFormData(headers)) {
        return data;
    }

    return JSON.stringify(data);
}

function isMultipartFormData(headers: ISingleLevelDto) {
    return headers["Content-Type"] === "multipart/form-data";
}

const httpService = new HttpService();
export default httpService;
