/** @jsx jsx */
import React from "react";
import { jsx, Flex, Box, Card } from "theme-ui";
import { IDetailedOrder, INote } from "../../../core/order/IDetailedOrder";
import { Styles } from "../../theme/utils";
import EditOrderHeader from "./EditOrderHeader";
import OrderEditor from "./OrderEditor";

interface IEditOrderPageContentProps {
  order: Readonly<IDetailedOrder>;
  orderNotes: INote[];
  onSaveChangesClick: () => void;
  onDeleteClick: () => void;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
  onOrderNotesChanged: (newNotesState: INote[]) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    height: "80px",
    marginBottom: 3,
  },
  cardContainer: {
    flexGrow: 1,
    flexDirection: "column",
  },
  card: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
};

const EditOrderPageContent: React.FC<IEditOrderPageContentProps> = ({
  order,
  orderNotes,
  onSaveChangesClick,
  onDeleteClick,
  onOrderChanged,
  onOrderNotesChanged,
}) => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.header}>
        <EditOrderHeader
          orderTitle={order.Description ?? ""}
          orderAddress={order.PropertyAddress?.AddressString ?? ""}
          onDeleteClick={onDeleteClick}
          onSaveChangesClick={onSaveChangesClick}
        />
      </Box>
      <Flex sx={styles.cardContainer}>
        <Card sx={styles.card} variant="paper">
          <OrderEditor
            order={order}
            orderNotes={orderNotes}
            onOrderChanged={onOrderChanged}
            onOrderNotesChanged={onOrderNotesChanged}
          />
        </Card>
      </Flex>
    </Flex>
  );
};

export default EditOrderPageContent;
