import { Id, IDtoObject } from "../common/type";

export interface IMaintainerSearchFilter extends IDtoObject {
    filter: string;
}

export interface IAdditionalOrderServiceToServer extends IDtoObject {
    Type: ServiceType;
    Photos: ISelectedPhotoToServer[];
    Style?: number | null;
    Phone: string;
    Email: string;
    Address: string;
}

export enum ServiceType {
    Custom,
    Photo,
    FloorPlan,
    VirtualTour,
    Video4k,
    EPC2,
    SlideshowVideo,
    InteractiveFloorPlan,
}

interface ISelectedPhotoToServer extends IDtoObject {
    UploadFileId: Id;
    Index?: number | null;
}

export interface IDeletePhotosToServer extends IDtoObject {
    isOffered: boolean;
    files: IUploadFile[];
}

export interface IUploadFile extends IDtoObject {
    UploadFileId: Id;
}

export interface IGenerateOrderToServer extends IDtoObject {
    orderTemplateId: number;
    quantity: number;
    applicationUserId: string;
}

export interface IOrderDataToServer extends IDtoObject {
    PropertyAddress: IUserAddressToServer;
    Contacts: IOrderContactToServer[];
    Common: ICommonOrderDataToServer;
    Keys: boolean;
    KeysWith: string;
    KeysWithDetail: string;
    KeysTimeOption: string;
    KeysTime: string;
    Notes: string;
    SizeProperty: PropertySize;
    OrderNotes: IOrderNoteToServer[];
    CustomerId: number;
}

export interface IUserAddressToServer extends IDtoObject {
    AddressId?: number;
    CreatedDateTime?: string;
    AmendedDateTime?: string;
    Timestamp?: number;
    HouseName: string;
    HouseNumber: string;
    DoorNumber: string;
    Road: string;
    Area: string;
    Town: string;
    Country: string;
    Postcode: string;
    Flat: string;
    AddressString: string;
}

export interface IOrderContactToServer extends IDtoObject {
    OrderContactId: Id;
    OrderId: Id;
    CreatedDateTime: string;
    AmendedDateTime: string;
    Name: string;
    Phone1: string;
    Phone2: string;
    Email: string;
    Type: OrderContactType;
}

export enum OrderContactType {
    Vendor,
    Tenant,
    Agent,
}

export interface ICommonOrderDataToServer extends IDtoObject {
    AppointmentDateTime?: string | null;
    AppointmentDuration?: number | null;
    NotifyViaSMS: boolean | null;
    ContactVendorDirectly: boolean | null;
}

export enum PropertySize {
    Unknown = 0,
    Small = 1, // 0 to 201
    Medium = 2, // 201 to 400
    Large = 3, // 401 to 600
}

export interface IOrderNoteToServer extends IDtoObject {
    Content: string;
    OrderId?: number;
    CustomerId?: number;
    WorkerId?: number;
}

export interface IOrderSort extends IDtoObject {
    SortBy: TOrderSortField;
    OrderBy: TOrderOrder;
}

export type TOrderSortField = "Status";
export type TOrderOrder = "Asc" | "Desc";
