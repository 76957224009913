import React, { useCallback, useState } from "react";
import { Box, IconButton } from "theme-ui";
import { TableContainer, Table, TableRow, TableCell, TableBody, Typography, makeStyles } from "@material-ui/core";
import { Id } from "../../../../core/common/type";
import { IService } from "../../../../core/package/IPackage";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import AddPopup from "./AddPopup";
import EditPopup from "./EditPopup";
import AddButton from "../../../components/AddButton";
import DeletePopup from "./DeletePopup";

interface IServicesProps {
  services: IService[];
  onAddService: (serviceData: IService) => void;
  onChangeService: (newServiceData: IService) => void;
  onDeleteService: (serviceId: Id) => void;
}

const useStyles = makeStyles(theme => ({
  tableContainer: {
    flexGrow: 1,
    paddingTop: "48px",
    height: 500,
  },
  servicesTable: {
    tableLayout: "fixed",
  },
  priceColumn: {
    width: 80,
  },
  iconColumn: {
    width: 40,
  },
  row: {
    width: "100%",
    border: "1px solid #E0E0E0",
    borderLeft: "none",
    borderRight: "none",
    "&:hover": {
      background: "#FFFFFF",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
    },
  },
  cell: {
    padding: "0 16px 0 0",
  },
  cellImage: {
    padding: "8px",
    width: "60px",
  },
  icon: {
    marginRight: "-50px",
    display: "inline-block",
    height: "20px",
    width: "20px",
    padding: "12px",
    background: "center center no-repeat",
    backgroundSize: "contain",
    content: '""',
  },
  editIcon: {
    width: "32px",
    height: "32px",
    alighSelf: "flex-end",
  },
  iconButton: {
    width: "24px",
  },
}));

const ServicesTabContent: React.FC<IServicesProps> = ({ services, onAddService, onChangeService, onDeleteService }) => {
  const styles = useStyles();
  const [serviceToEdit, setServiceToEdit] = useState(null as IService | null);
  const [serviceToDelete, setServiceToDelete] = useState(null as IService | null);
  const [addService, setAddService] = useState(false);

  const handleEditClick = useCallback(
    (service: IService) => () => {
      setServiceToEdit(service);
    },
    [],
  );

  const handleDeleteClick = useCallback(
    (service: IService) => () => {
      setServiceToDelete(service);
    },
    [],
  );

  const handleAddClick = useCallback(() => {
    setAddService(true);
  }, []);

  const clearState = useCallback(() => {
    setServiceToEdit(null);
    setServiceToDelete(null);
    setAddService(false);
  }, []);

  return (
    <TableContainer className={styles.tableContainer}>
      {addService && <AddPopup onConfirm={onAddService} closePopup={clearState} />}
      {serviceToEdit && <EditPopup service={serviceToEdit} onConfirm={onChangeService} closePopup={clearState} />}
      {serviceToDelete && <DeletePopup service={serviceToDelete} onConfirm={onDeleteService} closePopup={clearState} />}
      <Table size="small" className={styles.servicesTable}>
        <colgroup span={1} />
        <colgroup span={1} />
        <colgroup span={1}>
          <col className={styles.priceColumn} />
        </colgroup>
        <colgroup span={2}>
          <col className={styles.iconColumn} />
          <col className={styles.iconColumn} />
        </colgroup>
        <TableBody>
          {services.map((service, i) => (
            <TableRow className={styles.row} key={(service.Id as string) ?? i}>
              <TableCell className={styles.cellImage}>
                <Box
                  className={styles.icon}
                  style={{ backgroundImage: `url("${service.ImageFileBase64 ?? service.Image}")` }}
                />
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{service.Title}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <Typography noWrap>{service.Price}</Typography>
              </TableCell>
              <TableCell className={styles.cell}>
                <IconButton className={styles.iconButton}>
                  <Edit className={styles.editIcon} onClick={handleEditClick(service)} />
                </IconButton>
              </TableCell>
              <TableCell className={styles.cell}>
                <IconButton className={styles.iconButton}>
                  <Delete className={styles.editIcon} onClick={handleDeleteClick(service)} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddButton title="Add" variant="small" onClick={handleAddClick} />
    </TableContainer>
  );
};

export default ServicesTabContent;
