import loglevel, { Logger, LogLevelDesc } from "loglevel";
import config from "../../config/config";

loglevel.setDefaultLevel((config.logLevel as LogLevelDesc) || "debug");

function loggerFactory(name: string): Logger {
    return loglevel.getLogger(name);
}

export default loggerFactory;
