import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { EMPTY_DETAILED_ORDER } from "../../../core/order/consts";
import { IDetailedOrder, INote } from "../../../core/order/IDetailedOrder";
import orderPresenter from "../OrderPresenter";
import EditOrderPageContent from "./EditOrderPageContent";

interface IRouteParams {
  orderId: string;
}

const EditOrderPage: React.FC = () => {
  const {
    params: { orderId },
  } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const [order, setOrder] = useState(EMPTY_DETAILED_ORDER);
  const [serverOrder, setServerOrder] = useState(EMPTY_DETAILED_ORDER);

  const [orderNotes, setOrderNotes] = useState<INote[]>([]);
  const [serverOrderNotes, setServerOrderNotes] = useState<INote[]>([]);

  const getOrder = useCallback(async () => {
    const orderData = await orderPresenter.getOrder(orderId);
    const notesData = await orderPresenter.getOrderNotes(orderId);
    setServerOrder(orderData);
    setOrder(orderData);
    setServerOrderNotes(notesData);
    setOrderNotes(notesData);
  }, [orderId]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  const handleDeleteOrderClick = useCallback(async () => {
    try {
      await orderPresenter.deleteOrder(orderId);
      history.push("/orders/list");
      alert("order removed!");
      await orderPresenter.updateUnmaintainedOrdersCount();
    } catch (err) {
      alert(err);
    }
  }, [orderId, history]);

  const handleSaveChangesClick = useCallback(async () => {
    try {
      await orderPresenter.saveOrder(order, serverOrder, orderNotes, serverOrderNotes);
      history.push("/orders/list");
      alert("order updated!");
    } catch (err) {
      alert(err);
    }
  }, [history, order, orderNotes, serverOrder, serverOrderNotes]);

  const handleOrderChanged = useCallback((newOrderState: IDetailedOrder) => {
    setOrder(newOrderState);
  }, []);

  const handleOrderNotesChanged = useCallback((newNotesState: INote[]) => {
    setOrderNotes(newNotesState);
  }, []);

  return (
    <EditOrderPageContent
      order={order}
      orderNotes={orderNotes}
      onOrderChanged={handleOrderChanged}
      onOrderNotesChanged={handleOrderNotesChanged}
      onDeleteClick={handleDeleteOrderClick}
      onSaveChangesClick={handleSaveChangesClick}
    />
  );
};

export default EditOrderPage;
