/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Card, Flex, SxStyleProp, Text } from "theme-ui";
import moment from "moment";
import { INoteFromServer } from "../../core/note/ServerDto";
import { useAppSelector } from "../../store/store";
import Button from "../components/Button";
import TextAreaField from "../components/TextAreaField";

interface INoteCardProps {
  note: INoteFromServer;
  onChanged: (changes: Partial<INoteFromServer>, noteIndex: number) => void;
  position: number;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    display: "flex",
    flexDirection: "column",
    borderColor: "lightGray",
  },
  inputsContainer: {
    flexGrow: 1,
    marginTop: "16px",
  },
  removeButtonContainer: {
    marginTop: "16px",
    justifyContent: "flex-end",
  },
};

const NoteCard: React.FC<INoteCardProps> = ({ note, onChanged, position }) => {
  const handleNoteChange = useCallback(
    (newValue: string) => {
      onChanged(
        {
          Content: newValue,
        },
        position,
      );
    },
    [onChanged, position],
  );

  const user = useAppSelector(store => store.user);

  return (
    <Card sx={styles.root}>
      <Text variant="subHeading">
        {moment(note.CreatedDateTime ?? new Date()).format("DD MMM YYYY hh:mm a")} -{" "}
        {note.OwnerName ?? user.DisplayName}
      </Text>
      <TextAreaField
        sx={styles.inputsContainer}
        label="Text here"
        value={note.Content ?? ""}
        onChange={handleNoteChange}
      />
      <Flex sx={styles.removeButtonContainer}>
        <Button title="Remove note" variant="dangerOutline" />
      </Flex>
    </Card>
  );
};

export default NoteCard;
