import { IUser } from "./IUser";

// eslint-disable-next-line import/prefer-default-export
export const EMPTY_USER: IUser = {
    Id: null,
    IsActive: null,
    DaysToPayInvoices: null,
    PhoneNumber: null,
    SendEmailNotification: null,
    ConfirmPassword: null,
    Password: null,
    OldPassword: null,
    Email: null,
    UserName: null,
    LastName: null,
    FirstName: null,
    CustomerType: null,
    AccountType: null,
    InOfficeStaff: null,
    IsAdmin: null,
    IsAppliedForBusiness: null,
    Avatar: null,
    AvatarThumb: null,
};
