/** @jsx jsx */
import { merge } from "lodash";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Flex, jsx, Text } from "theme-ui";
import { Id } from "../../../../core/common/type";
import { IOfferedService, IOrderService, IOrderUpload, UploadFileStatus } from "../../../../core/order/IDetailedOrder";
import { ServiceType } from "../../../../core/order/ServerDto";
import Button from "../../../components/Button";
import ImageList, { IImage } from "../../../components/ImageList";
import { formMargin } from "../../../theme/globalStyles";
import { Styles } from "../../../theme/utils";
import ServiceTabHeader from "../components/ServiceTabHeader";

interface IUploadsTabContentProps {
  service: IOrderService | IOfferedService;
  fileTypes?: string;
  onChangeWorkerClick: () => void;
  onNotifyCustomerClick: () => void;
  onUploadRequest: (files: File[]) => void;
  onDownloadRequest: (photos: IOrderUpload[]) => void;
  onDeleteRequest: (photos: IOrderUpload[]) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  photoListContainer: {
    flexGrow: 1,
    overflowY: "auto",
  },
  dropZone: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    border: "3px dashed",
    borderColor: "gray",
  },
  photoListSubContainer: {
    height: 0,
  },
  imagesSubheading: {
    display: "flex",
    alignItems: "center",
    height: "32px",
    margin: "16px 0",
  },
};

const UploadsTabContent: React.FC<IUploadsTabContentProps> = ({
  service,
  fileTypes,
  onChangeWorkerClick,
  onNotifyCustomerClick,
  onUploadRequest,
  onDownloadRequest,
  onDeleteRequest,
}) => {
  const [selected, setSelected] = useState<Array<Id>>([]);

  const { getRootProps, getInputProps, inputRef: uploadInput, isDragActive } = useDropzone({
    onDrop: onUploadRequest,
    accept: fileTypes,
    noClick: true,
    noKeyboard: true,
  });

  const handleUploadClick = useCallback(() => {
    uploadInput.current?.click();
  }, [uploadInput]);

  const handleSelectionChange = useCallback((images: IImage[]) => {
    const selectedIndices = images.reduce((acc, img) => {
      if (img.isSelected) acc.push(img.id);
      return acc;
    }, [] as Id[]);
    setSelected(selectedIndices);
  }, []);

  const getSelectedImages = useCallback(() => {
    return service.UploadFiles?.filter(photo => selected.includes(photo.UploadFileId ?? "")) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service.UploadFiles, selected]);

  const handleDownloadRequest = useCallback(() => {
    const selectedImages = getSelectedImages();
    setSelected([]);
    onDownloadRequest(selectedImages);
  }, [getSelectedImages, onDownloadRequest]);

  const handleDeleteRequest = useCallback(() => {
    const selectedImages = getSelectedImages();
    setSelected([]);
    onDeleteRequest(selectedImages);
  }, [getSelectedImages, onDeleteRequest]);

  const confirmedFiles = service.UploadFiles?.filter(it => it.FileStatus === UploadFileStatus.Confirmed);
  const unconfirmedFiles = service.UploadFiles?.filter(it => it.FileStatus === UploadFileStatus.Unconfirmed);
  const haveSelectedContent = !!confirmedFiles?.length;

  const uploadFileToImageItem = useCallback(
    (file: IOrderUpload): IImage => {
      const fileId = file.UploadFileId ?? "";
      return {
        id: fileId,
        thumbURL: file.ThumbURL ?? "",
        fullURL: file.ThumbURL ?? "",
        isSelected: selected.includes(fileId),
      };
    },
    [selected],
  );

  return (
    <Flex sx={styles.root}>
      {service.OrderServiceId && (
        <ServiceTabHeader
          // @ts-ignore
          workerName={service.ApplicationUserName ?? ""}
          onChangeWorkerClick={onChangeWorkerClick}
          onNotifyCustomerClick={onNotifyCustomerClick}
        />
      )}
      <Flex {...getRootProps()} sx={merge({}, styles.photoListContainer, formMargin.row)}>
        <input {...getInputProps()} />
        {/* eslint-disable-next-line no-nested-ternary */}
        {isDragActive || !service.UploadFiles?.length ? (
          <Flex sx={styles.dropZone}>
            <Text variant="heading">Drop files here</Text>
          </Flex>
        ) : haveSelectedContent && service.Type === ServiceType.Photo ? (
          <Box sx={styles.photoListSubContainer}>
            {!!confirmedFiles?.length && (
              <Box>
                <Text sx={styles.imagesSubheading} variant="accentSubHeading">
                  Content selected
                </Text>
                <ImageList
                  images={confirmedFiles.map(uploadFileToImageItem)}
                  selectable
                  onSelectionChanged={handleSelectionChange}
                />
              </Box>
            )}
            {!!unconfirmedFiles?.length && (
              <Box>
                <Text sx={styles.imagesSubheading} variant="accentSubHeading">
                  Additional content
                </Text>
                <ImageList
                  images={unconfirmedFiles.map(uploadFileToImageItem)}
                  selectable
                  onSelectionChanged={handleSelectionChange}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={styles.photoListSubContainer}>
            <ImageList
              images={service.UploadFiles?.map(uploadFileToImageItem) ?? []}
              selectable
              onSelectionChanged={handleSelectionChange}
            />
          </Box>
        )}
      </Flex>
      <Flex sx={formMargin.row}>
        <Button variant="primary" title="UPLOAD" onClick={handleUploadClick} />
        {!!selected.length && (
          <Box>
            <Button
              sx={formMargin.rowElement}
              variant="primaryOutline"
              title="DOWNLOAD"
              onClick={handleDownloadRequest}
            />
            <Button sx={formMargin.rowElement} variant="dangerOutline" title="DELETE" onClick={handleDeleteRequest} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default UploadsTabContent;
