import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import xeroRepository from "../../core/xero/xeroRepository";
import CustomersListPage from "./CustomerList/CustomersListPage";
import CreateCustomerPage from "./SingleCustomer/CreateCustomer/CreateCustomerPage";
import EditCustomerPage from "./SingleCustomer/EditCustomer/EditCustomerPage";

const CustomersRootPage: React.FC = () => {
  const { path, url } = useRouteMatch();

  useEffect(() => {
    xeroRepository.xeroStatus();
  });

  return (
    <Switch>
      <Route path={`${path}/list`} component={CustomersListPage} />
      <Route path={`${path}/add`} component={CreateCustomerPage} />
      <Route path={`${path}/edit/:customerId/:branchId`} component={EditCustomerPage} />
      <Redirect to={`${url}/list`} />
    </Switch>
  );
};

export default CustomersRootPage;
