import { Id, TPrimitiveType } from "../../core/common/type";
import invoiceRepository from "../../core/invoice/InvoiceRepository";
import { IListOrder } from "../../core/order/IListOrder";
import { IOrderSort } from "../../core/order/ServerDto";

class InvoicePresenter {
    getOrders(
        filters: Record<string, TPrimitiveType | TPrimitiveType[]> = {},
        sort?: IOrderSort,
    ): Promise<IListOrder[]> {
        return invoiceRepository.getOrdersForInvoice(filters, sort);
    }

    generateInvoices(orderIds: Set<Id>): Promise<void> {
        return invoiceRepository.generateInvoices(Array.from(orderIds));
    }
}

const invoicePresenter = new InvoicePresenter();
export default invoicePresenter;
