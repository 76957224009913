import React, { useState } from "react";
import { IWorker } from "../../../core/worker/IWorker";
import TabbedEditor, { ITabbedEditorTabInfo } from "../../components/TabbedEditor";
import Notes from "../workerTabs/Notes";
import Services from "../workerTabs/Services";
import CreateAccountDetails from "./CreateAccountDetails";
import CreateWorkerDetails from "./CreateWorkerDetails";

interface ICreateWorkerEditorProps {
  worker: IWorker;
  onWorkerChanged: (newWorkerState: IWorker) => void;
}

const TABS = {
  Details: {
    id: "Details",
    title: "Details",
    component: CreateWorkerDetails,
  } as ITabbedEditorTabInfo<IWorker>,
  AccountDetails: {
    id: "AccountDetails",
    title: "Account Details",
    component: CreateAccountDetails,
  } as ITabbedEditorTabInfo<IWorker>,
  Services: {
    id: "Services",
    title: "Services",
    component: Services,
  } as ITabbedEditorTabInfo<IWorker>,
  Notes: {
    id: "Notes",
    title: "Notes",
    component: Notes,
  } as ITabbedEditorTabInfo<IWorker>,
};

type TEditWorkerTab = keyof typeof TABS;

const CreateWorkerEditor: React.FC<ICreateWorkerEditorProps> = ({ worker, onWorkerChanged }) => {
  const [tab, setTab] = useState<TEditWorkerTab>("Details");
  return (
    <TabbedEditor tabs={TABS} data={worker} currentTab={tab} onTabSelected={setTab} onDataChanged={onWorkerChanged} />
  );
};

export default CreateWorkerEditor;
