import { IDetailedOrder, IOrderContact } from "./IDetailedOrder";
import { IListOrder } from "./IListOrder";

export const EMPTY_LIST_ORDER: IListOrder = {
    OrderId: null,
    Ref: null,
    DateTaken: null,
    WorkDate: null,
    Cost: null,
    UpselCost: null,
    PropertyAddress: null,
    Occupier: null,
    Status: null,
    OrderStatus: null,
    OrderState: null,
    AdditionalPayments: [],
    Services: [],
};

const EMPTY_USER_ADDRESS: IDetailedOrder["PropertyAddress"] = {
    AddressId: null,
    CreatedDateTime: null,
    AmendedDateTime: null,
    Timestamp: null,
    HouseName: null,
    HouseNumber: null,
    DoorNumber: null,
    Road: null,
    Area: null,
    Town: null,
    Country: null,
    Postcode: null,
    Flat: null,
    AddressString: null,
};

const EMPTY_COMMON_ORDER_DATA: IDetailedOrder["Common"] = {
    AppointmentDateTime: null,
    AppointmentDuration: null,
    NotifyViaSMS: null,
    ContactVendorDirectly: null,
};

const EMPTY_ACCOUNT_DETAILS: IDetailedOrder["AccountDetails"] = {
    ApplicationUserId: null,
    Name: null,
    AccountsEmailAddress: null,
    DaysToPayInvoices: null,
    AccountPassword: null,
    SendEmailNotification: null,
    IsActive: null,
    FirstName: null,
    LastName: null,
    AccountPhoneNumber: null,
    IsAppliedForBusiness: null,
    CustomerType: null,
    Avatar: null,
    AvatarThumb: null,
};

export const EMPTY_DETAILED_ORDER: IDetailedOrder = {
    PropertyAddress: EMPTY_USER_ADDRESS,
    Contacts: [],
    Common: EMPTY_COMMON_ORDER_DATA,
    Keys: null,
    KeysWith: null,
    KeysWithDetail: null,
    KeysTimeOption: null,
    KeysTime: null,
    Notes: null,
    SizeProperty: null,
    OrderNotes: [],
    CustomerId: null,
    OrderState: null,
    Services: [],
    OfferedServices: [],
    OrderPrice: null,
    Description: null,
    CreatedDateTime: null,
    UpsellPrice: null,
    DefaultAmount: null,
    TrustedCustomer: null,
    Maintainer: null,
    OrderId: null,
    HasRejections: null,
    BranchId: null,
    CompanyName: null,
    AccountDetails: EMPTY_ACCOUNT_DETAILS,
    CustomerUserId: null,
};

export const EMPTY_ORDER_CONTACT: IOrderContact = {
    OrderContactId: null,
    OrderId: null,
    CreatedDateTime: null,
    AmendedDateTime: null,
    Type: null,
    Name: null,
    Email: null,
    Phone1: null,
    Phone2: null,
};
