/** @jsx jsx */
import React from "react";
import { Box, Card, Flex, jsx } from "theme-ui";
import { IAdministration } from "../../../core/administration/IAdministration";
import { Styles } from "../../theme/utils";
import EditAdministrationEditor from "./EditAdministrationEditor";
import EditAdministrationHeader from "./EditAdministrationHeader";

interface IEditAdministrationPageContentProps {
  administration: IAdministration;
  onCancelClick: () => void;
  onRemoveClick: () => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    height: "80px",
    marginBottom: 3,
  },
  cardContainer: {
    flexGrow: 1,
    flexDirection: "column",
  },
  card: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
};

const EditAdministrationPageContent: React.FC<IEditAdministrationPageContentProps> = ({
  administration,
  onCancelClick,
  onRemoveClick,
}) => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.header}>
        <EditAdministrationHeader
          onCancelClick={onCancelClick}
          onRemoveClick={onRemoveClick}
          administrationName={`${administration.FirstName ?? ""} ${administration.LastName ?? ""}`}
        />
      </Box>
      <Flex sx={styles.cardContainer}>
        <Card sx={styles.card} variant="paper">
          <EditAdministrationEditor data={administration} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default EditAdministrationPageContent;
