/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box } from "theme-ui";
import { noop } from "lodash";
import InputField from "./InputField";
import MinusIcon from "../icons/Minus";
import PlusIcon from "../icons/Plus";
import { Styles } from "../theme/utils";

export interface IIntegerSelectorProps {
  value?: number;
  onChangeRequest?: (newValue: number) => void;
  disabled?: boolean;
}

const styles: Styles = {
  iconContainer: {
    alignItems: "center",
    cursor: "pointer",
  },
  inputContainer: {
    width: "120px",
    margin: "0 8px",
  },
  inputField: {
    "& input": {
      // @ts-ignore
      textAlign: "center",
      // @ts-ignore
      MozAppearance: "textField",
    },
    "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button": {
      // @ts-ignore
      WebkitAppearance: "none",
      // @ts-ignore
      margin: 0,
    },
  },
};

const IntegerSelector: React.FC<IIntegerSelectorProps> = ({ disabled, value = 0, onChangeRequest = noop }) => {
  const handleIncreaseClick = useCallback(() => {
    onChangeRequest(value + 1);
  }, [value, onChangeRequest]);

  const handleDecreaseClick = useCallback(() => {
    onChangeRequest(value - 1);
  }, [value, onChangeRequest]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeRequest(event.target.value);
    },
    [onChangeRequest],
  );

  return (
    <Flex>
      <Flex onClick={handleDecreaseClick} sx={styles.iconContainer}>
        <MinusIcon />
      </Flex>
      <Box sx={styles.inputContainer}>
        <InputField
          variant="fullWidth"
          type="number"
          value={value}
          sx={styles.inputField}
          disabled={disabled}
          onChange={handleInputChange}
        />
      </Box>
      <Flex onClick={handleIncreaseClick} sx={styles.iconContainer}>
        <PlusIcon />
      </Flex>
    </Flex>
  );
};

export default IntegerSelector;
