/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, SxProps, Box, Label, Flex, Textarea } from "theme-ui";
import { noop } from "lodash";

interface ITextAreaFieldProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "style" | "onChange">,
    SxProps {
  className?: string;
  label?: string;
  onChange?: (newValue: string) => void;
  variant?: "fullArea";
}

const TextAreaField: React.FC<ITextAreaFieldProps> = ({
  className,
  label,
  onChange = noop,
  variant = "fullArea",
  sx,
  ...inputProps
}) => {
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <Flex className={className} sx={{ flexDirection: "column" }}>
      {label && <Label variant="label.input">{label}</Label>}
      <Box sx={{ flexGrow: 1 }}>
        <Textarea variant={`textarea.${variant}`} onChange={handleOnChange} {...inputProps} />
      </Box>
    </Flex>
  );
};

export default TextAreaField;
