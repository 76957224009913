const APP_PREFIX = "pht-";

class LocalStorage {
    set(key: string, value: unknown): void {
        if (value === null || value === undefined) return;

        let valueToSave = "";

        if (typeof value === "object") {
            valueToSave = JSON.stringify(value);
        } else {
            valueToSave = value as string;
        }

        localStorage.setItem(`${APP_PREFIX}${key}`, valueToSave);
    }

    get(key: string): Record<string, unknown> | string | null {
        const value = localStorage.getItem(`${APP_PREFIX}${key}`);
        try {
            return JSON.parse(value as string);
        } catch {
            if (value === "null" || value === "undefined") return null;
            return value;
        }
    }
}

const ls = new LocalStorage();
export default ls;
