import React from "react";
import createIcon from "./createIcon";

const StarIcon = createIcon(
  <path d="M12.3503 1.40718C13.1447 0.24803 14.8553 0.248029 15.6497 1.40717L19.2407 6.64679C19.5007 7.02625 19.8837 7.30446 20.3249 7.43453L26.4177 9.23059C27.7656 9.62792 28.2943 11.2549 27.4373 12.3686L23.5638 17.4029C23.2833 17.7675 23.137 18.2176 23.1497 18.6775L23.3243 25.0271C23.363 26.4318 21.979 27.4373 20.655 26.9665L14.6701 24.8383C14.2367 24.6842 13.7633 24.6842 13.3299 24.8383L7.34501 26.9665C6.02099 27.4373 4.63703 26.4318 4.67567 25.0271L4.8503 18.6775C4.86295 18.2176 4.71669 17.7675 4.43616 17.4029L0.562657 12.3686C-0.294268 11.2549 0.234353 9.62792 1.58225 9.23059L7.67508 7.43453C8.11633 7.30446 8.49926 7.02625 8.75932 6.64679L12.3503 1.40718Z" />,
  28,
  28,
  "currentColor",
  "none",
);

export default StarIcon;
