/** @jsx jsx */
import React from "react";
import { Box, Card, Flex, jsx } from "theme-ui";
import { IUser } from "../../core/user/IUser";
import { Styles } from "../theme/utils";
import UserEditor from "./UserEditor";
import UserHeader from "./UserHeader";

interface IUserPageContentProps {
  user: IUser;
  onUserChange: (newUserState: IUser) => void;
  onSaveClick: () => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
  },
  header: {
    height: "80px",
    marginBottom: 3,
  },
  cardContainer: {
    flexGrow: 1,
    flexDirection: "column",
  },
  card: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
};

const UserPageContent: React.FC<IUserPageContentProps> = ({ user, onUserChange, onSaveClick }) => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.header}>
        <UserHeader onSaveChangesClick={onSaveClick} />
      </Box>
      <Flex sx={styles.cardContainer}>
        <Card sx={styles.card} variant="paper">
          <UserEditor user={user} onUserChange={onUserChange} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default UserPageContent;
