/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import Button from "../../components/Button";
import { TPrimitiveType } from "../../../core/common/type";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import InputField from "../../components/InputField";
import { formMargin } from "../../theme/globalStyles";
import { IAdministration } from "../../../core/administration/IAdministration";

interface IAdministrationProps {
  data: IAdministration;
  onDataChanged: (newWorkerState: IAdministration) => void;
  showBottomCheckboxes?: boolean;
}

const CreateAdministrationEditor: React.FC<IAdministrationProps> = ({ data, onDataChanged }) => {
  const details = data.AdministrationDetails;

  const callOnChanged = useCallback(
    (fieldName: string, value: TPrimitiveType) => {
      onDataChanged({
        ...data,
        AdministrationDetails: {
          ...data.AdministrationDetails!,
          [fieldName]: value,
        },
      });
    },
    [data, onDataChanged],
  );

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const { fieldName } = event.target.dataset;
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      const { fieldName } = event.target.dataset;
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handleGeneratePasswordClick = useCallback(() => {
    const genPas = Math.random().toString(36).slice(-8);
    onDataChanged({
      ...data,
      AdministrationDetails: {
        ...data.AdministrationDetails!,
        password: genPas,
        confirmPassword: genPas,
      },
    });
  }, [data, onDataChanged]);

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={formMargin.row}>
        <InputField
          label="Login"
          value={details?.userName ?? ""}
          data-field-name="userName"
          onChange={handleInputChanged}
        />

        <InputField
          sx={formMargin.rowElement}
          label="Email Address"
          value={details?.email ?? ""}
          data-field-name="email"
          onChange={handleInputChanged}
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField
          label="First Name"
          value={details?.firstName ?? ""}
          data-field-name="firstName"
          onChange={handleInputChanged}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Last Name"
          value={details?.lastName ?? ""}
          data-field-name="lastName"
          onChange={handleInputChanged}
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField
          label="Password"
          value={details?.password ?? ""}
          data-field-name="password"
          onChange={handleInputChanged}
        />
        <InputField
          sx={formMargin.rowElement}
          label="Confirm Password"
          value={details?.confirmPassword ?? ""}
          data-field-name="confirmPassword"
          onChange={handleInputChanged}
        />
      </Flex>

      <Flex sx={formMargin.row}>
        <Button variant="primary" title="Generate Password" onClick={handleGeneratePasswordClick} />
      </Flex>

      <Box sx={formMargin.group}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">In Office staff</Text>}
          checked={!!details?.inOfficeStaff}
          data-field-name="inOfficeStaff"
          onChange={handleCheckboxChange}
        />
        <CheckboxWithLabel
          label={<Text variant="subHeading">Active</Text>}
          checked={!!details?.isActive}
          data-field-name="isActive"
          onChange={handleCheckboxChange}
        />
      </Box>
    </Flex>
  );
};

export default CreateAdministrationEditor;
