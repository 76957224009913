/** @jsx jsx */
import React from "react";
import { Box, Flex, Heading, jsx } from "theme-ui";
import Button from "../components/Button";

interface IPackagesPageHeaderProps {
  showSaveChanges: boolean;
  onSaveChangesClick: () => void;
}

const PackagesPageHeader: React.FC<IPackagesPageHeaderProps> = ({ showSaveChanges, onSaveChangesClick }) => {
  return (
    <Flex sx={{ justifyContent: "space-between" }}>
      <Box>
        <Flex>
          <Heading>Packages</Heading>
        </Flex>
      </Box>
      <Box sx={{ display: "flex" }}>
        {showSaveChanges && (
          <Box sx={{ marginLeft: 4 }}>
            <Button variant="primaryOutline" title="Save changes" onClick={onSaveChangesClick} />
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default PackagesPageHeader;
