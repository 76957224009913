/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { jsx, Flex, Box, Checkbox, Label } from "theme-ui";
import { noop } from "lodash";
import Lightbox from "react-image-lightbox";
import { Id } from "../../core/common/type";
import { Styles } from "../theme/utils";
import "react-image-lightbox/style.css";

interface IImageListProps {
  images: IImage[];
  selectable?: boolean;
  onSelectionChanged?: (images: IImage[]) => void;
}

export interface IImage {
  id: Id;
  thumbURL?: string;
  fullURL: string;
  isSelected?: boolean;
}

const styles: Styles = {
  root: {
    flexWrap: "wrap",
  },
  imageBox: {
    minHeight: "250px",
    minWidth: "300px",
    margin: "0 16px 16px 0",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    cursor: "pointer",
    position: "relative",
  },
  checkboxContainer: {
    position: "absolute",
    top: "17px",
    right: "17px",
    background: "white",
    width: "16px",
    height: "16px",
    padding: 0,
  },
  checkbox: {
    position: "absolute",
    top: "-4px",
    left: "-4px",
  },
  imageSlide: {
    height: "calc(100vh - 125px)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
};

const ImageList: React.FC<IImageListProps> = ({ images, selectable = false, onSelectionChanged = noop }) => {
  const [state, setState] = useState({
    isPreviewOpen: false,
    previewIndex: 0,
  });

  const handleImageClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const index = event.currentTarget.dataset.index ?? "";
    const numIndex = Number.parseInt(index, 10);
    setState({
      isPreviewOpen: true,
      previewIndex: numIndex,
    });
  }, []);

  const handleCheckboxChange = useCallback(
    (event: React.MouseEvent<HTMLLabelElement>) => {
      event.stopPropagation();
      event.preventDefault();
      const index = event.currentTarget.dataset.index ?? "";
      const numIndex = Number.parseInt(index, 10);

      const changedImages = images.map((img, i) => {
        if (i === numIndex) {
          return {
            ...img,
            isSelected: !img.isSelected,
          };
        }
        return img;
      });
      onSelectionChanged(changedImages);
    },
    [images, onSelectionChanged],
  );

  const previewURLs = images.map(it => it.thumbURL ?? it.fullURL);

  const handlePreviewClose = useCallback(() => {
    setState({
      isPreviewOpen: false,
      previewIndex: 0,
    });
  }, []);

  const handlePreviewMoveNext = useCallback(() => {
    setState(prevState => ({
      ...state,
      previewIndex: (prevState.previewIndex + previewURLs.length - 1) % previewURLs.length,
    }));
  }, [previewURLs.length, state]);

  const handlePreviewMovePrev = useCallback(() => {
    setState(prevState => ({
      ...state,
      previewIndex: (prevState.previewIndex + 1) % previewURLs.length,
    }));
  }, [previewURLs.length, state]);

  return (
    <Flex sx={styles.root}>
      {images.map((img, i) => (
        <Box
          key={img.id}
          sx={styles.imageBox}
          style={{ backgroundImage: `url("${img.thumbURL}")` }}
          onClick={handleImageClick}
          data-index={i}
        >
          {selectable && (
            <Box sx={styles.checkboxContainer}>
              <Label onClick={handleCheckboxChange} data-index={i}>
                <Checkbox sx={styles.checkbox} checked={img.isSelected} onChange={noop} />
              </Label>
            </Box>
          )}
        </Box>
      ))}
      {state.isPreviewOpen && (
        <Lightbox
          mainSrc={previewURLs[state.previewIndex]}
          nextSrc={previewURLs[(state.previewIndex + 1) % previewURLs.length]}
          prevSrc={previewURLs[(state.previewIndex + previewURLs.length - 1) % previewURLs.length]}
          onCloseRequest={handlePreviewClose}
          onMoveNextRequest={handlePreviewMoveNext}
          onMovePrevRequest={handlePreviewMovePrev}
        />
      )}
    </Flex>
  );
};

export default ImageList;
