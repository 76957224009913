import { useEffect } from "react";
import * as React from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import config from "../../config/config";
import { store } from "../../store/store";
import { setUser } from "../../store/user/user";
import authRepository from "../../core/auth/authRepository";
import { IAccessToken } from "../../core/auth/IAccessToken";
import { IUserFromServer } from "../../core/user/ServerDto";
import { setAccessToken } from "../../store/auth/authToken";
import ls from "../../core/localStorage/localStorage";

interface ILoginRouteParams {
  id: string;
}

const Login: React.FC = () => {
  const {
    params: { id },
  } = useRouteMatch<ILoginRouteParams>();

  useEffect(() => {
    async function doFetch() {
      const [user, token] = await authRepository.auth(id);

      if (user) {
        ls.set("user", user);
        store.dispatch(setUser(user as IUserFromServer));
      }
      if (token) {
        ls.set("token", token);
        store.dispatch(setAccessToken((token as unknown) as IAccessToken));
      }

      if (!user || !token) {
        window.location.href = config.logoutUrl;
      }
    }

    doFetch();
  }, [id]);

  return <Redirect to="/" />;
};

export default Login;
