/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box, Text } from "theme-ui";
import { IListWorkerFromServer } from "../../../core/worker/ServerDto";
import { Styles } from "../../theme/utils";

interface IWorkerListProps {
  workers: IListWorkerFromServer[];
  onSelect: (worker: IListWorkerFromServer) => void;
}

const styles: Styles = {
  root: {
    flexDirection: "column",
  },
  worker: {
    cursor: "pointer",
    padding: "16px 0",
    borderTop: "1px solid",
    borderColor: "gray",
    "&:last-of-type": {
      borderBottom: "1px solid",
      borderColor: "gray",
    },
    "&:hover": {
      color: "primary",
    },
  },
};

const WorkerList: React.FC<IWorkerListProps> = ({ workers, onSelect }) => {
  const handleWorkerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const workerId = event.currentTarget.dataset.workerId ?? "";
      const worker = workers.find(wrkr => wrkr.WorkerId?.toString() === workerId)!;
      onSelect(worker);
    },
    [workers, onSelect],
  );

  return (
    <Flex sx={styles.root}>
      {workers.map(worker => (
        <Box
          key={worker.WorkerId ?? ""}
          sx={styles.worker}
          data-worker-id={worker.WorkerId}
          onClick={handleWorkerClick}
        >
          <Text variant="menuItem">{worker.Name}</Text>
          <Text variant="subHeadingSecondaryText">{worker.Email}</Text>
          <Text variant="subHeadingSecondaryText">Rating: {worker.Rating}</Text>
        </Box>
      ))}
    </Flex>
  );
};

export default WorkerList;
