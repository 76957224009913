import React, { useEffect } from "react";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import CreateWorkerPage from "./CreateWorker/CreateWorkerPage";
import EditWorkerPage from "./EditWorker/EditWorkerPage";
import WorkerListPage from "./WorkerList/WorkerListPage";

const WorkersRootPage: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/list`} component={WorkerListPage} />
      <Route path={`${path}/add`} component={CreateWorkerPage} />
      <Route path={`${path}/edit/:workerId`} component={EditWorkerPage} />
      <Redirect to={`${url}/list`} />
    </Switch>
  );
};

export default WorkersRootPage;
