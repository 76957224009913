/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx } from "theme-ui";
import { clone, noop } from "lodash";
import { ICustomer, ICustomerContact } from "../../../../core/customer/ICustomer";
import { EMPTY_CONTACT } from "../../../../core/customer/consts";

import ContactsContent from "./ContactsContent";

interface IContactsProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const Contacts: React.FC<IContactsProps> = ({ customer, onChanged = noop }) => {
  const handleContactChange = useCallback(
    (changes: Readonly<Partial<ICustomerContact>>, contactIndex: number) => {
      const newContacts = clone(customer.Contacts);
      newContacts[contactIndex] = {
        ...newContacts[contactIndex],
        ...changes,
      };
      onChanged({
        Contacts: newContacts,
      });
    },
    [customer, onChanged],
  );

  const handleAddContactClick = useCallback(() => {
    onChanged({
      Contacts: customer.Contacts.concat([clone(EMPTY_CONTACT)]),
    });
  }, [customer.Contacts, onChanged]);

  const handleContactRemoveClick = useCallback(
    (contactIndex: number) => {
      onChanged({
        Contacts: customer.Contacts.filter((_, i) => i !== contactIndex),
      });
    },
    [onChanged, customer.Contacts],
  );

  return (
    <ContactsContent
      contacts={customer.Contacts}
      onAddContactClick={handleAddContactClick}
      onChanged={handleContactChange}
      onRemoveClick={handleContactRemoveClick}
    />
  );
};

export default Contacts;
