import React from "react";
import { ICustomer } from "../../../../core/customer/ICustomer";

interface IOrdersProps {
  customer: ICustomer;
}

const Orders: React.FC<IOrdersProps> = () => {
  return <div>No content here yet...</div>;
};

export default Orders;
