/** @jsx jsx */
import React from "react";
import { jsx, Flex, Heading } from "theme-ui";
import SearchInput from "../../components/SearchInput";

interface IWorkerListHeaderProps {
  onSearch: (searchValue: string) => void;
}

const WorkerListHeader: React.FC<IWorkerListHeaderProps> = ({ onSearch }) => {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={{ justifyContent: "space-between" }}>
        <Flex sx={{ marginLeft: 0, alignItems: "center" }}>
          <Heading>Workers</Heading>
        </Flex>
      </Flex>
      <Flex sx={{ marginTop: 3 }}>
        <SearchInput onSearch={onSearch} placeholder="Find worker" />
      </Flex>
    </Flex>
  );
};

export default WorkerListHeader;
