import React from "react";
import createIcon from "./createIcon";

const LogoutIcon = createIcon(
  <path
    d="M13 15V16.8824C13 18.0519 12.1046 19 11 19H3C1.89543 19 1 18.0519 1 16.8824V3.11765C1 1.9481 1.89543 1 3 1H11C12.1046 1 13 1.9481 13 3.11765V5M6 10H18M18 10L15 7M18 10L15 13"
    strokeWidth="2"
  />,
  20,
  20,
  "none",
  "currentColor",
);

export default LogoutIcon;
