/** @jsx jsx */
import React, { useCallback, useEffect, useState } from "react";
import { jsx } from "theme-ui";
import { IDetailedOrder } from "../../../../core/order/IDetailedOrder";
import { IPackage } from "../../../../core/package/IPackage";
import Select from "../../../components/Select";
import orderPresenter from "../../OrderPresenter";

interface IOrderPackageSelect {
  order: IDetailedOrder;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
}

const OrderPackageSelect: React.FC<IOrderPackageSelect> = ({ order, onOrderChanged }) => {
  const [packages, setPackages] = useState<IPackage[]>([]);

  useEffect(() => {
    async function getPackages() {
      if (!order.BranchId) return;
      const pkgs = await orderPresenter.getPossiblePackages(order.BranchId);
      setPackages(pkgs);
    }
    getPackages();
  }, [order]);

  const handlePackageChange = useCallback(
    async (newPackageTitle: string | undefined | null) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Are you sure you want to change the package?")) {
        const selectedPackage = packages.find(pkg => pkg.Title === newPackageTitle);
        try {
          await orderPresenter.changePackage(order.OrderId!, selectedPackage!.Id!);
          const newOrderState = await orderPresenter.getOrder(order.OrderId!);
          onOrderChanged(newOrderState);
        } catch (errorMessage) {
          alert(errorMessage);
        }
      }
    },
    [onOrderChanged, order.OrderId, packages],
  );

  return (
    <Select
      label="Package"
      options={packages.map(pkg => ({
        label: pkg.Title ?? "",
        value: pkg.Title ?? "",
      }))}
      value={order.Description ?? ""}
      onChange={handlePackageChange}
    />
  );
};

export default OrderPackageSelect;
