/** @jsx jsx */
import React from "react";
import { jsx, Box, Checkbox, SxProps, Label, SxStyleProp, Flex } from "theme-ui";
import { merge } from "lodash";
import { Styles } from "../theme/utils";

interface ICheckboxWithLabelProps extends Omit<React.ComponentProps<"input">, "ref">, SxProps {
  className?: string;
  labelStyles?: SxStyleProp;
  label: React.ReactElement;
  checked: boolean;
  variant?: "primary" | "secondary";
}

const styles: Styles = {
  outerLabel: {
    alignItems: "center",
    "& > div:first-of-type": {
      height: "100%",
    },
    margin: 0,
    cursor: "pointer",
  },
  checkbox: {
    flexShrink: 0,
    marginRight: 3,
  },
  checkboxIcon: {
    "input:focus ~ &": {
      backgroundColor: "transparent",
    },
  },
  labelContainer: {
    flexGrow: 1,
    minHeight: "32px",
    alignItems: "center",
  },
  label: {
    fontSize: 4,
  },
};

const primaryVariantStyles: Styles = {
  checkbox: {
    color: "inputBorder",
    "input:checked ~ &": {
      color: "primary",
    },
    "input:focus ~ &": {
      color: "inputBorder",
    },
  },
};

const secondaryVariantStyles: Styles = {
  checkbox: {
    color: "inputBorder",
    "input:checked ~ &": {
      color: "secondary",
    },
    "input:focus ~ &": {
      color: "inputBorder",
    },
    "input[disabled] ~ &": {
      color: "inputBorder",
    },
  },
};

const VARIANT_STYLES = {
  primary: primaryVariantStyles,
  secondary: secondaryVariantStyles,
};

const CheckboxWithLabel: React.FC<ICheckboxWithLabelProps> = ({
  className,
  label,
  labelStyles = {},
  variant = "secondary",
  ...inputProps
}) => {
  const extraStyles = VARIANT_STYLES[variant];

  return (
    <Box className={className}>
      <Label sx={styles.outerLabel}>
        <Box sx={merge({}, styles.checkbox, extraStyles.checkbox)}>
          <Checkbox sx={styles.checkboxIcon} {...inputProps} />
        </Box>
        <Flex sx={merge({}, styles.labelContainer, labelStyles)}>{label}</Flex>
      </Label>
    </Box>
  );
};

export default CheckboxWithLabel;
