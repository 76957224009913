import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const unmaintainedOrdersSlice = createSlice({
    name: "unmaintained-orders",
    initialState: {
        count: 0,
    },
    reducers: {
        setUnmaintainedOrdersCount(state, action: PayloadAction<number>) {
            state.count = action.payload;
        },
    },
});

export const { setUnmaintainedOrdersCount } = unmaintainedOrdersSlice.actions;

export default unmaintainedOrdersSlice.reducer;
