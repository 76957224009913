/** @jsx jsx */
import React, { useCallback, useMemo } from "react";
import { jsx, Box, Text } from "theme-ui";
import Select from "react-select";
import { noop, identity, cloneDeep, merge } from "lodash";
import {
  ICustomer,
  ICustomerContact,
  ICustomerNotificationContact,
  ICustomerNotificationInfo,
} from "../../../../core/customer/ICustomer";
import { INotifications } from "../../../../core/customer/ServerDto";
import { formMargin } from "../../../theme/globalStyles";

interface INotificationsProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const customStyles = {
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    width: "340px",
    overflow: "hidden",
  }),
  option: (provided: React.CSSProperties) => ({
    ...provided,
  }),
  control: () => ({
    width: "340px",
    borderRadius: "16px",
    border: "1px solid #8D8F92",
    display: "flex",
  }),
};

const Notifications: React.FC<INotificationsProps> = ({ customer, onChanged = noop }) => {
  const contactToOption = useCallback(
    (contact?: ICustomerContact) => ({
      value: `contact__${contact!.ContactId}`,
      label: contact!.Name ?? "",
    }),
    [],
  );

  const customerEmailOption = useMemo(() => {
    return {
      value: `customer`,
      label: "Account email" ?? "",
    };
  }, []);

  const options = useMemo(() => {
    const contacts = customer.Contacts.map(contactToOption);
    return [customerEmailOption, ...contacts];
  }, [contactToOption, customer.Contacts, customerEmailOption]);

  const getContact = useCallback(
    (customerContact: ICustomerNotificationContact) => {
      // eslint-disable-next-line eqeqeq
      return customer.Contacts.find(contact => contact.ContactId == customerContact.ContactId);
    },
    [customer],
  );

  const getSelectedContactOptions = useCallback(
    (notificationType: keyof INotifications) => {
      const contacts = customer.Notifications[notificationType].CustomerContacts.map(getContact)
        .filter(identity)
        .map(contactToOption);
      const customerOptions = customer.Notifications[notificationType].Enabled ? [customerEmailOption] : [];
      return customerOptions.concat(contacts);
    },
    [customer.Notifications, getContact, contactToOption, customerEmailOption],
  );

  const callOnChanged = useCallback(
    (notificationType: keyof INotifications, changes: Partial<ICustomerNotificationInfo>) => {
      onChanged({
        Notifications: {
          ...customer.Notifications,
          [notificationType]: {
            ...customer.Notifications[notificationType],
            ...changes,
          },
        },
      });
    },
    [customer, onChanged],
  );

  function useUpdateNotificationContacts(notificationType: keyof INotifications) {
    return useCallback(
      (selected: unknown | null | undefined) => {
        if (Array.isArray(selected)) {
          const selectedCopy = cloneDeep(selected);
          const customerIndex = selectedCopy.findIndex(it => it.value === `customer`);
          if (customerIndex !== -1) selectedCopy.splice(customerIndex, 1);

          callOnChanged(notificationType, {
            Enabled: customerIndex !== -1,
            CustomerContacts: selectedCopy.map(it => {
              const [, ContactId] = (it.value ?? "").split("__");
              return { ContactId };
            }),
          });
        } else {
          callOnChanged(notificationType, {
            Enabled: false,
            CustomerContacts: [],
          });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [notificationType, callOnChanged],
    );
  }

  const bookingInProgressContacts = getSelectedContactOptions("SendBookingInprogressNotification");
  const bookingPlacedContacts = getSelectedContactOptions("SendBookingPlacingNotification");
  const workCompletedContacts = getSelectedContactOptions("SendWorkCompletedNotification");
  const contentSelectedContacts = getSelectedContactOptions("SendContentSelectedNotification");
  const awaitingAdditionalPaymentContacts = getSelectedContactOptions("SendAwaitAdditionalPaymentNotification");
  const orderPaidContacts = getSelectedContactOptions("SendOrderPayedNotification");
  const returnForAdditionalWorkContacts = getSelectedContactOptions("SendReturnForAdditionalWorkNotification");

  const updateBookingInProgress = useUpdateNotificationContacts("SendBookingInprogressNotification");
  const updateBookingPlaced = useUpdateNotificationContacts("SendBookingPlacingNotification");
  const updateWorkCompleted = useUpdateNotificationContacts("SendWorkCompletedNotification");
  const updateContentSelected = useUpdateNotificationContacts("SendContentSelectedNotification");
  const updateAwaitingAdditionalPayment = useUpdateNotificationContacts("SendAwaitAdditionalPaymentNotification");
  const updateOrderPaid = useUpdateNotificationContacts("SendOrderPayedNotification");
  const updateReturnForAdditionalWork = useUpdateNotificationContacts("SendReturnForAdditionalWorkNotification");

  return (
    <Box sx={{ marginTop: "48px" }}>
      <Text sx={{ margin: "0 0 4px 12px" }} variant="subHeading">
        Send booking in progress notification
      </Text>
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        styles={customStyles}
        value={bookingInProgressContacts}
        onChange={updateBookingInProgress}
      />
      <Text sx={merge({}, { margin: "0 0 4px 12px" }, formMargin.row)} variant="subHeading">
        Send booking placed notification
      </Text>
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        styles={customStyles}
        value={bookingPlacedContacts}
        onChange={updateBookingPlaced}
      />
      <Text sx={merge({}, { margin: "0 0 4px 12px" }, formMargin.row)} variant="subHeading">
        Send work completed notification
      </Text>
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        styles={customStyles}
        value={workCompletedContacts}
        onChange={updateWorkCompleted}
      />
      <Text sx={merge({}, { margin: "0 0 4px 12px" }, formMargin.row)} variant="subHeading">
        Send content selected notification
      </Text>
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        styles={customStyles}
        value={contentSelectedContacts}
        onChange={updateContentSelected}
      />
      <Text sx={merge({}, { margin: "0 0 4px 12px" }, formMargin.row)} variant="subHeading">
        Send awaiting additional payment notification
      </Text>
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        styles={customStyles}
        value={awaitingAdditionalPaymentContacts}
        onChange={updateAwaitingAdditionalPayment}
      />
      <Text sx={merge({}, { margin: "0 0 4px 12px" }, formMargin.row)} variant="subHeading">
        Send order paid notification
      </Text>
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        styles={customStyles}
        value={orderPaidContacts}
        onChange={updateOrderPaid}
      />
      <Text sx={merge({}, { margin: "0 0 4px 12px" }, formMargin.row)} variant="subHeading">
        Send return for additional work notification
      </Text>
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        styles={customStyles}
        value={returnForAdditionalWorkContacts}
        onChange={updateReturnForAdditionalWork}
      />
    </Box>
  );
};

export default Notifications;
