import React from "react";
import createIcon from "./createIcon";

const AdministrationIcon = createIcon(
  <>
    <path
      d="M9.22206 2.45092C9.96671 1.00557 12.0332 1.00557 12.7779 2.45092L12.9801 2.84335C13.4275 3.71185 14.4369 4.12997 15.3675 3.83224L15.7879 3.69772C17.3365 3.20224 18.7977 4.6635 18.3023 6.21207L18.1677 6.63251C17.87 7.56303 18.2881 8.57246 19.1566 9.01991L19.549 9.22208C20.9944 9.96673 20.9944 12.0333 19.549 12.7779L19.1566 12.9801C18.2881 13.4275 17.87 14.437 18.1677 15.3675L18.3023 15.7879C18.7977 17.3365 17.3365 18.7978 15.7879 18.3023L15.3675 18.1678C14.4369 17.87 13.4275 18.2882 12.9801 19.1566L12.7779 19.5491C12.0332 20.9944 9.96671 20.9944 9.22206 19.5491L9.01988 19.1566C8.57244 18.2882 7.563 17.87 6.63249 18.1678L6.21204 18.3023C4.66347 18.7978 3.20221 17.3365 3.69769 15.7879L3.83222 15.3675C4.12994 14.437 3.71182 13.4275 2.84333 12.9801L2.4509 12.7779C1.00554 12.0333 1.00554 9.96673 2.4509 9.22208L2.84333 9.01991C3.71182 8.57246 4.12994 7.56303 3.83222 6.63251L3.69769 6.21207C3.20221 4.6635 4.66347 3.20224 6.21204 3.69772L6.63249 3.83224C7.563 4.12997 8.57244 3.71185 9.01988 2.84335L9.22206 2.45092Z"
      strokeWidth="2"
    />
    <path
      d="M25.5365 18.0644C26.9772 17.7314 28.2686 19.0228 27.9356 20.4634L27.8761 20.7209C27.7262 21.3691 27.9085 22.0493 28.3623 22.5357L28.5426 22.7289C29.5513 23.8101 29.0786 25.5741 27.6645 26.0061L27.4118 26.0833C26.7755 26.2776 26.2776 26.7756 26.0832 27.4118L26.0061 27.6645C25.5741 29.0787 23.81 29.5513 22.7289 28.5426L22.5357 28.3624C22.0493 27.9085 21.369 27.7263 20.7209 27.8761L20.4634 27.9356C19.0228 28.2686 17.7314 26.9772 18.0644 25.5366L18.1239 25.2791C18.2737 24.6309 18.0914 23.9507 17.6376 23.4643L17.4573 23.2711C16.4486 22.1899 16.9213 20.4259 18.3354 19.9939L18.5882 19.9167C19.2244 19.7224 19.7224 19.2244 19.9167 18.5882L19.9939 18.3355C20.4258 16.9213 22.1899 16.4487 23.2711 17.4574L23.4643 17.6376C23.9507 18.0915 24.6309 18.2737 25.2791 18.1239L25.5365 18.0644Z"
      strokeWidth="2"
    />
    <path
      d="M14 11C14 12.6569 12.6568 14 11 14C9.34312 14 7.99998 12.6569 7.99998 11C7.99998 9.34314 9.34312 8 11 8C12.6568 8 14 9.34314 14 11Z"
      strokeWidth="2"
    />
    <path
      d="M24 23C24 23.5523 23.5523 24 23 24C22.4477 24 22 23.5523 22 23C22 22.4477 22.4477 22 23 22C23.5523 22 24 22.4477 24 23Z"
      strokeWidth="2"
    />
  </>,
  31,
  31,
  "none",
  "currentColor",
);

export default AdministrationIcon;
