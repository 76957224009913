import { SxStyleProp } from "theme-ui";

export const formMargin: Record<string, SxStyleProp> = {
    rowElement: {
        marginLeft: 3,
    },
    row: {
        marginTop: 3,
    },
    group: {
        marginTop: 6,
    },
};

export const link: Record<string, SxStyleProp> = {
    neutralColor: {
        color: "inherit",
        "&:visited": {
            color: "inherit",
        },
        "&:active": {
            color: "inherit",
        },
    },
};
