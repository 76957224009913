import React from "react";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import AdministrationListPage from "./AdministrationList/AdministrationListPage";
import CreateAdministrationPage from "./AdministrationList/CreateAdministrationPage";
import EditAdministrationPage from "./AdministrationList/EditAdministrationPage";

const AdministrationRootPage: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/list`} component={AdministrationListPage} />
      <Route path={`${path}/add`} component={CreateAdministrationPage} />
      <Route path={`${path}/edit/:administrationId`} component={EditAdministrationPage} />
      <Redirect to={`${url}/list`} />
    </Switch>
  );
};

export default AdministrationRootPage;
