import React, { useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import { Id } from "../../../../core/common/type";
import { IDetailedOrder, IOfferedService, IOrderService, IOrderUpload } from "../../../../core/order/IDetailedOrder";
import orderPresenter from "../../OrderPresenter";
import UploadsTabContent from "./UploadsTabContent";

interface IPhotoTabProps {
  order: IDetailedOrder;
  fileTypes?: string;
  onChangeWorkerRequest: (serviceId: Id) => void;
  onUploadRequest: (serviceId: Id, files: File[]) => void;
  onDeleteRequest: (serviceId: Id, uploads: IOrderUpload[]) => void;
  getService: (id: Id) => IOfferedService | IOrderService | undefined;
}

interface IRouteParams {
  serviceId: string;
}

const UploadsTab: React.FC<IPhotoTabProps> = ({
  order,
  fileTypes,
  onChangeWorkerRequest,
  onUploadRequest,
  onDeleteRequest,
  getService,
}) => {
  const {
    params: { serviceId },
  } = useRouteMatch<IRouteParams>();

  const handleChangeWorkerClick = useCallback(() => {
    onChangeWorkerRequest(serviceId);
  }, [serviceId, onChangeWorkerRequest]);

  const handleNotifyCustomerClick = useCallback(() => {
    orderPresenter.notifyCustomer(serviceId);
  }, [serviceId]);

  const handleUploadRequest = useCallback(
    (files: File[]) => {
      onUploadRequest(serviceId, files);
    },
    [serviceId, onUploadRequest],
  );

  const handleDownloadClick = useCallback(
    (photos: IOrderUpload[]) => {
      const fileIds = photos.map(photo => photo.UploadFileId);
      orderPresenter.downloadOrderFiles(order.OrderId!, [{ name: "fullSize" }], fileIds, { OrderServiceId: serviceId });
    },
    [order.OrderId, serviceId],
  );

  const handleDeleteClick = useCallback(
    (uploads: IOrderUpload[]) => {
      onDeleteRequest(serviceId, uploads);
    },
    [serviceId, onDeleteRequest],
  );

  const service = getService(serviceId);

  if (!service) {
    return <div>No service found!</div>;
  }

  return (
    <UploadsTabContent
      service={service}
      fileTypes={fileTypes}
      onChangeWorkerClick={handleChangeWorkerClick}
      onNotifyCustomerClick={handleNotifyCustomerClick}
      onUploadRequest={handleUploadRequest}
      onDownloadRequest={handleDownloadClick}
      onDeleteRequest={handleDeleteClick}
    />
  );
};

export default UploadsTab;
