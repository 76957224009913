/** @jsx jsx */
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { jsx, Flex, Box, Card } from "theme-ui";
import { IListWorker } from "../../../core/worker/IWorker";
import { IWorkerListFilters, IWorkerListSort } from "./types";
import WorkersTable from "./WorkersTable";
import WorkerListControl from "./WorkerListControl";
import WorkerListHeader from "./WorkerListHeader";

interface IWorkerListPageContentProps {
  workers: IListWorker[];
  filters: IWorkerListFilters;
  onFiltersChange: (newFiltersState: IWorkerListFilters) => void;
  sort: IWorkerListSort;
  onSortChange: (newSortState: IWorkerListSort) => void;
}

const WorkerListPageContent: React.FC<IWorkerListPageContentProps> = ({
  workers,
  filters,
  onFiltersChange,
  sort,
  onSortChange,
}) => {
  const history = useHistory();

  const handleSearch = useCallback(
    (searchValue: string) => {
      onFiltersChange({
        ...filters,
        searchValue,
      });
    },
    [filters, onFiltersChange],
  );

  const handleAddWorkerClick = useCallback(() => {
    history.push("/workers/add");
  }, [history]);

  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <WorkerListHeader onSearch={handleSearch} />
      </Box>
      <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} variant="paper">
          <WorkerListControl onAddClick={handleAddWorkerClick} sort={sort} onSortChange={onSortChange} />
          <WorkersTable sx={{ marginTop: 3 }} workers={workers} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default WorkerListPageContent;
