/** @jsx jsx */
import React, { useCallback } from "react";
import { Box, Flex, jsx, Text } from "theme-ui";
import moment from "moment";
import { OrderState } from "../../../core/order/IDetailedOrder";
import CheckboxWithLabel from "../../components/CheckboxWithLabel";
import DateInput from "../../components/DateInput";
import Select, { ISelectOption } from "../../components/Select";
import { Styles } from "../../theme/utils";
import orderPresenter from "../OrderPresenter";
import { IOrderListFilters } from "./IOrderListFilters";

interface IOrderListControl {
  filters: IOrderListFilters;
  onFiltersChanged: (newFiltersState: IOrderListFilters) => void;
}

const styles: Styles = {
  root: {
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "-8px",
    marginRight: "-8px",
    "& > div": {
      marginTop: "8px",
      marginRight: "8px",
    },
  },
  sortFieldContainer: {
    width: "248px",
  },
  datesContainer: {
    alignItems: "center",
    flex: "1 1 0",
  },
  dateContainer: {
    flexWrap: "nowrap",
    alignItems: "center",
  },
  dateText: {
    flexShrink: 0,
    marginRight: "4px",
  },
  dateDelimiter: {
    margin: "0 4px",
    flexShrink: 0,
  },
};

const ORDER_STATE_OPTIONS: ISelectOption[] = [
  { value: "", label: "Any" },
  toSelectOption(OrderState.DateAgreement),
  toSelectOption(OrderState.BeingVerified),
  toSelectOption(OrderState.BeingExecuted),
  toSelectOption(OrderState.WorkDone),
  toSelectOption(OrderState.ContentSelected),
  toSelectOption(OrderState.AwaitingForAdditionalPayment),
  toSelectOption(OrderState.OrderPaid),
  toSelectOption(OrderState.ReturnForAdditionalWork),
];

function toSelectOption(orderState: OrderState): ISelectOption {
  return {
    value: orderState.toString(10),
    label: orderPresenter.orderStatusToString(orderState),
  };
}

const OrderListControl: React.FC<IOrderListControl> = ({ filters, onFiltersChanged }) => {
  const handleAssignToMeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFiltersChanged({
        ...filters,
        assignedToMe: event.target.checked,
      });
    },
    [filters, onFiltersChanged],
  );

  const handleOrderStateChange = useCallback(
    (newState: string | undefined | null) => {
      const orderState = newState ? Number.parseInt(newState, 10) : null;
      onFiltersChanged({
        ...filters,
        orderState,
      });
    },
    [filters, onFiltersChanged],
  );

  const handleDateChange = useCallback(
    (newDate: Date | null, fieldName?: string) => {
      onFiltersChanged({
        ...filters,
        [fieldName!]: (newDate && moment(newDate).utc(true).startOf("day").toISOString()) ?? null,
      });
    },
    [filters, onFiltersChanged],
  );

  return (
    <Flex sx={styles.root}>
      <Box sx={styles.sortFieldContainer}>
        <Select
          label="Order State"
          options={ORDER_STATE_OPTIONS}
          value={filters.orderState?.toString() ?? ""}
          onChange={handleOrderStateChange}
        />
      </Box>
      <Flex sx={{ alignItems: "center" }}>
        <CheckboxWithLabel
          label={<Text variant="subHeading">Assigned to me</Text>}
          checked={!!filters.assignedToMe}
          onChange={handleAssignToMeChange}
        />
      </Flex>
      <Flex sx={styles.dateContainer}>
        <Text variant="subHeading" sx={styles.dateText}>
          Date Order Taken
        </Text>
        <DateInput value={filters.dateOrderTaken} onChange={handleDateChange} identifier="dateOrderTaken" />
      </Flex>
      <Flex sx={styles.dateContainer}>
        <Text variant="subHeading" sx={styles.dateText}>
          Date Work to be Done
        </Text>
        <DateInput value={filters.dateWorkToBeDone} onChange={handleDateChange} identifier="dateWorkToBeDone" />
      </Flex>
    </Flex>
  );
};

export default OrderListControl;
