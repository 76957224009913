import { identity, isEqual, cloneDeep, intersectionWith, differenceWith } from "lodash";
import { generateNewPassword } from "../../core/common/passwordGenerator";
import { Id } from "../../core/common/type";
import noteRepository from "../../core/note/NoteRepository";
import { IChangePasswordToServer } from "../../core/user/ServerDto";
import userRepository from "../../core/user/UserRepository";
import { EMPTY_WORKER } from "../../core/worker/consts";
import { IListWorker, IWorker } from "../../core/worker/IWorker";
import {
    INewPostCodeToServer,
    INewWorkerToServer,
    IRemovePostCodeToServer,
    IWorkerDetailsToServer,
    IWorkerListFiltersToServer,
    IWorkerServiceFromServer,
    IWorkerServicesToServer,
    IWorkerServiceToServer,
    IWorkerSort,
} from "../../core/worker/ServerDto";
import workerRepository from "../../core/worker/WorkerRepository";
import { IWorkerListFilters, IWorkerListSort } from "./WorkerList/types";

class WorkerPresenter {
    getWorkers(filters: IWorkerListFilters, sort?: IWorkerListSort): Promise<IListWorker[]> {
        const workerFilters: IWorkerListFiltersToServer = {
            Name: "",
            EmailAddress: "",
        };
        if (filters.searchValue) {
            workerFilters.CombinedFilter = filters.searchValue;
        }

        const workerSort: IWorkerSort | undefined =
            sort?.sortField && sort.sortOrder
                ? {
                      SortBy: sort.sortField,
                      OrderBy: sort.sortOrder,
                  }
                : undefined;

        return workerRepository.getList(workerFilters, workerSort);
    }

    async createNewWorker(): Promise<IWorker> {
        const worker = cloneDeep(EMPTY_WORKER);
        worker.AccountDetails.IsActive = true;
        worker.AccountDetails.SendEmailNotification = true;
        try {
            worker.Services = await workerRepository.getServices();
        } catch (err) {
            throw new Error("Couldn't get worker services!");
        }
        return worker;
    }

    async getWorker(workerUserId: Id): Promise<IWorker> {
        const [mainData, services, notes, portfolio] = await Promise.all([
            workerRepository.getData(workerUserId),
            workerRepository.getServices(workerUserId),
            workerRepository.getNotes(workerUserId),
            workerRepository.getPortfolio(workerUserId),
        ]);

        if (!mainData || !services || !notes) {
            throw new Error("Couldn't get worker data!");
        }

        const serverAccountDetails = mainData.AccountDetails!;

        return {
            ...mainData,
            WorkerDetails: {
                ...mainData.WorkerDetails!,
            },
            AccountDetails: {
                Id: workerUserId,
                UserName: serverAccountDetails.Name,
                Email: serverAccountDetails.AccountsEmailAddress,
                DaysToPayInvoices: serverAccountDetails.DaysToPayInvoices,
                FirstName: serverAccountDetails.FirstName,
                LastName: serverAccountDetails.LastName,
                OldPassword: null,
                Password: serverAccountDetails.AccountPassword,
                ConfirmPassword: null,
                SendEmailNotification: serverAccountDetails.SendEmailNotification,
                IsActive: serverAccountDetails.IsActive,
                PhoneNumber: serverAccountDetails.AccountPhoneNumber,
                IsAppliedForBusiness: serverAccountDetails.IsAppliedForBusiness,
                CustomerType: serverAccountDetails.CustomerType,
                Avatar: serverAccountDetails.Avatar,
                AvatarThumb: serverAccountDetails.AvatarThumb,
            },
            Postcodes: mainData.Postcodes ?? [],
            Services: services,
            Notes: notes,
            Portfolio: portfolio,
        };
    }

    async saveWorker(newWorker: IWorker, oldWorker: IWorker): Promise<void> {
        if (!isEqual(newWorker.WorkerDetails, oldWorker.WorkerDetails)) {
            const details = newWorker.WorkerDetails;
            const workerDetailsData: IWorkerDetailsToServer = {
                ...details,
                GoogleCalendarId: details?.GoogleCalendarId ?? "",
                Notes: details?.Notes ?? undefined,
                PhoneNumber: details?.PhoneNumber ?? undefined,
                Rating: details?.Rating ?? undefined,
                FullTimeType: details?.FullTimeType ?? undefined,
            };
            await workerRepository.updateDetails(newWorker.AccountDetails.Id!, workerDetailsData);
        }

        if (!isEqual(newWorker.AccountDetails, oldWorker.AccountDetails)) {
            await userRepository.updateAccountDetails(newWorker.AccountDetails, newWorker.AccountDetails.Id!);
        }

        const newPasswordInfo = getNewPasswordInfo(newWorker);
        if (newPasswordInfo) {
            await userRepository.changePassword(newPasswordInfo);
        }

        if (!isEqual(newWorker.Services, oldWorker.Services)) {
            const workerServicesData: IWorkerServicesToServer = {
                Services: toServerServices(newWorker.Services),
            };
            await workerRepository.updateServices(newWorker.AccountDetails.Id!, workerServicesData);
        }

        if (!isEqual(newWorker.Postcodes, oldWorker.Postcodes)) {
            const changedPostCodes = intersectionWith(
                newWorker.Postcodes,
                oldWorker.Postcodes,
                (serverPostCode, workerPostCode) =>
                    serverPostCode.WorkerPostcodeId === workerPostCode.WorkerPostcodeId &&
                    !isEqual(serverPostCode, workerPostCode),
            );

            const removedPostCodes = differenceWith(
                oldWorker.Postcodes,
                newWorker.Postcodes,
                (serverPostCode, workerPostCode) => serverPostCode.WorkerPostcodeId === workerPostCode.WorkerPostcodeId,
            );

            const addedPostCodes = newWorker.Postcodes.filter(postcode => !postcode.WorkerId);

            await Promise.all(
                changedPostCodes.map(postCode =>
                    workerRepository.removePostCode((postCode as unknown) as IRemovePostCodeToServer),
                ),
            );
            await Promise.all(
                changedPostCodes.map(postCode =>
                    workerRepository.addPostCode((postCode as unknown) as INewPostCodeToServer),
                ),
            );

            await Promise.all(
                removedPostCodes.map(postCode =>
                    workerRepository.removePostCode((postCode as unknown) as IRemovePostCodeToServer),
                ),
            );

            await Promise.all(
                addedPostCodes.map(postCode =>
                    workerRepository.addPostCode({
                        WorkerId: newWorker.WorkerId!,
                        Postcode: postCode.Postcode!,
                    }),
                ),
            );
        }

        const newNotes = newWorker.Notes.filter(note => !note.NoteId);
        if (newNotes) {
            await Promise.all(
                newNotes.map(note => {
                    return noteRepository.addNote({
                        Content: note.Content ?? "",
                        WorkerId: newWorker.WorkerId,
                    });
                }),
            );
        }
    }

    async addWorker(worker: IWorker): Promise<void> {
        if (!worker.AccountDetails?.FirstName) {
            throw Error("Please input first name!");
        }
        if (!worker.AccountDetails?.LastName) {
            throw Error("Please input last name!");
        }
        if (!worker.AccountDetails?.Email) {
            throw Error("Please input email!");
        }
        if (!worker.AccountDetails?.UserName) {
            throw new Error("Please input login!");
        }
        if (!worker.WorkerDetails?.PhoneNumber) {
            throw new Error("Please input phone!");
        }
        if (!worker.AccountDetails.Password) {
            throw new Error("Please input password!");
        }
        if (!worker.AccountDetails.ConfirmPassword) {
            throw new Error("Please confirm password!");
        }
        if (worker.AccountDetails.Password !== worker.AccountDetails.ConfirmPassword) {
            throw new Error("Passwords don't match!");
        }

        const newWorkerData: INewWorkerToServer = {
            FirstName: worker.AccountDetails.FirstName,
            LastName: worker.AccountDetails.LastName,
            Email: worker.AccountDetails.Email,
            Username: worker.AccountDetails.UserName,
            HouseName: worker.WorkerDetails.HouseName ?? undefined,
            HouseNumber: worker.WorkerDetails.HouseNumber ?? undefined,
            Road: worker.WorkerDetails.Road ?? undefined,
            Area: worker.WorkerDetails.Area ?? undefined,
            Town: worker.WorkerDetails.Town ?? undefined,
            Country: worker.WorkerDetails.Country ?? undefined,
            Postcode: worker.WorkerDetails.Postcode ?? undefined,
            PhoneNumber: worker.WorkerDetails.PhoneNumber,
            AccountPhoneNumber: worker.AccountDetails.PhoneNumber ?? undefined,
            GoogleCalendarId: worker.WorkerDetails?.GoogleCalendarId ?? undefined,
            IsActive: !!worker.AccountDetails.IsActive,
            SendEmailNotification: !!worker.AccountDetails.SendEmailNotification,
            Password: worker.AccountDetails.Password,
            ConfirmPassword: worker.AccountDetails.ConfirmPassword,
            WorkerTypeId: 0,
            FullTimeType: worker.WorkerDetails.FullTimeType ?? undefined,
            Rating: worker.WorkerDetails.Rating ?? undefined,
            Services: toServerServices(worker.Services),
        };
        const workerId = await workerRepository.create(newWorkerData);

        await Promise.all(
            worker.Postcodes.filter(postCode => postCode.Postcode!).map(postCode =>
                workerRepository.addPostCode({
                    WorkerId: workerId,
                    Postcode: postCode.Postcode!,
                }),
            ),
        );

        await Promise.all(
            worker.Notes.filter(note => note.Content).map(note =>
                noteRepository.addNote({
                    WorkerId: workerId,
                    Content: note.Content!,
                }),
            ),
        );
    }

    deleteWorker(workerId: Id) {
        return workerRepository.delete(workerId);
    }
}

function getNewPasswordInfo(worker: IWorker): IChangePasswordToServer | undefined {
    const accountDetails = worker.AccountDetails;
    if (accountDetails.Id && accountDetails.OldPassword && accountDetails.Password && accountDetails.ConfirmPassword) {
        return {
            UserId: accountDetails.Id,
            OldPassword: accountDetails.OldPassword,
            NewPassword: accountDetails.Password,
            ConfirmPassword: accountDetails.ConfirmPassword,
        };
    }
}

function toServerServices(services: IWorkerServiceFromServer[]): IWorkerServiceToServer[] {
    return services
        .map(
            service =>
                !!service.OrderTypeId && {
                    ...service,
                },
        )
        .filter(identity) as IWorkerServiceToServer[];
}

const workerPresenter = new WorkerPresenter();
export default workerPresenter;
