/** @jsx jsx */
import React, { useCallback, useEffect } from "react";
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom";
import { jsx, Flex } from "theme-ui";
import { ITabbedEditorProps, ITabbedEditorTabInfo } from "./TabbedEditor";
import { SimpleTabs } from "./Tabs";

interface IRouteParams {
  tabId: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface ITabbedEditorWithRoutesProps<TTab extends string, TData extends object>
  extends Pick<ITabbedEditorProps<TTab, TData>, "tabs" | "data" | "onDataChanged"> {
  baseUrl: string;
  basePath: string;
  onTabChanged: (newTabId: string) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TabbedEditorWithRoutes = <TTab extends string, TData extends object>({
  tabs,
  data,
  onDataChanged,
  basePath,
  baseUrl,
  onTabChanged,
}: ITabbedEditorWithRoutesProps<TTab, TData>): React.ReactElement => {
  const {
    params: { tabId },
  } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  useEffect(() => {
    onTabChanged(tabId);
  }, [onTabChanged, tabId]);

  const handleTabSelected = useCallback(
    (newTabId: string) => {
      history.push(`${baseUrl}/${newTabId}`);
    },
    [baseUrl, history],
  );

  const tabsArray = Object.values<ITabbedEditorTabInfo<TData>>(tabs);

  return (
    <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
      <Flex sx={{ justifyContent: "center" }}>
        <SimpleTabs tabs={tabsArray} value={tabId} onSelect={handleTabSelected} />
      </Flex>
      <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
        <Switch>
          {tabsArray.map(tab => (
            <Route path={`${basePath}/${tab.id}`} key={tab.id}>
              <tab.component data={data} onDataChanged={onDataChanged} {...(tab.componentProps ?? {})} />
            </Route>
          ))}
        </Switch>
      </Flex>
    </Flex>
  );
};

export default TabbedEditorWithRoutes;
