/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, SxStyleProp, Flex } from "theme-ui";
import { noop, clone } from "lodash";
import { ICustomer, ICustomerNote } from "../../../../core/customer/ICustomer";
import { EMPTY_NOTE } from "../../../../core/note/consts";
import AddButton from "../../../components/AddButton";
import CardList from "../../../components/CardList";
import NoteCard from "../../../notes/NoteCard";

interface INotesProps {
  customer: ICustomer;
  onChanged?: (changes: Partial<ICustomer>) => void;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-start",
    marginTop: "24px",
  },
  cardList: {
    flexGrow: 1,
    marginTop: "16px",
    width: "100%",
  },
};

const Notes: React.FC<INotesProps> = ({ customer, onChanged = noop }) => {
  const handleAddNoteClick = useCallback(() => {
    onChanged({
      Notes: customer.Notes.concat([clone(EMPTY_NOTE)]),
    });
  }, [customer.Notes, onChanged]);

  const handleNoteChange = useCallback(
    (changes: Partial<ICustomerNote>, noteIndex: number) => {
      const newNotes = clone(customer.Notes);
      newNotes[noteIndex] = {
        ...newNotes[noteIndex],
        ...changes,
      };
      onChanged({
        Notes: newNotes,
      });
    },
    [customer.Notes, onChanged],
  );

  return (
    <Flex sx={styles.root}>
      <AddButton title="Add Note" onClick={handleAddNoteClick} />
      <CardList
        sx={styles.cardList}
        cards={customer.Notes.map((note, i) => (
          <NoteCard key={note.NoteId ?? `__${i}`} note={note} onChanged={handleNoteChange} position={i} />
        ))}
      />
    </Flex>
  );
};

export default Notes;
