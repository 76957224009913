/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { merge } from "lodash";
import StarIcon from "../icons/Star";
import { Styles } from "../theme/utils";

interface IRatingProps {
  className?: string;
  value?: number;
  onChange?: (value: number) => void;
  canEdit?: boolean;
  size?: "default" | "small";
}

const styles: Styles = {
  emptyIcon: {
    color: "gray",
  },
  filledIcon: {
    color: "primary",
  },
  defaultSize: {
    width: "28px",
    height: "28px",
    marginRight: "12px",
  },
  smallSize: {
    width: "14px",
    height: "14px",
    marginRight: "6px",
  },
};

const Rating: React.FC<IRatingProps> = ({ className, value = 0, onChange, canEdit, size = "default" }) => {
  const sizeStyle = styles[`${size}Size`];

  return (
    <div className={className}>
      <ReactStars
        value={value}
        count={5}
        size={0}
        onChange={onChange}
        emptyIcon={<StarIcon sx={merge({}, styles.emptyIcon, sizeStyle)} />}
        filledIcon={<StarIcon sx={merge({}, styles.filledIcon, sizeStyle)} />}
        edit={canEdit}
      />
    </div>
  );
};

export default Rating;
