/** @jsx jsx */
import { merge } from "lodash";
import React, { useCallback, useState } from "react";
import ReactModal from "react-modal";
import { Flex, jsx } from "theme-ui";
import { IDetailedOrder, INote, OrderState } from "../../../../core/order/IDetailedOrder";
import { IUserFromServer } from "../../../../core/user/ServerDto";
import { useAppSelector } from "../../../../store/store";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import PhoneInputField from "../../../components/PhoneInputField";
import Select, { ISelectOption } from "../../../components/Select";
import { formMargin } from "../../../theme/globalStyles";
import { Styles } from "../../../theme/utils";
import UserList from "../../../users/UserList";
import orderPresenter from "../../OrderPresenter";
import OrderNotes from "../components/OrderNotes";

interface IAccountTabProps {
  order: IDetailedOrder;
  orderNotes: Readonly<Array<INote>>;
  onOrderChanged: (newOrderState: IDetailedOrder) => void;
  onOrderNotesChanged: (newNotesState: INote[]) => void;
}

const styles: Styles = {
  root: {
    flexGrow: 1,
    flexDirection: "column",
  },
  maintainerBlock: {
    alignItems: "flex-end",
  },
};

const STATUS_OPTIONS: ISelectOption[] = [
  toStatusOption(OrderState.DateAgreement),
  toStatusOption(OrderState.BeingVerified),
  toStatusOption(OrderState.BeingExecuted),
  toStatusOption(OrderState.WorkDone),
  toStatusOption(OrderState.ContentSelected),
  toStatusOption(OrderState.AwaitingForAdditionalPayment),
  toStatusOption(OrderState.OrderPaid),
  toStatusOption(OrderState.ReturnForAdditionalWork),
];

function toStatusOption(state: OrderState): ISelectOption {
  return {
    value: state.toString(),
    label: orderPresenter.orderStatusToString(state),
  };
}

const AccountTab: React.FC<IAccountTabProps> = ({ order, orderNotes, onOrderChanged, onOrderNotesChanged }) => {
  const account = order.AccountDetails;
  const maintainer = order.Maintainer;

  const maintainerName = `${maintainer?.FirstName ?? ""} ${maintainer?.LastName ?? ""}`;

  const [userListState, setUserListState] = useState({
    isOpen: false,
    users: [] as IUserFromServer[],
  });

  const handleUserListCloseRequest = useCallback(() => {
    setUserListState({
      isOpen: false,
      users: [],
    });
  }, []);

  const handleUserSelect = useCallback(
    (user: IUserFromServer) => {
      setUserListState({
        isOpen: false,
        users: [],
      });
      onOrderChanged({
        ...order,
        Maintainer: user,
      });
    },
    [onOrderChanged, order],
  );

  const handleFindMaintainerClick = useCallback(async () => {
    const maintainers = await orderPresenter.findMaintainers();
    setUserListState({
      isOpen: true,
      users: maintainers,
    });
  }, []);

  const user = useAppSelector(store => store.user);

  const handleTakeMaintenanceClick = useCallback(() => {
    const currentUser = user;
    if (!currentUser.Id) return;
    onOrderChanged({
      ...order,
      Maintainer: currentUser,
    });
  }, [onOrderChanged, order, user]);

  const handleOrderStateChange = useCallback(
    (newValue: string | undefined | null) => {
      const state: OrderState = Number.parseInt(newValue!, 10);
      onOrderChanged({
        ...order,
        OrderState: state,
      });
    },
    [onOrderChanged, order],
  );

  return (
    <Flex sx={styles.root}>
      <Flex>
        <InputField label="Login" value={account.Name ?? ""} disabled />
        <InputField
          sx={formMargin.rowElement}
          label="Email Address"
          value={account.AccountsEmailAddress ?? ""}
          disabled
        />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField label="First Name" value={account.FirstName ?? ""} disabled />
        <InputField sx={formMargin.rowElement} label="Last Name" value={account.LastName ?? ""} disabled />
      </Flex>
      <Flex sx={formMargin.row}>
        <PhoneInputField label="Account phone number" value={account.AccountPhoneNumber ?? ""} disabled />
      </Flex>
      <Flex sx={formMargin.row}>
        <InputField label="Days to Pay Invoices" value={account.DaysToPayInvoices ?? ""} disabled />
      </Flex>
      <Flex sx={formMargin.row}>
        <Select
          label="Status"
          options={STATUS_OPTIONS}
          value={order.OrderState?.toString() ?? ""}
          onChange={handleOrderStateChange}
        />
      </Flex>
      <Flex sx={merge({}, formMargin.row, styles.maintainerBlock)}>
        <InputField label="Maintainer" value={maintainerName} disabled />
        <Button
          sx={formMargin.rowElement}
          variant="primaryOutlineSmall"
          title="Find"
          onClick={handleFindMaintainerClick}
        />
        <Button
          sx={formMargin.rowElement}
          variant="primaryOutlineSmall"
          title="Take"
          onClick={handleTakeMaintenanceClick}
        />
      </Flex>
      <OrderNotes sx={formMargin.row} notes={orderNotes} onOrderNotesChanged={onOrderNotesChanged} />
      <ReactModal isOpen={userListState.isOpen} onRequestClose={handleUserListCloseRequest}>
        <UserList users={userListState.users} onSelect={handleUserSelect} />
      </ReactModal>
    </Flex>
  );
};

export default AccountTab;
