/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { Flex, Box, jsx, Card } from "theme-ui";
import { Id } from "../../core/common/type";
import { IListOrder } from "../../core/order/IListOrder";
import OrderList from "../orders/OrderList/OrderList";
import { IInvoiceOrderFilters, IInvoiceOrderSort } from "./types";
import InvoiceListControl from "./InvoiceListControl";
import InvoicesHeader from "./InvoicesHeader";

interface IInvoicesPageContentProps {
  orders: IListOrder[];
  onGenerateInvoicesClick: (orderIds: Set<Id>) => void;
  filters: IInvoiceOrderFilters;
  onFiltersChange: (newFiltersState: IInvoiceOrderFilters) => void;
  sort: IInvoiceOrderSort;
  onSortChange: (newSortState: IInvoiceOrderSort) => void;
}

const InvoicesPageContent: React.FC<IInvoicesPageContentProps> = ({
  orders,
  onGenerateInvoicesClick,
  filters,
  onFiltersChange,
  sort,
  onSortChange,
}) => {
  const [selected, setSelected] = useState(new Set<Id>());

  const handleSearch = useCallback(
    (searchValue: string) => {
      onFiltersChange({
        ...filters,
        searchValue,
      });
    },
    [filters, onFiltersChange],
  );

  const handleOrderSelect = useCallback(
    (id: Id, value: boolean) => {
      let newSelected = selected;
      if (value && !selected.has(id)) {
        newSelected = new Set(selected);
        newSelected.add(id);
      } else if (!value && selected.has(id)) {
        newSelected = new Set(selected);
        newSelected.delete(id);
      }
      setSelected(newSelected);
    },
    [selected],
  );

  const handleSelectAllChange = useCallback(
    (value: boolean) => {
      let newSelected: Set<Id>;
      if (value) {
        newSelected = new Set(orders.map(getId));
      } else {
        newSelected = new Set();
      }
      setSelected(newSelected);
    },
    [orders],
  );

  const handleGenerateInvoicesClick = useCallback(() => {
    onGenerateInvoicesClick(selected);
  }, [onGenerateInvoicesClick, selected]);

  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <InvoicesHeader onSearch={handleSearch} onGenerateInvoicesClick={handleGenerateInvoicesClick} />
      </Box>
      <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} variant="paper">
          <InvoiceListControl
            selectAll={isAllSelected(orders, selected)}
            onSelectAllChange={handleSelectAllChange}
            filters={filters}
            onFiltersChange={onFiltersChange}
            sort={sort}
            onSortChange={onSortChange}
          />
          <OrderList orders={orders} selectable selected={selected} onSelect={handleOrderSelect} />
        </Card>
      </Flex>
    </Flex>
  );
};

function isAllSelected(orders: IListOrder[], selected: Set<Id>): boolean {
  return orders.length > 0 && orders.length === selected.size;
}

function getId(order: IListOrder): Id {
  return order.OrderId!;
}

export default InvoicesPageContent;
