/** @jsx jsx */
import React from "react";
import { Box, Card, Flex, jsx, SxStyleProp, Text } from "theme-ui";
import { makeStyles } from "@material-ui/core/styles";
import { IPackage } from "../../../core/package/IPackage";
import SelectedIcon from "../../components/SelectedIcon";

interface IGeneratePackageGroupCardProps {
  className?: string;
  title: string;
  packages: IPackage[];
  onClick: (pkg: IPackage) => () => void;
  selectedOrderTemplateId?: number;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    padding: "8px 16px",
    borderColor: "lightGray",
    minHeight: "60vh",
  },
  firstRow: {
    paddingBottom: "8px",
    borderBottom: "1px solid",
    borderBottomColor: "lightGray",
  },
  row: {
    borderBottom: "1px solid",
    borderBottomColor: "lightGray",
    paddingRight: "4px",
    alignItems: "center",
    minHeight: 32,
  },
  checkboxWithLabel: {
    flexGrow: 1,
  },
  label: {
    flexGrow: 1,
  },
  titleBlock: {
    flexGrow: 1,
    alignItems: "center",
  },
  price: {
    flexBasis: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const useStyles = makeStyles({
  selectedIconBlock: {
    width: 34,
    display: "flex",
    justifyContent: "center",
    flexShrink: 0,
  },
});

const GeneratePackageGroupCard: React.FC<IGeneratePackageGroupCardProps> = ({
  className,
  title,
  packages,
  onClick,
  selectedOrderTemplateId,
}) => {
  const classes = useStyles();
  return (
    <Card sx={styles.root} className={className}>
      <Box sx={styles.firstRow}>
        <Text variant="accentSubHeading">{title}</Text>
      </Box>
      {packages.map(pkg => {
        return (
          <Flex onClick={onClick(pkg)} sx={styles.row} key={pkg.Id ?? ""}>
            <Flex sx={styles.label}>
              <Flex sx={styles.titleBlock}>
                <div className={classes.selectedIconBlock}>
                  {selectedOrderTemplateId === pkg.Id && <SelectedIcon />}
                </div>
                <Text variant={selectedOrderTemplateId === pkg.Id ? "subHeadingPrimary" : "subHeading"}>
                  {pkg.Title}
                </Text>
              </Flex>

              <Text sx={styles.price} variant="subHeading">
                {pkg.Price}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Card>
  );
};

export default GeneratePackageGroupCard;
