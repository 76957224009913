import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AdministrationRootPage from "../administrations/AdministrationRootPage";
import CustomersRootPage from "../customers/CustomersRootPage";
import DashboardPage from "../dashboard/DashboardPage";
import InvoicesPage from "../invoices/InvoicesPage";
import Login from "../login/Login";
import OrdersRootPage from "../orders/OrdersRootPage";
import PackagesPage from "../packages/PackagesPage";
import UserPage from "../user/UserPage";
import WorkersRootPage from "../workers/WorkersRootPage";

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route path="/user" component={UserPage} />
      <Route exact path="/" component={DashboardPage} />
      <Route path="/customers" component={CustomersRootPage} />
      <Route path="/orders" component={OrdersRootPage} />
      <Route path="/invoices" component={InvoicesPage} />
      <Redirect exact path="/packages" to="/packages/packages" />
      <Route exact path="/packages/:tabId" component={PackagesPage} />
      <Route path="/workers" component={WorkersRootPage} />
      <Route path="/administration" component={AdministrationRootPage} />
      <Route path="/login/:id" component={Login} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Pages;
