/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Flex, Box, Card } from "theme-ui";
import { useHistory } from "react-router-dom";
import { IListOrder } from "../../../core/order/IListOrder";
import { IOrderListFilters } from "./IOrderListFilters";
import OrderList from "./OrderList";
import OrderListControl from "./OrderListControl";
import OrderListHeader from "./OrderListHeader";
import AddButton from "../../components/AddButton";

interface IOrdersPageContentProps {
  orderCount: number;
  orders: IListOrder[];
  filters: IOrderListFilters;
  onFiltersChange: (newFiltersState: IOrderListFilters) => void;
}

const OrderListPageContent: React.FC<IOrdersPageContentProps> = ({ orderCount, orders, filters, onFiltersChange }) => {
  const history = useHistory();
  const handleSearch = useCallback(
    (searchValue: string) => {
      onFiltersChange({
        ...filters,
        searchValue,
      });
    },
    [filters, onFiltersChange],
  );

  const handleAddOrderClick = useCallback(() => {
    history.push("/orders/add");
  }, [history]);

  return (
    <Flex sx={{ flexDirection: "column", flexGrow: 1 }}>
      <Box sx={{ height: "80px", marginBottom: 3 }}>
        <OrderListHeader orderCount={orderCount} onSearch={handleSearch} />
      </Box>
      <Flex sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }} variant="paper">
          <OrderListControl filters={filters} onFiltersChanged={onFiltersChange} />
          <Flex sx={{ marginTop: 16 }}>
            <AddButton title="Add Order" onClick={handleAddOrderClick} />
          </Flex>
          <OrderList sx={{ marginTop: 3 }} orders={orders} />
        </Card>
      </Flex>
    </Flex>
  );
};

export default OrderListPageContent;
