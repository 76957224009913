/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Card, Flex, Box, SxStyleProp, Text } from "theme-ui";
import { ICustomerContact } from "../../../../core/customer/ICustomer";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import PhoneInputField from "../../../components/PhoneInputField";

interface IContactCardProps {
  contact: ICustomerContact;
  onChanged: (changes: Readonly<Partial<ICustomerContact>>, contactIndex: number) => void;
  onRemoveClick: (contactIndex: number) => void;
  position: number;
}

const styles: Record<string, SxStyleProp> = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderColor: "lightGray",
  },
  inputsContainer: {
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > *": {
      flexBasis: "calc(50% - 8px)",
      flexShrink: 0,
      marginTop: "16px",
      marginBottom: "32px",
    },
  },
  removeButtonContainer: {
    justifyContent: "flex-end",
  },
};

const ContactCard: React.FC<IContactCardProps> = ({ contact, onChanged, onRemoveClick, position }) => {
  const callOnChanged = useCallback(
    (fieldName: string, value: string) => {
      onChanged(
        {
          ContactId: contact.ContactId,
          [fieldName]: value,
        },
        position,
      );
    },
    [contact.ContactId, onChanged, position],
  );

  const handlePhoneInputChange = useCallback(
    (value: string, fieldName?: string) => {
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { fieldName } = event.currentTarget.dataset;
      const { value } = event.currentTarget;
      callOnChanged(fieldName!, value);
    },
    [callOnChanged],
  );

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(position);
  }, [position, onRemoveClick]);

  return (
    <Card sx={styles.root}>
      <Box>
        <Text variant="subHeading">Contact</Text>
        <Flex sx={styles.inputsContainer}>
          <InputField
            label="Contact Name"
            variant="fullWidth"
            value={contact.Name ?? ""}
            data-field-name="Name"
            onChange={handleInputChange}
          />
          <InputField
            label="Email Address"
            type="email"
            variant="fullWidth"
            value={contact.EmailAddress ?? ""}
            data-field-name="EmailAddress"
            onChange={handleInputChange}
          />
          <PhoneInputField
            label="Phone Number 1"
            variant="fullWidth"
            value={contact.PhoneNumber1 ?? ""}
            id="PhoneNumber1"
            onChange={handlePhoneInputChange}
          />
          <PhoneInputField
            label="Phone Number 2"
            variant="fullWidth"
            value={contact.PhoneNumber2 ?? ""}
            id="PhoneNumber2"
            onChange={handlePhoneInputChange}
          />
        </Flex>
      </Box>
      <Flex sx={styles.removeButtonContainer}>
        <Button title="Remove contact" variant="dangerOutline" onClick={handleRemoveClick} />
      </Flex>
    </Card>
  );
};

export default ContactCard;
