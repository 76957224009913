import { isEqual } from "lodash";
import { Id, IDto, IDtoObject, TPrimitiveType } from "../../core/common/type";
import noteRepository from "../../core/note/NoteRepository";
import {
    IDetailedOrder,
    IOfferedService,
    IOrderUpload,
    IOrderService,
    OrderState,
    INote,
} from "../../core/order/IDetailedOrder";
import { IListOrder } from "../../core/order/IListOrder";
import orderRepository from "../../core/order/OrderRepository";
import unmaintainedOrdersService from "../../core/order/UnmaintainedOrdersService";
import { toPackage } from "../../core/package/convert";
import { IPackage } from "../../core/package/IPackage";
import packageRepository from "../../core/package/PackageRepository";
import uploader from "../../core/upload/uploader";
import { IUserFromServer } from "../../core/user/ServerDto";
import { IListWorkerFromServer } from "../../core/worker/ServerDto";
import { IGenerateOrderToServer, OrderContactType } from "../../core/order/ServerDto";

const ORDER_STATUS_TEXT: Record<OrderState, string> = {
    [OrderState.DateAgreement]: "Awaiting Confirmation of Date/Time",
    [OrderState.BeingVerified]: "Booking in Progress",
    [OrderState.BeingExecuted]: "Booking Placed",
    [OrderState.WorkDone]: "Work Completed",
    [OrderState.ContentSelected]: "Complete and awaiting for payment (content selected)",
    [OrderState.AwaitingForAdditionalPayment]: "Awaiting additional Payment",
    [OrderState.OrderPaid]: "Completed and paid",
    [OrderState.ReturnForAdditionalWork]: "Return for Additional Work",
};

const ORDER_STATUS_PROGRESS: Record<OrderState, number> = {
    [OrderState.DateAgreement]: 0,
    [OrderState.BeingVerified]: 0,
    [OrderState.BeingExecuted]: 1,
    [OrderState.WorkDone]: 2,
    [OrderState.ContentSelected]: 3,
    [OrderState.AwaitingForAdditionalPayment]: 3,
    [OrderState.OrderPaid]: 4,
    [OrderState.ReturnForAdditionalWork]: 1,
};

const ORDER_CONTACT_TYPE_TEXT: Record<OrderContactType, string> = {
    [OrderContactType.Agent]: "Agent",
    [OrderContactType.Tenant]: "Tenant",
    [OrderContactType.Vendor]: "Vendor",
};

class OrderPresenter {
    getOrders(filters: Record<string, TPrimitiveType> = {}): Promise<IListOrder[]> {
        return orderRepository.getFilteredList(filters);
    }

    getOrder(orderId: Id): Promise<IDetailedOrder> {
        return orderRepository.getDetailedOrderData(orderId);
    }

    getOrderNotes(orderId: Id): Promise<INote[]> {
        return orderRepository.getNotes(orderId);
    }

    async getDefaultPackages(): Promise<IPackage[]> {
        const pkgs = await packageRepository.getPackages();
        return pkgs.map(toPackage);
    }

    async getPackages(customerId: string): Promise<IPackage[]> {
        const packages = await packageRepository.getPackages(customerId);
        return packages.map(toPackage);
    }

    async getPossiblePackages(branchId: Id): Promise<IPackage[]> {
        const packages = await orderRepository.getPossiblePackages(branchId);
        return packages.map(toPackage) || [];
    }

    generateOrder(data: IGenerateOrderToServer): Promise<string> {
        return orderRepository.generateOrder(data);
    }

    changePackage(orderId: Id, packageId: Id): Promise<void> {
        return orderRepository.changeTemplate(orderId, packageId);
    }

    async saveOrder(
        newOrder: IDetailedOrder,
        oldOrder: IDetailedOrder,
        newOrderNotes: INote[],
        oldOrderNotes: INote[],
    ): Promise<void> {
        if (!isEqual(newOrder, oldOrder)) {
            await orderRepository.setDetailedOrderData({
                ...newOrder,
                Maintainer: newOrder.Maintainer
                    ? newOrder.Maintainer
                    : (({
                          Id: "",
                          ApplicationUserId: "",
                          FirstName: "",
                          LastName: "",
                      } as unknown) as IUserFromServer),
            });
        }

        if (!isEqual(newOrderNotes, oldOrderNotes)) {
            const addedNotes = newOrderNotes.filter(note => !note.NoteId);
            await Promise.all(
                addedNotes.map(note =>
                    noteRepository.addNote({
                        Content: note.Content ?? "",
                        OrderId: newOrder.OrderId,
                    }),
                ),
            );
        }
    }

    deleteOrder(orderId: Id): Promise<void> {
        return orderRepository.delete(orderId);
    }

    uploadPhotos(orderServiceId: Id, files: File[], onFileUploaded: (updatedService: IOrderService) => void): void {
        uploader.addOrderPhoto(files, orderServiceId, service => onFileUploaded(service as IOrderService));
    }

    uploadOfferedServicePhotos(
        offeredServiceId: Id,
        files: File[],
        onFileUploaded: (updatedService: IOfferedService) => void,
    ): void {
        uploader.addOfferedServiceFile(files, offeredServiceId, service => onFileUploaded(service as IOfferedService));
    }

    uploadPlans(orderServiceId: Id, files: File[], onFileUploaded: (updatedService: IOrderService) => void): void {
        uploader.addOrderPlan(files, orderServiceId, service => onFileUploaded(service as IOrderService));
    }

    async downloadOrderFiles(orderId: Id, resolutions: IDtoObject[], fileIds?: Id[], serviceData?: IDto) {
        const response = await orderRepository.downloadOrderFiles(orderId, resolutions, fileIds, serviceData);
        const dlPath = `${orderRepository.ORDER_PATH}/download-archive/${response.FileDownloadRequestID}`;
        window.open(dlPath, "_self");
    }

    deleteServiceFiles(orderServiceId: Id, files: IOrderUpload[]): Promise<IOrderService> {
        return orderRepository.deletePhotos({ isOffered: false, files }, orderServiceId) as Promise<IOrderService>;
    }

    deleteOfferedServiceFiles(offeredServiceId: Id, files: IOrderUpload[]): Promise<IOfferedService> {
        return orderRepository.deletePhotos({ isOffered: true, files }, offeredServiceId) as Promise<IOfferedService>;
    }

    updateUnmaintainedOrdersCount(): Promise<void> {
        return unmaintainedOrdersService.updateCount();
    }

    notifyCustomer(orderServiceId: Id): Promise<string> {
        return orderRepository.notifyCustomer(orderServiceId);
    }

    findMaintainers(filter = ""): Promise<IUserFromServer[]> {
        return orderRepository.findMaintainer({ filter });
    }

    findWorkers(filter = ""): Promise<IListWorkerFromServer[]> {
        return orderRepository.findWorker({ filter });
    }

    assignWorker(orderServiceId: Id, workerId: Id): Promise<void> {
        return orderRepository.reAssignWorker(orderServiceId, workerId);
    }

    orderStatusToString(orderStatus: OrderState): string {
        return ORDER_STATUS_TEXT[orderStatus];
    }

    orderStatusToProgress(orderStatus: OrderState): number {
        return ORDER_STATUS_PROGRESS[orderStatus];
    }

    contactTypeToString(type: OrderContactType): string {
        return ORDER_CONTACT_TYPE_TEXT[type];
    }
}

const orderPresenter = new OrderPresenter();
export default orderPresenter;
